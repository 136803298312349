import React from 'react';
import {
  Checkbox,
  FormGroup,
  TextField,
  FormControlLabel,
} from '@mui/material';
import { useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import { REASON_OF_CANCELLATION } from '../model/constants';
import {
  CheckboxWrapper,
  CheckboxContainer,
  Subtitle,
} from '../cancel-subscription-form.elements';
import { CancelSubscriptionFormValues } from '../model/types';

function CancelReasons() {
  const { t } = useTranslation();

  const { values, handleChange } =
    useFormikContext<CancelSubscriptionFormValues>() || {};

  return (
    <>
      <Subtitle variant="subtitle2">
        {t('cancelSubscription.cancelationReasonText')}
      </Subtitle>
      <CheckboxWrapper>
        <FormGroup data-testid="reason-container">
          {REASON_OF_CANCELLATION.map((item) => (
            <CheckboxContainer key={item.value}>
              <FormControlLabel
                key={item.value}
                control={
                  <Checkbox
                    color="primary"
                    name={item.value}
                    onChange={handleChange}
                  />
                }
                label={t(`cancelSubscription.reasons.${item.value}`)}
              />
              {item.textField && (
                <TextField
                  name="otherText"
                  variant="standard"
                  onChange={handleChange}
                  value={values.otherText}
                  disabled={Boolean(!values.other)}
                  placeholder={t('labels.leaveFeedbackHere')}
                />
              )}
            </CheckboxContainer>
          ))}
        </FormGroup>
      </CheckboxWrapper>
    </>
  );
}

export default CancelReasons;
