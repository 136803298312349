import { removeSessionStorage, removeLocalStorage } from '~/v3/utils/storage';
import { useSignOutMutation } from 'services/authApi';
import { useDispatch } from 'react-redux';
import { api } from 'services/api';
import config from 'config';
import RoutePathname from 'constants/route';
import {
  PANTONE_WEB_REDIRECT,
  USER_REDIRECTED_FROM_PANTONE,
} from '~/v3/constants/storage';
import useWorkspace from 'components/WorkspaceUI/useWorkspace';
import { queryClient } from '~/v3/react-query';
import useRouter from '~/v3/hooks/use-router';
import { reset as resetPalette } from 'features/PaletteWorkspace/paletteSlice';
import { getSessionStorageKey } from '~/v3/components/features/promotions/modal';
import useGetActivePromotion from '~/v3/components/features/promotions/lib/hooks/use-get-active-promotion';

function useSignOut() {
  const dispatch = useDispatch();
  const { push } = useRouter();
  const { resetWorkspaces } = useWorkspace();
  const [signOut] = useSignOutMutation();
  const { analytics } = window;

  const activePromotion = useGetActivePromotion();

  const signOutUser = async () => {
    resetWorkspaces(async () => {
      removeSessionStorage(PANTONE_WEB_REDIRECT);
      removeSessionStorage(USER_REDIRECTED_FROM_PANTONE);
      removeLocalStorage(getSessionStorageKey(activePromotion));
      removeLocalStorage(config.paletteWorkspace);

      try {
        await signOut().unwrap();
        if (analytics) {
          analytics.reset();
        }
        dispatch(api.util.resetApiState());
        dispatch(resetPalette());
        /* SSO users won't execute the following code as amplify issues a full page reload */

        queryClient.getQueryCache().clear();
        /* invalidating react-query cache after sign out */
        push(RoutePathname.auth.welcome);
      } catch (signOutError) {
        // eslint-disable-next-line no-console
        console.log({ signOutError });
      }
    });
  };

  return { signOutUser };
}

export default useSignOut;
