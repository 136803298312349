import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function ConvertNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.904494 0.955811C0.503116 0.955811 0.177734 1.29324 0.177734 1.70949V8.9949H5.59049L4.33766 7.69567C3.95923 7.30323 3.95923 6.66697 4.33766 6.27453C4.71608 5.88209 5.32962 5.88209 5.70805 6.27453L8.61508 9.28923C8.99351 9.68167 8.99351 10.3179 8.61508 10.7104L5.70805 13.7251C5.32962 14.1175 4.71608 14.1175 4.33766 13.7251C3.95923 13.3326 3.95923 12.6964 4.33766 12.3039L5.59049 11.0047H0.177734V18.2904C0.177734 18.7066 0.503116 19.044 0.904494 19.044H9.62561C10.027 19.044 10.3524 18.7066 10.3524 18.2904V1.70949C10.3524 1.29324 10.027 0.955811 9.62561 0.955811H0.904494Z"
        fill={theme.palette.icon.primary}
      />
      <path
        d="M15.1146 11.0036L19.7521 11.0036C20.1803 11.0036 20.5273 10.6565 20.5273 10.2284L20.5273 9.76901C20.5273 9.34088 20.1803 8.9938 19.7521 8.9938L15.1146 8.9938L16.3674 7.69457C16.7458 7.30213 16.7458 6.66586 16.3674 6.27343C15.989 5.88099 15.3755 5.88099 14.997 6.27343L12.09 9.28813C11.7116 9.68057 11.7116 10.3168 12.09 10.7093L14.997 13.724C15.3755 14.1164 15.989 14.1164 16.3674 13.724C16.7458 13.3315 16.7458 12.6953 16.3674 12.3028L15.1146 11.0036Z"
        fill={theme.palette.icon.secondary}
      />
    </SvgIcon>
  );
}

export default ConvertNavigationIcon;
