import React from 'react';
import { rootElementIdSelector } from '~/v3/constants/root-element-id-selector';
import CCPAPolicyContent from '~/v3/components/shared/policies/ccpa-policy.html';

function CCPAPolicy() {
  return (
    <>
      <style>
        {`[class*="appRoutes"] {
               padding: 0;
        }

        div[id="${rootElementIdSelector}"] {
          overflow: hidden;
        }
        `}
      </style>
      <iframe
        title="ccpa"
        id="ccpa"
        name="ccpa-iframe"
        srcDoc={CCPAPolicyContent}
        sandbox="allow-scripts allow-popups allow-top-navigation"
        style={{ height: '100%', width: '100%', border: 0 }}
      />
    </>
  );
}

export default CCPAPolicy;
