import { createSlice } from '@reduxjs/toolkit';

export const workspaceSlice = createSlice({
  name: 'workspace',
  initialState: {
    workingColor: null,
  },
  reducers: {
    setWorkingColor: (state, action) => {
      state.workingColor = action.payload;
    },
  },
});

export const { setWorkingColor } = workspaceSlice.actions;

export default workspaceSlice.reducer;

export const selectWorkingColor = (state) => state.workspace.workingColor;
