import { useDispatch, useSelector } from 'react-redux';
import {
  open,
  close,
  selectColor,
  selectIsOpen,
  selectAnchorEl,
  selectAnchorPosition,
  selectIsInWorkspace,
} from './colorMenuSlice';

function useColorMenu() {
  const dispatch = useDispatch();
  const color = useSelector(selectColor);
  const isOpen = useSelector(selectIsOpen);
  const anchorEl = useSelector(selectAnchorEl);
  const anchorPosition = useSelector(selectAnchorPosition);
  const isInWorkspace = useSelector(selectIsInWorkspace);

  return {
    color,
    isOpen,
    anchorEl,
    anchorPosition,
    isInWorkspace,
    open: (nextColor) => dispatch(open(nextColor)),
    close: () => dispatch(close()),
  };
}

export default useColorMenu;
