import { Breakpoint } from '~/v3/types/promotion';
import { DesktopBreakpoint, MobileBreakpoint, TabletBreakpoint } from './types';

export const mobileScreenBreakpoints: {
  [key in MobileBreakpoint]: Breakpoint;
} = {
  extension: { width: 320, height: 721 },
  iphone12Mini: { width: 360, height: 780 },
  iphoneSe: { width: 375, height: 667 },
  iphone12Pro: { width: 390, height: 844 },
  pixel7: { width: 412, height: 915 },
  iphone14ProMax: { width: 430, height: 932 },
  iphone16ProMax: { width: 460, height: 1030 },
};

export const tabletScreenBreakpoints: {
  [key in TabletBreakpoint]: Breakpoint;
} = {
  iPad: {
    width: 768,
    height: 1024,
  },
  microsoftSurfacePro8: { width: 912, height: 1368 },
  iPadPro: { width: 1024, height: 1366 },
};

export const desktopScreenBreakpoints: {
  [key in DesktopBreakpoint]: Breakpoint;
} = {
  Desktop: { width: 1100, height: 912 },
  MacBook13: { width: 1280, height: 800 },
  WSXGA: { width: 1680, height: 1050 },
  FullHD: { width: 1920, height: 1080 },
  UltraWideFullHD: { width: 2560, height: 1080 },
  QHD: { width: 2560, height: 1440 },
  UltraWideQHD: { width: 3440, height: 1440 },
  UltraWide4K: { width: 3840, height: 1600 },
  FourKUltraHD: { width: 3840, height: 2160 },
  DualQHD: { width: 5120, height: 1440 },
};
