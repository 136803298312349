import { Subscription, SubscriptionStatus } from '~/v3/types/subsription';
import { FastSpringProduct } from '~/v3/types/fastspring';
import { CancelSubscriptionFormValues } from '../../model/types';
import { REASON_OF_CANCELLATION } from '../../model/constants';

export const initialFormValues: CancelSubscriptionFormValues = {
  tehnicalIssues: false,
  tooExpensive: false,
  notWorthTheCost: false,
  didNotUseEnough: false,
  tooDifficultToUse: false,
  problemWithSupportTeam: false,
  neededForSpecificProject: false,
  jobHasChanged: false,
  other: false,
  otherText: '',
  cancelationAction: false,
  cancelationFeedback: '',
  subscriptionToBeCanceledId: undefined,
};

export const cancelationOptionInfo = new Map<FastSpringProduct, string>([
  [
    FastSpringProduct.COLOR_PREMIUM_MONTHLY,
    'cancelSubscription.cancelOnlyPremium',
  ],
  [
    FastSpringProduct.COLOR_PREMIUM_YEARLY,
    'cancelSubscription.cancelOnlyPremium',
  ],
  [
    FastSpringProduct.INSIDER_PREMIUM_MONTHLY,
    'cancelSubscription.cancelOnlyTrends',
  ],
  [
    FastSpringProduct.INSIDER_PREMIUM_YEARLY,
    'cancelSubscription.cancelOnlyTrends',
  ],
  [FastSpringProduct.MULTISEAT_YEARLY, 'cancelSubscription.cancelOnlyPremium'],
]);

export const shouldStopSyncing = (
  userSubscription: Subscription,
  cancelingSubscriptionIds: string[]
): boolean => {
  if (!userSubscription.subscriptions.length) {
    return false;
  }

  const cancelingSubscriptions = userSubscription.subscriptions.filter(
    (sub) =>
      sub.subscriptionId &&
      cancelingSubscriptionIds.includes(sub.subscriptionId)
  );

  if (cancelingSubscriptions.length === 0) {
    return false;
  }

  return cancelingSubscriptions.every(
    (sub) => sub.state === SubscriptionStatus.CANCELED
  );
};

export function shouldSelectSubscriptionsForCancelation(
  activeSubscriptions: Subscription[]
) {
  return activeSubscriptions.length > 1;
}

export function isCancelButtonDisabled(values: CancelSubscriptionFormValues) {
  return (
    REASON_OF_CANCELLATION.some((reason) => values[reason.value]) &&
    values.cancelationAction
  );
}

export function getCancelationReasons(
  values: CancelSubscriptionFormValues
): string[] {
  const cancelationReasons = Object.keys(values).filter(
    (key) => key !== 'other' && values[key as keyof typeof values] === true
  );

  if (values.other) {
    cancelationReasons.push(values.otherText);
  }

  return cancelationReasons;
}

export function getSubscriptionCancellationDetails(
  values: CancelSubscriptionFormValues
) {
  const isSubscriptionABundle =
    values.subscriptionToBeCanceledId === 'bundleSubscription';

  const subscriptionToBeCancelledId = isSubscriptionABundle
    ? ''
    : values.subscriptionToBeCanceledId;

  return { isSubscriptionABundle, subscriptionToBeCancelledId };
}

export function getSubscriptionsToBeCanceled(
  activeSubscriptions: Subscription[],
  isSubscriptionABundle: boolean,
  subscriptionToBeCancelledId?: string
): Subscription[] {
  if (isSubscriptionABundle) return activeSubscriptions;

  return activeSubscriptions.filter(
    (sub) => sub.subscriptionId === subscriptionToBeCancelledId
  );
}
