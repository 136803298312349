import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

function ListIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      viewBox="0 0 32 20"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: 32,
        height: 20,
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M5.07134 1H21.5M5.07134 7H21.5M5.07134 19H21.5M1.52184 1H1.53294M5.07134 13H21.5M1.5163 13H1.5052M1.51353 19H1.52463M1.49997 7H1.51107"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default ListIcon;
