import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  title: null,
  content: null,
  confirmLabel: null,
  cancelLabel: null,
  cancelButton: true,
  disableBackdropClick: false,
};

export const confirmSlice = createSlice({
  name: 'confirmDialog',
  initialState,
  reducers: {
    open: (state, action) => ({
      ...initialState,
      ...action.payload,
      isOpen: true,
    }),
    close: () => initialState,
  },
});

export const { open, close } = confirmSlice.actions;

export default confirmSlice.reducer;

export const selectConfirmDialog = (state) => state.confirmDialog;
