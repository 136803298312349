import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  loader: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: ({ height }) => height,
  },
}));

function Loader({ medium, big, iconSize }) {
  const getSize = () => {
    if (medium) {
      return '300px';
    }
    if (big) {
      return '100%';
    }
    return 'auto';
  };

  const classes = useStyles({
    height: getSize(),
    size: iconSize,
  });

  return (
    <div
      className={classes.loader}
      data-id="circular-progress"
      data-testid="loader"
    >
      <CircularProgress color="primary" size={iconSize} />
    </div>
  );
}

Loader.defaultProps = {
  medium: false,
  big: false,
  iconSize: 40,
};

Loader.propTypes = {
  medium: PropTypes.bool,
  big: PropTypes.bool,
  iconSize: PropTypes.number,
};

export default Loader;
