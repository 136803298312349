import {
  Subscription,
  SubscriptionProviderType,
  SubscriptionStatus,
} from '../types/subsription';

const filterCouponCodeSubscriptions = (subscriptions: Subscription[]) =>
  subscriptions.filter(
    (sub) =>
      sub.name === SubscriptionProviderType.COUPON &&
      sub.active &&
      sub.state !== SubscriptionStatus.CANCELED
  );

export default filterCouponCodeSubscriptions;
