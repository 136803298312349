import React, {
  useEffect,
  useCallback,
  useContext,
  createContext,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import useRouter from '~/v3/hooks/use-router';
import { selectCurrentUser } from '~/v2/services/authApi';
import {
  useInitXrpEvents,
  useInitGaTracker,
  useInitSegment,
  useInitIntercom,
  useInitHotjar,
} from '~/v2/analytics/hooks';
import useGetPageTitle from '~/v2/hooks/useGetPageTitle';
import { useUserContext } from '~/v3/providers/UserProvider';
import { useTranslation } from 'react-i18next';
import { useCookiesScript } from '~/v3/hooks/use-cookies-script';
import useOpenIntercomPopUpOnFirstVisit from '~/v2/hooks/useOpenIntercomPopUpOnFirstVisit';
import { getUnauthenticatedUserId } from '~/v3/utils/get-unauthenticated-user-id';

export const AnalyticsContext = createContext();

export function AnalyticsProvider({ children }) {
  const { t } = useTranslation();
  const { location } = useRouter();
  const { data: user } = useSelector(selectCurrentUser);
  const getUnauthUser = useCallback(() => getUnauthenticatedUserId(), []);
  const { country } = useUserContext();
  const unauthUser = getUnauthUser();
  useInitHotjar();
  useCookiesScript();
  useInitSegment();
  useInitIntercom();
  useOpenIntercomPopUpOnFirstVisit();

  const pageTitle = useGetPageTitle();
  const xrp = useInitXrpEvents();
  const ga = useInitGaTracker();

  useEffect(() => {
    if (ga) {
      ga.getUserId = () => user?.attributes.sub || unauthUser;
    }
    if (ga) ga.trackScreen({ page_path: location?.pathname });

    if (window?.analytics && location?.pathname !== '/') {
      window.analytics.page(t(`routes.${pageTitle}`), location?.pathname, {
        userId: user?.attributes.sub || unauthUser,
        ...(country.isFromUS && { countryId: country.data?.code }),
      });
    }

    if (window.cwr) {
      window.cwr('recordPageView', location?.pathname);
    }
  }, [
    pageTitle,
    ga,
    t,
    user?.attributes.sub,
    location?.pathname,
    unauthUser,
    country,
  ]);

  const memoizedContextValue = useMemo(
    () => ({
      ga,
      xrp,
    }),
    [ga, xrp]
  );

  return (
    <AnalyticsContext.Provider value={memoizedContextValue}>
      {children}
    </AnalyticsContext.Provider>
  );
}

export function useAnalytics() {
  const context = useContext(AnalyticsContext);

  if (context === undefined) {
    throw new Error('useAnalytics must be used within a AnalyticsContext');
  }

  return context;
}

AnalyticsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
