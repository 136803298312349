import { createSlice } from '@reduxjs/toolkit';

type Event = {
  type: string;
  data: string;
};

const initialState: {
  xmlHttpRequestHandlerState: boolean;
  events: Event[];
} = {
  xmlHttpRequestHandlerState: false,
  events: [],
};

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState,
  reducers: {
    sendAnalyticsEvent: (
      state,
      { payload: { type, data } }: { payload: Event }
    ) => {
      state.events.push({
        type,
        data,
      });
    },
    removeAnalyticsEvent: (state, { payload }) => {
      state.events = state.events.filter((event) => event === payload);
    },
    changeXMLHttpRequestState: (
      state,
      { payload: { status } }: { payload: { status: boolean } }
    ) => {
      state.xmlHttpRequestHandlerState = status;
    },
  },
});

export const {
  sendAnalyticsEvent,
  removeAnalyticsEvent,
  changeXMLHttpRequestState,
} = analyticsSlice.actions;

export const getUnprocessedAnalyticsEvent = (state: {
  analytics: {
    events: Event[];
  };
}) => state.analytics.events[0] ?? null;

export const getXmlHttpRequestHandlerState = (state: {
  analytics: {
    xmlHttpRequestHandlerState: boolean;
  };
}) => state.analytics.xmlHttpRequestHandlerState;

export default analyticsSlice.reducer;
