import React from 'react';
import { usePalettesNavigation } from '~/v2/pages/MyPalettes/myPalettesHooks';
import { useTranslation } from 'react-i18next';
import { usePalettesInfiniteScroll } from '~/v3/hooks/use-palettes-infinite-scroll';
import { getPaletteFromLocalStorage } from '~/v3/utils/get-palette-from-local-storage';
import AccordionGroup from '~/v3/components/shared/accordion-group';

const testIds = {
  alert: 'get-saved-palettes-error',
  loadingSkeleton: 'get-saved-palettes-loading',
  list: 'saved-palettes-list',
};

function SavedPalettes() {
  const {
    palettes: savedPalettes,
    hasMoreData,
    isFetching,
    error,
  } = usePalettesInfiniteScroll();

  const { t } = useTranslation();
  const { goToEditPalette } = usePalettesNavigation();
  const localPalette = getPaletteFromLocalStorage();

  return (
    <AccordionGroup
      isError={!!error}
      error={error}
      isLoading={isFetching}
      list={savedPalettes}
      selectedId={localPalette?.palette?.id}
      onClickListItem={(palette) => goToEditPalette(palette)}
      testIds={testIds}
      label={t('labels.personal')}
      hasMoreData={hasMoreData}
    />
  );
}
export default SavedPalettes;
