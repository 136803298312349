import { useQuery } from '@tanstack/react-query';

import { getColor } from '~/v3/api/color-api';

type UseGetColorProperties = {
  colorCode?: string;
};

function useGetColor({ colorCode }: UseGetColorProperties) {
  return useQuery({
    queryKey: ['get-color', colorCode],
    queryFn: () => getColor({ colorCode }),
    enabled: !!colorCode,
    staleTime: Infinity,
  });
}

export default useGetColor;
