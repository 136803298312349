import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useFormik } from 'formik';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormHelperText from '@mui/material/FormHelperText';
import { string, object } from 'yup';

const useStyles = makeStyles((theme) => ({
  addCollaboratorContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
  helperTextContainer: {
    position: 'relative',
    paddingBottom: theme.spacing(2),
  },
  helperText: {
    position: 'absolute',
    [theme.breakpoints.down('sm')]: {
      position: 'static',
    },
    paddingBottom: 'inherit',
  },
  shareButton: {
    minWidth: 100,
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(2),
    },
  },
  inputContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    width: '100%',
  },
  input: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
    },
  },
  inputRoot: {
    backgroundColor: 'white',
    borderRadius: theme.spacing(1),
  },
}));

function CollaboratorsForm({ error, disabled, addCollaborator }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: { email: '' },
    validationSchema: () =>
      object().shape({
        email: string(t('validators.email'))
          .email(t('validators.email'))
          .required(t('validators.emailRequired')),
      }),
    onSubmit: (values, { resetForm }) => {
      addCollaborator(values.email);
      resetForm();
    },
  });

  const isApiError = error?.length > 0;
  const isClientError = formik.touched.email && Boolean(formik.errors.email);

  const handleChange = (e) => {
    formik.setFieldValue('email', e.target.value);
  };

  const getHelperText = () => {
    if (isApiError && !isClientError) return error;
    if (isClientError && !isApiError) return formik.errors.email;

    return t('labels.addPerPerson');
  };

  return (
    <form
      onSubmit={formik.handleSubmit}
      className={classes.addCollaboratorContainer}
      noValidate
    >
      <div className={classes.inputContainer}>
        <TextField
          type="email"
          autoFocus
          className={classes.input}
          InputProps={{ classes: { input: classes.inputRoot } }}
          value={formik.values.email}
          onChange={handleChange}
          label={t('labels.collaboratorEmail')}
          data-id="add-collaborator-input"
          data-testid="add-collaborator-input"
        />
        <div className={classes.helperTextContainer}>
          <FormHelperText
            className={classes.helperText}
            error={isApiError || isClientError}
          >
            {getHelperText()}
          </FormHelperText>
        </div>
      </div>
      <Button
        variant="contained"
        type="submit"
        color="primary"
        className={classes.shareButton}
        disabled={disabled}
        data-id="add-collaborator-button"
        data-testid="add-collaborator-button"
      >
        {t('labels.share')}
      </Button>
    </form>
  );
}

CollaboratorsForm.defaultProps = {
  error: '',
  disabled: false,
};

CollaboratorsForm.propTypes = {
  error: PropTypes.string,
  disabled: PropTypes.bool,
  addCollaborator: PropTypes.func.isRequired,
};

export default CollaboratorsForm;
