import useSubscriptionStatus, {
  UseSubscriptionStatusOutput,
} from '~/v3/hooks/use-subscription-status';
import Colors from '../theme/colors';

type LabelKey =
  | 'labels.connectPlusInsider'
  | 'labels.insiderPremium'
  | 'labels.connectPremium'
  | 'labels.premiumPlus'
  | 'labels.basic';

interface SubscriptionData {
  labelKey: LabelKey;
  backgroundColor: string;
}

export function hasConnectAndInsiderSubscription(
  subscriptionStatus: UseSubscriptionStatusOutput
): boolean {
  return (
    (subscriptionStatus.isActiveFastspring ||
      subscriptionStatus.isOnConnectCouponCodeTrial) &&
    (subscriptionStatus.isActiveTrendsSubscription ||
      subscriptionStatus.isOnColorInsiderCouponCodeTrial)
  );
}

export function hasInsiderSubscription(
  subscriptionStatus: UseSubscriptionStatusOutput
): boolean {
  return (
    (subscriptionStatus.isActiveTrendsSubscription ||
      subscriptionStatus.isOnColorInsiderCouponCodeTrial) &&
    !subscriptionStatus.isActiveFastspring
  );
}

export function hasConnectSubscription(
  subscriptionStatus: UseSubscriptionStatusOutput
): boolean {
  return (
    (subscriptionStatus.isActiveFastspring ||
      subscriptionStatus.isOnConnectCouponCodeTrial) &&
    !subscriptionStatus.isActiveTrendsSubscription &&
    !subscriptionStatus.isOnColorInsiderCouponCodeTrial
  );
}

export function getMatchedSubscription(
  subscriptionStatus: UseSubscriptionStatusOutput
): SubscriptionData | null {
  const { isPremiumPlus, isPremium } = subscriptionStatus;

  if (hasConnectAndInsiderSubscription(subscriptionStatus)) {
    return {
      labelKey: 'labels.connectPlusInsider',
      backgroundColor: Colors.darkBlue,
    };
  }

  if (hasInsiderSubscription(subscriptionStatus)) {
    return {
      labelKey: 'labels.insiderPremium',
      backgroundColor: Colors.purple,
    };
  }

  if (hasConnectSubscription(subscriptionStatus) || isPremium) {
    return {
      labelKey: 'labels.connectPremium',
      backgroundColor: Colors.green,
    };
  }

  if (isPremiumPlus) {
    return {
      labelKey: 'labels.premiumPlus',
      backgroundColor: Colors.green,
    };
  }

  return null;
}

export function useSubscriptionData(): SubscriptionData | null {
  const subscriptionStatus = useSubscriptionStatus();

  if (subscriptionStatus.isLoading) {
    return null;
  }

  const matchedSubscription = getMatchedSubscription(subscriptionStatus);

  if (matchedSubscription) {
    return matchedSubscription;
  }

  return {
    labelKey: 'labels.basic',
    backgroundColor: Colors.pantoneLogoBlue,
  };
}
