/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import Link from '@mui/material/Link';
import { useTranslation } from 'react-i18next';

import routes from 'constants/route';
import events from 'analytics/events';
import useRouter from '~/v3/hooks/use-router';
import useTracker from 'analytics/useTracker';
import usePaletteWS from 'features/PaletteWorkspace/usePaletteWS';
import useConfirm from 'features/ConfirmDialog/useConfirm';
import { removeNullColors } from 'utils/palette';
import { platformLink } from 'utils/links';
import { useGetPalettesQuery } from 'services/paletteApi';
import { useGetMoodboardsQuery } from 'services/moodboardApi';
import { isSidebarEnabled } from '~/v3/feature-flags';
import { useWorkspaceStoreActions } from '~/v3/store/workspace-store';

export const usePanoramaExpiredDialog = () => {
  const { confirm } = useConfirm();
  const { t } = useTranslation();

  const openPanoramaExpiredDialog = () => {
    confirm({
      content: (
        <>
          {t('labels.panoramaExpired')}{' '}
          <Link
            color="secondary"
            variant="subtitle2"
            onClick={() =>
              platformLink('https://www.pantone.com/contact-us', '_blank')
            }
            underline="hover"
          >
            {t('labels.contactUs')}
          </Link>
        </>
      ),
    });
  };

  return {
    openPanoramaExpiredDialog,
  };
};

export const usePalettesNavigation = () => {
  const ga = useTracker();
  const { push } = useRouter();
  const { openPanoramaExpiredDialog } = usePanoramaExpiredDialog();
  const { resetWorkspace, setWorkspacePalette } = usePaletteWS();
  const { onExpandWorkspace } = useWorkspaceStoreActions();

  const handlePaletteEdit = (palette) => {
    const filteredPalette = removeNullColors(palette);
    if (isSidebarEnabled) onExpandWorkspace();

    if (!filteredPalette.colors.length) {
      openPanoramaExpiredDialog();
      return;
    }

    setWorkspacePalette({ palette });
    push(routes.colorPicker);
    ga.trackEvent(events.e38);
  };

  const handleCreatePalette = () => {
    resetWorkspace();
    ga.trackEvent(events.e44);
    push(routes.colorPicker);
  };

  return {
    goToEditPalette: handlePaletteEdit,
    goToCreatePalette: handleCreatePalette,
  };
};

export const usePalettesInfiniteScroll = () => {
  const [nextToken, setNextToken] = useState();
  const { data: combinedPages } = useGetPalettesQuery(undefined);
  const {
    data: currentPage,
    isFetching,
    error,
  } = useGetPalettesQuery(nextToken, {
    skip: nextToken === null,
  });

  return {
    palettes: combinedPages?.palettes,
    isFetching,
    hasMoreData: !!currentPage?.nextToken,
    loadMoreData: () => setNextToken(currentPage?.nextToken),
    error,
  };
};

export const useMoodboardsInfiniteScroll = () => {
  const [nextToken, setNextToken] = useState();
  const { data: combinedPages } = useGetMoodboardsQuery(undefined);
  const {
    data: currentPage,
    isFetching,
    error,
  } = useGetMoodboardsQuery(nextToken, {
    skip: nextToken === null,
  });

  return {
    moodboards: combinedPages?.moodboards,
    isFetching,
    hasMoreData: !!currentPage?.nextToken,
    loadMoreData: () => setNextToken(currentPage?.nextToken),
    error,
  };
};

export default {
  usePalettesNavigation,
  usePanoramaExpiredDialog,
  useMoodboardsInfiniteScroll,
};
