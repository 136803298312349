import { userProfileAvatarWidth } from '~/v3/components/shared/avatar-icon';

export const headerBorderBottomSize = 1;
export const scaledHeaderPadding = 3.5;
export const scaledHeaderPaddingMobile = 1.5;
export const headerHeight = 64;
export const customerServiceButtonWidth = userProfileAvatarWidth;
export const searchButtonWidth = userProfileAvatarWidth;
export const headerItemMobileWidth = 34;
export const searchButtonMobileWidth = 28;
export const insiderChipColor = 'rgba(0, 134, 255, 0.07)';
