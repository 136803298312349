import { setSessionStorage, getSessionStorage } from '~/v3/utils/storage';

import config from '~/v2/config';

const now = Date.now();

export function getUnauthenticatedUserId(): string {
  const sessionStorageUnauthId = getSessionStorage(
    config.UNAUTH_SESSION_STORAGE
  ) as string;

  const unauthId = sessionStorageUnauthId ?? `Unauthenticated_User_${now}`;

  if (!sessionStorageUnauthId) {
    setSessionStorage(config.UNAUTH_SESSION_STORAGE, unauthId);
  }

  return unauthId;
}
