import { matchPath } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';

import RoutePath from '~/v2/constants/route';
import { CORE_VERSION, getAppVersionMode } from '../config';

type RoutePathKeys = keyof typeof RoutePath;
type AuthKeys = keyof typeof RoutePath.auth;
type RootPath = {
  [key in AuthKeys]: string;
};

function mapRoutesWithPaths(routes: typeof RoutePath) {
  const mappedRoutes: { path: string }[] = [];
  Object.keys(routes).forEach((key, index) => {
    const value = RoutePath[key as RoutePathKeys] as RootPath;

    if (index === 0) {
      Object.keys(RoutePath[key as RoutePathKeys]).forEach((key1) => {
        mappedRoutes.push({
          path: value[key1 as AuthKeys],
        });
      });
      return;
    }

    mappedRoutes.push({ path: RoutePath[key as RoutePathKeys] as string });
  });
  return mappedRoutes;
}

const routes = mapRoutesWithPaths(RoutePath);
const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://28f389a21e0723fba5ec3496f4c4c702@o4505998229504000.ingest.sentry.io/4505998290059264',
  environment: getAppVersionMode(),
  release: CORE_VERSION + getAppVersionMode(),
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.reactRouterV5BrowserTracingIntegration({
      history,
      routes,
      matchPath,
    }),
    Sentry.thirdPartyErrorFilterIntegration({
      filterKeys: ['pantone-connect'],
      behaviour: 'drop-error-if-exclusively-contains-third-party-frames',
    }),
  ],
  tracesSampleRate:
    getAppVersionMode() === 'dev' || getAppVersionMode() === 'beta' ? 1.0 : 0.4,
  tracePropagationTargets: [
    /localhost/,
    /^https:\/\/connect\.pantone\/.com/,
    'https://d8vdtrtq78n35.cloudfront.net/',
    /^https:\/\/d236m83v8dj56h\.cloudfront\/.net/,
  ],
  ignoreErrors: [
    'Non-Error exception captured',
    'Non-Error promise rejection captured',
    /CustomEvent/gi,
  ],
});
