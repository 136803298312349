import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function InfoIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: 12, height: 12, ...sx }}
    >
      <path
        d="M6.86982 3.23523V2.05823H5.74782V3.23523H6.86982ZM6.85882 9.71423V4.03823H5.75882V9.71423H6.85882Z"
        stroke="none"
      />
      <circle cx="6" cy="6" r="5.5" fill="transparent" />
    </SvgIcon>
  );
}

export default InfoIcon;
