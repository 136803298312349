import React from 'react';
import { Rgb } from '~/v3/types/pantone-color';
import { isAdobe } from '~/v2/utils/platform';
import { isLightColor } from '../utils/color-utils';

type NewColorDotIndicatorProps = Readonly<{
  rgbValues: Rgb;
}>;

function NewColorDotIndicatorListView({
  rgbValues,
}: NewColorDotIndicatorProps) {
  let dotPosition = { top: '12px', bottom: 'unset' };

  if (isAdobe) {
    dotPosition = { top: 'unset', bottom: '5px' };
  }

  return (
    <div
      data-testid="new-color-dot-indicator"
      style={{
        ...dotPosition,
        position: 'absolute',
        left: '12px',
        width: '6px',
        height: '6px',
        backgroundColor: isLightColor(rgbValues) ? 'white' : 'black',
        borderRadius: '100px',
      }}
    />
  );
}

export default NewColorDotIndicatorListView;
