export const getCurrentLocation = () => {
  const { protocol, host, pathname } = window.location;
  return `${protocol}//${host}${pathname}`;
};

export const getBaseUrl = () => {
  const getUrl = window.location;
  return `${getUrl.protocol}//${getUrl.host}`;
};

export const getNextURLParams = ({ initialParams, nextParams }) => {
  const params = new URLSearchParams(initialParams);

  Object.keys(nextParams).forEach((paramName) => {
    if (params.has(paramName)) {
      params.delete(paramName);
    }

    const nextValue =
      typeof nextParams[paramName] === 'function'
        ? nextParams[paramName]()
        : nextParams[paramName];
    if (nextValue) params.append(paramName, nextValue);
  });

  return params.toString();
};

export const updateURLQuery =
  ({ push, pathname, search }) =>
  (query) => {
    if (query)
      push({
        pathname,
        search: getNextURLParams({
          initialParams: search,
          nextParams: query,
        }),
      });
  };

export const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === '[::1]' ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const getAppVersionMode = () => {
  let appVersionMode = 'dev';
  if (process.env.NODE_ENV === 'production') appVersionMode = 'prod';
  if (process.env.UAT) appVersionMode = 'uat';
  if (process.env.BETA) appVersionMode = 'beta';
  return appVersionMode;
};

export const getColorFromInfoURL = (url) =>
  url
    .substring(
      url.lastIndexOf('/') + 1,
      url.includes('?') ? url.indexOf('?') : url.length
    )
    .split(' ')
    .join('_');
