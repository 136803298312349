import { ViewDropdownOption } from '~/v3/pick/components/constants';
import { API, graphqlOperation } from '@aws-amplify/api';
import { Auth } from '@aws-amplify/auth';
import { amplifyUserPoolSettings } from '~/v2/utils/amplify';
import { CurrentUser, UserCountry } from '../types/user';
import { Offer } from '../types/offer';

export async function getUserViewPreference(): Promise<ViewDropdownOption> {
  API.configure(amplifyUserPoolSettings);

  const {
    data: { getUserData: userData },
  } = (await API.graphql(
    graphqlOperation(`{        
          getUserData {
            viewPreference
          }
      }`)
  )) as {
    data: {
      getUserData: {
        viewPreference: ViewDropdownOption;
      };
    };
  };
  return userData.viewPreference;
}

export async function getUserData(): Promise<{ offers: Offer[] }> {
  API.configure(amplifyUserPoolSettings);

  const {
    data: { getUserData: userData },
  } = (await API.graphql(
    graphqlOperation(`{        
          getUserData {
            offers {    
              id
            }
          }
      }`)
  )) as {
    data: {
      getUserData: {
        offers: Offer[];
      };
    };
  };
  return { offers: userData.offers };
}

export async function setUserViewPreference(
  viewPreference: ViewDropdownOption
): Promise<void> {
  await API.graphql(
    graphqlOperation(
      `mutation CreateUserData($viewPreference: String) {
          createUserData(
            input: {
              viewPreference: $viewPreference
            }
          ) {
            viewPreference
          }
        }`,
      {
        viewPreference,
      }
    )
  );
}

export async function getAndStoreUserCountry(): Promise<UserCountry> {
  const response = (await API.graphql(
    graphqlOperation(`{
        getAndStoreCurrentCountry {
          code
          name
          region
          language
        }
      }`)
  )) as { data: { getAndStoreCurrentCountry: UserCountry } };

  return response.data.getAndStoreCurrentCountry;
}

export async function getCurrentUser() {
  Auth.configure(amplifyUserPoolSettings);

  const user = (await Auth.currentAuthenticatedUser()) as CurrentUser;

  return {
    username: user?.username,
    attributes: user?.attributes,
  };
}
