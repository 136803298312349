import React from 'react';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import PantoneLogoWithIcon from '../PantoneLogoWithIcon';

const useStyles = makeStyles((theme) => ({
  watermark: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    padding: theme.spacing(2.5),
    borderTopLeftRadius: theme.spacing(3.75),
    background: 'rgba(255, 255, 255, 0.7)',
  },
  createdBy: {
    fontWeight: 700,
    fontSize: theme.spacing(2.45),
    paddingRight: theme.spacing(0.5),
    marginTop: 1,
    textTransform: 'uppercase',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    color: theme.palette.common.black,
  },
  pantoneConnectLogo: {
    width: 100,
    marginLeft: theme.spacing(0.625),
  },
}));

const Watermark = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.watermark}>
      <span className={classes.createdBy}>{t('labels.createdBy')}</span>
      <PantoneLogoWithIcon className={classes.pantoneConnectLogo} />
    </div>
  );
};

export default Watermark;
