import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  name: '',
  colors: [],
  imageUrl: null,
  moodboard: null,
  unsavedChanges: false,
};

export const moodboardWorkspaceSlice = createSlice({
  name: 'moodboardWorkspace',
  initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setImageUrl: (state, action) => {
      state.imageUrl = action.payload;
    },
    setMoodboard: (state, action) => {
      state.name = action.payload?.name;
      state.colors = action.payload?.colors;
      state.moodboard = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    reset: (state) => {
      state.name = '';
      state.colors = [];
      state.imageUrl = state.imageUrl
        ? state.imageUrl
        : state.moodboard?.imageUrl || null;
      state.moodboard = null;
    },
  },
});

export const {
  reset,
  clear,
  setName,
  setColors,
  setImageUrl,
  setMoodboard,
  setUnsavedChanges,
} = moodboardWorkspaceSlice.actions;

export default moodboardWorkspaceSlice.reducer;

export const selectId = (state) => state.moodboardWorkspace.moodboard?.id;
export const selectName = (state) => state.moodboardWorkspace.name;
export const selectColors = (state) => state.moodboardWorkspace.colors;
export const selectImageUrl = (state) => state.moodboardWorkspace.imageUrl;
export const selectMoodboard = (state) => state.moodboardWorkspace.moodboard;
export const selectUnshavedChanges = (state) =>
  state.moodboardWorkspace.unsavedChanges;
