import React from 'react';
import { getLocalStorage } from '~/v3/utils/storage';
import OldExtensionVersionDialog from 'components/OldExtensionVersion/OldExtensionVersionDialog';
import { DONT_SHOW_OLD_EXTENSION_VERSION_DIALOG_KEY } from 'constants/local-storage-keys';
import useDialog from '~/v3/hooks/useDialog';

const isOldExtensionVersionDialogHidden = Boolean(
  getLocalStorage(DONT_SHOW_OLD_EXTENSION_VERSION_DIALOG_KEY)
);

function OldExtensionVersionContainer() {
  const initialIsDialogOpenValue = !isOldExtensionVersionDialogHidden;

  const {
    isDialogOpen: isOldExtensionVersionDialogOpen,
    onCloseDialog: onCloseOldExtensionVersionDialog,
  } = useDialog(initialIsDialogOpenValue);

  return (
    <OldExtensionVersionDialog
      open={isOldExtensionVersionDialogOpen}
      onClose={onCloseOldExtensionVersionDialog}
    />
  );
}

export default OldExtensionVersionContainer;
