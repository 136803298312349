import { useConfiguredGetSubscriptionQuery } from 'services/subscriptionApi';
import useRouter from '~/v3/hooks/use-router';
import { removeSessionStorage } from '~/v3/utils/storage';
import { SUBSCRIPTION_TYPE } from 'constants/storage';
import {
  redirectToPickPage,
  redirectToTrends,
  redirectToColorInfoPage,
  shouldBeRedirectedToTrendsPage,
  shouldBeRedirectedToColorInfoPage,
} from '~/v2/utils/paywall';
import { ARTICLE_ID } from '~/v3/constants/storage';

function useUserHasSubscribed() {
  const { refetch } = useConfiguredGetSubscriptionQuery(undefined);
  const router = useRouter();

  const handleOnSubscribed = () => {
    removeSessionStorage(SUBSCRIPTION_TYPE);
    refetch();
    if (shouldBeRedirectedToColorInfoPage()) {
      redirectToColorInfoPage({ router });
    } else if (shouldBeRedirectedToTrendsPage()) {
      redirectToTrends({ router });
      removeSessionStorage(ARTICLE_ID);
    } else {
      redirectToPickPage({ router });
    }
  };

  return { onSubscribed: handleOnSubscribed };
}

export default useUserHasSubscribed;
