import { amplifyUserPoolSettings } from '~/v2/utils/amplify';
import { SubscriptionProduct, SubscriptionType } from '../types/subsription';

type CreateSurveyArguments = {
  product: SubscriptionProduct[];
  cancelationAction: boolean;
  cancelationReasons: string[];
  cancelationFeedback?: string;
  subscriptionType: SubscriptionType[];
};

const initApi = async () => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');

  API.configure(amplifyUserPoolSettings);

  return { API, graphqlOperation };
};

export async function createSurvey({
  product,
  subscriptionType,
  cancelationAction,
  cancelationReasons,
  cancelationFeedback,
}: CreateSurveyArguments): Promise<unknown> {
  const { API, graphqlOperation } = await initApi();

  try {
    const data = (await API.graphql(
      graphqlOperation(
        `mutation CreateCancelationSurvey($cancelationReasons: [String]!, $cancelationAction: String!, $cancelationFeedback: String, $product: [String]!, $subscriptionType: [String]!) {
            createCancelationSurvey(
        input: { cancelationReasons: $cancelationReasons, cancelationAction: $cancelationAction, cancelationFeedback: $cancelationFeedback, product: $product, subscriptionType: $subscriptionType }
      ) {
            id,
            creationTimestamp
          }
      }`,
        {
          product,
          cancelationReasons,
          cancelationAction,
          cancelationFeedback,
          subscriptionType,
        }
      )
    )) as {
      data: {
        createCancelationSurvey: unknown;
      };
    };

    return { data: data?.data?.createCancelationSurvey };
  } catch (error) {
    return { error };
  }
}
