import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const DOT_SIZE = 10;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    minWidth: 300,
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
  gradient: {
    flex: '1 1 auto',
    height: '100%',
  },
  gradientBorder: {
    width: DOT_SIZE / 2,
  },
  overlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dot: {
    width: 10,
    height: 10,
    minHeight: 2,
    maxHeight: 10,
    border: '1px white solid',
  },
}));

const ViewAsGradient = ({ colors }) => {
  const classes = useStyles();
  const gradientColors = [...colors];
  const lastIndex = gradientColors.length - 1;
  const ratio = parseFloat((100 / lastIndex).toFixed(2));
  let gradient = 'linear-gradient(90deg,';
  const firstColor = gradientColors.shift();
  const lastColor = gradientColors.pop();
  gradient = gradient.concat(
    ` rgb(${firstColor.rgb.r}, ${firstColor.rgb.g}, ${firstColor.rgb.b}) 0%,`
  );
  gradient = gradient.concat(
    gradientColors
      .map(
        (c, index) =>
          ` rgb(${c.rgb.r}, ${c.rgb.g}, ${c.rgb.b}) ${(index + 1) * ratio}%`
      )
      .join(', ')
  );
  if (colors.length > 2) gradient = `${gradient},`;
  gradient = gradient.concat(
    `rgb(${lastColor.rgb.r}, ${lastColor.rgb.g}, ${lastColor.rgb.b}) 100%)`
  );

  return (
    <div className={classes.container}>
      <div
        className={classes.gradientBorder}
        style={{
          backgroundColor: `rgb(${firstColor.rgb.r},${firstColor.rgb.g},${firstColor.rgb.b})`,
        }}
      />
      <div className={classes.gradient} style={{ background: gradient }} />
      <div
        className={classes.gradientBorder}
        style={{
          backgroundColor: `rgb(${lastColor.rgb.r},${lastColor.rgb.g},${lastColor.rgb.b})`,
        }}
      />
      <div className={classes.overlay}>
        {colors.map((color) => (
          <div
            key={color.code}
            className={classes.dot}
            style={{
              backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
            }}
          />
        ))}
      </div>
    </div>
  );
};

ViewAsGradient.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ViewAsGradient;
