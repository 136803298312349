import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function CrossReferenceIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: 12, height: 12, ...sx }}
    >
      <path d="M7.2107 4.35355C7.40596 4.15829 7.40596 3.84171 7.2107 3.64645L4.02872 0.464466C3.83345 0.269204 3.51687 0.269204 3.32161 0.464466C3.12635 0.659729 3.12635 0.976311 3.32161 1.17157L6.15004 4L3.32161 6.82843C3.12635 7.02369 3.12635 7.34027 3.32161 7.53553C3.51687 7.7308 3.83345 7.7308 4.02871 7.53553L7.2107 4.35355ZM-4.37114e-08 4.5L6.85714 4.5L6.85714 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z" />
      <path d="M4.7893 11.6464C4.59404 11.8417 4.59404 12.1583 4.7893 12.3536L7.97128 15.5355C8.16655 15.7308 8.48313 15.7308 8.67839 15.5355C8.87365 15.3403 8.87365 15.0237 8.67839 14.8284L5.84996 12L8.67839 9.17157C8.87365 8.97631 8.87365 8.65973 8.67839 8.46447C8.48313 8.2692 8.16655 8.2692 7.97129 8.46447L4.7893 11.6464ZM12 11.5L5.14286 11.5L5.14286 12.5L12 12.5L12 11.5Z" />
    </SvgIcon>
  );
}

export default CrossReferenceIcon;
