import React from 'react';
import PropTypes from 'prop-types';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import i18n from '~/v2/i18n';

import useProfileMenu from '~/v2/data/useProfileMenu';
import useTracker from 'analytics/useTracker';
import events from 'analytics/events';
import { setLocalStorage } from '~/v3/utils/storage';
import config from 'config';
import { ClickableMenuItem, MenuDivider } from './UserProfileDropdownMenuItem';

function SelectLanguageContent({ onClickItem, onClose }) {
  const { themeSection } = useProfileMenu();
  const ga = useTracker();

  const goBackMenuItem = {
    eventName: 'GoBack',
    icon: <ArrowBackIcon />,
    label: i18n.t('labels.language'),
    pathname: null,
    subMenu: null,
  };

  const handleChangeLanguage = (e, menuItem) => {
    i18n.changeLanguage(menuItem.value);
    setLocalStorage(config.HAS_USER_DEFINED_LANGUAGE, true);
    onClickItem(e, menuItem);
    ga.trackEvent({ ...events.e74, TERM: menuItem.value });
  };

  const handleCloseMenu = () => {
    onClose(null);
  };

  const selectedLanguage = i18n.languages[0];

  return (
    <>
      <ClickableMenuItem
        menuItem={goBackMenuItem}
        onClick={handleCloseMenu}
        isBackButton
      />
      <MenuDivider />
      {themeSection.changeLanguage.subMenu.map((menuItem) => (
        <ClickableMenuItem
          key={menuItem.eventName}
          menuItem={menuItem}
          selected={selectedLanguage === menuItem.value}
          isCheckButton
          isChecked={menuItem.value === selectedLanguage}
          onClick={handleChangeLanguage}
          data-id={`${menuItem.value}-language`}
        />
      ))}
    </>
  );
}

SelectLanguageContent.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectLanguageContent;
