import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import PantoneLogo from 'components/PantoneLogo';

const useStyles = makeStyles((theme) => ({
  fullScreenColorRoot: {
    display: 'flex',
    alignItems: 'flex-end',
  },
  fullScreenColorContent: {
    display: 'flex',
    alignItems: 'start',
    flexDirection: 'column',
    padding: theme.spacing(5),
    color: ({ color }) =>
      theme.palette.isDark(color)
        ? theme.palette.common.white
        : theme.palette.common.black,
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(3),
    },
  },
  bookTitle: {
    padding: theme.spacing(2, 0),
  },
  logo: {
    height: 17,
    marginBottom: theme.spacing(2),
  },
  colorValues: {
    display: 'flex',
    flexDirection: 'column',
  },
  colorItems: {
    display: 'grid',
    gridTemplateColumns: '180px 180px',
    alignItems: 'center',
    padding: theme.spacing(0.1, 0),
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '100px 180px',
    },
  },
}));

function FullScreenColor({ color }) {
  const { t } = useTranslation();

  const classes = useStyles({ color: color?.rgb });

  return (
    <div className={classes.fullScreenColorRoot} data-id="fullscreen-color">
      <div className={classes.fullScreenColorContent}>
        <PantoneLogo className={classes.logo} color="currentColor" />

        <Typography variant="h6">{color.code}</Typography>
        <Typography>{color?.name || null}</Typography>
        <Typography className={classes.bookTitle}>
          {color.book.title}
        </Typography>

        <div className={classes.colorValues}>
          <div className={classes.colorItems}>
            <Typography>{color.lab ? 'L*a*b*' : null}</Typography>
            <Typography>
              {color.lab
                ? `${Math.round(color.lab.l)} ${Math.round(
                    color.lab.a
                  )} ${Math.round(color.lab.b)}`
                : null}
            </Typography>
          </div>
          <div className={classes.colorItems}>
            <Typography>{color.rgb ? 'sRGB' : null}</Typography>
            <Typography>
              {color.rgb
                ? `${color.rgb.r} ${color.rgb.g} ${color.rgb.b}`
                : null}
            </Typography>
          </div>
          <div className={classes.colorItems}>
            <Typography>{color.hex ? 'HEX' : null}</Typography>
            <Typography>{color.hex ? color.hex : null}</Typography>
          </div>
          <div className={classes.colorItems}>
            <Typography>
              {color.pageNumberInBook ? t('labels.libraryBookPage') : null}
            </Typography>
            <Typography>
              {color.pageNumberInBook ? color.pageNumberInBook : null}
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}

FullScreenColor.propTypes = {
  color: PropTypes.shape({
    pageNumberInBook: PropTypes.number,
    rgb: PropTypes.shape({
      r: PropTypes.number,
      g: PropTypes.number,
      b: PropTypes.number,
    }),
    lab: PropTypes.shape({
      l: PropTypes.number,
      a: PropTypes.number,
      b: PropTypes.number,
    }),
    hex: PropTypes.string,
    name: PropTypes.string,
    code: PropTypes.string,
    book: PropTypes.shape({
      title: PropTypes.string,
    }),
  }).isRequired,
};

export default FullScreenColor;
