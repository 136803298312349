import { createTheme } from '@mui/material/styles';
import colors from './colors';
import helvetica from './helvetica';

const theme = createTheme();

export default {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
      },
      body: {
        height: '100%',
        userSelect: 'none',
        overscrollBehavior: 'contain',
      },
      input: {
        '::-webkit-outer-spin-button': {
          webkitApperance: 'none',
          margin: 0,
        },
        '::-webkit-inner-spin-button': {
          webkitApperance: 'none',
          margin: 0,
        },
      },
      '@font-face': helvetica,
      fallbacks: helvetica.map((font) => ({
        '@font-face': font,
      })),
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: 16,
        backgroundColor: colors.WhiteGrey[200],
        padding: theme.spacing(4),
      },
    },
  },
  MuiTabs: {
    styleOverrides: {
      indicator: {
        backgroundColor: colors.Blue[300],
        height: '1px',
      },
    },
  },
  MuiTab: {
    styleOverrides: {
      root: {
        minWidth: '160px',
        textTransform: 'capitalize',
        fontWeight: 400,
        '&:hover': {
          background: colors.WhiteGrey[200],
          borderBottom: `1px solid ${colors.Blue[300]}`,
          color: colors.Blue[300],
        },
        '&.Mui-selected': {
          fontWeight: 500,
        },
        flexDirection: 'row',
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(1.25),
          marginTop: theme.spacing(0.75),
        },
      },

      labelIcon: {
        styleOverrides: {
          minHeight: 40,
        },
      },
    },
  },
  MuiListItemIcon: {
    styleOverrides: {
      root: {
        minWidth: 40,
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      contained: {
        marginLeft: 0,
      },
    },
  },

  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderTopLeftRadius: 8,
        borderTopRightRadius: 8,
        borderBottomRightRadius: 8,
        borderBottomLeftRadius: 8,
        backgroundColor: colors.WhiteGrey[200],
        border: `1px solid ${colors.WhiteGrey[400]}`,
        '&:hover': {
          backgroundColor: colors.WhiteGrey[300],
        },
        '&$focused': {
          backgroundColor: colors.WhiteGrey[300],
        },
        '&.Mui-error': {
          border: `1px solid ${colors.Red.border}`,
          backgroundColor: colors.Red.background,
        },
      },
      underline: {
        '&:before': {
          content: '""',
          transition: 'none',
          border: 0,
        },
        '&:after': {
          borderBottom: 0,
        },
      },
    },
  },
  MuiAccordion: {
    styleOverrides: {
      root: {
        border: 'none',
        boxShadow: 'none',
        width: '100%',
        backgroundColor: 'transparent',

        '&:not(:last-child)': {
          borderBottom: `1px solid ${theme.palette.divider}`,
        },

        '&:before': {
          display: 'none',
        },

        '&$expanded': {
          margin: 'auto',
        },
      },
      expanded: {},
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          backgroundColor: 'transparent',
          border: 'none',
          minHeight: 84,
          paddingLeft: theme.spacing(3),

          '&$expanded': {
            minHeight: 84,
          },
        },
        expandIcon: {
          marginRight: 0,
        },
        content: {
          margin: '27px 0',

          '&$expanded': {
            margin: '27px 0',
          },
        },
        expanded: {},
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          padding: theme.spacing(0, 5, 3, 5),
          'word-break': 'break-word',
        },
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        root: {
          '&$selected': {
            borderTop: `1px solid ${colors.JetBlack[400]}`,
            '& svg': {
              fill: colors.Blue['300'],
            },
          },
        },
      },
      label: {
        styleOverrides: {
          '&$selected': {
            fontSize: 12,
            color: colors.JetBlack['600'],
          },
        },
      },
    },
  },
};
