import React from 'react';
import CustomDragLayer from 'components/CustomDragLayer';
import useSessionExpired from '~/v2/hooks/useSessionExpired';
import useCouponCode from '~/v2/pages/CouponCodes/useCouponCode';
import V3AppRoutes from '~/v3/routes/v3-app-routes';
import useVisualizeWebsockets from '~/v3/hooks/use-visualize-websockets';
import useSyncUserSubscription from '~/v3/react-query/hooks/use-sync-user-subscriptions';
import useSyncPantoneComSubscription from '~/v3/hooks/use-sync-pantone-com-subscription';
import useDetectCognitoCookieDeletion from '~/v3/hooks/use-detect-cognito-cookie-deletion';

function AuthenticatedApp() {
  useCouponCode();
  useSessionExpired();
  useSyncUserSubscription();
  useVisualizeWebsockets();
  useSyncPantoneComSubscription();
  useDetectCognitoCookieDeletion();

  return (
    <>
      <CustomDragLayer />
      <V3AppRoutes />
    </>
  );
}

export default AuthenticatedApp;
