import config from 'config';
import { intercomCustomLaunchSelector } from '../../v3/constants/intercom';

const { INTERCOM_API_BASE, INTERCOM_APP_ID } = config;

const generateScript = () => {
  return `<script>
  // We pre-filled your app ID in the widget URL: 'https://widget.intercom.io/widget/${INTERCOM_APP_ID}}'
  (function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}}';var x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(document.readyState==='complete'){l();}else if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();
  </script>`;
};

export function updateIntercomClient({ name, email, createdAt } = {}) {
  window.intercomSettings = {
    api_base: INTERCOM_API_BASE,
    app_id: INTERCOM_APP_ID,
    name,
    email,
    created_at: createdAt,
    custom_launcher_selector: intercomCustomLaunchSelector,
  };

  if (window.Intercom) {
    window.Intercom('boot', {
      api_base: INTERCOM_API_BASE,
      app_id: INTERCOM_APP_ID,
      name,
      email,
      created_at: createdAt,
      custom_launcher_selector: intercomCustomLaunchSelector,
    });
  }
}

export function getIntercomClient() {
  updateIntercomClient();
  return generateScript();
}

export default getIntercomClient;
