export const BaseRoutePaths = {
  picker: '/picker',
  info: '/info',
  colorStory: '/colorStory',
  explore: '/explore',
  insight: '/color-insider',
  update: '/update',
  auth: '/auth',
};

const RoutePathname = {
  // Auth routes
  auth: {
    root: '/',
    welcome: `${BaseRoutePaths.auth}/welcome`,
    signInEmail: `${BaseRoutePaths.auth}/sign-in-email`,
    signInError: `${BaseRoutePaths.auth}/sign-in-error`,
    createAccount: `${BaseRoutePaths.auth}/create-account`,
    accountCreated: `${BaseRoutePaths.auth}/auth/account-created`,
    passwordRecovery: `${BaseRoutePaths.auth}/password-recovery`,
    passwordVerification: `${BaseRoutePaths.auth}/password-verification`,
    forgotPasswordSucces: `${BaseRoutePaths.auth}/forgot-password-success`,
    userSegment: `${BaseRoutePaths.auth}/user-segment`,
    reviewTerms: `${BaseRoutePaths.auth}/review-terms`,
    error: `${BaseRoutePaths.auth}/error`,
    singleSignOn: `${BaseRoutePaths.auth}/single-sign-on`,
    completeAccountInvite: `${BaseRoutePaths.auth}/complete-account-invite`,
  },

  // App routes
  myPalettes: '/myDashboard',
  colorPicker: `${BaseRoutePaths.picker}`,
  paletteUpdate: `${BaseRoutePaths.picker}${BaseRoutePaths.update}/:id`,
  colorExtract: '/extract',
  colorConvert: '/convert',
  colorMeasure: '/measure',
  colorInfo: `${BaseRoutePaths.info}/:colorCode`,
  colorStories: '/colorStories',
  colorStory: `${BaseRoutePaths.colorStory}`,
  colorStoryNew: `${BaseRoutePaths.colorStory}/new`,
  colorStoryTemplate: `${BaseRoutePaths.colorStory}/add-pictures`,
  colorStoryCreate: `${BaseRoutePaths.colorStory}/create`,
  colorStoryUpdate: `${BaseRoutePaths.colorStory}${BaseRoutePaths.update}/:id`,
  trending: '/trending',
  myProfile: '/profile',
  myAccount: '/account',
  paywall: '/paywall',
  statistics: '/statistics',

  // Other page
  landing: '/intro',
  convert: '/convert-from-value',
  search: '/search',
  faq: '/faq',
  sendFeedback: '/send-feedback',
  platforms: '/platforms',
  about: '/about',
  offline: '/offline',
  legacyCouponCodes: '/redeem-code-legacy',
  companyCouponCodes: '/activate-benq',
  bundleCodes: '/activate',
  couponCodeActivation: '/activate-code',
  eula: '/end-user-license-agreement',
  cancelSubscription: '/cancel-subscription',
  cancelSubscriptionFeedback: '/cancel-subscription/feedback',
  cancelSubscriptionOfferApplied: '/cancel-subscription/offer-applied',
  welcomePage: 'https://welcomeconnect.pantone.com',
  landingPage: 'https://pantone.com/connect',
  explore: `${BaseRoutePaths.explore}`,
  insight: `${BaseRoutePaths.insight}`,
  article: `${BaseRoutePaths.insight}/:slug`,
  blog: `${BaseRoutePaths.explore}/:slug`,
  privacyPolicy: '/privacy-policy',
  cookiePolicy: '/cookie-policy',
  ccpaPolicy: '/ccpa-policy',
  scanGuide: '/scan-guide',
};

export default RoutePathname;
