import { updatePaletteFromLocalStorage } from 'utils/palette';

const workspaceMiddleware =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (action.type.startsWith('paletteWorkspace')) {
      const result = next(action);
      updatePaletteFromLocalStorage(getState().paletteWorkspace);

      return result;
    }

    return next(action);
  };

export default workspaceMiddleware;
