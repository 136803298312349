import useSubscriptions from '~/v3/hooks/use-subscriptions';
import { ProductType } from '~/v3/paywall/constants';
import { SubscriptionType } from '~/v3/types/subsription';

export const useGetEventTrendSubscriptionType = () => {
  const { subscriptions: trendsSubscriptions, isLoading } = useSubscriptions(
    ProductType.TRENDS
  );

  if (isLoading) {
    return undefined;
  }

  const isActiveTrendsSubscription = trendsSubscriptions.some(
    (sub) => sub.active
  );

  if (isActiveTrendsSubscription) {
    return SubscriptionType.Premium;
  }

  return SubscriptionType.Free;
};
