export const getColorFreeFields = () => `
  name
  code
`;

export const getColorBasicFields = () => `
  code
  name
  rgb {r g b}
  hex
  positionInBook
  pageNumberInBook
  lab {l a b}
  cmyk {c m y k}
  book {
    id
    title
  }
  recipes { units colorant { name value } }
`;

export const getColorPickPageFields = () => `
  code
  creationDate
  name
  hex
  rgb {r g b}
  lab {l a b}
  cmyk {c m y k}
`;

export default {
  getColorBasicFields,
  getColorFreeFields,
  getColorPickPageFields,
};
