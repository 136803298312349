import { isWeb } from 'utils/platform';
import { isLocalhost } from 'utils/urls';

function hasUserAcceptedOneTrustTargeting() {
  const getCookieValue = (name) => {
    const cookieString = `; ${document.cookie}`;
    const parts = cookieString.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    }
    return '';
  };
  const getParamValue = (cookieString, paramName) => {
    return (
      cookieString
        .split('&')
        .map((pair) => pair.split('='))
        .find(([key]) => key === paramName)?.[1] || null
    );
  };

  const optanonConsentValue = getCookieValue('OptanonConsent');
  const decoded = decodeURIComponent(optanonConsentValue);

  const interactionCountVal = getParamValue(decoded, 'interactionCount');
  const interactionCount = interactionCountVal
    ? parseInt(interactionCountVal, 10)
    : 0;

  if (interactionCount <= 0) {
    return false;
  }

  const groupsValue = getParamValue(decoded, 'groups');
  if (!groupsValue) {
    return false;
  }

  const categories = groupsValue.split(',');
  for (const category of categories) {
    const [catId, catStatus] = category.split(':');
    if (catId === 'C0004' && catStatus === '1') {
      return true;
    }
  }

  return false;
}

/*
 * If the third party library is analytical it should not be enabled on WEB Mode until the user accepts
 * the cookies. In the other enviornments it should be enabled right away.
 */
export const addThirdPartyLibraryScript = (
  script,
  { isAnalitical = true } = {}
) => {
  if (
    isAnalitical &&
    isWeb &&
    !isLocalhost &&
    !hasUserAcceptedOneTrustTargeting()
  ) {
    script.type = 'text/plain';
    script.classList.add('optanon-category-C0002');
  }
  return script;
};

export default { addThirdPartyLibraryScript };
