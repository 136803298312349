import ColorSpace from '~/v3/constants/color-space';
import { Cmyk, Lab, Rgb } from '~/v3/types/pantone-color';
import { ColorSpaceValues } from '../types/color-space';

function getColorSpaceValues({
  colorSpace,
  colorSpaceValues,
}: {
  colorSpace: ColorSpace;
  colorSpaceValues?: ColorSpaceValues;
}) {
  if (colorSpace === ColorSpace.LAB) {
    return `colorLAB: { l: ${(colorSpaceValues as Lab).l}, a: ${
      (colorSpaceValues as Lab).a
    }, b: ${(colorSpaceValues as Lab).b} }`;
  }

  if (colorSpace === ColorSpace.RGB) {
    return `colorRGB: { r: ${(colorSpaceValues as Rgb).r}, g: ${
      (colorSpaceValues as Rgb).g
    }, b: ${(colorSpaceValues as Rgb).b} }`;
  }

  if (colorSpace === ColorSpace.HEX) {
    return `colorHex: "${colorSpaceValues as string}"`;
  }

  if (colorSpace === ColorSpace.CMYK) {
    return `colorCMYK: { c: ${(colorSpaceValues as Cmyk).c}, m: ${
      (colorSpaceValues as Cmyk).m
    }, y: ${(colorSpaceValues as Cmyk).y}, k: ${
      (colorSpaceValues as Cmyk).k
    } }`;
  }

  throw new Error(`Invalid color space: ${colorSpace}`);
}

export default getColorSpaceValues;
