export const listChipMinWidth = 345;
export const listChipHeight = 66;
export const listChipGap = 8;
export const chipLargeWidth = 345;
export const chipLargeHeight = chipLargeWidth + chipLargeWidth / 3;
export const leftClickMenuWidth = 150;
export const extractChipWidth = 224;

// These constants ensure that the proportions of the Pantone chip
// will always stay the same, based on the width of the chip
// NOTE: The values are taken from the Pantone Brand Guidelines

export const pantoneLogoWidthRatio = 185 / 345;
export const pantoneLogoHeightRatio = 27 / 345;
export const pantoneLogoMarginRatio = 14 / 345;

export const colorCodeFontRatio = 30.38 / 345;
export const colorCodeMarginRatio = 18 / 345;

export const colorNameFontRatio = 30.38 / 345;

export const pantoneChipInfoAreaPaddingRatio = 23 / 345;
