import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import colors from '~/v3/theme/colors';

type Props = BoxProps & {
  size?: number;
  dataId?: string;
};

function Circle({ sx, size = 4, dataId = 'circle' }: Props) {
  return (
    <Box
      data-testid={dataId}
      sx={{
        width: size,
        height: size,
        backgroundColor: colors.pantoneLogoBlue,
        borderRadius: '50%',
        ...sx,
      }}
    />
  );
}

export default Circle;
