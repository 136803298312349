/* eslint-disable prettier/prettier */
const HOSTNAME = 'https://www.pantone.com';
const HOSTNAME_CHS = 'https://www.pantonecn.com';

export default {
  CONTACT: `${HOSTNAME}/contact-us`,
  CONTACT_SALES: `${HOSTNAME}/contact-pantone-connect`,
  I18N: {
    de: `${HOSTNAME}/eu/de`,
    es: `${HOSTNAME}/eu/es`,
    fr: `${HOSTNAME}/eu/fr`,
    it: `${HOSTNAME}/eu/it`,
    pt: `${HOSTNAME}/eu/en`,
    en: HOSTNAME,
    ja: HOSTNAME,
    ko: HOSTNAME,
    cht: `${HOSTNAME}/hk/tc`,
    chs: HOSTNAME_CHS,
  },
  COLOR: {
    de: `${HOSTNAME}/eu/de/connect`,
    es: `${HOSTNAME}/eu/es/connect`,
    fr: `${HOSTNAME}/eu/fr/connect`,
    it: `${HOSTNAME}/eu/it/connect`,
    pt: `${HOSTNAME}/eu/en/connect`,
    en: `${HOSTNAME}/connect`,
    ja: `${HOSTNAME}/connect`,
    ko: `${HOSTNAME}/connect`,
    chs: `${HOSTNAME_CHS}/connect`,
    cht: `${HOSTNAME}/hk/tc/connect`,
  },
  COLOR_CARD: {
    de: `${HOSTNAME}/eu/de/pantone-color-match-card`,
    es: `${HOSTNAME}/eu/es/pantone-color-match-card`,
    fr: `${HOSTNAME}/eu/fr/pantone-color-match-card`,
    it: `${HOSTNAME}/eu/it/pantone-color-match-card`,
    pt: `${HOSTNAME}/eu/en/pantone-color-match-card`,
    en: `${HOSTNAME}/pantone-color-match-card`,
    ja: `${HOSTNAME}/pantone-color-match-card`,
    ko: `${HOSTNAME}/pantone-color-match-card`,
    chs: `${HOSTNAME_CHS}/pantone-color-match-card.html`,
    cht: `${HOSTNAME}/hk/tc/pantone-color-match-card`,
  },
  COLOR_SYSTEMS: {
    de: `${HOSTNAME}/eu/de/farbsysteme/insiderwissen-zu-den-pantone-farbsystemen-einfuhrung`,
    es: `${HOSTNAME}/eu/es/sistemas-de-color/sistemas-color-intro`,
    fr: `${HOSTNAME}/eu/fr/systemes-de-couleurs/systemes-de-couleurs-introduction`,
    it: `${HOSTNAME}/eu/it/color-systems/pantone-color-systems-explained`,
    pt: `${HOSTNAME}/eu/en/color-systems/pantone-color-systems-explained`,
    en: `${HOSTNAME}/color-systems/pantone-color-systems-explained`,
    ja: `${HOSTNAME}/color-systems/pantone-color-systems-explained`,
    ko: `${HOSTNAME}/color-systems/pantone-color-systems-explained`,
    chs: `${HOSTNAME_CHS}/color-systems-intro`,
    cht: `${HOSTNAME}/hk/tc/color-systems/pantone-color-systems-explained`,
  },
  PRIVACY_POLICY: {
    de: `${HOSTNAME}/eu/de/datenschutz`,
    es: `${HOSTNAME}/eu/es/politica-de-confidencialidad`,
    fr: `${HOSTNAME}/eu/fr/politique-de-respect-de-la-vie-privee`,
    it: `${HOSTNAME}/eu/it/politica-di-privacy`,
    pt: `${HOSTNAME}/eu/en/privacy-policy`,
    en: `${HOSTNAME}/privacy-policy`,
    ja: `${HOSTNAME}/privacy-policy`,
    ko: `${HOSTNAME}/privacy-policy`,
    chs: `${HOSTNAME_CHS}/privacy-policy`,
    cht: `${HOSTNAME}/hk/tc/privacy-policy`,
    link: 'https://www.pantone.com/about/privacy-policy',
  },
  BOOK: {
    pantoneSolidCoated: 'formula-guide-coated-uncoated',
    pantoneSolidUncoated: 'formula-guide-coated-uncoated',
    pantoneColorBridgeCoated: 'color-bridge-guide-coated',
    pantoneColorBridgeUncoated: 'color-bridge-guide-uncoated',
    pantoneExtendedGamutCoated: 'extended-gamut-coated-guide',
    pantoneMetallicsSolidCoated: 'metallic-chips-book',
    pantonePastelsNeonsCoated: 'pastels-neons-guide-coated-uncoated',
    pantonePastelsNeonsUncoated: 'pastels-neons-guide-coated-uncoated',
    pantoneFhCottonTcx: 'fashion-home-interiors-cotton-chip-set',
    pantoneFhCottonTcxExtended: 'fashion-home-interiors-cotton-chip-set',
    pantoneFhiPaperTpg: 'fashion-home-interiors-color-guide',
    pantoneFhiPaperTpgExtended: 'fashion-home-interiors-color-guide',
    pantoneFhNylonBrightsTn: 'nylon-brights-set',
    pantoneFhiPolyesterTsx: 'polyester-swatch-book',
    pantoneFhiMetallicShimmersTpm:
      'fashion-home-interiors-fhi-metallic-shimmers-color-guide',
    pantoneCmykCoated: 'cmyk-guide-coated-uncoated',
    pantoneCmykUncoated: 'cmyk-guide-coated-uncoated',
    pantoneSkinToneGuide: 'pantone-skintone-guide',
  },
  TOU: {
    de: 'https://www.pantone.com/eu/de/nutzungsbedingungen',
    en: 'https://www.pantone.com/terms-of-use',
    es: 'https://www.pantone.com/eu/es/condiciones-de-uso',
    fr: 'https://www.pantone.com/eu/fr/conditions-dutilisation',
    it: 'https://www.pantone.com/eu/it/articoli',
    pt: 'https://www.pantone.com/eu/en/terms-of-use',
    ja: 'https://www.pantone.com/terms-of-use',
    ko: 'https://www.pantone.com/terms-of-use',
    chs: 'https://www.pantone.com/terms-of-use',
    cht: 'https://www.pantone.com/hk/en/terms-of-use',
  },
  FEEDBACK: {
    WEB: 'https://surveys.hotjar.com/s?siteId=797391&surveyId=131517',
    EXTENSION: 'https://surveys.hotjar.com/s?siteId=797391&surveyId=158106',
    EXTENSION_CHS:
      'https://surveys.hotjar.com/b6bdea4f-dfd5-4655-a15b-af0d447c8322',
    EXTENSION_CHT:
      'https://surveys.hotjar.com/d050545f-529a-411a-a633-3e70fa5d0f62',
  },
  PLATFORMS: {
    WEB: `${HOSTNAME}/connect`,
    IOS: 'https://apps.apple.com/us/app/pantone-connect/id1491023737',
    ADOBE:
      'https://exchange.adobe.com/creativecloud.details.103029.pantone-connect.html',
    ANDROID:
      'https://play.google.com/store/apps/details?id=com.pantone.xrite.pantoneconnect',
  },
  FASTSPRING_SUPPORT_URL: 'https://fastspring.com/question-a-charge',
  MEASURE_URL: 'pantone://measure',
  PANORAMA_URL: 'https://smart.pantone.com/panorama',
  PANTONE_COY_2023_URL: 'https://www.pantone.com/color-of-the-year',
  COY_URL: 'https://www.pantone.com/color-of-the-year-2023',
  NEW_COLORS_URL: '/new-colors-2023',
  PROMO_CODE_FAQ:
    'https://intercom.help/pantone/en/articles/7987840-using-promotional-code-during-pantone-connect-purchase',
};
