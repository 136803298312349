import { styled, Theme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import UserProfileMenu from '~/v2/components/AppBar/UserProfileMenu';
import colors from '~/v3/theme/colors';
import CustomerServiceButton from './customer-service-button';
import MyPalettesButton from './my-palettes-button';
import PantoneConnectHeaderLogo from './pantone-connect-header-logo';
import SearchBar from './search-bar';
import headerNavigationItems from './navigation';
import {
  headerBorderBottomSize,
  headerHeight,
  scaledHeaderPadding,
} from '../model/constants';

const StyledBox = styled(Box)(({ theme }) => ({
  height: headerHeight,
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: theme.spacing(0, scaledHeaderPadding),
  borderBottom: `${headerBorderBottomSize}px solid ${colors.whiteGray}`,
  backgroundColor: theme.palette.background.paper,
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(0, 1.5),
  },
  [theme.breakpoints.down(380)]: {
    height: `calc(${headerHeight}px - 10px)`,
  },
}));

const ProfileInfo = styled(Box)(() => ({
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
}));

function Header({ sx }: BoxProps) {
  const isLargeScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.up('md')
  );

  return (
    <StyledBox sx={sx} data-testid="header-v3">
      <Box display="flex" alignItems="center" height="100%">
        <PantoneConnectHeaderLogo />
        {isLargeScreen &&
          headerNavigationItems.map((HeaderNavigationItem) => (
            <HeaderNavigationItem key={HeaderNavigationItem.name} />
          ))}
      </Box>

      <ProfileInfo>
        {isLargeScreen && <MyPalettesButton />}

        <SearchBar />

        <CustomerServiceButton />

        <UserProfileMenu />
      </ProfileInfo>
    </StyledBox>
  );
}

export default Header;
