import { batch, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useTracker from 'analytics/useTracker';
import useConfirm from 'features/ConfirmDialog/useConfirm';
import {
  selectUnshavedChanges as selectUnsavedChangesPalette,
  reset as resetPalette,
} from 'features/PaletteWorkspace/paletteSlice';
import {
  selectUnshavedChanges as selectUnsavedChangesMoodboard,
  reset as resetMoodboard,
} from 'features/MoodboardWorkspace/moodboardSlice';
import { setWorkingColor, selectWorkingColor } from './workspaceSlice';

// TODO: Implement better solution in the future.
function useWorkspace() {
  const dispatch = useDispatch();
  const { confirm } = useConfirm();
  const { t } = useTranslation();
  const ga = useTracker();
  const unsavedPaletteChanges = useSelector(selectUnsavedChangesPalette);
  const unsavedMoodboardChanges = useSelector(selectUnsavedChangesMoodboard);
  const workingColor = useSelector(selectWorkingColor);

  const setColor = (color) => {
    dispatch(setWorkingColor(color));
  };

  const resetWorkspaces = async (callback) => {
    if (unsavedPaletteChanges || unsavedMoodboardChanges) {
      const isConfirmed = await confirm({
        content: t('messages.workspaceUnsavedChanges'),
        cancelLabel: t('labels.goBack'),
        confirmLabel: t('labels.continueUnsaved'),
      });

      if (isConfirmed) {
        batch(() => {
          dispatch(resetPalette());
          dispatch(resetMoodboard());
        });
        callback();
        ga.trackWorkspaceEvent({ action: 'resetConfirm' });
      } else {
        ga.trackWorkspaceEvent({ action: 'resetCancel' });
      }
    } else {
      callback();
    }
  };

  return {
    resetWorkspaces,
    setColor,
    workingColor,
  };
}

export default useWorkspace;
