export const PANTONE_WEB_REDIRECT = 'PANTONE_WEB_REDIRECT';
export const USER_REDIRECTED_FROM_PANTONE = 'USER_REDIRECTED_FROM_PANTONE';
export const SUBSCRIPTION_TYPE = 'SUBSCRIPTION_TYPE';
export const REDIRECT_PANTONE_USER_TO_EXPLORE =
  'REDIRECT_PANTONE_USER_TO_EXPLORE';
export const ARTICLE_ID = 'ARTICLE_ID';
export const USED_PRODUCT_IDS_FOR_PAYLOAD = 'productIds';
export const SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE =
  'SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE';
export const COUPON = 'coupon';
