import { useCallback, useEffect } from 'react';

import useScript from '~/v3/hooks/use-script';
import { useUserContext } from '~/v3/providers/UserProvider';
import { FastSpringOrder } from '~/v3/types/fastspring';
import { getSessionStorage, removeSessionStorage } from '~/v3/utils/storage';
import { isProduction } from '~/v3/config';
import { USED_PRODUCT_IDS_FOR_PAYLOAD } from '~/v3/constants/storage';

type UseFastSpringPopupProps = {
  onSubscribe?: (
    productIds: string[] | null,
    orderReference: FastSpringOrder | null
  ) => Promise<void>;
  onClose?: (productIds: string[] | null) => void;
};

const FASTSPRING_SDK_URL =
  'https://sbl.onfastspring.com/sbl/1.0.1/fastspring-builder.min.js';

const FASTSPRING_STOREFRONT_DOMAIN = isProduction
  ? 'pantone.onfastspring.com'
  : 'pantone.test.onfastspring.com';
const FASTSPRING_DEFAULT_STOREFRONT = '/popup-premium-v2';
const FASTSPRING_CHINA_STOREFRONT = '/popup-premium-china';

const FASTSPRING_ACCESS_KEY = 'B6WNBHOQT02OVK_MKLSS1G';

export type FastSpringScriptAttributes = {
  'data-storefront': string;
  'data-access-key': string;
  'data-continuous': string;
  'data-popup-closed': string;
};

const useFastSpringPopupStore = ({
  onSubscribe,
  onClose,
}: UseFastSpringPopupProps) => {
  const { country } = useUserContext();

  const status = useScript<FastSpringScriptAttributes>(FASTSPRING_SDK_URL, {
    id: 'fsc-api',
    'data-storefront': `${FASTSPRING_STOREFRONT_DOMAIN}${
      country.isFromChina
        ? FASTSPRING_CHINA_STOREFRONT
        : FASTSPRING_DEFAULT_STOREFRONT
    }`,
    'data-access-key': FASTSPRING_ACCESS_KEY,
    'data-continuous': 'true',
    type: 'text/javascript',
    'data-popup-closed': 'handleClose',
  });

  const handleCloseFastSpringPopup = useCallback(
    async (orderReference: FastSpringOrder | null) => {
      const hasUserPurchasedSubscription = !!orderReference;
      const usedProductIds = getSessionStorage<string[]>(
        USED_PRODUCT_IDS_FOR_PAYLOAD,
        true
      ) as string[] | null;

      removeSessionStorage(USED_PRODUCT_IDS_FOR_PAYLOAD);

      if (onSubscribe && hasUserPurchasedSubscription) {
        await onSubscribe(usedProductIds, orderReference);
      }

      if (onClose) {
        onClose(usedProductIds);
      }
    },
    [onSubscribe, onClose]
  );

  const handleOpenFastSpringPopup = (
    securePayload: string,
    secureKey: string
  ) => {
    window.fastspring.builder.reset();
    window.fastspring.builder.secure(securePayload, secureKey);
    window.fastspring.builder.checkout();
  };

  useEffect(() => {
    if (status === 'ready') {
      window.handleClose = handleCloseFastSpringPopup;
    }
  }, [status, handleCloseFastSpringPopup]);

  return { handleOpenFastSpringPopup, handleCloseFastSpringPopup };
};

export default useFastSpringPopupStore;
