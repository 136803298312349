import routes from 'constants/route';
import {
  USER_REDIRECTED_FROM_PANTONE,
  SUBSCRIPTION_TYPE,
  PANTONE_WEB_REDIRECT,
  REDIRECT_PANTONE_USER_TO_EXPLORE,
} from '~/v3/constants/storage';
import useUserHasSubscribed from 'pages/Paywall/useUserHasSubscribed';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'services/authApi';
import useRouter from '~/v3/hooks/use-router';
import { getSessionStorage, removeSessionStorage } from '~/v3/utils/storage';
import usePurchaseFastSpringSubscription from '~/v3/paywall/components/pricing-plans-cards/hooks/use-purchase-fastspring-subscription';
import RoutePathname from '../constants/route';

function useDidUserSignInWithSSO() {
  const { location, query } = useRouter();
  const { data: user } = useSelector(selectCurrentUser);
  const { onSubscribed } = useUserHasSubscribed();
  const { onPurchase } = usePurchaseFastSpringSubscription(onSubscribed);
  const userComesFromPantone = getSessionStorage(USER_REDIRECTED_FROM_PANTONE);
  const subscriptionPlan = getSessionStorage(SUBSCRIPTION_TYPE, true);
  const colorCode = getSessionStorage(PANTONE_WEB_REDIRECT);
  const { push } = useRouter();

  const handleSuccessfulSSO = async () => {
    if (userComesFromPantone && colorCode && subscriptionPlan && user) {
      onPurchase(1, subscriptionPlan.type, subscriptionPlan.product);
    }
  };

  useEffect(() => {
    if (location.pathname === routes.auth.root) {
      if (query.code) {
        handleSuccessfulSSO();
      }
      if (getSessionStorage(REDIRECT_PANTONE_USER_TO_EXPLORE)) {
        removeSessionStorage(REDIRECT_PANTONE_USER_TO_EXPLORE);
        push(RoutePathname.insight);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, user]);
}

export default useDidUserSignInWithSSO;
