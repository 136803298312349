import { useEffect, useState } from 'react';

function useDialog(isDialogInitiallyOpen = false) {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(
    isDialogInitiallyOpen
  );

  useEffect(() => {
    if (isDialogInitiallyOpen) {
      setIsDialogOpen(true);
    }
  }, [isDialogInitiallyOpen]);

  const onOpenDialog = () => setIsDialogOpen(true);

  const onCloseDialog = () => setIsDialogOpen(false);

  return {
    isDialogOpen,
    onOpenDialog,
    onCloseDialog,
  };
}

export default useDialog;
