import { useState } from 'react';
import { useGetMoodboardsQuery } from '~/v2/services/moodboardApi';
import { ColorStory } from '../types/color-story';

export const useMoodboardsInfiniteScroll = () => {
  const [nextToken, setNextToken] = useState<string | null>();

  const { data: combinedPages } = useGetMoodboardsQuery(undefined) as {
    data: {
      moodboards: ColorStory[];
    };
  };

  const {
    data: currentPage,
    isFetching,
    error,
  } = useGetMoodboardsQuery(nextToken, {
    skip: nextToken === null,
  }) as {
    data: {
      moodboards: ColorStory[];
      nextToken: string | null;
    };
    isFetching: boolean;
    error: unknown;
  };

  return {
    moodboards: combinedPages?.moodboards,
    isFetching,
    hasMoreData: !!currentPage?.nextToken,
    loadMoreData: () => setNextToken(currentPage?.nextToken),
    error,
  };
};
