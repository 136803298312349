import React from 'react';
import { rootElementIdSelector } from '~/v3/constants/root-element-id-selector';
import { useTranslation } from 'react-i18next';
import { htmlLanguageRegex } from '~/v3/constants/regex';
import cookiePolicyContent from '~/v3/components/shared/policies/cookie-policy.html';

function CookiePolicy() {
  const { i18n } = useTranslation();

  const content = cookiePolicyContent.replace(
    htmlLanguageRegex,
    `lang="${i18n.language}-${i18n.language}"`
  );

  return (
    <>
      <style>
        {`[class*="appRoutes"] {
               padding: 0;
        }

        div[id="${rootElementIdSelector}"] {
          overflow: hidden;
        }
        `}
      </style>
      <iframe
        title="cookie-policy"
        id="cookie-policy"
        name="cookies-iframe"
        srcDoc={content}
        sandbox="allow-scripts allow-top-navigation allow-popups"
        style={{ height: '100%', width: '100%', border: 0 }}
      />
    </>
  );
}

export default CookiePolicy;
