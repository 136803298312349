import * as Sentry from '@sentry/react';
import { useCallback, useEffect } from 'react';
import { BaseRoutePaths } from '~/v2/constants/route';
import { isAdobe } from '~/v2/utils/platform';
import { BOOKS } from '~/v3/constants/books';

import useTracker from '~/v2/analytics/useTracker';
import { unsubscribeMeasurements } from '~/v3/api/measure-api';
import { errorRedirectingToColorDataEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import useSubscribeMeasurements from '~/v3/react-query/hooks/use-subscribe-measurements';

function useVisualizeWebsockets() {
  const { push } = useRouter();
  const ga = useTracker();
  const { mutate } = useSubscribeMeasurements({
    onError: (error) => {
      Sentry.captureException(error);
      ga.trackEvent(errorRedirectingToColorDataEvent);
    },
  });

  const goToColorData = useCallback(
    ({ colorCode }: { colorCode: string }) => {
      const codeFormatted = colorCode.split(' ').join('_');

      push(`${BaseRoutePaths.info}/${codeFormatted}?tab=info`);
    },
    [push]
  );

  useEffect(() => {
    mutate(({ bookId: colorCode }) => {
      if (!isAdobe || !colorCode) return;

      // For now we use the Measure API to get the imported color
      // as we are saving a color code inside the `measureBook` variable, we check if the variable value is not a Pantone book
      const isGoingToColorData = !Object.keys(BOOKS).some(
        (book) => book === colorCode
      );

      if (isGoingToColorData) {
        goToColorData({ colorCode });
      }
    });

    return () => {
      unsubscribeMeasurements();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useVisualizeWebsockets;
