import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import useTracker from 'analytics/useTracker';
import useHover from 'hooks/useHover';
import { isAdobe } from 'utils/platform';
import adobe from 'platforms/adobe';
import config from 'config';
import { isCoY, isPanoramaBook } from 'utils/color';
import usePaywall from 'hooks/usePaywall';
import useColorMenu from 'features/ColorMenu/useColorMenu';

import {
  isTouchEnabled,
  dragAndDropVariants,
} from '~/v3/constants/drag-and-drop';
import { useLazyGetSubscriptionQuery } from '~/v3/react-query/hooks/use-get-subscription';
import { COY_COLOR_CODE } from '~/v3/config';
import eyedropperCursor from '../assets/images/eyedropper.png';

const useStyles = makeStyles((theme) => ({
  root: {
    width: 136,
    maxWidth: 136,
    height: ({ fixedSize }) => (fixedSize ? 202 : null),
    display: 'flex',
    flexFlow: 'column',
    alignSelf: 'flex-start', // override flex equal height of columns
    cursor: 'pointer',
  },
  skeleton: {
    width: 136,
    maxWidth: 136,
    height: 203,
    border: '1px solid transparent',
    borderColor: theme.palette.whiteGrey[400],
    background: theme.palette.background.secondary,
  },
  chipColor: {
    height: 112,
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& $iconIndicator': {
        opacity: 1,
      },
    },
  },
  adobeCursor: {
    cursor: `url(${eyedropperCursor}) 0 20, auto`,
  },
  iconIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
    backgroundColor: 'transparent',
    width: 20,
    height: 20,
  },
  chipDetails: {
    flex: 1,
    borderStyle: 'solid',
    borderColor: 'transparent',
    borderWidth: '0px 1px 1px 1px',
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(1, 1, 2),
    overflow: 'hidden',
  },
  chipDetailsOutlined: {
    borderColor: theme.palette.divider,
  },
  pantoneOverline: {
    lineHeight: '18px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  colorCode: {
    lineHeight: '22px',
    fontWeight: 500,
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },

  bookOverline: {
    fontWeight: '500',
    lineHeight: '18px',
    textTransform: 'capitalize',
    paddingTop: theme.spacing(0.5),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  dataBlurred: {
    filter: 'blur(5px)',
  },
}));

function PantoneChip({
  color,
  variant,
  fixedSize,
  analytics,
  shadow,
  ...props
}) {
  const colorDataExists = !!(color?.rgb || color?.hex || color?.lab);
  const classes = useStyles({ fixedSize });
  const theme = useTheme();
  const [hoverRef, isHovered] = useHover();
  const { open } = useColorMenu();
  const [, drag, preview] = useDrag(() => ({
    type: dragAndDropVariants.PANTONE_CHIP_SMALL,
    item: { color },
  }));
  const ga = useTracker();
  const { triggerPaywall, paywallCases } = usePaywall();
  const { trigger } = useLazyGetSubscriptionQuery();

  const handleMenuOpen = async (event) => {
    const lazySubscriptionData = await trigger();
    if (event.nativeEvent.type === 'contextmenu' && isTouchEnabled) return;
    event.preventDefault();

    const isMouseRightClick = event.button === 2;

    if (!isAdobe || (isAdobe && isMouseRightClick)) {
      open({
        anchorEl: event.target,
        color,
        setIsInWorkspace: false,
        analytics,
        anchorPosition: {
          left: event.clientX,
          top: event.clientY,
        },
      });
    } else {
      if (
        !isCoY(COY_COLOR_CODE, color) &&
        !lazySubscriptionData?.active &&
        config.PAYWALL_ENABLED
      ) {
        // Prevent free users on Adobe to add colors to the foreground/background color
        triggerPaywall(paywallCases.adobe.id);
        return;
      }
      adobe.handleColorEvent(event, color);
    }
    ga.trackChipEvent({ action: 'click', ...analytics });
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (variant === 'skeleton') return <div className={classes.skeleton} />;
  if (!color) return null;

  const handleMenuOpenAdobe = (event) => {
    event.stopPropagation();
    open({
      anchorEl: event.target,
      color,
      setIsInWorkspace: false,
      anchorPosition: {
        left: event.clientX,
        top: event.clientY,
      },
    });
  };

  const backgroundColor = `#${color.hex}`;

  return (
    <Paper
      {...props}
      ref={hoverRef}
      elevation={isHovered || shadow ? 2 : 0}
      className={classes.root}
      onClick={handleMenuOpen}
      onContextMenu={handleMenuOpen}
      square
      data-testid={`pantone-chip-${color?.code}`}
    >
      <div ref={drag}>
        <div
          className={classNames(classes.chipColor, {
            [classes.adobeCursor]: isAdobe,
          })}
          style={{ backgroundColor }}
        >
          {isAdobe && (
            <IconButton
              className={classes.iconIndicator}
              size="small"
              onClick={handleMenuOpenAdobe}
            >
              <MoreVertIcon
                style={{
                  fontSize: 20,
                  color: theme.palette.isDark(color?.rgb)
                    ? theme.palette.primary.contrastText
                    : theme.palette.text.primary,
                }}
              />
            </IconButton>
          )}
        </div>
        <div
          className={classNames(
            classes.chipDetails,
            classes.chipDetailsOutlined
          )}
        >
          {isPanoramaBook(color) ? (
            <Typography variant="subtitle2">Color Panorama</Typography>
          ) : (
            <Typography
              component="p"
              variant="overline"
              color="textSecondary"
              className={classes.pantoneOverline}
            >
              PANTONE
            </Typography>
          )}

          <Typography
            variant="subtitle2"
            className={classNames(classes.colorCode, {
              [classes.dataBlurred]: !colorDataExists,
            })}
            data-testid="colorcode"
          >
            {color.code}
          </Typography>
          <Typography
            component="p"
            variant="caption"
            color="textPrimary"
            className={classNames(classes.bookOverline, {
              [classes.dataBlurred]: !colorDataExists,
            })}
          >
            {color.name || '\u00A0'}
          </Typography>
        </div>
      </div>
    </Paper>
  );
}

PantoneChip.defaultProps = {
  variant: null,
  fixedSize: false,
  analytics: {},
  shadow: false,
};

PantoneChip.propTypes = {
  color: PropTypes.shape().isRequired,
  variant: PropTypes.oneOf(['outlined', 'skeleton']),
  // Sets chip size to a fixed value.
  // This is to avoid uneven sizes when chip is used in a palette or any other view and color name is not provided.
  fixedSize: PropTypes.bool,
  analytics: PropTypes.shape({}),
  shadow: PropTypes.bool,
};

export default PantoneChip;
