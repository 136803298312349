import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginLeft: -theme.spacing(0.5),
    minWidth: theme.spacing(2.5),
    minHeight: theme.spacing(2.5),
    padding: theme.spacing(0.5),
    borderRadius: theme.spacing(3),
    background: theme.palette.primary.light,
  },

  label: {
    lineHeight: '1.01em',
    color: theme.palette.primary.contrastText,
    fontSize: '12px',
    textAlign: 'center',
  },
}));

function CollaboratorCount({ count }) {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Typography className={classes.label}>{count}</Typography>
    </div>
  );
}

CollaboratorCount.propTypes = {
  count: PropTypes.number.isRequired,
};

export default CollaboratorCount;
