import React from 'react';
import Box from '@mui/material/Box';
import CustomerServiceIcon from '~/v3/components/shared/icons/customer-service-icon';
import { openIntercomButtonIdSelector } from '~/v3/constants/intercom';
import useTracker from '~/v2/analytics/useTracker';
import { SxProps, useTheme, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { customerServiceEvent } from '~/v3/events';
import {
  customerServiceButtonWidth,
  headerItemMobileWidth,
} from '../../model/constants';

function CustomerServiceButton() {
  const ga = useTracker();
  const theme = useTheme();

  const { t } = useTranslation();

  const onHelpNavigationItemClick = () => {
    ga.trackEvent(customerServiceEvent);
  };

  const customerServiceButtonStyle: SxProps = {
    width: customerServiceButtonWidth,
    height: '100%',
    cursor: 'pointer',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: headerItemMobileWidth,
      marginRight: '4px',
    },
  };

  return (
    <Tooltip title={t('labels.customerSupport')}>
      <Box
        data-testid="customer-service"
        sx={customerServiceButtonStyle}
        id={openIntercomButtonIdSelector}
        onClick={onHelpNavigationItemClick}
      >
        <CustomerServiceIcon
          sx={{
            [theme.breakpoints.down('sm')]: {
              height: 19,
              width: 18,
            },
          }}
        />
      </Box>
    </Tooltip>
  );
}

export default CustomerServiceButton;
