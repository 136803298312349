import {
  List,
  Accordion,
  AccordionProps,
  accordionClasses,
  styled,
  AccordionDetails,
  AccordionDetailsProps,
  AccordionSummary,
  AccordionSummaryProps,
} from '@mui/material';
import React from 'react';
import colors from '~/v3/theme/colors';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';

export const SidebarList = styled(List)({
  padding: 0,
});

export const AccordionSidebar = styled((props: AccordionProps) => (
  <Accordion data-testid="accordion" {...props} />
))(({ theme }) => ({
  marginBottom: theme.spacing(1),
  [`&.${accordionClasses.root}`]: { borderBottom: 0 },
}));

export const AccordionDetailsSidebar = styled(
  (props: AccordionDetailsProps) => (
    <AccordionDetails data-testid="accordion-details" {...props} />
  )
)(({ theme }) => ({
  backgroundColor: colors.white,
  padding: theme.spacing(0, 0, 2),
}));

export const AccordionSummarySidebar = styled(
  (props: AccordionSummaryProps) => (
    <AccordionSummary
      sx={{
        '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
          transform: 'rotate(90deg)',
        },
      }}
      data-testid="accordion-summary"
      expandIcon={
        <KeyboardArrowRightIcon
          sx={{
            fontSize: 18,
            color: colors.black,
          }}
        />
      }
      {...props}
    />
  )
)(({ theme }) => ({
  minHeight: 'unset',
  '& .MuiAccordionSummary-content.MuiAccordionSummary-contentGutters': {
    fontSize: 10,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: 0.8,
    textTransform: 'uppercase',
    color: colors.black,
  },
  '&.MuiAccordionSummary-root.MuiAccordionSummary-gutters': {
    padding: theme.spacing(0, 0, 1),
  },
  '&.MuiAccordionSummary-root.Mui-expanded.MuiAccordionSummary-gutters': {
    minHeight: 'unset',
    padding: theme.spacing(0, 0, 1),
    backgroundColor: colors.white,
  },
  '&.MuiAccordionSummary-root.MuiAccordionSummary-gutters: hover': {
    backgroundColor: colors.white,
  },
  '& .MuiAccordionSummary-content.Mui-expanded': {
    margin: 0,
  },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));
