import React from 'react';

import {
  LeftMenuVariants,
  LeftClickMenu,
} from '~/v3/components/shared/menus/left-click-menu';
import useColorMenu from './useColorMenu';

function ColorMenuPopover() {
  // TODO: Deprecate this component once we port the new menu design to v3
  const { isOpen, close, anchorPosition, color, isInWorkspace } =
    useColorMenu();

  if (!color) return null;
  return (
    <LeftClickMenu
      variant={LeftMenuVariants.COLOR_INFO}
      color={color}
      onClose={close}
      open={isOpen}
      anchorPosition={anchorPosition}
      isInWorkspace={isInWorkspace}
    />
  );
}

export default ColorMenuPopover;
