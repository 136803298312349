import { useSelector, useDispatch } from 'react-redux';
import { open, close, selectConfirmDialog } from './confirmSlice';

let resolveCallback;

function useConfirm() {
  const dispatch = useDispatch();
  const confirmDialog = useSelector(selectConfirmDialog);

  const onCancel = () => {
    dispatch(close());
    resolveCallback(false);
  };

  const onConfirm = () => {
    dispatch(close());
    resolveCallback(true);
  };

  const confirm = (payload) => {
    dispatch(open(payload));

    return new Promise((res) => {
      resolveCallback = res;
    });
  };

  return {
    ...confirmDialog,
    confirm,
    onCancel,
    onConfirm,
  };
}

export default useConfirm;
