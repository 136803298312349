import { useTheme } from '@mui/material';
import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import { getPromotionPath } from '~/v3/components/features/promotions/lib/utils';
import { createMediaQuery } from '../utils';
import { tabletScreenBreakpoints } from '../../model/constants';
import { TabletBreakpoint } from '../../model/types';

export function useGetMediaQueriesTabletScreen({
  promotion,
  language,
  area,
}: {
  promotion: Promotion;
  language: Language;
  area: PromotionsArea;
}) {
  const theme = useTheme();

  const tabletMediaQueries = Object.keys(tabletScreenBreakpoints).reduce(
    (queries, key) => {
      const breakpoint = tabletScreenBreakpoints[key as TabletBreakpoint];
      return {
        ...queries,
        [createMediaQuery(breakpoint.width, breakpoint.height)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint,
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
        },
        [createMediaQuery(breakpoint.height, breakpoint.width)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint: { height: breakpoint.width, width: breakpoint.height },
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
        },
        [theme.breakpoints.up(breakpoint.width)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint,
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        },
      };
    },
    {}
  );

  return tabletMediaQueries;
}
