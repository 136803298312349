import HelveticaNowDisplayLight from '../assets/fonts/HelveticaNowDisplay-Light.woff2';
import HelveticaNowDisplayRegular from '../assets/fonts/HelveticaNowDisplay-Regular.woff2';
import HelveticaNowDisplayMedium from '../assets/fonts/HelveticaNowDisplay-Medium.woff2';
import HelveticaNowDisplayBold from '../assets/fonts/HelveticaNowDisplay-Bold.woff2';
import HelveticaNowTextLight from '../assets/fonts/HelveticaNowText-Light.woff2';
import HelveticaNowTextRegular from '../assets/fonts/HelveticaNowText-Regular.woff2';
import HelveticaNowTextMedium from '../assets/fonts/HelveticaNowText-Medium.woff2';
import HelveticaNowTextBold from '../assets/fonts/HelveticaNowText-Bold.woff2';
import HelveticaNowDisplayBlack from '../assets/fonts/HelveticaNowDisplay-Black.woff2';

export const helveticaNowDisplayLight = {
  fontFamily: 'Helvetica Now Display',
  fontDisplay: 'swap',
  fontWeight: '300',
  src: `url(${HelveticaNowDisplayLight}) format('woff2')`,
};

export const helveticaNowDisplayRegular = {
  fontFamily: 'Helvetica Now Display',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `url(${HelveticaNowDisplayRegular}) format('woff2')`,
};

export const helveticaNowDisplayMedium = {
  fontFamily: 'Helvetica Now Display',
  fontDisplay: 'swap',
  fontWeight: '500',
  src: `url(${HelveticaNowDisplayMedium}) format('woff2')`,
};

export const helveticaNowDisplayBold = {
  fontFamily: 'Helvetica Now Display',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `url(${HelveticaNowDisplayBold}) format('woff2')`,
};

export const helveticaNowDisplayBlack = {
  fontFamily: 'Helvetica Now Display',
  fontDisplay: 'swap',
  fontWeight: '900',
  src: `url(${HelveticaNowDisplayBlack}) format('woff2')`,
};

export const helveticaNowTextLight = {
  fontFamily: 'Helvetica Now Text',
  fontDisplay: 'swap',
  fontWeight: '300',
  src: `url(${HelveticaNowTextLight}) format('woff2')`,
};

export const helveticaNowTextRegular = {
  fontFamily: 'Helvetica Now Text',
  fontDisplay: 'swap',
  fontWeight: '400',
  src: `url(${HelveticaNowTextRegular}) format('woff2')`,
};

export const helveticaNowTextMedium = {
  fontFamily: 'Helvetica Now Text',
  fontDisplay: 'swap',
  fontWeight: '500',
  src: `url(${HelveticaNowTextMedium}) format('woff2')`,
};

export const helveticaNowTextBold = {
  fontFamily: 'Helvetica Now Text',
  fontDisplay: 'swap',
  fontWeight: '700',
  src: `url(${HelveticaNowTextBold}) format('woff2')`,
};

export default [
  helveticaNowDisplayLight,
  helveticaNowDisplayRegular,
  helveticaNowDisplayMedium,
  helveticaNowDisplayBold,
  helveticaNowTextLight,
  helveticaNowTextRegular,
  helveticaNowTextMedium,
  helveticaNowTextBold,
  helveticaNowDisplayBlack,
];
