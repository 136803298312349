/* eslint-disable prettier/prettier */
import linkConstants from 'constants/links';
import i18n from 'i18next';
import routes from 'constants/route';
import adobe from '../platforms/adobe';
import config from '../config';
import { getCurrentLocation } from './urls';
import { isWeb, isAdobe, isAndroid, isIOS } from './platform';
import { isPanoramaBook } from './color';

const currentLang = () => i18n.languages[0] || 'en';

function adobeLinkHandler(url, target) {
  if (adobe.csi.openURLInDefaultBrowser) {
    if (target === '_blank') {
      adobe.csi.openURLInDefaultBrowser(url);
    } else {
      window.location = url;
    }
  } else {
    window.open(url, target);
  }
}

function androidLinkHandler(url, target) {
  if (target === '_blank') {
    window.open(`pantone://link/url/${url}`, '_system');
  } else {
    window.location = url;
  }
}

function iOSLinkHandler(url, target, settings) {
  if (!target) {
    window.location = url;
    return;
  }

  let iOSTarget = target;
  if (target === '_blank') iOSTarget = '_system';

  let options;
  if (settings.externalLinkInWebview) {
    options = `location=no,closebuttoncolor=#ffffff,closebuttoncaption=${i18n.t(
      'labels.close'
    )}`;
    iOSTarget = '_blank';
    const inAppBrowser = window.cordova.InAppBrowser.open(
      url,
      iOSTarget,
      options
    );
    const currentLocation = getCurrentLocation();
    const onLoadStart = (event) => {
      if (event.url.indexOf(currentLocation) === 0) {
        inAppBrowser.removeEventListener(onLoadStart);
        inAppBrowser.close();
        window.location = event.url;
      }
    };
    inAppBrowser.addEventListener('loadstart', onLoadStart);
  } else {
    window.cordova.InAppBrowser.open(url, iOSTarget, options);
  }
}

export function platformLink(url, target, settings = {}) {
  if (isWeb) window.open(url, target);
  if (isIOS) iOSLinkHandler(url, target, settings);
  if (isAdobe) adobeLinkHandler(url, target);
  if (isAndroid) androidLinkHandler(url, target);
}

export function getPrivacyPolicyURL() {
  platformLink(linkConstants.PRIVACY_POLICY.link, '_blank');
}

export function getCookiePolicyURL() {
  const url = `${window.location.origin}/#${routes.cookiePolicy}`;
  platformLink(url, '_blank');
}

export function getCCPAPolicyURL() {
  const url = `${window.location.origin}/#${routes.ccpaPolicy}`;
  platformLink(url, '_blank');
}

export function getPrivacyTouURL() {
  return platformLink(linkConstants.TOU[currentLang()], '_blank');
}

export function getColorURL(colorCode) {
  const code = colorCode.replace(/ /g, '-');
  const url = `${linkConstants.COLOR[currentLang()]}/${code}`;
  platformLink(url, '_blank');
}

export function termsOfUseURL() {
  const url = `${window.location.origin}/#${routes.eula}`;
  platformLink(url, '_blank');
}

export function FaqURL() {
  const url = 'https://intercom.help/pantone/en/';
  platformLink(url, '_blank');
}

export function getPanoramaUrl() {
  platformLink(linkConstants.PANORAMA_URL, '_blank');
}

export function physicalSampleRedirect(color) {
  if (isPanoramaBook(color)) {
    getPanoramaUrl();
    return;
  }
  getColorURL(color.code);
}

const pantoneDomains = ['www.pantone.com', 'www.pantonecn.com'];

export function isPantoneDomainURL(url) {
  if (url) {
    return pantoneDomains.some((domain) => url.includes(domain));
  }

  return false;
}

export function getIdpIdentifier(identifier) {
  return `${config.SINGLE_SIGN_ON_URL}&idp_identifier=${identifier}`;
}

export function getIdentityProvider(provider) {
  return `${config.SINGLE_SIGN_ON_URL}&identity_provider=${provider}`;
}
