export enum Products {
  CONNECT_PREMIUM = 'PREMIUM',
  INSIDER_PREMIUM = 'TRENDS',
}

export enum CouponCodeType {
  SINGLE_USE = 'SINGLE_USE',
  MULTI_USE = 'MULTI_USE',
}

export type CouponCode = {
  id: string;
  activatedTimestamp: number;
  isUsed: boolean;
  userId: string;
  days?: number;
  products?: Products[];
  validFrom?: number | null;
  validTo?: number | null;
  type?: CouponCodeType;
  usagesLeft?: number;
};
