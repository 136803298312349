import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useSnackbar from 'features/Snackbar/useSnackbar';
import { getSessionStorage, removeSessionStorage } from '~/v3/utils/storage';
import { useAddCouponCodeToUserMutation } from '~/v2/services/couponApi';
import { useFeatureFlags } from '@xrite/feature-flags-client';
import useRouter from '~/v3/hooks/use-router';
import RoutePathname from '~/v2/constants/route';
import { CouponCodeType } from '~/v3/types/coupons';

function useCouponCode() {
  const [code, setCode] = useState(null);
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const bookCode = getSessionStorage('coupon');
  const { getFeatureFlags } = useFeatureFlags();
  const isUnlimitedCouponCodeEnabled =
    getFeatureFlags?.isUnlimitedCouponCodeEnabled;
  const { push } = useRouter();

  const [addCouponCode, { data }] = useAddCouponCodeToUserMutation();

  useEffect(() => {
    if (bookCode) setCode(JSON.parse(bookCode));
  }, [bookCode]);

  useEffect(() => {
    if (code) {
      if (
        isUnlimitedCouponCodeEnabled &&
        code?.type === CouponCodeType.MULTI_USE
      ) {
        push(RoutePathname.bundleCodes);
      } else {
        addCouponCode(code?.id).unwrap();
      }
    }
  }, [code]);

  if (data && data?.result === 'success' && bookCode) {
    removeSessionStorage('coupon');
    openSnackbar({
      message: code?.company
        ? t('messages.companyCouponCodeAdded')
        : t('messages.couponCodeAdded'),
    });
  }

  return code;
}

export default useCouponCode;
