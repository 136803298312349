import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import useProfileMenu from '~/v2/data/useProfileMenu';
import config from 'config';
import useSubscriptionStatus from 'hooks/useSubscriptionStatus';
import { ClickableMenuItem, MenuDivider } from './UserProfileDropdownMenuItem';

export function SelectSubscriptionContent({ onClickItem, onClose }) {
  const { subscriptionSection } = useProfileMenu();
  const { t } = useTranslation();
  const { hasActiveSubscriptionThatIsNotCanceled } = useSubscriptionStatus();

  const goBackMenuItem = {
    eventName: 'GoBack',
    icon: <ArrowBackIcon />,
    label: t('labels.subscription'),
    pathname: null,
    subMenu: null,
  };

  const handleCloseMenu = () => {
    onClose(null);
  };

  return (
    <>
      <ClickableMenuItem
        menuItem={goBackMenuItem}
        onClick={handleCloseMenu}
        isBackButton
      />
      <MenuDivider />
      {subscriptionSection?.manageSubscription?.subMenu.map((menuItem) => {
        if (
          menuItem.eventName === 'cancelSubscription' &&
          !hasActiveSubscriptionThatIsNotCanceled &&
          config.CANCELLATION_SURVEY_ENABLED
        ) {
          return null;
        }

        return (
          <ClickableMenuItem
            key={menuItem.eventName}
            menuItem={menuItem}
            onClick={onClickItem}
            data-id={`${menuItem.value}-subscription`}
          />
        );
      })}
    </>
  );
}

SelectSubscriptionContent.propTypes = {
  onClickItem: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SelectSubscriptionContent;
