import events from '~/v2/analytics/events';
import { chipClickEventIds } from '~/v2/analytics/utils';
import RoutePathname from '~/v2/constants/route';
import { store } from '~/v2/store';
import { getPaletteFromLocalStorage } from '~/v3/utils/get-palette-from-local-storage';

const actions = {
  workspace: {
    remove: 'e-workspace_chip_remove_from_palette',
    addName: 'e-workspace_chips_enter_palette_name',
    editName: 'e-workspace_chips_edit_palette_name',
    confirmName: 'e-workspace_chips_confirm_palette_name',
    save: 'e-workspace_chips_save_palette',
    reset: 'e-workspace_chip_reset_palette',
    resetConfirm: 'e-workspace_chip_reset_palette_confirmation',
    resetCancel: 'e-workspace_chip_reset_palette_cancellation',
    download: 'e-workspace_chip_download_palette',
    analyze: 'e-workspace_chip_analyze_palette',
  },
  info: {
    add: 'add_to_palette',
    getPhysicalSample: 'get_physical_sample',
    seeOnPantone: 'see_on_pantone_website',
  },
};

const findEventByName = (name) => {
  const eventId = Object.keys(events).find(
    (id) => events[id].EVENT_LABEL === name
  );

  if (eventId) {
    return events[eventId];
  }

  return null;
};

export const getEvent = ({
  type,
  page,
  section,
  bookId,
  action,
  ...otherProps
}) => {
  const reduxState = store?.getState?.();
  const localStoragePalette = getPaletteFromLocalStorage();

  switch (type) {
    case 'chip': {
      const event = events[chipClickEventIds[action]];

      return {
        ...event,
        EVENT_BOOK_ID: bookId,
        ...(section ? { EVENT_AREA: section } : {}),
        ...otherProps,
      };
    }

    case 'workspace': {
      const event = findEventByName(actions.workspace[action]);

      const workspace =
        page.route === RoutePathname.colorPicker
          ? 'paletteWorkspace'
          : 'moodboardWorkspace';
      const objectName =
        page.route === RoutePathname.colorPicker ? 'palette' : 'moodboard';

      const paletteNameOld = reduxState?.[workspace]?.[objectName]?.name;

      return {
        EVENT_PLACE: 'body',
        EVENT_COMPONENT: reduxState?.[workspace]?.[objectName]?.id
          ? 'workspace_existing'
          : 'workspace_create',
        EVENT_GROUP: 'na',
        EVENT_BOOK_ID: 'na',
        PALETTE_ID: localStoragePalette?.palette?.id ?? 'na',
        PALETTE_NAME:
          localStoragePalette?.name !== '' ? localStoragePalette?.name : 'na',
        PALETTE_NAME_OLD: paletteNameOld !== '' ? paletteNameOld : 'na',
        ...event,
      };
    }

    case 'info': {
      return {
        ...events.e9,
        EVENT_LABEL: actions.info[action],
        EVENT_AREA: section,
      };
    }

    default:
      return null;
  }
};

export default { getEvent };
