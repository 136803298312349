import { useEffect } from 'react';
import i18n from 'i18n';

import config from 'config';
import { useUserContext } from '~/v3/providers/UserProvider';
import { getLocalStorage } from '~/v3/utils/storage';
import { selectCurrentUser } from 'services/authApi';
import { useSelector } from 'react-redux';

function getLanguageFromURL() {
  if (typeof window !== 'undefined') {
    const query = window.location.hash.split('?')[1];
    const params = query?.split('&');

    const language = params?.find((param) => param.includes('language'));

    return language ? language.split('=')[1] : null;
  }

  return null;
}

function usePreferredLanguage() {
  const { country } = useUserContext();
  const { data: user } = useSelector(selectCurrentUser);

  const languageFromURL = getLanguageFromURL();
  const shouldLanguageFromURLBeSet =
    !!user === false && languageFromURL !== null;

  useEffect(() => {
    if (shouldLanguageFromURLBeSet) {
      i18n.changeLanguage(languageFromURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldLanguageFromURLBeSet]);

  useEffect(() => {
    const hasUserDefinedTheLanguage = getLocalStorage(
      config.HAS_USER_DEFINED_LANGUAGE
    );
    if (
      country.data &&
      !shouldLanguageFromURLBeSet &&
      !hasUserDefinedTheLanguage
    ) {
      i18n.changeLanguage(country.data?.language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);
}

export default usePreferredLanguage;
