import React from 'react';
import { useTranslation } from 'react-i18next';
import RoutePathname from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import ConvertNavigationIcon from '~/v3/components/shared/icons/convert-navigation-icon';
import { headerNavigationEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import BaseNavigationItem from '../base-navigation-item';
import {
  StyledNavigationItem,
  StyledNavigationText,
} from '../../../header.elements';

function ConvertNavigationItem() {
  const route = RoutePathname.colorConvert;

  const { pathname: currentRoute, push } = useRouter();

  const { t } = useTranslation();

  const ga = useTracker();

  const onNavigateToConvertPage = () => {
    push(route);
    ga.trackEvent({
      ...headerNavigationEvent,
      EVENT_LABEL: 'convert_nav',
    });
  };

  const isSelected = route === currentRoute;

  return (
    <BaseNavigationItem
      isSelected={isSelected}
      as={StyledNavigationItem}
      icon={<ConvertNavigationIcon />}
      onClick={onNavigateToConvertPage}
      text={
        <StyledNavigationText>{t('routes.colorConvert')}</StyledNavigationText>
      }
      dataId="convert"
    />
  );
}

export default ConvertNavigationItem;
