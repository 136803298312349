import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  palette: null,
};

export const paletteAnalysis = createSlice({
  name: 'paletteAnalysis',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.palette = action.payload;
    },
    close: () => initialState,
  },
});

export const { open, close } = paletteAnalysis.actions;

export default paletteAnalysis.reducer;

export const selectPalette = (state) => state.paletteAnalysis.palette;
export const selectIsOpen = (state) => state.paletteAnalysis.isOpen;
