import React from 'react';
import PropTypes from 'prop-types';
import MenuList from '@mui/material/MenuList';
import Popover from '@mui/material/Popover';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  popover: {
    marginTop: theme.spacing(1),
    maxWidth: 300,
    width: '100%',
    maxHeight: `calc(100% - 80px)`,
    [theme.breakpoints.down('sm')]: {
      maxHeight: `calc(100% - 160px)`,
    },
  },
  popoverChild: {
    marginTop: 0,
  },
}));

function UserProfileDropdownMenu({
  anchorEl,
  children,
  onClose,
  anchorOrigin = {},
  transformOrigin = {},
  isChild = false,
}) {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Popover
      open={!!anchorEl}
      elevation={4}
      style={{ zIndex: theme.zIndex.popover }}
      classes={{
        paper: `${classes.popover} ${isChild ? classes.popoverChild : ''}`,
      }}
      anchorEl={anchorEl}
      onClose={() => onClose(null)}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
        ...anchorOrigin,
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
        ...transformOrigin,
      }}
    >
      <MenuList id="menu-list-grow">{children}</MenuList>
    </Popover>
  );
}

UserProfileDropdownMenu.defaultProps = {
  onClose: () => {},
  anchorEl: null,
  anchorOrigin: {},
  transformOrigin: {},
  isChild: false,
};

UserProfileDropdownMenu.propTypes = {
  children: PropTypes.node.isRequired,
  anchorEl: PropTypes.shape(),
  onClose: PropTypes.func,
  anchorOrigin: PropTypes.shape(),
  transformOrigin: PropTypes.shape(),
  isChild: PropTypes.bool,
};

export default UserProfileDropdownMenu;
