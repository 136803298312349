import React from 'react';
import useResolution from '~/v3/hooks/use-resolution';
import { ReactNodeLike } from 'prop-types';
import { AuthContainerBigScreen } from '~/v3/components/features/promotions/containers/auth-container-big-screen';
import { AuthContainerSmallScreen } from '~/v3/components/features/promotions/containers/auth-container-small-screen';
import useGetActivePromotion from '~/v3/components/features/promotions/lib/hooks/use-get-active-promotion';
import AuthDrawer from './auth-drawer';

function AuthWrapper({ children }: Readonly<{ children: ReactNodeLike }>) {
  const { isSmallScreen } = useResolution();

  const activePromotion = useGetActivePromotion();

  if (!children) {
    return null;
  }

  if (isSmallScreen) {
    return (
      <AuthContainerSmallScreen promotion={activePromotion}>
        <AuthDrawer>{children}</AuthDrawer>
      </AuthContainerSmallScreen>
    );
  }

  return <AuthContainerBigScreen>{children}</AuthContainerBigScreen>;
}

export default AuthWrapper;
