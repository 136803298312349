import { useDispatch, useSelector } from 'react-redux';
import {
  open as openSnackbar,
  close as closeSnackbar,
  selectIsOpen,
  selectMessage,
  selectAutoHideDuration,
  selectType,
  selectStyle,
  setStyle,
} from './snackbarSlice';

function useSnackbar() {
  const isOpen = useSelector(selectIsOpen);
  const message = useSelector(selectMessage);
  const type = useSelector(selectType);
  const autoHideDuration = useSelector(selectAutoHideDuration);
  const style = useSelector(selectStyle);
  const dispatch = useDispatch();

  return {
    isOpen,
    message,
    type,
    style,
    autoHideDuration,
    setStyle: (styles) => dispatch(setStyle(styles)),
    openSnackbar: (content) => dispatch(openSnackbar(content)),
    closeSnackbar: () => dispatch(closeSnackbar()),
  };
}

export default useSnackbar;
