export default {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  it: 'Italiano',
  pt: 'Português',
  chs: '汉语',
  cht: '漢語',
  ja: '日本語',
  ko: '한국어',
};
