import { CookieStorage } from 'cookie-storage';
import config from '../config';
import { isLocalhost } from './urls';

export class AmplifyAuthStorage {
  static syncPromise = null;

  static cookieStorage = new CookieStorage();

  static setItem(key, value) {
    return this.cookieStorage.setItem(key, value, {
      secure: !isLocalhost,
      expires: config.AUTH_COOKIE_EXPIRES,
      sameSite: 'Lax',
    });
  }

  static getItem(key) {
    // Since we are migrating to new storage, we have to retain user session
    // Check localStorage and if there is value, move it to cookie storage
    const localStorageValue = localStorage.getItem(key);
    if (localStorageValue) {
      localStorage.removeItem(key);
      this.setItem(key, localStorageValue);
      return localStorageValue;
    }

    return this.cookieStorage.getItem(key);
  }

  static removeItem(key) {
    return this.cookieStorage.removeItem(key);
  }

  static clear() {
    // We need to remove only items starts with 'CognitoIdentity'
    Object.keys(localStorage)
      .filter((key) => key.startsWith('CognitoIdentity'))
      .forEach((key) => localStorage.removeItem(key));

    Object.keys(this.cookieStorage)
      .filter((key) => key.startsWith('CognitoIdentity'))
      .forEach((key) => this.cookieStorage.removeItem(key));
  }

  static sync() {
    return Promise.resolve();
  }
}

const amplifyBasicSettings = {
  aws_appsync_graphqlEndpoint: config.api.API_URI,
  aws_appsync_region: config.api.REGION,
  Analytics: {
    AWSPinpoint: {
      region: 'us-east-1', // PinPoint works only in us-east-1 with Cognito User Pool
      appId: 'cd6394088ac3426991950c766b3aa9a9',
    },
  },
};

export const amplifyApiKeySettings = {
  Auth: {
    region: config.api.REGION,
    storage: AmplifyAuthStorage,
  },
  ...amplifyBasicSettings,
  aws_appsync_authenticationType: 'API_KEY',
  aws_appsync_apiKey: config.api.API_KEY,
};

export const amplifyIAMSettings = {
  identityPoolId: config.api.IDENTITY_POOL_ID,
  Auth: {
    identityPoolId: config.api.IDENTITY_POOL_ID,
    region: config.api.REGION,
    storage: AmplifyAuthStorage,
  },
  ...amplifyBasicSettings,
  aws_appsync_authenticationType: 'AWS_IAM',
};

export const amplifyUserPoolSettings = {
  Auth: {
    userPoolId: config.api.USER_POOL_ID,
    region: config.api.REGION,
    userPoolWebClientId: config.api.APP_CLIENT_ID,
    oauth: config.api.oauth,
    storage: AmplifyAuthStorage,
    // NOTE: In order to integrate pantone.com users, we have to keep this authentication flow. Any other flow is not going
    //       to run the migraton lambda on Cognito User Pool.
    authenticationFlowType: 'USER_PASSWORD_AUTH',
  },
  ...amplifyBasicSettings,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_apiKey: config.api.API_KEY,
};
