/* eslint-disable @typescript-eslint/no-empty-function */
import React from 'react';
import { MenuList, styled } from '@mui/material';
import colors from '~/v3/theme/colors';

import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import { isAdobe } from '~/v2/utils/platform';
import BaseItems from './base-items';
import PaletteItem from './palette-item';
import AdobeItems from './adobe-items';

type MenuProps = {
  color: PantoneColorWithBook;
  style?: React.CSSProperties;
  className?: string;
  onClick: () => void;
};

export const MenuListStyled = styled(MenuList)<{
  rgb: { r: number; g: number; b: number };
}>(({ theme, rgb }) => ({
  background: `rgb(${rgb.r},${rgb.g},${rgb.b})`,
  color: theme.palette.isDark(rgb) ? colors.white : colors.black,
  padding: theme.spacing(0),
  width: '100%',
  margin: 0,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(0.375),
}));

function BaseMenu({ color, style, className, onClick }: Readonly<MenuProps>) {
  return (
    <MenuListStyled
      rgb={color?.rgb}
      data-id="base-menu"
      dense
      style={style}
      className={className}
      onClick={onClick}
    >
      {isAdobe && <AdobeItems color={color} />}
      <PaletteItem color={color} />
      <BaseItems color={color} />
    </MenuListStyled>
  );
}

export default BaseMenu;
