import { useMutation } from '@tanstack/react-query';

import { createSurvey } from '~/v3/api/survey-api';

export default function useCreateSurveyMutation() {
  const { mutateAsync: createSurveyFn, ...mutation } = useMutation({
    mutationFn: createSurvey,
  });

  return {
    createSurvey: createSurveyFn,
    ...mutation,
  };
}
