import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { rgbToHex } from '@xrite/color-js';
import { makeStyles, useTheme } from '@mui/styles';
import config from 'config';

import PantoneLogo from 'components/PantoneLogo';

const getColorBrightness = ({ r, g, b }) => {
  const brightnessLevel = (r * 299 + g * 587 + b * 114) / 1000;
  return brightnessLevel <= 128;
};

const useStyles = makeStyles(() => ({
  color: {
    flex: '1 1 auto',
    display: 'flex',
    backgroundColor: ({ backgroundColor }) => `#${backgroundColor}`,
    padding: config.PICTURE_SIZE / 50,
    minWidth: 0,
  },
  compactColor: {
    flex: '1 1 auto',
    display: 'flex',
    backgroundColor: ({ backgroundColor }) => `#${backgroundColor}`,
    paddingLeft: config.PICTURE_SIZE / 50,
    paddingRight: config.PICTURE_SIZE / 50,
    paddingTop: config.PICTURE_SIZE / 500,
    paddingBottom: config.PICTURE_SIZE / 500,
  },
  colorRow: {
    flexDirection: 'row',
    alignItems: 'flex-end',
  },
  colorColumn: {
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
  },
  primaryColor: {
    flex: '15 1 auto',
  },
  pantoneLogo: {
    height: config.PICTURE_SIZE / 50,
    marginRight: '2%',
    marginBottom: '0.5%',
  },
  compactPantoneLogo: {
    height: config.PICTURE_SIZE / 67,
    marginRight: '2%',
  },
  colorCode: {
    fontWeight: 'normal',
    height: config.PICTURE_SIZE / 33,
  },
  compactColorCode: {
    height: 20,
  },
}));

export default function ShareTemplateColor({
  color,
  primaryColor,
  verticalAlign,
  numberOfColors,
}) {
  const theme = useTheme();
  const isDarkColor = getColorBrightness(color.rgb);
  const backgroundColor = rgbToHex(color.rgb);
  const contentColor = isDarkColor
    ? theme.palette.common.white
    : theme.palette.common.main;
  const classes = useStyles({ backgroundColor });

  const compact = numberOfColors > 10;

  return (
    <div
      className={classNames({
        [classes.color]: !compact,
        [classes.compactColor]: compact,
        [classes.primaryColor]: primaryColor,
        [classes.colorRow]: !verticalAlign,
        [classes.colorColumn]: verticalAlign,
      })}
    >
      <PantoneLogo
        className={classNames({
          [classes.pantoneLogo]: !compact,
          [classes.compactPantoneLogo]: compact,
        })}
        color={contentColor}
      />
      <svg
        className={classNames({
          [classes.colorCode]: !compact,
          [classes.compactColorCode]: compact,
        })}
        viewBox="0 6 260 18"
      >
        <text fill={contentColor} x="0" y="20">
          {color.code}
        </text>
      </svg>
      <svg
        className={classNames({
          [classes.colorCode]: !compact,
          [classes.compactColorCode]: compact,
        })}
        viewBox="0 6 260 18"
      >
        {color.name && (
          <text fill={contentColor} x="0" y="20">
            {color.name}
          </text>
        )}
      </svg>
    </div>
  );
}

ShareTemplateColor.defaultProps = {
  primaryColor: false,
  verticalAlign: false,
  numberOfColors: 0,
};

ShareTemplateColor.propTypes = {
  color: PropTypes.shape().isRequired,
  primaryColor: PropTypes.bool,
  verticalAlign: PropTypes.bool,
  numberOfColors: PropTypes.number,
};
