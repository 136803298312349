import { useEffect, useState } from 'react';
import useDebounce from '~/v3/hooks/use-debounce';

function useWindow() {
  const [isResizing, setIsResizing] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  const debouncedResizing = useDebounce(isResizing, 500);

  useEffect(() => {
    setIsResizing(false);
  }, [debouncedResizing]);

  useEffect(() => {
    function handleResize() {
      setIsResizing(true);
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener('resize', handleResize);

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return { windowSize, isResizing };
}

export default useWindow;
