import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import routes from 'constants/route';
import Loader from 'components/Loader';
import { selectCurrentUser } from 'services/authApi';
import useRouter from '~/v3/hooks/use-router';
import usePrevious from 'hooks/usePrevious';
import AuthWrapper from './components/auth-wrapper';

const SignInError = React.lazy(() =>
  import(/* webpackChunkName: "SignInError" */ './SignInError')
);

const Welcome = React.lazy(() =>
  import(/* webpackChunkName: "SignInError" */ './components/welcome')
);
const SignInWithEmail = React.lazy(() =>
  import(/* webpackChunkName: "SignInWithEmail" */ './SignInWithEmail')
);
const CreateAccount = React.lazy(() =>
  import(/* webpackChunkName: "CreateAccount" */ './CreateAccount')
);
const PasswordRecovery = React.lazy(() =>
  import(/* webpackChunkName: "PasswordRecovery" */ './PasswordRecovery')
);
const PasswordVerification = React.lazy(() =>
  import(
    /* webpackChunkName: "PasswordVerification" */ './PasswordVerification'
  )
);
const CreateAccountSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "CreateAccountSuccess" */ './CreateAccountSuccess'
  )
);
const ForgotPasswordSuccess = React.lazy(() =>
  import(
    /* webpackChunkName: "ForgotPasswordSuccess" */ './ForgotPasswordSuccess'
  )
);

const SingleSignOn = React.lazy(() =>
  import(/* webpackChunkName: "SingleSignONn" */ './SingleSignOn')
);
const UserSegment = React.lazy(() =>
  import(/* webpackChunkName: "UserSegment" */ './UserSegment')
);
const ErrorPage = React.lazy(() =>
  import(/* webpackChunkName: "ErrorPage" */ './ErrorPage')
);
const RedirectionHandler = React.lazy(() =>
  import(/* webpackChunkName: "RedirectionHandler" */ './RedirectionHandler')
);
const ReviewTerms = React.lazy(() =>
  import(/* webpackChunkName: "ReviewTerms" */ './ReviewTerms')
);
const CompleteAccountInvite = React.lazy(() =>
  import(
    /* webpackChunkName: "CompleteAccountInvite" */ './CompleteAccountInvite'
  )
);

function Auth() {
  const { data: user, isLoading } = useSelector(selectCurrentUser);
  const { location } = useRouter();
  const previousPath = usePrevious(location.pathname);

  if (isLoading) {
    return <Loader medium />;
  }

  if (!user) {
    return (
      <AuthWrapper>
        <Switch>
          <Route path={routes.auth.welcome}>
            <Welcome />
          </Route>
          <Route path={routes.auth.signInError}>
            <SignInError />
          </Route>
          <Route path={routes.auth.signInEmail}>
            <SignInWithEmail />
          </Route>
          <Route path={routes.auth.createAccount}>
            <CreateAccount />
          </Route>
          <Route path={routes.auth.singleSignOn}>
            <SingleSignOn />
          </Route>
          <Route path={routes.auth.accountCreated}>
            <CreateAccountSuccess />
          </Route>
          <Route path={routes.auth.passwordRecovery}>
            <PasswordRecovery />
          </Route>
          <Route path={routes.auth.passwordVerification}>
            <PasswordVerification />
          </Route>
          <Route path={routes.auth.forgotPasswordSucces}>
            <ForgotPasswordSuccess />
          </Route>
          <Route path={routes.auth.reviewTerms}>
            <ReviewTerms />
          </Route>
          <Route path={routes.auth.completeAccountInvite}>
            <CompleteAccountInvite />
          </Route>
          <Route path={routes.auth.error}>
            <ErrorPage />
          </Route>
          <Route>
            <RedirectionHandler />
          </Route>
        </Switch>
      </AuthWrapper>
    );
  }

  return (
    <AuthWrapper>
      <Switch>
        <Route path={routes.auth.userSegment}>
          <UserSegment previousPath={previousPath} />
        </Route>
        <Redirect to={routes.auth.userSegment} />
      </Switch>
    </AuthWrapper>
  );
}

export default Auth;
