import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

import Watermark from '../Watermark';
import ShareTemplateColor from '../templates/ShareTemplateColor';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(0),
    display: 'flex',
    flexDirection: 'column',
  },
  verticalTemplate: {
    transform: 'rotate(90deg)',
  },
}));

function ColorsVerticall({ colors, primaryColor, verticalTemplate }) {
  const classes = useStyles();

  return (
    <div>
      <div
        className={classNames(classes.container, {
          [classes.verticalTemplate]: verticalTemplate,
        })}
      >
        {colors.map((color, index) => (
          <ShareTemplateColor
            key={color.code}
            color={color}
            contentSize={32}
            primaryColor={primaryColor && index === 0}
            numberOfColors={colors.length}
          />
        ))}
      </div>
      <Watermark />
    </div>
  );
}

ColorsVerticall.defaultProps = {
  primaryColor: false,
  verticalTemplate: false,
};

ColorsVerticall.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  primaryColor: PropTypes.bool,
  verticalTemplate: PropTypes.bool,
};

export default ColorsVerticall;
