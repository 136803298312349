import { useEffect } from 'react';
import config from '~/v2/config';
import { useCookiesStore } from '~/v3/store/cookies-store';
import '~/v3/lib/data-layer-helper';

const dataLayer = (window.dataLayer || []) as Array<unknown>;

window._dataLayerHelper = new window.DataLayerHelper!(dataLayer, {
  listener(model) {
    if (model.event === 'OneTrustGroupsUpdated') {
      const areCookiesSettingsChanged =
        useCookiesStore.getState().oneTrustActiveGroups !==
        model.OnetrustActiveGroups;

      if (areCookiesSettingsChanged) {
        // The timeout is added so the cookies dialog is not laggy
        setTimeout(() => {
          useCookiesStore
            .getState()
            .actions.onOneTrustActiveGroupsChanged(model.OnetrustActiveGroups);
        }, 500);
      }
    }
  },
  listenToPast: false,
});

function OptanonWrapper() {
  try {
    if (typeof window.dataLayer !== 'undefined') {
      return window.dataLayer.push({
        event: 'OneTrustGroupsUpdated',
      });
    }
    console.warn('GTM dataLayer not available - tracking disabled');
    return false;
  } catch (error) {
    console.warn('GTM push failed:', error);
    return false;
  }
}

export function useCookiesScript() {
  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];

    const cookieScript = document.createElement('script');

    cookieScript.type = 'text/javascript';

    cookieScript.src = 'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js';

    cookieScript.setAttribute('data-domain-script', config.ONE_TRUST_API_KEY);

    head.appendChild(cookieScript);

    const optanonWrapperScript = document.createElement('script');

    optanonWrapperScript.type = 'text/javascript';

    // Note: The OptanonWrapper function cannot be declared as an inline function
    // because webpack will remove the function name and OneTrust script will not be able to find it
    optanonWrapperScript.textContent = OptanonWrapper as unknown as string;

    head.appendChild(optanonWrapperScript);

    return () => {
      head.removeChild(cookieScript);
      head.removeChild(optanonWrapperScript);
    };
  }, []);
}
