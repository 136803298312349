import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import useConfirm from './useConfirm';

function ConfirmDialog() {
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    isOpen,
    title,
    content,
    cancelButton,
    cancelLabel,
    confirmLabel,
    disableBackdropClick,
    onCancel,
    onConfirm,
  } = useConfirm();

  const handleClose = (event, reason) => {
    if (reason === 'backdropClick' && disableBackdropClick) {
      return;
    }

    onCancel();
  };

  return (
    <Dialog
      open={isOpen}
      style={{ zIndex: theme.zIndex.confirmDialog }}
      onClose={handleClose}
    >
      {title && <DialogTitle>{title}</DialogTitle>}

      <DialogContent data-testid="dialog-content">{content}</DialogContent>

      <DialogActions>
        {cancelButton && (
          <Button onClick={onCancel} data-testid="dialog-close">
            {cancelLabel || t('labels.cancel')}
          </Button>
        )}
        <Button onClick={onConfirm} data-testid="dialog-confirm">
          {confirmLabel || t('labels.continue')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDialog;
