import { create } from 'zustand';
import createSelectors from '~/v3/store/selectors';
import { Color } from '../types/pantone-color';

type FullScreenStore = {
  isOpen: boolean;
  color: Omit<Color, 'name'> | null;
  actions: {
    openColorFullScreen: (color: Omit<Color, 'name'>) => void;
    close: () => void;
  };
};

const useFullScreenStore = create<FullScreenStore>()((set) => ({
  isOpen: false,
  color: null,
  actions: {
    openColorFullScreen: (color: Omit<Color, 'name'>) =>
      set((state) => ({ ...state, color, isOpen: true })),
    close: () => set((state) => ({ ...state, colorCode: null, isOpen: false })),
  },
}));

export const useFullScreenActions = () =>
  useFullScreenStore((state) => state.actions);

export default createSelectors(useFullScreenStore);
