import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

import { useColorMode } from '~/v3/components/shared/providers/color-mode-provider';
import useProfileMenu from '~/v2/data/useProfileMenu';
import i18next from 'i18next';
import { ClickableMenuItem, MenuDivider } from './UserProfileDropdownMenuItem';

const goBackMenuItem = {
  eventName: 'GoBack',
  icon: <ArrowBackIcon />,
  label: i18next.t('labels.theme'),
  pathname: null,
  subMenu: null,
};

type MenuItem = {
  eventName: string;
  pathname: string;
  subMenu: MenuItem;
  value: string;
};

type SelectThemeContentProps = {
  onClickItem: (e: Event, menuItem: MenuItem) => void;
  onClose: () => void;
};

function SelectThemeContent({
  onClickItem,
  onClose,
}: Readonly<SelectThemeContentProps>) {
  const { themeSection } = useProfileMenu();
  const context = useColorMode();

  const handleChangeTheme = (e: Event, menuItem: MenuItem) => {
    onClickItem(e, menuItem);
  };

  const handleCloseMenu = () => {
    onClose();
  };

  return (
    <>
      {/* @ts-expect-error This is a JavaScript file and is untyped  */}
      <ClickableMenuItem
        menuItem={goBackMenuItem}
        onClick={handleCloseMenu}
        isBackButton
      />

      <MenuDivider />
      {themeSection.changeTheme.subMenu.map((menuItem) => (
        //  @ts-expect-error This is a JavaScript file and is untyped
        <ClickableMenuItem
          key={menuItem.eventName + menuItem.value}
          menuItem={menuItem}
          selected={false}
          isCheckButton
          isChecked={menuItem.value === context?.currentMode}
          onClick={handleChangeTheme}
        />
      ))}
    </>
  );
}

export default SelectThemeContent;
