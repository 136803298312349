import { SxProps, useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracker from '~/v2/analytics/useTracker';
import RoutePathname from '~/v2/constants/route';
import { trendsMenuEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import TrendNavigationIcon from '~/v3/components/shared/icons/trend-navigation-icon';

import { useFeatureFlags } from '@xrite/feature-flags-client';
import ColorInsiderIcon from '~/v3/components/shared/icons/color-insider-icon';
import {
  StyledFooterNavigation,
  footerItemTransition,
  selectedFooterItemColor,
  selectedFooterNavigationStyle,
} from '../../footer.elements';

function InsightFooterNavigation() {
  const trendsRoute = RoutePathname.insight;

  const { getFeatureFlags } = useFeatureFlags();
  const isArticlesNewCategoriesEnabled =
    getFeatureFlags?.isArticlesNewCategoriesEnabled;

  const { push, pathname: currentRoute } = useRouter();

  const ga = useTracker();

  const { t } = useTranslation();

  const theme = useTheme();

  const onNavigateToTrendsPage = () => {
    push(trendsRoute);
    ga.trackMenuEvent(trendsMenuEvent);
  };

  const isSelected = currentRoute.includes(trendsRoute);

  const containerStyle = isSelected ? selectedFooterNavigationStyle : {};

  const iconStyle: SxProps = {
    '& .trends-icon-bar': {
      transition: footerItemTransition,
      ...(isSelected
        ? {
            fill: selectedFooterItemColor,
            stroke: selectedFooterItemColor,
          }
        : {
            fill: theme.palette.background.default,
            stroke: theme.palette.text.primary,
          }),
    },
  };

  return (
    <StyledFooterNavigation
      sx={containerStyle}
      onClick={onNavigateToTrendsPage}
      icon={
        isArticlesNewCategoriesEnabled ? (
          <ColorInsiderIcon isSelected={isSelected} />
        ) : (
          <TrendNavigationIcon sx={iconStyle} />
        )
      }
      text={<Typography>{t('routes.insight')}</Typography>}
      data-testid="insight"
    />
  );
}

export default InsightFooterNavigation;
