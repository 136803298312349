import { useSelector } from 'react-redux';
import useTracker from '~/v2/analytics/useTracker';
import { BaseRoutePaths } from '~/v2/constants/route';
import useMoodboardWS from '~/v2/features/MoodboardWorkspace/useMoodboardWS';
import {
  selectId,
  selectName,
} from '~/v2/features/PaletteWorkspace/paletteSlice';
import usePaletteWS from '~/v2/features/PaletteWorkspace/usePaletteWS';
import { onLeftClickMenuEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import { useWorkspaceStoreActions } from '~/v3/store/workspace-store';
import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import { PaletteActions } from '../../model/types';

function useGetPaletteAction(color: PantoneColorWithBook) {
  const ga = useTracker();
  const { pathname } = useRouter();
  const isOnColorStory = pathname.includes(BaseRoutePaths.colorStory);
  const paletteName = useSelector<string>(selectName);
  const paletteId = useSelector<string>(selectId);

  const paletteEvent = {
    ...onLeftClickMenuEvent,
    TERM: color.code,
    EVENT_BOOK_ID: color.book.id,
    PALETTE_ID: paletteId ?? 'na',
    PALETTE_NAME: paletteName !== '' ? paletteName : 'na',
  };

  const {
    addWorkspaceColor: addWorkspaceColorToPaletteWorkspace,
    isInWorkspace: isInPaletteWorkspace,
    removeWorkspaceColor: removePaletteWorkspaceColor,
  } = usePaletteWS();

  const {
    addWorkspaceColor: addWorkspaceColorToMoodboardWorkspace,
    isInWorkspace: isInMoodboardWorkspace,
    removeWorkspaceColor: removeMoodboardWorkspaceColor,
  } = useMoodboardWS();

  const { onExpandWorkspace } = useWorkspaceStoreActions();

  const isInPaletteWS = isInPaletteWorkspace(color);
  const colorInMoodboardWS = isInMoodboardWorkspace(color) as boolean;

  const paletteAction = () => {
    onExpandWorkspace();
    addWorkspaceColorToPaletteWorkspace(color);
    ga.trackEvent({
      ...paletteEvent,
      EVENT_LABEL: 'add_to_palette',
    });
  };
  const type = PaletteActions.addToPalette;

  if (isOnColorStory) {
    if (colorInMoodboardWS) {
      return {
        action: () => {
          removeMoodboardWorkspaceColor(color);
          ga.trackEvent({
            ...paletteEvent,
            EVENT_LABEL: 'remove_from_workspace',
          });
        },
        type: PaletteActions.removeFromPalette,
      };
    }

    if (!colorInMoodboardWS) {
      return {
        action: () => {
          onExpandWorkspace();
          addWorkspaceColorToMoodboardWorkspace(color);
          ga.trackEvent({
            ...onLeftClickMenuEvent,
            EVENT_LABEL: 'add_to_workspace',
            TERM: color.code,
          });
        },
        type: PaletteActions.addToPalette,
      };
    }
  }

  if (isInPaletteWS) {
    return {
      action: () => {
        removePaletteWorkspaceColor(color);
        ga.trackEvent({
          ...paletteEvent,
          EVENT_LABEL: 'remove_from_palette',
        });
      },
      type: PaletteActions.removeFromPalette,
    };
  }

  return {
    action: paletteAction,
    type,
  };
}

export default useGetPaletteAction;
