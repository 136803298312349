import React from 'react';
import extractErrorMessageFromError from '~/v3/utils/extract-error-message-from-error';
import SidebarAlert from './ui/sidebar-alert';
import SidebarLoadingSkeleton from './ui/sidebar-loading-skeleton';
import {
  SidebarList,
  AccordionSidebar,
  AccordionDetailsSidebar,
  AccordionSummarySidebar,
} from './accordion-group.elements';
import SidebarMenuListItem from './ui/list-item';
import { IAccordionGroup, ListItemsType } from './model/types';
import SeeMoreButton from './ui/see-more-button';

function AccordionGroup<ListItems extends ListItemsType>({
  isError,
  error,
  isLoading,
  list,
  selectedId,
  onClickListItem,
  testIds,
  label,
  hasMoreData,
}: Readonly<IAccordionGroup<ListItems>>) {
  if (isError) {
    return (
      <SidebarAlert data-testid={testIds.alert}>
        {extractErrorMessageFromError(error)}
      </SidebarAlert>
    );
  }

  if (isLoading) {
    return <SidebarLoadingSkeleton data-testid={testIds.loadingSkeleton} />;
  }

  const renderItems = () =>
    list?.map((element) => {
      const isSelected = selectedId === element.id;
      return (
        <SidebarMenuListItem
          key={element.id}
          name={element.name}
          onClick={() => onClickListItem(element)}
          isSelected={isSelected}
        />
      );
    });

  return (
    <AccordionSidebar>
      <AccordionSummarySidebar>{label}</AccordionSummarySidebar>
      <AccordionDetailsSidebar>
        <SidebarList data-testid={testIds.list}>
          {renderItems()}
          {hasMoreData && <SeeMoreButton />}
        </SidebarList>
      </AccordionDetailsSidebar>
    </AccordionSidebar>
  );
}

export default AccordionGroup;
