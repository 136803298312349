import React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material';
import WorkspaceSmall from './WorkspaceSmall';
import WorkspaceLarge from './WorkspaceLarge';

function WorkspaceUI(props) {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return mobile ? <WorkspaceSmall {...props} /> : <WorkspaceLarge {...props} />;
}

export default WorkspaceUI;
