import React from 'react';
import RoutePathname from '~/v2/constants/route';
import useRouter from '~/v3/hooks/use-router';
import useTracker from '~/v2/analytics/useTracker';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { myPalettesEvent } from '~/v3/events';
import sidebarStore, { useSidebarStoreActions } from '~/v3/store/sidebar-store';
import ListIcon from '~/v3/components/shared/icons/list-icon';
import { SxProps } from '@mui/material';
import { isSidebarEnabled } from '~/v3/feature-flags';
import MyDashboardIcon from '~/v3/components/shared/icons/my-dashboard-icon';
import {
  StyledFooterNavigation,
  selectedFooterNavigationStyle,
} from '../../footer.elements';

const myPalettesRoute = RoutePathname.myPalettes;

function MyPalettesFooterNavigation() {
  const { push, pathname: currentRoute } = useRouter();
  const { onOpenSidebar, onCloseSidebar } = useSidebarStoreActions();
  const { isSidebarOpen } = sidebarStore();

  const ga = useTracker();

  const { t } = useTranslation();

  const isSelected = currentRoute.includes(myPalettesRoute);

  const containerStyle: SxProps = isSidebarEnabled
    ? {
        ...(isSidebarOpen && selectedFooterNavigationStyle),
      }
    : {
        ...(isSelected && selectedFooterNavigationStyle),
      };

  const handleOnClick = () => {
    if (isSidebarOpen) onCloseSidebar();
    else onOpenSidebar();
  };

  const onNavigateToMyPalettes = () => {
    push(myPalettesRoute);
    ga.trackMenuEvent(myPalettesEvent);
  };

  const onClick = isSidebarEnabled ? handleOnClick : onNavigateToMyPalettes;
  const myDashboardIcon = isSidebarEnabled ? <ListIcon /> : <MyDashboardIcon />;

  return (
    <StyledFooterNavigation
      sx={containerStyle}
      onClick={onClick}
      icon={myDashboardIcon}
      text={<Typography>{t('routes.myDashboard')}</Typography>}
      data-testid="mypalettes"
    />
  );
}

export default MyPalettesFooterNavigation;
