import { PantoneColor } from '../../types/pantone-color';

const GREYS_SATURATION_MAX_VALUE = 7;
const WHITE_LUMINOSITY_THRESHOLD = 66;
const BLACK_LUMINOSITY_THRESHOLD = 30;
const BLACK_SATURATION_MAX_VALUE = 35;
const BLACK_SUM_LUMINOSITY_SATURATION_MAX_VALUE = 42;

const addLeadingZeros = (x: number) => x.toString().padStart(3, '0');

type HueItem = {
  id: string;
  cssColor: string;
  filterFunction: (color: PantoneColor) => boolean | undefined;
};

type AddExtraColorProps = {
  index: number;
  cssColor: string;
  filterFunction: (color: PantoneColor) => boolean | undefined;
};

class HueFilters {
  collection: HueItem[] = [];

  map: { [id: string]: HueItem } = {};

  updateFilters(bookData: PantoneColor[]) {
    this.collection = [];
    this.map = {};

    const hueSortedBookData = bookData
      .slice(0)
      .sort((a, b) => (a?.hsl?.h ?? 0) - (b?.hsl?.h ?? 0));

    const firstValue =
      hueSortedBookData[hueSortedBookData.length - 1].hsl?.h ?? 0;
    const secondValue = hueSortedBookData[0].hsl?.h ?? 0;

    // Use a different number of groups if the difference of the Hue is not big
    // or if the number of colors in the book is not big
    let groupsNumber = 10;
    const hueDifference = firstValue - secondValue;

    if (hueDifference < 60) {
      groupsNumber = 3;
    } else if (hueDifference < 180) {
      groupsNumber = 5;
    }

    const groupElementsNumber = Math.ceil(bookData.length / groupsNumber);

    for (
      let i = 0;
      i < groupsNumber && i * groupElementsNumber < hueSortedBookData.length;
      i += 1
    ) {
      const minIndex = i * groupElementsNumber;
      let maxIndex = minIndex + (groupElementsNumber - 1);
      if (maxIndex >= hueSortedBookData.length)
        maxIndex = hueSortedBookData.length - 1;

      const minHue = hueSortedBookData[minIndex]?.hsl?.h ?? 0;
      const maxHue = hueSortedBookData[maxIndex]?.hsl?.h ?? 0;
      const average = minHue + (maxHue - minHue) / 2;
      const cssColor = `hsl(${average}, 100%, 50%)`;

      const nextIndex = maxIndex + 1;
      let nextHue: number;
      if (nextIndex >= hueSortedBookData.length - 1) nextHue = maxHue + 1;
      else nextHue = hueSortedBookData[nextIndex]?.hsl?.h ?? 0;

      const filterFunction = (color: PantoneColor) => {
        const hue = color?.hsl?.h ?? 0;
        return hue >= minHue && hue < nextHue;
      };

      const hueFilter = {
        id: `hue-${addLeadingZeros(i)}`,
        cssColor,
        filterFunction,
      };

      this.collection.push(hueFilter);
      this.map[hueFilter.id] = hueFilter;
    }

    // Add a grey filter if necessary
    const someGreys = hueSortedBookData.some(
      (color: PantoneColor) =>
        (color?.hsl?.s ?? 0) <= GREYS_SATURATION_MAX_VALUE
    );
    if (someGreys) {
      const whiteFilterFunction = (color: PantoneColor) =>
        (color?.hsl?.s ?? 0) <= GREYS_SATURATION_MAX_VALUE &&
        (color?.hsl?.l ?? 0) >= WHITE_LUMINOSITY_THRESHOLD;
      if (hueSortedBookData.some(whiteFilterFunction)) {
        this.addExtraColor({
          index: this.collection.length,
          cssColor: 'hsl(0, 0%, 90%)',
          filterFunction: whiteFilterFunction,
        });
      }

      const greyFilterFunction = (color: PantoneColor) =>
        (color?.hsl?.s ?? 0) <= GREYS_SATURATION_MAX_VALUE &&
        (color?.hsl?.l ?? 0) > BLACK_LUMINOSITY_THRESHOLD &&
        (color?.hsl?.l ?? 0) < WHITE_LUMINOSITY_THRESHOLD;
      if (hueSortedBookData.some(greyFilterFunction)) {
        this.addExtraColor({
          index: this.collection.length,
          cssColor: 'hsl(0, 0%, 50%)',
          filterFunction: greyFilterFunction,
        });
      }

      const blackFilterFunction = (color: PantoneColor) =>
        (color?.hsl?.s ?? 0) <= BLACK_SATURATION_MAX_VALUE &&
        (color?.hsl?.l ?? 0) <= BLACK_LUMINOSITY_THRESHOLD &&
        (color?.hsl?.l ?? 0) + (color?.hsl?.s ?? 0) <=
          BLACK_SUM_LUMINOSITY_SATURATION_MAX_VALUE;
      if (hueSortedBookData.some(blackFilterFunction)) {
        this.addExtraColor({
          index: this.collection.length,
          cssColor: 'hsl(0, 0%, 0%)',
          filterFunction: blackFilterFunction,
        });
      }
    }
  }

  addExtraColor({ index, cssColor, filterFunction }: AddExtraColorProps) {
    const hueFilter = {
      id: `hue-${addLeadingZeros(index)}`,
      cssColor,
      filterFunction,
    };

    this.collection.push(hueFilter);
    this.map[hueFilter.id] = hueFilter;
  }
}

const singleton = new HueFilters();

export default singleton;
