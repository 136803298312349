import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { ListItemIcon, MenuItem, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CheckIcon from '@mui/icons-material/Check';
import DropdownMenu from './UserProfileDropdownMenu';

const useStyles = makeStyles((theme) => ({
  menuItem: {
    paddingLeft: theme.spacing(3),
    height: theme.spacing(6),
    '&:hover': {
      '& $icons': {
        color: theme.palette.secondary.main,
      },
      '& $subMenuSecondLabel': {
        color: theme.palette.primary.dark,
      },
    },
  },
  menuDivider: {
    marginTop: theme.spacing(1),
    height: 0,
    marginBottom: theme.spacing(1),
    borderTop: `1px solid ${theme.palette.divider}`,
  },
  icons: {
    color: theme.palette.secondary.light,
  },
  iconsBackButton: {
    color: theme.palette.primary.main,
  },
  subMenuLabelWrapper: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
  },
  subMenuSecondLabel: {
    color: theme.palette.text.secondary,
    fontSize: '12px',
  },
  subMenuLabelChevron: {
    marginRight: theme.spacing(1),
  },
}));

export function MenuDivider() {
  const classes = useStyles();
  return <div className={classes.menuDivider} />;
}

export function ClickableMenuItem({
  menuItem,
  selected,
  onClick,
  isBackButton,
  isCheckButton,
  isChecked,
  hasSecondLabel,
  secondLabel,
  ...props
}) {
  const classes = useStyles();

  const iconClasses = isBackButton ? classes.iconsBackButton : classes.icons;

  let iconContent = null;
  if (isCheckButton) {
    iconContent = isChecked && <CheckIcon />;
  } else {
    iconContent = menuItem.icon;
  }

  return (
    <MenuItem
      key={menuItem.eventName}
      classes={{ root: classes.menuItem }}
      selected={selected}
      onClick={(e) => onClick(e, menuItem)}
      data-testid={`${menuItem.eventName}-button`}
      {...props}
    >
      <ListItemIcon className={iconClasses}>{iconContent}</ListItemIcon>

      {hasSecondLabel ? (
        <>
          <div className={classes.subMenuLabelWrapper}>
            <Typography variant="body2">{menuItem.label}</Typography>
            <Typography variant="body2" className={classes.subMenuSecondLabel}>
              {secondLabel}
            </Typography>
          </div>

          <ChevronRightIcon className={classes.subMenuLabelChevron} />
        </>
      ) : (
        <Typography variant="body2">{menuItem.label}</Typography>
      )}
    </MenuItem>
  );
}

ClickableMenuItem.defaultProps = {
  selected: false,
  hasSecondLabel: false,
  isBackButton: false,
  isCheckButton: false,
  isChecked: false,
  secondLabel: '',
};

ClickableMenuItem.propTypes = {
  menuItem: PropTypes.shape().isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  isBackButton: PropTypes.bool,
  isCheckButton: PropTypes.bool,
  isChecked: PropTypes.bool,
  hasSecondLabel: PropTypes.bool,
  secondLabel: PropTypes.string,
};

export function SubMenuWrapperItem({
  menuItem,
  secondLabel,
  selected,
  onClick,
  onClickSubMenuItem,
}) {
  const classes = useStyles();
  const [openDropdown, setOpenDropdown] = useState(null);

  const handleClickMainItem = (e) => {
    if (onClick) {
      onClick(e, menuItem);
    }

    setOpenDropdown(e.currentTarget);
  };

  const handleClickItem = (e, item) => {
    if (onClickSubMenuItem) {
      onClickSubMenuItem(e, item);
    }

    setOpenDropdown(null);
  };

  return (
    <>
      <MenuItem
        key={menuItem.eventName}
        classes={{ root: classes.menuItem }}
        selected={selected}
        onClick={handleClickMainItem}
      >
        <ListItemIcon className={classes.icons}>{menuItem.icon}</ListItemIcon>

        <div className={classes.subMenuLabelWrapper}>
          <Typography variant="body2">{menuItem.label}</Typography>
          <Typography variant="body2" className={classes.subMenuSecondLabel}>
            {secondLabel}
          </Typography>
        </div>

        <ChevronRightIcon className={classes.subMenuLabelChevron} />
      </MenuItem>

      <DropdownMenu
        anchorEl={openDropdown}
        onClose={() => setOpenDropdown(null)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        isChild
      >
        {menuItem.subMenu.map((nav) => (
          <ClickableMenuItem
            key={nav.eventName}
            menuItem={nav}
            onClick={(e) => handleClickItem(e, nav)}
          />
        ))}
      </DropdownMenu>
    </>
  );
}

SubMenuWrapperItem.defaultProps = {
  onClick: null,
  selected: false,
};

SubMenuWrapperItem.propTypes = {
  menuItem: PropTypes.shape().isRequired,
  secondLabel: PropTypes.string.isRequired,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onClickSubMenuItem: PropTypes.func.isRequired,
};
