import React from 'react';
import { useTranslation } from 'react-i18next';
import RoutePathname from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import Button from '~/v3/components/core/buttons/button';
import { useTheme } from '@mui/material';
import useRouter from '~/v3/hooks/use-router';
import { myPalettesNav } from '~/v3/pages/my-dashboard/events';

function MyPalettesButton() {
  const { pathname: currentRoute, push } = useRouter();

  const theme = useTheme();
  const { trackEvent } = useTracker();

  const { t } = useTranslation();

  const isOnMyPalettesRoute = currentRoute === RoutePathname.myPalettes;

  const onNavigateToMyPalettes = () => {
    push(RoutePathname.myPalettes);
    trackEvent({
      event_timestamp: new Date().toISOString(),
      ...myPalettesNav,
    });
  };

  const myPalettesButtonStyles = {
    [theme.breakpoints.down('lg')]: {
      width: 90,
      fontSize: 11,
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    width: 125,
    height: 34,
  };

  return (
    <Button
      sx={myPalettesButtonStyles}
      color="gray"
      onClick={onNavigateToMyPalettes}
      selected={isOnMyPalettesRoute}
      data-testid="mypalettes"
    >
      {t('routes.myDashboard')}
    </Button>
  );
}

export default MyPalettesButton;
