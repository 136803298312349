/* eslint-disable radix */
import queryString from 'query-string';
import { gte, lt } from 'semver';
import { setSessionStorage, getSessionStorage } from '~/v3/utils/storage';

const root = document.getElementById('pantoneConnect');
const rootOS = root?.getAttribute('os');
const rootVersion = root?.getAttribute('version');
const params = queryString.parse(
  window.location.search ||
    window.location.hash.substring(window.location.hash.indexOf('?'))
);

const os = params.os || rootOS;
const version = params.version || rootVersion || 'WEBAPP';

const platformVersion =
  params.platformVersion ||
  params.extensionVersion ||
  getSessionStorage('platformVersion');

const platformTimestamp =
  parseInt(params.platformTimestamp) ||
  getSessionStorage('platformTimestamp', true);

if (platformVersion) setSessionStorage('platformVersion', platformVersion);
if (platformTimestamp)
  setSessionStorage('platformTimestamp', platformTimestamp);

export const isWeb = version === 'WEBAPP';
export const isAdobe = version === 'ADOBE';
export const isMobile = version === 'MOBILE';
export const isAndroid = isMobile && os === 'ANDROID';
export const isIOS = isMobile && os === 'IOS';

export const PLATFORM_VERSION = platformVersion;
export const PLATFORM_TIMESTAMP = platformTimestamp;

export const getPlatform = () => {
  if (isAdobe) return 'ADOBE';
  if (isMobile) return 'MOBILE';
  if (isAndroid) return 'ANDROID';
  if (isIOS) return 'IOS';

  return 'WEB';
};

export const isUserAdobeExtensionVersionLessThan = (v) =>
  isAdobe && lt(PLATFORM_VERSION, v);

export const checkMinimumVersions = ({ adobe, android, ios }) =>
  (isIOS && platformVersion && gte(platformVersion, ios || '0.0.0')) ||
  (isAdobe && platformVersion && gte(platformVersion, adobe || '0.0.0')) ||
  (isAndroid && platformVersion && gte(platformVersion, android || '0.0.0')) ||
  isWeb;
