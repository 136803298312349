import { Box, styled } from '@mui/material';
import { PromotionsArea } from '~/v3/types/promotion';
import { useGetMediaQueriesDesktopScreen } from '../containers/auth-container-small-screen/lib/hooks/use-media-queries-desktop-screen';
import { useGetMediaQueriesTabletScreen } from '../containers/auth-container-small-screen/lib/hooks/use-media-queries-tablet-screen';
import { ContainerProps } from '../containers/auth-container-small-screen/auth-container-small-screen.elements';
import { getPromotionBasePath } from '../lib/utils';

const area = PromotionsArea.AUTH;
export const Image = styled(Box)<ContainerProps>(({ promotion, language }) => {
  const mediaQueriesTablet = useGetMediaQueriesTabletScreen({
    language,
    promotion,
    area,
  });

  const mediaQueriesDesktop = useGetMediaQueriesDesktopScreen({
    language,
    promotion,
    area,
  });

  return {
    flex: 1,
    position: 'relative',
    background: `url(${getPromotionBasePath(
      promotion
    )}/images/${area}/${language}/${language}-768x1024.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    ...mediaQueriesTablet,
    ...mediaQueriesDesktop,
  };
});
