import { gte } from 'semver';
import { PLATFORM_VERSION, isIOS } from '~/v2/utils/platform';

export const themeModes = {
  light: 'light',
  dark: 'dark',
};
export const CORE_VERSION = process.env.APP_VERSION;

export const getAppVersionMode = () => {
  let appVersionMode = 'dev';
  if (process.env.NODE_ENV === 'production') appVersionMode = 'production';
  if (process.env.UAT) appVersionMode = 'uat';
  return appVersionMode;
};

export const isProduction =
  process.env.NODE_ENV === 'production' &&
  !process.env.UAT &&
  !process.env.BETA;

export const isBeta = process.env.NODE_ENV === 'production' && process.env.BETA;

export const iosInAppPurchasingSupportedVersion = '1.1.0';

export const isIosInAppPurchasingSupported =
  isIOS &&
  PLATFORM_VERSION &&
  gte(String(PLATFORM_VERSION), iosInAppPurchasingSupportedVersion);

export default {
  themeModes,
  CORE_VERSION,
  getAppVersionMode,
};

enum FeatureFlagsUrl {
  Develop = 'https://32lxhcraru5g3uzvpgk3v6jgju0sstzz.lambda-url.eu-central-1.on.aws/',
  Production = 'https://ad77s4uwghkkbg5vfyghm7xcvu0qoslc.lambda-url.us-east-1.on.aws/',
}

const getSplitTestingLambdaUrl = () => {
  if (isProduction) {
    return FeatureFlagsUrl.Production;
  }

  if (isBeta) {
    return FeatureFlagsUrl.Develop; // should be updated with FF setup for beta environment
  }

  return FeatureFlagsUrl.Develop;
};

export const SPLIT_TESTING_LAMBDA_URL = getSplitTestingLambdaUrl();

export const SPLIT_TESTING_APPLICATION_NAME = 'pantone-connect-feature-flags';

export const SPLIT_TESTING_CONFIGURATION_NAME = 'connect-release-flags-client';

export const SPLIT_TESTING_FLAG = 'paywallPageSplitTest';

export const COY_COLOR_CODE = ['17-1230 TCX', '17-1230 TPG'];

export const VWO_ACCOUNT_ID = isProduction ? '1030159' : '1029445';
