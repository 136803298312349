/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import config from 'config';
import useTracker from 'analytics/useTracker';
import usePaywall from 'hooks/usePaywall';
import useSnackbar from 'features/Snackbar/useSnackbar';
import LoadingButton from 'components/LoadingButton';
import { palettesLimitReached } from 'utils/palette';
import { useConfiguredGetSubscriptionQuery } from 'services/subscriptionApi';
import {
  useGetPalettesQuery,
  useCreatePaletteMutation,
  useUpdatePaletteMutation,
} from 'services/paletteApi';
import useRouter from '~/v3/hooks/use-router';
import routes from '~/v2/constants/route';
import {
  selectName,
  selectColors,
  selectPalette,
  selectUnshavedChanges,
  setPalette,
  setUnsavedChanges,
  setName,
  reset,
} from './paletteSlice';

function PaletteSave({ ...rest }) {
  const { t } = useTranslation();
  const name = useSelector(selectName);
  const colors = useSelector(selectColors);
  const palette = useSelector(selectPalette);
  const unsavedChanges = useSelector(selectUnshavedChanges);
  const dispatch = useDispatch();
  const { push } = useRouter();

  const { data: subscriptionStatus } =
    useConfiguredGetSubscriptionQuery(undefined);
  const { triggerPaywall, paywallCases } = usePaywall();
  const ga = useTracker();
  const [loading, setLoading] = useState(false);
  const { openSnackbar } = useSnackbar();
  const [createPalette, { data: createdPalette }] = useCreatePaletteMutation();
  const [updatePalette, { data: updatedPalette, isError }] =
    useUpdatePaletteMutation();
  const { limitReached } = useGetPalettesQuery(undefined, {
    selectFromResult: ({ data }) => ({
      limitReached: palettesLimitReached(data?.palettes),
    }),
  });

  useEffect(() => {
    if (createdPalette) {
      dispatch(setPalette(createdPalette));
      openSnackbar({
        message: t('messages.paletteCreateSuccessMessage', { name }),
      });
    }
  }, [createdPalette]);

  useEffect(() => {
    if (updatedPalette) {
      dispatch(setPalette(updatedPalette));
      openSnackbar({
        message: t('messages.paletteUpdateSuccessMessage', { name }),
      });
    }
  }, [updatedPalette]);

  useEffect(() => {
    if (!colors?.length) {
      dispatch(setUnsavedChanges(false));
    }
  }, [colors]);

  useEffect(() => {
    if (isError) {
      dispatch(reset());
      openSnackbar({
        message: t('labels.paletteAccessDenided', { palette: palette.name }),
      });
      push(routes.colorPicker);
    }
  }, [isError]);

  const handleClick = async () => {
    setLoading(true);
    const colorCodes = colors.map((color) => color.code);

    if (config.PAYWALL_ENABLED && !subscriptionStatus?.active && limitReached) {
      triggerPaywall(paywallCases.paletteLimit.id);
      setLoading(false);
      return;
    }

    const paletteName = name || t('labels.untitled');
    dispatch(setName(paletteName));

    if (palette?.id) {
      await updatePalette({
        ...palette,
        name: paletteName,
        colors: colorCodes,
      });
    } else {
      await createPalette({ name: paletteName, colors: colorCodes });
    }

    setLoading(false);
    dispatch(setUnsavedChanges(false));
    ga.trackWorkspaceEvent({ action: 'save' });
  };

  return (
    <LoadingButton
      color="primary"
      variant="contained"
      disabled={!colors?.length || !unsavedChanges}
      onClick={handleClick}
      data-testid="save-button"
      loading={loading}
      {...rest}
    >
      {unsavedChanges || !colors?.length ? t('labels.save') : t('labels.saved')}
    </LoadingButton>
  );
}

export default PaletteSave;
