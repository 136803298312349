import React, { useState } from 'react';
import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import MenuList from '@mui/material/Menu';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckIcon from '@mui/icons-material/Check';
import TranslateIcon from '@mui/icons-material/Translate';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import i18n from '~/v2/i18n';
import languageConstants from 'constants/languages';
import events from 'analytics/events';
import useTracker from 'analytics/useTracker';
import { setLocalStorage } from '~/v3/utils/storage';
import config from 'config';

const useStyles = makeStyles(() => ({
  menu: {
    width: 250,
  },
}));

function LanguagesMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();
  const ga = useTracker();

  const selectedLanguage = i18n.languages[0];
  const languages = Object.keys(languageConstants).map((key) => ({
    key,
    name: languageConstants[key],
  }));

  const handleOpen = (event) => {
    setAnchorEl(event.target);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (lang) => {
    i18n.changeLanguage(lang);
    setLocalStorage(config.HAS_USER_DEFINED_LANGUAGE, true);
    handleClose();
    ga.trackEvent({ ...events.e74, TERM: lang });
  };

  return (
    <>
      <Button
        size="small"
        data-testid="languages-menu"
        startIcon={<TranslateIcon />}
        endIcon={<ExpandMoreIcon />}
        onClick={handleOpen}
      >
        {languageConstants[selectedLanguage]}
      </Button>
      <MenuList
        open={!!anchorEl}
        anchorEl={anchorEl}
        classes={{ paper: classes.menu }}
        onClose={handleClose}
      >
        {languages.map((lang) => (
          <ListItem
            dense
            button
            key={lang.key}
            data-id={`${lang.key}-language-button`}
            selected={lang.key === selectedLanguage}
            onClick={() => handleChange(lang.key)}
          >
            <ListItemIcon className={classes.icons}>
              {lang.key === selectedLanguage && <CheckIcon color="secondary" />}
            </ListItemIcon>
            <ListItemText primary={lang.name} />
          </ListItem>
        ))}
      </MenuList>
    </>
  );
}

export default LanguagesMenu;
