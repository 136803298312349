import { getColorBrightness } from 'utils/color';

import newColors from '~/v3/theme/colors';
import colors from './colors';
import components from './components';
import typography from './typography';

export default {
  palette: {
    secondary: {
      light: colors.Illuminating['300'],
      main: colors.Illuminating['500'],
      dark: colors.Illuminating['800'],
      contrastText: colors.JetBlack['500'],
    },
    info: {
      light: colors.LightBlue['300'],
      main: colors.LightBlue['500'],
      dark: colors.LightBlue['800'],
      background: colors.LightBlue['100'],
      border: colors.LightBlue['200'],
      contrastText: colors.WhiteGrey['100'],
    },
    gray: {
      main: newColors.darkGrey,
      dark: newColors.darkGrey,
      light: newColors.lightGrey,
      contrastText: newColors.white,
    },
    blue: {
      main: newColors.pantoneLogoBlue,
      contrastText: newColors.white,
    },
    success: {
      light: '#81C784',
      main: '#4CAF50',
      dark: '#388E3C',
      background: '#EDF7ED',
      border: '#A5D7A7',
      contrastText: colors.WhiteGrey['100'],
    },
    warning: {
      light: '#FFB547',
      main: '#FF9800',
      dark: '#C77700',
      background: '#FFF5E5',
      border: '#FFCB80',
      contrastText: colors.JetBlack['700'],
    },
    error: {
      light: '#F88078',
      main: '#F44336',
      dark: '#E31B0C',
      background: '#FEECEB',
      border: '#F9A19A',
      contrastText: colors.WhiteGrey['100'],
    },
    border: {
      dark: 'rgba(225, 225, 225, 0.2)',
      light: 'rgba(0, 0, 0, 0.05)',
    },
    whiteGrey: colors.WhiteGrey,
    isDark: (rgb) => (rgb ? getColorBrightness(rgb) : null),
  },
  zIndex: {
    referenceColor: 100,
    popover: 1500,
    appBar: 1000,
    confirmDialog: 1600,
    fullScreenDialog: 1800,
    statisticFilters: 100,
  },
  mixins: {
    toolbar: {
      minHeight: 56,
      minHeightIOS: 40,
    },
  },
  shadows: [
    'none',
    '0px 1px 4px rgba(54, 59, 72, 0.15)',
    '0px 2px 6px rgba(54, 59, 72, 0.2)',
    '0px 4px 6px rgba(54, 59, 72, 0.2)',
    '0px 4px 10px rgba(54, 59, 72, 0.25)',
    '0px 5px 12px rgba(54, 59, 72, 0.25)',
    '0px 7px 13px rgba(54, 59, 72, 0.25)',
    '0px 8px 14px rgba(54, 59, 72, 0.27)',
    '0px 9px 14px rgba(54, 59, 72, 0.27)',
    '0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
    '0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
    '0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
    '0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
    '0px 8px 9px -4px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 10px -4px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
    '0px 8px 11px -4px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
    '0px 9px 11px -4px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
    '0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
    '0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
    '0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
    '0px 20px 46px rgba(54, 59, 72, 0.37)',
  ],
  breakpoints: {
    values: {
      xs: 0,
      extension: 370,
      sm: 600,
      md: 1024,
      lg: 1280,
      xl: 1920,
    },
  },
  typography,
  components,
};
