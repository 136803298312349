import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Loader from './Loader';

function LoadingButton({
  children,
  disabled,
  loading,
  endIcon,
  startIcon,
  ...props
}) {
  return (
    <Button
      {...props}
      disabled={loading || disabled}
      endIcon={loading ? null : endIcon}
      startIcon={loading ? null : startIcon}
    >
      {loading ? <Loader iconSize={24} /> : children}
    </Button>
  );
}

LoadingButton.defaultProps = {
  children: null,
  disabled: false,
  loading: false,
  endIcon: null,
  startIcon: null,
};

LoadingButton.propTypes = {
  children: PropTypes.node,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  endIcon: PropTypes.node,
  startIcon: PropTypes.node,
};

export default LoadingButton;
