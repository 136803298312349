import ConvertNavigationItem from './convert';
import ExtractNavigationItem from './extract';
import InsightNavigationItem from './insight';
import MeasureNavigationItem from './measure';
import PickNavigationItem from './pick';
import ColorStoryNavigationItem from './color-story';
import ColorDataNavigationItem from './color-data';

const headerNavigationItems = [
  PickNavigationItem,
  ExtractNavigationItem,
  ConvertNavigationItem,
  MeasureNavigationItem,
  ColorStoryNavigationItem,
  ColorDataNavigationItem,
  InsightNavigationItem,
];

export default headerNavigationItems;
