import RefreshOutlined from '@mui/icons-material/RefreshOutlined';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import events from '~/v2/analytics/events';
import useTracker from '~/v2/analytics/useTracker';
import {
  selectId,
  selectName,
} from '~/v2/features/PaletteWorkspace/paletteSlice';

function RefreshButton({ onSubmit }) {
  const { t } = useTranslation();
  const ga = useTracker();
  const name = useSelector(selectName);
  const id = useSelector(selectId);

  const handleClick = () => {
    onSubmit(undefined);
    ga.trackEvent({
      ...events.e25,
      EVENT_COMPONENT: 'button',
      TERM: `${name}`,
      PALETTE_ID: id,
      PALETTE_NAME: name,
    });
  };

  return (
    <Tooltip title={t('labels.clearColors')}>
      <IconButton
        color="secondary"
        data-testid="clear-colors-button"
        onClick={handleClick}
      >
        <RefreshOutlined />
      </IconButton>
    </Tooltip>
  );
}

RefreshButton.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default RefreshButton;
