import { Typography } from '@mui/material';
import useTracker from '~/v2/analytics/useTracker';
import { onLeftClickMenuEvent } from '~/v3/events';
import useFullScreenColor from '~/v3/hooks/use-full-screen-color';
import { PantoneColor } from '~/v3/types/pantone-color';
import FullScreenIcon from '~/v3/components/shared/icons/full-screen-icon';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledMenuItem } from '../left-click-menu.elements';
import { BaseMenuKeys } from '../model/types';

function FullScreenMenuItem({ color }: Readonly<{ color: PantoneColor }>) {
  const { open: openColorFullScreen } = useFullScreenColor();
  const ga = useTracker();
  const { t } = useTranslation();

  async function handleFullScreenAction() {
    await openColorFullScreen(color);
    ga.trackEvent({
      ...onLeftClickMenuEvent,
      EVENT_LABEL: 'color_chip_full_screen',
      TERM: color.code,
      EVENT_BOOK_ID: color?.book?.id,
    });
  }
  return (
    <StyledMenuItem
      rgb={color?.rgb}
      key={BaseMenuKeys.fullScreen}
      stroke
      onClick={() => handleFullScreenAction()}
    >
      <FullScreenIcon />
      <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
        {t('labels.fullScreen')}
      </Typography>
    </StyledMenuItem>
  );
}

export default FullScreenMenuItem;
