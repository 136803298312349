import { FastSpringProducts } from '~/v3/fastspring-products';
import { ProductType } from '~/v3/paywall/constants';

export enum SubscriptionType {
  Free = 'free',
  Premium = 'premium',
  PremiumPlus = 'premium-plus',
  Trial = 'trial',
}

export enum SubscriptionStatus {
  COUPON_CODE_TRIAL_EXPIRED = 'coupon_code_trial_expired',
  SUBSCRIPTION_ENDED = 'subscription_ended',
  COUPON_CODE_TRIAL = 'coupon_code_trial',
  USER_NOT_SUBSCRIBED = 'user_not_subscribed',
  USER_NOT_SUBSCRIBED_AND_HAS_ORDER_HISTORY = 'user_not_subscribed_and_has_order_history',
  TRIAL = 'trial',
  CANCELED = 'canceled',
  ACTIVE = 'active',
  EXPIRED = 'expired',
  DEACTIVATED = 'deactivated',
}

export enum SubscriptionProviderType {
  COUPON = 'coupon_code',
  COUPON_CODE_TRENDS = 'coupon_code_trends',
  FASTSPRING = 'fastspring',
  FASTSPRING_TRENDS = 'fastspring_trends',
  LICENSE_MANAGER = 'license_manager',
  WHITELIST = 'whitelist_user',
}

export type SubscriptionProduct = `${ProductType}` | FastSpringProducts;

export type Subscription = {
  name?: string;
  subscriptionId?: string;
  active: boolean;
  daysLeft: number;
  providerCode?: string;
  state: SubscriptionStatus;
  subscriptionProvider?: string;
  subscriptionType: SubscriptionType;
  subscriptions: Subscription[];
  product?: SubscriptionProduct;
  coupons: UsedCoupon[] | null;
};

type UsedCoupon = {
  company?: string;
  days: number;
  id: string;
  redeemedAt: string;
};

export enum SubscriptionName {
  FASTSPRING = 'fastspring',
  FASTSPRING_INSIDER = 'fastspring_trends',
  LICENSE_MANAGER = 'license_manager',
  COUPON_CODE = 'coupon_code',
}

export type SubscriptionDetails = {
  subscriptionId: string | null;
  name: SubscriptionName;
  active: boolean;
  product: string | null;
  state: SubscriptionStatus;
  daysLeft?: number | null;
  subscriptionType: SubscriptionType;
};

export type GetSubscriptionResponse = {
  data: {
    getSubscription: {
      subscriptionId?: string | null;
      active: boolean;
      state: string;
      daysLeft?: number | null;
      subscriptionType: string;
      subscriptionProvider: string | null;
      product?: string | null;
      subscriptions?: SubscriptionDetails[];
    };
  };
};
