import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import colorwheel from '../../assets/colorwheel-stroke.png';

const DOT_SIZE_PERCENTAGE = 0.1;
const DOT_OFFSET_PERCENTAGE = 0;
const ROTATION_OFFSET = 0;
const WHEEL_SIZE_PERCENTAGE = 80;

const useStyles = makeStyles(() => ({
  container: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  containerOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dotsContainer: {
    position: 'relative',
  },
  wheel: {
    maxWidth: `${WHEEL_SIZE_PERCENTAGE}%`,
    maxHeight: `${WHEEL_SIZE_PERCENTAGE}%`,
    height: `${WHEEL_SIZE_PERCENTAGE}%`,
    objectFit: 'contain',
  },
  dot: {
    position: 'absolute',
    borderRadius: '50%',
  },
}));

const HueDistribution = ({ colors }) => {
  const classes = useStyles();
  const targetRef = useRef();
  const [componentDimensions, setComponentDimensions] = useState(null);

  const handleResize = () => {
    if (targetRef.current)
      setComponentDimensions(targetRef.current.getBoundingClientRect());
  };
  useEffect(handleResize, [targetRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  let wheelSize;
  let dotSize;
  let dotOffset;
  if (componentDimensions) {
    const height =
      componentDimensions &&
      (componentDimensions.height / 100) * WHEEL_SIZE_PERCENTAGE;
    const width =
      componentDimensions &&
      (componentDimensions.width / 100) * WHEEL_SIZE_PERCENTAGE;
    wheelSize = width < height ? width : height;
    dotSize = wheelSize * DOT_SIZE_PERCENTAGE;
    dotOffset = wheelSize * DOT_OFFSET_PERCENTAGE + dotSize / 2;
  }

  return (
    <div className={classes.container} ref={targetRef}>
      <img
        className={classes.wheel}
        src={colorwheel}
        alt="hue distribution"
        onLoad={handleResize}
      />
      <div className={classes.containerOverlay}>
        {wheelSize && (
          <div
            className={classes.dotsContainer}
            style={{
              width: wheelSize,
              height: wheelSize,
            }}
          >
            {colors.map((color) => (
              <div
                key={color.code}
                className={classes.dot}
                style={{
                  top:
                    wheelSize / 2 -
                    (wheelSize / 2 + dotOffset) *
                      Math.cos(
                        color.hsl.h * (Math.PI / 180) - ROTATION_OFFSET
                      ) -
                    dotSize / 2,
                  left:
                    wheelSize / 2 +
                    (wheelSize / 2 + dotOffset) *
                      Math.sin(
                        color.hsl.h * (Math.PI / 180) - ROTATION_OFFSET
                      ) -
                    dotSize / 2,
                  backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
                  width: dotSize,
                  height: dotSize,
                }}
              />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

HueDistribution.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default HueDistribution;
