import React from 'react';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

import ImageDownloadDialog from 'components/ImageDownloadDialog';
import FullScreenDialog from 'components/FullScreenDialogNew';
import useImageDownload from './useImageDownload';

const ImageDownload = () => {
  const { close, isOpen, colors } = useImageDownload();
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <FullScreenDialog
      title={t('labels.selectATemplate')}
      open={isOpen}
      style={{ zIndex: theme.zIndex.popover }}
      onClose={close}
    >
      {colors.length && <ImageDownloadDialog colors={colors} />}
    </FullScreenDialog>
  );
};

export default ImageDownload;
