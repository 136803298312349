import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function ColorDataMobileIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 24 18"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: 20,
        height: 18,
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        d="M1.177 2.6152C1.177 1.73154 1.77396 1.0152 2.51033 1.0152H3.84367C4.58005 1.0152 5.177 1.73154 5.177 2.6152V15.4152C5.177 16.2989 4.58005 17.0152 3.84367 17.0152H2.51033C1.77396 17.0152 1.177 16.2989 1.177 15.4152V2.6152Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M8.177 2.6152C8.177 1.73154 8.77396 1.0152 9.51033 1.0152H10.8437C11.58 1.0152 12.177 1.73154 12.177 2.6152V15.4152C12.177 16.2989 11.58 17.0152 10.8437 17.0152H9.51033C8.77396 17.0152 8.177 16.2989 8.177 15.4152V2.6152Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M15.177 2.6152C15.177 1.73154 15.774 1.0152 16.5103 1.0152H17.8437C18.58 1.0152 19.177 1.73154 19.177 2.6152V15.4152C19.177 16.2989 18.58 17.0152 17.8437 17.0152H16.5103C15.774 17.0152 15.177 16.2989 15.177 15.4152V2.6152Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M23.1781 0.984863L13.6012 11.2773L8.56071 6.67278L1 13.9858M23.1781 0.984863H17.1295M23.1781 0.984863V7.48534"
        stroke={theme.palette.icon.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default ColorDataMobileIcon;
