import useRouter from '~/v3/hooks/use-router';
import RoutePathname from 'constants/route';

const usePaywall = () => {
  const { push } = useRouter();

  const paywallCases = {
    default: {
      id: 'default',
    },
    adobe: {
      id: 'adobe',
    },
    paletteLimit: {
      id: 'paletteLimit',
    },
  };

  const triggerPaywall = (paywallCase) => {
    if (paywallCase) {
      push(`${RoutePathname.paywall}?paywallCase=${paywallCase}`);
    } else {
      push(RoutePathname.paywall);
    }
  };

  return {
    triggerPaywall,
    paywallCases,
  };
};

export default usePaywall;
