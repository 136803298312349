import React from 'react';
import useTracker from '~/v2/analytics/useTracker';
import events from '~/v2/analytics/events';
import RoutePathname from '~/v2/constants/route';
import PantoneConnectLogo from '~/v3/components/shared/icons/pantone-connect-logo-icon';
import { SxProps, useTheme } from '@mui/material';
import useRouter from '~/v3/hooks/use-router';

function PantoneConnectHeaderLogo() {
  const ga = useTracker();
  const theme = useTheme();

  const { push } = useRouter();

  const onPantoneLogoClick = () => {
    ga.trackEvent(events.e1);
    push(RoutePathname.colorPicker);
  };

  const pantoneConnectLogoStyles: SxProps = {
    cursor: 'pointer',
    marginRight: {
      xl: 6,
      md: 4,
    },
    width: {
      xl: 101,
      md: 90,
      xs: 101,
    },
    [theme.breakpoints.down('sm')]: {
      height: 23,
      width: 82,
    },
  };

  return (
    <PantoneConnectLogo
      onClick={onPantoneLogoClick}
      data-testid="pantone-connect-header-logo"
      sx={pantoneConnectLogoStyles}
    />
  );
}

export default PantoneConnectHeaderLogo;
