/*
  'na' means null but we don't send null because the field in GA shows as empty and we don't want that.
*/

export default {
  e1: {
    EVENT_ID: 'e1',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'logo',
    EVENT_LABEL: 'e-logo',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'logo_and_profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e2: {
    EVENT_ID: 'e2',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile',
    EVENT_LABEL: 'e-profile',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'logo_and_profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e3: {
    EVENT_ID: 'e3',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile',
    EVENT_LABEL: 'e-signout',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'logo_and_profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e4: {
    EVENT_ID: 'e4',
    EVENT_PATH: 'search',
    EVENT_PAGE: 'search',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'search_placeholder',
    EVENT_LABEL: 'e-srch_open',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'search_resulted_top',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e5: {
    EVENT_ID: 'e5',
    EVENT_PATH: 'search?query=value',
    EVENT_PAGE: 'search_query',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'search_placeholder',
    EVENT_LABEL: 'e-srch_close',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'search_resulted_top',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e6: {
    EVENT_ID: 'e6',
    EVENT_PATH: 'search?query=value',
    EVENT_PAGE: 'search_query',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'search_placeholder',
    EVENT_LABEL: 'e-srch_send_query',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'search_resulted_top',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e7: {
    EVENT_ID: 'e7',
    EVENT_PATH: 'search?query=value',
    EVENT_PAGE: 'search_query',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'search_placeholder',
    EVENT_LABEL: 'e-srch_capture_query_term',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'search_resulted_top',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e8: {
    EVENT_ID: 'e8',
    EVENT_LABEL: 'add_to_palette',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e9: {
    EVENT_ID: 'e9',
    EVENT_LABEL: 'e-get_chip_info',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e10: {
    EVENT_ID: 'e10',
    EVENT_LABEL: 'e-get_chip_shades',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e11: {
    EVENT_ID: 'e11',
    EVENT_PATH: 'search',
    EVENT_PAGE: 'search',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'search_placeholder',
    EVENT_LABEL: 'e-get_chip_harmonies',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e12: {
    EVENT_ID: 'e12',
    EVENT_LABEL: 'e-get_chip_cross_reference',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e13: {
    EVENT_ID: 'e13',
    EVENT_PLACE: 'body',
    EVENT_LABEL: 'e-download_ase',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e14: {
    EVENT_ID: 'e14',
    EVENT_PLACE: 'body',
    EVENT_LABEL: 'e-get_physical_sample',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e15: {
    EVENT_ID: 'e15',
    EVENT_COMPONENT: 'create_new',
    EVENT_LABEL: 'e-clicked_on_chip',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e16: {
    EVENT_ID: 'e16',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'search_results_info_section',
    EVENT_LABEL: 'e-see_on_pantone_website',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'info_bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e17: {
    EVENT_ID: 'e17',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'search_results_info_section',
    EVENT_LABEL: 'e-copied_srgb',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'info_bottom',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e18: {
    EVENT_ID: 'e18',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'search_results_info_section',
    EVENT_LABEL: 'e-copied_hex',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'info_bottom',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e19: {
    EVENT_ID: 'e19',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'search_results_info_section',
    EVENT_LABEL: 'e-copied_lab',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'info_bottom',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e20: {
    EVENT_ID: 'e20',
    EVENT_LABEL: 'e-workspace_chip_remove_from_palette',
    EVENT_ACTION: 'click',
  },
  e21: {
    EVENT_ID: 'e21',
    EVENT_LABEL: 'e-workspace_chips_edit_palette_name',
    EVENT_ACTION: 'click',
  },
  e22: {
    EVENT_ID: 'e22',
    EVENT_LABEL: 'e-workspace_chips_enter_palette_name',
    EVENT_ACTION: 'click',
  },
  e23: {
    EVENT_ID: 'e23',
    EVENT_LABEL: 'e-workspace_chips_confirm_palette_name',
    EVENT_ACTION: 'click',
  },
  e24: {
    EVENT_ID: 'e24',
    EVENT_LABEL: 'e-workspace_chips_save_palette',
    EVENT_ACTION: 'click',
  },
  e25: {
    EVENT_ID: 'e25',
    EVENT_LABEL: 'clear_workspace',
    EVENT_ACTION: 'click',
    EVENT_PLACE: 'workspace',
  },
  e26: {
    EVENT_ID: 'e26',
    EVENT_LABEL: 'e-workspace_chip_reset_palette_confirmation',
    EVENT_ACTION: 'click',
  },
  e27: {
    EVENT_ID: 'e27',
    EVENT_LABEL: 'e-workspace_chip_reset_palette_cancellation',
    EVENT_ACTION: 'click',
  },
  e28: {
    EVENT_ID: 'e28',
    EVENT_LABEL: 'e-workspace_chip_analyze_palette',
    EVENT_ACTION: 'click',
  },
  e29: {
    EVENT_ID: 'e29',
    EVENT_LABEL: 'e-workspace_chip_download_palette',
    EVENT_ACTION: 'click',
  },
  e30: {
    EVENT_ID: 'e30',
    EVENT_LABEL: 'e-workspace_chip_show_color_details',
    EVENT_ACTION: 'click',
  },
  e31: {
    EVENT_ID: 'e31',
    EVENT_PLACE: 'body',
    EVENT_LABEL: 'e-clicked_on_library_filter',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'library',
    EVENT_BOOK_ID: 'na',
  },
  e32: {
    EVENT_ID: 'e32',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'mypalettes',
    EVENT_LABEL: 'e-mypalettes',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'menu',
  },
  e33: {
    EVENT_ID: 'e33',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'adobe_extension_message',
    EVENT_LABEL: 'e-cross_adobe_extension_message',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_top',
  },
  e34: {
    EVENT_ID: 'e34',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'personal_palettes',
    EVENT_LABEL: 'e-mypalettes_personalpalettes',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_top',
  },
  e35: {
    EVENT_ID: 'e35',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'shared_palettes',
    EVENT_LABEL: 'e-mypalettes_sharedpalettes',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_top',
  },
  e36: {
    EVENT_ID: 'e36',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'color_story',
    EVENT_LABEL: 'e-mypalettes_colorstory',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_top',
  },
  e37: {
    EVENT_ID: 'e37',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'shared_color_story',
    EVENT_LABEL: 'e-mypalettes_sharedcolorstory',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_sub_menu',
  },
  e38: {
    EVENT_ID: 'e38',
    EVENT_COMPONENT: 'edit_palette',
    EVENT_LABEL: 'e-edit_palette',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e39: {
    EVENT_ID: 'e39',
    EVENT_COMPONENT: 'share',
    EVENT_LABEL: 'e-share_palette_open',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_created_palette',
  },
  e40: {
    EVENT_ID: 'e40',
    EVENT_COMPONENT: 'palette_cancellation_cross_mark',
    EVENT_LABEL: 'e-share_palette_cancellation_without_email',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_created_palette_search_box',
  },
  e41: {
    EVENT_ID: 'e41',
    EVENT_COMPONENT: 'palette_cancellation_cross_mark',
    EVENT_LABEL: 'e-share_palette_added_close',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_created_palette_search_box',
  },
  e42: {
    EVENT_ID: 'e42',
    EVENT_COMPONENT: 'share_palette_action_share_button',
    EVENT_LABEL: 'e-share_palette_action_share',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_created_palette_search_box',
  },
  e43: {
    EVENT_ID: 'e43',
    EVENT_COMPONENT: 'palette_delete',
    EVENT_LABEL: 'e-delete_palette',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e44: {
    EVENT_ID: 'e44',
    EVENT_COMPONENT: 'create_new_pallete',
    EVENT_LABEL: 'e-create_new_palette',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e45: {
    EVENT_ID: 'e45',
    EVENT_COMPONENT: 'try_color_story',
    EVENT_LABEL: 'e-try_color_story',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e46: {
    EVENT_ID: 'e46',
    EVENT_COMPONENT: 'share_pallete_action_share',
    EVENT_LABEL: 'e-share_palette_remove_person',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e47: {
    EVENT_ID: 'e47',
    EVENT_COMPONENT: 'share_pallete_cancellation',
    EVENT_LABEL: 'e-share_palette_cancellation_with_email',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'personal_palettes',
  },
  e48: {
    EVENT_ID: 'e48',
    EVENT_COMPONENT: 'see_faq',
    EVENT_LABEL: 'e-see_faq',
    EVENT_ACTION: 'click',
  },
  e49: {
    EVENT_ID: 'e49',
    EVENT_LABEL: 'e-about_color_system',
    EVENT_ACTION: 'click',
  },
  e50: {
    EVENT_ID: 'e50',
    EVENT_LABEL: 'e-filter_color_filled',
    EVENT_ACTION: 'click',
  },
  e51: {
    EVENT_ID: 'e51',
    EVENT_LABEL: 'e-add-all-colors',
    EVENT_ACTION: 'click',
  },
  e52: {
    EVENT_ID: 'e52',
    EVENT_LABEL: 'e-try_color_story',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'shared_color_story',
  },
  e53: {
    EVENT_ID: 'e53',
    EVENT_COMPONENT: 'palette_header',
    EVENT_LABEL: 'e-chip_analyze_palette_details',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'shared_color_story',
  },
  e54: {
    EVENT_ID: 'e54',
    EVENT_COMPONENT: 'palette_header',
    EVENT_LABEL: 'e-chip_download_palette',
    EVENT_ACTION: 'click',
  },
  e55: {
    EVENT_ID: 'e55',
    EVENT_LABEL: 'e-images_sub_menu',
    EVENT_ACTION: 'click',
  },
  e56: {
    EVENT_ID: 'e56',
    EVENT_LABEL: 'e-images_template',
    EVENT_ACTION: 'click',
  },
  e57: {
    EVENT_ID: 'e57',
    EVENT_COMPONENT: 'tap',
    EVENT_LABEL: 'e-image_add_tap',
    EVENT_ACTION: 'click',
  },
  e58: {
    EVENT_ID: 'e58',
    EVENT_COMPONENT: 'click',
    EVENT_LABEL: 'e-image_add_click',
    EVENT_ACTION: 'click',
  },
  e59: {
    EVENT_ID: 'e59',
    EVENT_COMPONENT: 'drag_n_drop',
    EVENT_LABEL: 'e-image_add_drag_n_drop',
    EVENT_ACTION: 'click',
  },
  e60: {
    EVENT_ID: 'e60',
    EVENT_LABEL: 'e-image_upload_picture',
    EVENT_ACTION: 'click',
  },
  e61: {
    EVENT_ID: 'e61',
    EVENT_LABEL: 'e-image_upload_search_in_adobe_stock',
    EVENT_ACTION: 'click',
  },
  e62: {
    EVENT_ID: 'e62',
    EVENT_COMPONENT: 'upload',
    EVENT_LABEL: 'e-image_upload_upload_succeeded',
    EVENT_ACTION: 'click',
  },
  e63: {
    EVENT_ID: 'e63',
    EVENT_COMPONENT: 'upload',
    EVENT_LABEL: 'e-image_upload_upload_failed',
    EVENT_ACTION: 'click',
  },
  e64: {
    EVENT_ID: 'e64',
    EVENT_LABEL: 'e-image_upload_through_adobe_stock_sq',
    EVENT_ACTION: 'click',
  },
  e65: {
    EVENT_ID: 'e65',
    EVENT_LABEL: 'e-image_upload_through_adobe_stock_search',
    EVENT_ACTION: 'click',
  },
  e66: {
    EVENT_ID: 'e66',
    EVENT_LABEL: 'e-image_upload_through_adobe_stock_enter',
    EVENT_ACTION: 'click',
  },
  e67: {
    EVENT_ID: 'e67',
    EVENT_LABEL: 'e-image_upload_through_adobe_stock_chosen',
    EVENT_ACTION: 'click',
  },
  e68: {
    EVENT_ID: 'e68',
    EVENT_LABEL: 'e-image_upload_uploaded_extract_colors',
    EVENT_ACTION: 'click',
  },
  e69: {
    EVENT_ID: 'e69',
    EVENT_COMPONENT: 'randomize',
    EVENT_LABEL: 'e-image_upload_uploaded_circle_spotter',
    EVENT_ACTION: 'click',
  },
  e70: {
    EVENT_ID: 'e70',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'adobe_extension_message',
    EVENT_LABEL: 'e-cross_adobe_extension_message_close',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'mypalettes_top',
  },
  e71: {
    EVENT_ID: 'e71',
    EVENT_COMPONENT: 'upload',
    EVENT_LABEL: 'e-image_upload_new_picture',
    EVENT_ACTION: 'click',
  },
  e72: {
    EVENT_ID: 'e72',
    EVENT_COMPONENT: 'search',
    EVENT_LABEL: 'e-image_searched',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'extract_find_a_picture',
  },
  e73: {
    EVENT_ID: 'e73',
    EVENT_COMPONENT: 'upload',
    EVENT_LABEL: 'e-image_searched_randomized',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'extract_find_a_picture',
  },
  e74: {
    EVENT_ID: 'e74',
    EVENT_PLACE: 'top',
    EVENT_COMPONENT: 'dropdown',
    EVENT_LABEL: 'e-language',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'laguage',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e75: {
    EVENT_ID: 'e75',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'selection',
    EVENT_LABEL: 'e-login_through',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'email',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e76: {
    EVENT_ID: 'e76',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-login',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'login_button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e77: {
    EVENT_ID: 'e77',
    EVENT_PLACE: 'placeholder',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-email_value',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'login_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e78: {
    EVENT_ID: 'e78',
    EVENT_PLACE: 'footer',
    EVENT_COMPONENT: 'link',
    EVENT_LABEL: 'e-terms_of_use',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e79: {
    EVENT_ID: 'e79',
    EVENT_PLACE: 'footer',
    EVENT_COMPONENT: 'link',
    EVENT_LABEL: 'e-privacy_policy',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e80: {
    EVENT_ID: 'e80',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-password',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e81: {
    EVENT_ID: 'e81',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-password_view',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e82: {
    EVENT_ID: 'e82',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-sign_in',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'login_success',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e83: {
    EVENT_ID: 'e83',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-cannot_sign_in',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e84: {
    EVENT_ID: 'e84',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-restore_password',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e85: {
    EVENT_ID: 'e85',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-go_back',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e86: {
    EVENT_ID: 'e86',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-send_code',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e87: {
    EVENT_ID: 'e87',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-go_to_sign_in',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e88: {
    EVENT_ID: 'e88',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-enter_code',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'code_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e89: {
    EVENT_ID: 'e89',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'element',
    EVENT_LABEL: 'e-enter_code_increment',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e90: {
    EVENT_ID: 'e90',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-enter_new_password',
    EVENT_ACTION: 'text',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e91: {
    EVENT_ID: 'e91',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'element',
    EVENT_LABEL: 'e-new_password_view',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e92: {
    EVENT_ID: 'e92',
    EVENT_PATH: '/password-verification',
    EVENT_PAGE: 'password-verification',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-changed_password',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e93: {
    EVENT_ID: 'e93',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-password',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e94: {
    EVENT_ID: 'e94',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-password_view',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e95: {
    EVENT_ID: 'e95',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-confirm_password',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e96: {
    EVENT_ID: 'e96',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-confirm_password_view',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'password_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e97: {
    EVENT_ID: 'e97',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-first_name',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'name_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e98: {
    EVENT_ID: 'e98',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'text',
    EVENT_LABEL: 'e-last_name',
    EVENT_ACTION: 'enter',
    EVENT_AREA: 'name_placeholder',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e99: {
    EVENT_ID: 'e99',
    EVENT_PATH: '/create-account',
    EVENT_PAGE: 'create-account',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-sign_up',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'button',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e100: {
    EVENT_ID: 'e100',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-profile_profile',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e101: {
    EVENT_ID: 'e101',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-language',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e102: {
    EVENT_ID: 'e102',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-registration_code',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e103: {
    EVENT_ID: 'e103',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-faq',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e104: {
    EVENT_ID: 'e104',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-about',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e105: {
    EVENT_ID: 'e105',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-privacy_policy',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e106: {
    EVENT_ID: 'e106',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-clear_app_cache',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'profile',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
  e107: {
    EVENT_ID: 'e107',
    EVENT_LABEL: 'e-colors',
    EVENT_ACTION: 'click',
  },
  e108: {
    EVENT_ID: 'e108',
    EVENT_COMPONENT: 'menu_dropdown',
    EVENT_LABEL: 'e-colors_pick',
    EVENT_ACTION: 'click',
  },
  e109: {
    EVENT_ID: 'e109',
    EVENT_COMPONENT: 'menu_dropdown',
    EVENT_LABEL: 'e-colors_extract',
    EVENT_ACTION: 'click',
  },
  e110: {
    EVENT_ID: 'e110',
    EVENT_COMPONENT: 'menu_dropdown',
    EVENT_LABEL: 'e-colors_convert',
    EVENT_ACTION: 'click',
  },
  e111: {
    EVENT_ID: 'e111',
    EVENT_COMPONENT: 'menu_dropdown',
    EVENT_LABEL: 'e-colors_measure',
    EVENT_ACTION: 'click',
  },
  e112: {
    EVENT_ID: 'e112',
    EVENT_LABEL: 'e-convert_color_space',
    EVENT_ACTION: 'click',
  },
  e113: {
    EVENT_ID: 'e113',
    EVENT_LABEL: 'e-convert_srgb_color_picker',
    EVENT_ACTION: 'click',
  },
  e114: {
    EVENT_ID: 'e114',
    EVENT_LABEL: 'e-convert_srgb_random_color_spotter',
    EVENT_ACTION: 'click',
  },
  e115: {
    EVENT_ID: 'e115',
    EVENT_LABEL: 'e-convert_srgb_horizontal_color_spotter',
    EVENT_ACTION: 'click',
  },
  e116: {
    EVENT_ID: 'e116',
    EVENT_LABEL: 'e-convert_color_picker_convert',
    EVENT_ACTION: 'click',
  },
  e117: {
    EVENT_ID: 'e117',
    EVENT_COMPONENT: 'color_measure_android_app',
    EVENT_LABEL: 'e-color_measure_android_app',
    EVENT_ACTION: 'click',
  },
  e118: {
    EVENT_ID: 'e118',
    EVENT_COMPONENT: 'color_measure_ios_app',
    EVENT_LABEL: 'e-color_measure_ios_app',
    EVENT_ACTION: 'click',
  },
  e119: {
    EVENT_ID: 'e119',
    EVENT_COMPONENT: 'color_measure_buy_card',
    EVENT_LABEL: 'e-color_measure_buy_card',
    EVENT_ACTION: 'click',
  },
  e120: {
    EVENT_ID: 'e120',
    EVENT_COMPONENT: 'color_measure_check_support',
    EVENT_LABEL: 'e-color_measure_check_support',
    EVENT_ACTION: 'click',
  },
  e121: {
    EVENT_ID: 'e121',
    EVENT_LABEL: 'e-trending',
    EVENT_ACTION: 'click',
  },
  e122: {
    EVENT_ID: 'e122',
    EVENT_COMPONENT: 'tab',
    EVENT_LABEL: 'e-color_of_the_year',
    EVENT_ACTION: 'click',
  },
  e123: {
    EVENT_ID: 'e123',
    EVENT_COMPONENT: 'tab',
    EVENT_LABEL: 'e-trending_palettes',
    EVENT_ACTION: 'click',
  },
  e124: {
    EVENT_ID: 'e124',
    EVENT_LABEL: 'e-trending_coy_add_to_palette',
    EVENT_ACTION: 'click',
  },
  e125: {
    EVENT_ID: 'e125',
    EVENT_LABEL: 'e-trending_coy_analyze',
    EVENT_ACTION: 'click',
  },
  e126: {
    EVENT_ID: 'e126',
    EVENT_LABEL: 'e-trending_coy_edit_palettes',
    EVENT_ACTION: 'click',
  },
  e127: {
    EVENT_ID: 'e127',
    EVENT_LABEL: 'e-trending_tp_add_to_palette',
    EVENT_ACTION: 'click',
  },
  e128: {
    EVENT_ID: 'e128',
    EVENT_LABEL: 'e-trending_coy_download_ase',
    EVENT_ACTION: 'click',
  },
  e129: {
    EVENT_ID: 'e129',
    EVENT_LABEL: 'e-trending_coy_edit_palettes',
    EVENT_ACTION: 'click',
  },
  e131: {
    EVENT_ID: 'e131',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'colorstory',
    EVENT_LABEL: 'e-colorstory',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'menu',
  },
  e132: {
    EVENT_ID: 'e132',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'popup',
    EVENT_LABEL: 'e-paywall_popped',
    EVENT_ACTION: 'click',
  },
  e133: {
    EVENT_ID: 'e133',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'popup',
    EVENT_LABEL: 'e-paywall_go_back_to_free',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na',
  },
  e134: {
    EVENT_ID: 'e134',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_clicked_monthly',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na',
  },
  e135: {
    EVENT_ID: 'e135',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_clicked_yearly',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na',
  },
  e136: {
    EVENT_ID: 'e136',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'placeholder',
    EVENT_LABEL: 'e-redeem_code_book_code',
    EVENT_ACTION: 'text',
    EVENT_AREA: 'na',
  },
  e137: {
    EVENT_ID: 'e137',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'placeholder',
    EVENT_LABEL: 'e-redeem_code_purchase_date',
    EVENT_ACTION: 'text',
    EVENT_AREA: 'na',
  },
  e138: {
    EVENT_ID: 'e138',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-redeem_code_enter_code',
    EVENT_ACTION: 'click',
  },
  e139: {
    EVENT_ID: 'e139',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-redeem_code_start_trial',
    EVENT_ACTION: 'click',
  },
  e140: {
    EVENT_ID: 'e140',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-color_measure_measure',
    EVENT_ACTION: 'click',
  },
  e141: {
    EVENT_ID: 'e141',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-color_measure_result',
    EVENT_ACTION: 'click',
  },
  e142: {
    EVENT_ID: 'e142',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_bought_monthly',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na',
  },
  e143: {
    EVENT_ID: 'e143',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_bought_yearly',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na',
  },
  e144: {
    EVENT_ID: 'e144',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'carousel',
    EVENT_LABEL: 'e-explore_carousel_slide',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na', // carousel || read_more
  },
  e145: {
    EVENT_ID: 'e145',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'carousel',
    EVENT_LABEL: 'e-explore_carousel_dots',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na', // carousel || article
  },
  e146: {
    EVENT_ID: 'e146',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'carousel',
    EVENT_LABEL: 'e-explore_article_carousel_arrows',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'article',
  },
  e147: {
    EVENT_ID: 'e147',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'article_summary',
    EVENT_LABEL: 'e-explore_article',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'na', // discover_more || article,
  },
  e148: {
    EVENT_ID: 'e148',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'article_palette',
    EVENT_LABEL: 'e-explore_article_upgrade_to_premium',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'article_palette',
  },
  e149: {
    EVENT_ID: 'e149',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'article_palette',
    EVENT_LABEL: 'e-explore_add_palette_to_workspace',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'article_palette',
  },
  e150: {
    EVENT_ID: 'e150',
    EVENT_PATH: '/paywall',
    EVENT_PAGE: 'paywall',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'paywall_toggle_multiseats',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'plans_middle',
  },
  e151: {
    EVENT_ID: 'e151',
    EVENT_PATH: '/paywall',
    EVENT_PAGE: 'paywall',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_business_upgrade',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'plans_middle',
  },
  e152: {
    EVENT_ID: 'e152',
    EVENT_PATH: '/paywall',
    EVENT_PAGE: 'paywall',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-paywall_back-to-free',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'plans_middle',
  },
  e153: {
    EVENT_ID: 'e153',
    EVENT_PATH: '/paywall',
    EVENT_PAGE: 'paywall',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'paywall_toggle_individual',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'plans_middle',
  },
  e154: {
    EVENT_ID: 'e154',
    EVENT_PATH: '/picker',
    EVENT_PAGE: 'picker',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-picker_change-hue-spectrum',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'picker',
  },
  e155: {
    EVENT_ID: 'e155',
    EVENT_PATH: '/picker',
    EVENT_PAGE: 'picker',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-picker_increase-chip-size',
  },
  e156: {
    EVENT_ID: 'e156',
    EVENT_PATH: '/picker',
    EVENT_PAGE: 'picker',
    EVENT_PLACE: 'body',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'e-picker_decrease-chip-size',
  },
  e157: {
    EVENT_ID: 'e157',
    EVENT_PLACE: 'footer',
    EVENT_COMPONENT: 'link',
    EVENT_LABEL: 'e-cookie_policy',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e158: {
    EVENT_ID: 'e158',
    EVENT_PLACE: 'footer',
    EVENT_COMPONENT: 'link',
    EVENT_LABEL: 'e-ccpa_policy',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e159: {
    EVENT_ID: 'e159',
    EVENT_PLACE: 'footer',
    EVENT_COMPONENT: 'link',
    EVENT_LABEL: 'e-faq',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'bottom',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
  },
  e160: {
    EVENT_ID: 'e160',
    EVENT_LABEL: 'mypalette_duplicate_palette',
    EVENT_ACTION: 'click',
  },
  e161: {
    EVENT_ID: 'e161',
    EVENT_ACTION: 'click',
    EVENT_LABEL: 'marketing_opt-in',
    EVENT_COMPONENT: 'checkbox',
  },
  e162: {
    EVENT_ID: 'e162',
    EVENT_PLACE: 'body',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'user-segment',
    EVENT_COMPONENT: 'button',
  },
  e163: {
    EVENT_ID: 'e163',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'paywall_business',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'paywall_contact_salesperson',
  },
  e164: {
    EVENT_ID: 'e164',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_google',
  },
  e165: {
    EVENT_ID: 'e165',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_facebook',
  },
  e166: {
    EVENT_ID: 'e166',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_apple',
  },
  e167: {
    EVENT_ID: 'e167',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_email',
  },
  e168: {
    EVENT_ID: 'e168',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_sso',
  },
  e169: {
    EVENT_ID: 'e169',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'signup',
    EVENT_COMPONENT: 'button',
    EVENT_LABEL: 'signup_email',
  },
  e170: {
    EVENT_ID: 'e170',
    EVENT_ACTION: 'click',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    EVENT_LABEL: 'download_palette',
  },
  e171: {
    EVENT_ID: 'e171',
    EVENT_ACTION: 'click',
    EVENT_LABEL: 'entry_link_click',
  },
  e172: {
    EVENT_ID: 'e172',
    EVENT_PLACE: 'header',
    EVENT_COMPONENT: 'profile_dropdown',
    EVENT_LABEL: 'e-profile_activate-code',
    EVENT_ACTION: 'click',
    EVENT_AREA: 'activate-code',
    EVENT_GROUP: 'na',
    EVENT_BOOK_ID: 'na',
    TERM: 'na',
  },
};
