import { Box, Button, styled, TextField, Typography } from '@mui/material';
import colors from '~/v3/theme/colors';

export const RadioButtonWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1.125),
    paddingRight: theme.spacing(1.125),
  },
}));

export const Subtitle = styled(Typography)(({ theme }) => ({
  color: colors.mediumBlue,
  marginBottom: theme.spacing(1),
}));

export const StyledButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
  marginRight: theme.spacing(1.5),
  [theme.breakpoints.down('sm')]: {
    marginRight: theme.spacing(0),
  },
}));

export const ButtonWrapper = styled(Box)(({ theme }) => ({
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
  },
}));

export const FeedbackWrapper = styled(Box)(() => ({
  width: '100%',
}));

export const StyledTextField = styled(TextField)(() => ({
  width: '100%',
}));

export const CheckboxWrapper = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(4),
  [theme.breakpoints.down('sm')]: {
    paddingLeft: theme.spacing(1.125),
    paddingRight: theme.spacing(1.125),
  },
}));

export const CheckboxContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),
}));
