import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AssessmentIcon from '@mui/icons-material/AssessmentOutlined';
import { useTranslation } from 'react-i18next';

import useTracker from 'analytics/useTracker';
import usePaletteAnalysis from 'features/PaletteAnalysis/usePaletteAnalysis';
import { hasProtectedPanoramaColors, removeNullColors } from 'utils/palette';
import { paletteEngaged } from '~/v3/pages/my-dashboard/events';

function AnalyzeButton({ id, name, colors, disabled }) {
  const palette = { name, colors };
  const filteredPalette = removeNullColors(palette);
  const { open } = usePaletteAnalysis();
  const ga = useTracker();
  const { t } = useTranslation();

  const isDisabled =
    hasProtectedPanoramaColors(palette) ||
    !filteredPalette?.colors?.length ||
    disabled;

  const handleAnalyze = () => {
    open(filteredPalette);
    ga.trackWorkspaceEvent({ action: 'analyze' });
    ga.trackEvent({
      event_timestamp: new Date().toISOString(),
      ...paletteEngaged,
      PALETTE_NAME: name,
      PALETTE_ID: id,
      EVENT_COMPONENT: 'analyze_button',
    });
  };

  return (
    <Tooltip
      enterTouchDelay={0}
      title={t(
        hasProtectedPanoramaColors(palette)
          ? 'labels.analyzePanoramaPaletteForNonPremiumPlus'
          : ''
      )}
      data-id="analyze-button-tooltip"
    >
      <Box alignSelf="center">
        <IconButton
          color="secondary"
          disabled={isDisabled}
          onClick={handleAnalyze}
          data-testid="analyze-palette-button"
          size="large"
          sx={{ padding: 0 }}
        >
          <AssessmentIcon />
        </IconButton>
      </Box>
    </Tooltip>
  );
}

AnalyzeButton.defaultProps = {
  disabled: false,
};

AnalyzeButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
};

export default AnalyzeButton;
