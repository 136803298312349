import React, { createElement } from 'react';
import PropTypes from 'prop-types';
import { rgbToHls } from '@xrite/color-js';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

import DashboardComponent from './DashboardComponent';
import HueDistribution from './HueDistribution';
import LuminanceMap from './LuminanceMap';
import ColorBlind from './ColorBlind';
import ViewAsGradient from './ViewAsGradient';
import ColorCombos from './ColorCombos';
import LightDarkTheme from './LightDarkTheme';
import ColorsList from './ColorsList';

const useStyles = makeStyles((theme) => ({
  container: {
    overflowY: 'auto',
    overflowX: 'hidden',
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 5, 5, 5),
    [theme.breakpoints.down('md')]: {
      padding: 0,
    },
  },
  dashboardContainer: {
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    flexBasis: '48%',
    marginBottom: `calc(${theme.mixins.toolbar.minHeight}px + ${theme.spacing(
      5
    )})`,
    [theme.breakpoints.down('md')]: {
      flexBasis: '100%',
    },
  },
}));

const graphs = [
  {
    component: ColorsList,
    title: 'labels.paletteAnalysis.colorsList',
  },
  {
    component: HueDistribution,
    title: 'labels.paletteAnalysis.hueDistribution',
  },
  {
    component: LuminanceMap,
    title: 'labels.paletteAnalysis.luminanceMap',
  },
  {
    component: ColorBlind,
    title: 'labels.paletteAnalysis.colorblindSimulation',
  },
  {
    component: ViewAsGradient,
    title: 'labels.paletteAnalysis.viewAsGradient',
    isVisible: ({ colors }) => colors.length > 1,
  },
  {
    component: ColorCombos,
    title: 'labels.paletteAnalysis.colorCombos',
    isVisible: ({ colors }) => colors.length > 1,
  },
  { component: LightDarkTheme, title: 'labels.paletteAnalysis.lightAndDark' },
];

const PaletteAnalysis = ({ colors }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const colorsWithAdditionalData = colors.map((color) => {
    const newColor = { ...color };
    newColor.hsl = rgbToHls(color.rgb);
    return newColor;
  });

  return (
    <div className={classes.container}>
      {graphs
        .filter((graph) =>
          graph.isVisible ? graph.isVisible({ colors }) : true
        )
        .map((graph) => (
          <div key={graph.title} className={classes.dashboardContainer}>
            <DashboardComponent title={t(graph.title)}>
              {createElement(graph.component, {
                colors: colorsWithAdditionalData,
              })}
            </DashboardComponent>
          </div>
        ))}
    </div>
  );
};

PaletteAnalysis.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default PaletteAnalysis;
