import { api } from './api';
import authQueries from './authQueries';

const userSegmentTag = 'userSegment';
const isAuthenticatedTag = 'isAuthenticatedTag';
const getAccountTypeTag = 'getAccountType';

const authApi = api.enhanceEndpoints({
  addTagTypes: [isAuthenticatedTag, userSegmentTag, getAccountTypeTag],
});

const extendedApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    getCurrentUser: build.query({
      queryFn: authQueries.getCurrentUser,
      providesTags: [isAuthenticatedTag],
    }),
    createAccount: build.mutation({
      queryFn: authQueries.createAccount,
    }),
    completeAccountCreation: build.mutation({
      queryFn: authQueries.completeAccountCreation,
    }),
    deleteAccount: build.mutation({
      queryFn: authQueries.deleteAccount,
      invalidatesTags: (result) => (result ? [isAuthenticatedTag] : []),
    }),
    deleteAccountData: build.mutation({
      queryFn: authQueries.deleteAccountData,
    }),
    signIn: build.mutation({
      queryFn: authQueries.signIn,
      invalidatesTags: (result) =>
        result ? [isAuthenticatedTag, userSegmentTag, getAccountTypeTag] : [],
    }),
    signOut: build.mutation({
      queryFn: authQueries.signOut,
      invalidatesTags: (result) =>
        result ? [isAuthenticatedTag, getAccountTypeTag] : [],
    }),
    forgotPassowrd: build.mutation({
      queryFn: authQueries.forgotPassowrd,
    }),
    forgotPasswordSubmit: build.mutation({
      queryFn: authQueries.forgotPasswordSubmit,
    }),
    changePassword: build.mutation({
      queryFn: authQueries.changePassword,
    }),
    accountType: build.mutation({
      queryFn: authQueries.accountType,
      providesTags: [getAccountTypeTag],
    }),
    updateUserSegment: build.mutation({
      queryFn: authQueries.updateUserSegment,
      invalidatesTags: [userSegmentTag],
    }),
    getUserSegment: build.query({
      queryFn: authQueries.getUserSegment,
      providesTags: [userSegmentTag],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserSegmentQuery,
  useGetCurrentUserQuery,
  useSignInMutation,
  useSignOutMutation,
  useForgotPassowrdMutation,
  useForgotPasswordSubmitMutation,
  useChangePasswordMutation,
  useCreateAccountMutation,
  useCompleteAccountCreationMutation,
  useDeleteAccountMutation,
  useDeleteAccountDataMutation,
  useAccountTypeMutation,
  useUpdateUserSegmentMutation,
} = extendedApi;

export const selectCurrentUser = api.endpoints.getCurrentUser.select(undefined);
