import { Subscription, SubscriptionProviderType } from '../types/subsription';

export const filterFastSpringSubscriptions = (
  subscription: Subscription
): Subscription[] => {
  const fastspringSubscriptions = subscription?.subscriptions?.filter(
    (s: Subscription) =>
      s.name === SubscriptionProviderType.FASTSPRING ||
      s.name === SubscriptionProviderType.FASTSPRING_TRENDS
  );
  return fastspringSubscriptions || [];
};
