import { uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import useTracker from 'analytics/useTracker';
import useSnackbar from 'features/Snackbar/useSnackbar';
import useConfirm from 'features/ConfirmDialog/useConfirm';
import { hasDuplicateColors } from 'utils/color';
import {
  reset,
  setColors,
  setPalette,
  setUnsavedChanges,
  selectColors,
  selectUnshavedChanges,
} from './paletteSlice';

const MAX_COLORS = 40;

function usePaletteWS() {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { openSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const ga = useTracker();

  const colors = useSelector(selectColors);
  const unsavedChanges = useSelector(selectUnshavedChanges);

  const isInWorkspace = (color) =>
    !!colors.find((c) => c?.code === color?.code);

  const checkForUnsavedChanges = async (handlerFn) => {
    if (unsavedChanges) {
      const isConfirmed = await confirm({
        content: t('messages.workspaceUnsavedChanges'),
      });

      if (isConfirmed) {
        handlerFn();
        ga.trackWorkspaceEvent({ action: 'resetConfirm' });
      } else {
        ga.trackWorkspaceEvent({ action: 'resetCancel' });
      }
    } else {
      handlerFn();
    }
  };

  const addWorkspaceColor = (color) => {
    if (colors && colors.length > MAX_COLORS) {
      openSnackbar({
        message: t('messages.maxColorSupport', { maxColors: MAX_COLORS }),
      });
      return;
    }

    if (isInWorkspace(color)) {
      openSnackbar({
        message: t('messages.colorCodeExists', { colorCode: color?.code }),
      });
      return;
    }

    dispatch(setColors([...colors, color]));
    dispatch(setUnsavedChanges(true));
  };

  const setWorkspacePalette = ({ palette, hasUnsavedChanges }) => {
    checkForUnsavedChanges(() => {
      dispatch(setPalette(palette));

      if (!hasUnsavedChanges) {
        dispatch(setUnsavedChanges(false));
      }
    });
  };

  const resetWorkspace = () => {
    checkForUnsavedChanges(() => {
      dispatch(reset());
    });
  };

  const setWorkspaceColors = (nextColors) => {
    checkForUnsavedChanges(() => {
      if (hasDuplicateColors([...nextColors, ...colors])) {
        openSnackbar({
          message: t('messages.duplicateColorCodes'),
        });
      }

      const uniqueNewColors = uniqBy([...nextColors, ...colors], 'code');
      dispatch(setColors(uniqueNewColors));
      dispatch(setUnsavedChanges(true));
    });
  };

  const removeWorkspaceColor = (color) => {
    const filteredColors = colors.filter((c) => c.code !== color.code);
    dispatch(setColors(filteredColors));
    dispatch(setUnsavedChanges(true));
  };

  const clearWorkspace = (cb) => {
    checkForUnsavedChanges(() => {
      dispatch(reset());
      if (typeof cb === 'function') {
        cb();
      }
    });
  };

  return {
    addWorkspaceColor,
    setWorkspacePalette,
    isInWorkspace,
    resetWorkspace,
    clearWorkspace,
    setWorkspaceColors,
    removeWorkspaceColor,
  };
}

export default usePaletteWS;
