export const getInitialsOfName = (inputPart1 = 'OP', inputPart2 = null) => {
  const namePart1 = `${inputPart1 || ''}`.trim().replace(/\s\s+/g, ' ');
  const namePart2 = `${inputPart2 || ''}`.trim().replace(/\s\s+/g, ' ');

  // If first part has values
  if (namePart1?.length) {
    // If second part also has values
    if (namePart2?.length) {
      return `${namePart1[0].toUpperCase()}${namePart2[0].toUpperCase()}`;
    }

    // If first part has space separated value
    const splits = namePart1.split(' ');
    if (splits.length > 1) {
      return `${splits[0][0].toUpperCase()}${splits[1][0].toUpperCase()}`;
    }

    // Get first 2 letters of first part
    return `${namePart1[0].toUpperCase()}${(namePart1[1] || '').toUpperCase()}`;
  }

  // If first part is invalid
  return 'OP';
};

export const getIdFromColorValue = (value, separator) =>
  Object.keys(value)
    .map((key) => Math.round(value[key]))
    .join(separator);

export const snakeCase = (text) => text?.toLowerCase()?.replace(/ /g, '_');

export default {
  getInitialsOfName,
  getIdFromColorValue,
  snakeCase,
};
