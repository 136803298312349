import { create } from 'zustand';
import createSelectors from '~/v3/store/selectors';

type WorkspaceStore = {
  isExpanded: boolean;
  actions: {
    onExpandWorkspace: () => void;
    onCollapseWorkspace: () => void;
  };
};

const useWorkspaceStore = create<WorkspaceStore>()((set) => ({
  isExpanded: false,
  actions: {
    onExpandWorkspace: () => set((state) => ({ ...state, isExpanded: true })),
    onCollapseWorkspace: () =>
      set((state) => ({ ...state, isExpanded: false })),
  },
}));

export const useWorkspaceStoreActions = () =>
  useWorkspaceStore((state) => state.actions);

export default createSelectors(useWorkspaceStore);
