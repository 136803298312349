import colors from 'theme/colors';

export default {
  fontFamily: 'Helvetica Now Text',
  h1: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '4.5rem',
    fontWeight: 400,
    lineHeight: '88px',
  },
  h2: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '3.5rem',
    fontWeight: 400,
    lineHeight: '72px',
  },
  h3: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '2.5rem',
    fontWeight: 400,
    lineHeight: '48px',
  },
  h4: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '1.5rem',
    fontWeight: 500,
    lineHeight: '32px',
  },
  h5: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '1.25rem',
    fontWeight: 700,
    lineHeight: '24px',
  },
  h6: {
    fontFamily: 'Helvetica Now Display',
    fontSize: '1.125rem',
    fontWeight: 700,
    lineHeight: '24px',
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 500,
    lineHeight: '26px',
  },
  subtitle2: {
    fontSize: '0.875rem',
    fontWeight: 500,
    lineHeight: '22px',
  },
  body1: {
    fontSize: '1rem',
    fontWeight: 400,
    lineHeight: '26px',
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '22px',
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: '18px',
    color: colors.JetBlack[400],
  },
  overline1: {
    fontSize: '0.625rem',
    fontWeight: 500,
    lineHeight: '16px',
    textTransform: 'uppercase',
  },
  overline2: {
    fontSize: '0.625rem',
    fontWeight: 500,
    lineHeight: '14px',
  },
  pullQuote: {
    color: 'red',
    fontSize: '2rem',
  },
  'v3.label1': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 10,
    lineHeight: '145%',
    textAlign: 'center',
    letterSpacing: '0.08em',
    textTransform: 'uppercase',
  },
  'v3.label2': {
    fontFamily: 'Helvetica Now Display',
    fontSize: 7,
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '140%',
    letterSpacing: '0.56px',
  },
  'v3.label3': {
    fontFamily: 'Helvetica Now Display',
    fontSize: 7,
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '140%',
    letterSpacing: ' 0.56px',
  },
  'v3.h3': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: 300,
    fontSize: '19px',
    lineHeight: '115%',
  },
  'v3.body1': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 400,
    letterSpacing: '2%',
    lineHeight: '145%',
  },
  'v3.body2': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '145%',
  },
  'v3.body3': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '10px',
    lineHeight: '145%',
    letterSpacing: '0.02em',
  },
  'v3.heading1': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: '325',
    fontSize: '40px',
    lineHeight: '140%',
  },
  'v3.heading3': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '24px',
    lineHeight: '120%',
  },
  'v3.heading4': {
    fontFamily: 'Helvetica Now Display',
    fontStyle: 'normal',
    fontWeight: '400',
    fontSize: '16px',
    lineHeight: '120%',
  },
  'v3.heading5': {
    fontFamily: 'Helvetica Now Display',
    fontSize: '26px',
    lineHeight: '26px',
    fontWeight: 'bold',
  },
};
