import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Box, Tooltip, useTheme } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';

import useTracker from 'analytics/useTracker';
import config from 'config';
import RoutePath from 'constants/route';
import useColorMenu from 'features/ColorMenu/useColorMenu';
import usePaywall from 'hooks/usePaywall';
import useRouter from '~/v3/hooks/use-router';
import { usePanoramaExpiredDialog } from 'pages/MyPalettes/myPalettesHooks';
import adobe from 'platforms/adobe';
import { useLazyGetSubscriptionQuery } from '~/v3/react-query/hooks/use-get-subscription';
import { isCoY, isPanoramaBook } from 'utils/color';
import { isAdobe } from 'utils/platform';
import {
  dragAndDropVariants,
  isTouchEnabled,
} from '~/v3/constants/drag-and-drop';
import { useTranslation } from 'react-i18next';
import { COY_COLOR_CODE } from '~/v3/config';
import eyedropperCursor from '../assets/images/eyedropper.png';
import PantoneChipPanoramaSkeleton from './PantoneChipPanoramaSkeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    cursor: 'pointer',
    '&:hover': {
      '& $iconIndicator': {
        opacity: 1,
      },
    },
  },
  adobeCursor: {
    cursor: `url(${eyedropperCursor}) 0 20, auto`,
  },
  rounded: {
    borderRadius: theme.shape.borderRadius,
  },
  hover: {
    '&:hover': {
      boxShadow: theme.shadows[4],
    },
  },
  iconIndicator: {
    position: 'absolute',
    top: 0,
    right: 0,
    opacity: 0,
  },
  bottomLeftDot: {
    width: 4,
    height: 4,
    opacity: 0.25,
    borderRadius: '50%',
    position: 'absolute',
    left: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
  },
}));

function PantoneChipSmall({
  color,
  size,
  disabledDrag,
  hover,
  style,
  moveChip,
  index,
  colorId,
  reorderChips,
  children,
  isInWorkspace,
  ...props
}) {
  const defaultSize = 60;
  const classes = useStyles();
  const { open } = useColorMenu();
  const ga = useTracker();
  const theme = useTheme();
  const { openPanoramaExpiredDialog } = usePanoramaExpiredDialog();
  const { triggerPaywall, paywallCases } = usePaywall();
  const { location } = useRouter();
  const { t } = useTranslation();

  const { trigger } = useLazyGetSubscriptionQuery();

  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop(
    () => ({
      accept: dragAndDropVariants.V3_PANTONE_INFO_CHIP,
      collect(monitor) {
        return {
          handlerId: monitor.getHandlerId(),
        };
      },
      hover(item) {
        if (!ref.current) {
          return;
        }
        reorderChips(item.colorId, index);
        item.index = index;
      },
    }),
    [reorderChips]
  );

  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: dragAndDropVariants.V3_PANTONE_INFO_CHIP,
      item: () => {
        return { color, colorId };
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      end: (item, monitor) => {
        const didDrop = monitor.didDrop();
        if (!didDrop) moveChip({ color, colorId });
      },
    }),
    [moveChip, colorId, color]
  );

  const showColorMenu = (event) => {
    event.stopPropagation();
    if (color) {
      open({
        anchorEl: event.target,
        color,
        isInWorkspace: !!colorId,
        anchorPosition: {
          top: event.clientY,
          left: event.clientX,
        },
      });
    }
  };

  const handleMenuOpen = (event) => {
    event.preventDefault();
    if (event.nativeEvent.type === 'contextmenu' && isTouchEnabled) return;
    showColorMenu(event);
  };

  const handleOnAdobeThreeDotsClick = (event) => {
    showColorMenu(event);
    ga.trackChipEvent({
      action: 'click',
      bookId: color?.book?.id,
      EVENT_PLACE: isInWorkspace ? 'workspace' : 'body',
      TERM: color.code,
      EVENT_ACTION: 'display-context-menu',
    });
  };

  const handleOnAdobeChipClick = async (event) => {
    const lazySubscriptionData = await trigger();
    event.stopPropagation();

    const isMouseRightClick = event.button === 2;
    if (isMouseRightClick) {
      showColorMenu(event);
    } else {
      // Prevent free users on Adobe to add colors to the foreground/background color
      if (
        !isCoY(COY_COLOR_CODE, color) &&
        location.pathname !== RoutePath.myPalettes &&
        !lazySubscriptionData?.active &&
        config.PAYWALL_ENABLED
      ) {
        triggerPaywall(paywallCases.adobe.id);
        return;
      }

      adobe.handleColorEvent(event, color);

      ga.trackChipEvent({
        action: 'click',
        bookId: color?.book?.id,
        TERM: color.code,
        EVENT_ACTION: 'set-foreground-color-in-app',
        EVENT_PLACE: isInWorkspace ? 'workspace' : 'body',
      });

      ga.trackChipEvent({
        action: 'click',
        bookId: color?.book?.id,
        TERM: color.code,
        EVENT_LABEL: 'e-foreground_color_set',
        EVENT_PLACE: isInWorkspace ? 'workspace' : 'body',
      });
    }
  };

  const handleOnChipClick = (event) => {
    if (!color) {
      openPanoramaExpiredDialog();
      return;
    }

    if (isAdobe) {
      handleOnAdobeChipClick(event);
    } else {
      handleMenuOpen(event);
      ga.trackChipEvent({
        action: 'click',
        bookId: color?.book?.id,
        EVENT_PLACE: isInWorkspace ? 'workspace' : 'body',
        TERM: color.code,
        EVENT_ACTION: 'display-context-menu',
      });
    }
  };

  useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sizeToUse = size || defaultSize;
  const contrastColor = theme.palette.isDark(color?.rgb)
    ? theme.palette.primary.contrastText
    : theme.palette.text.primary;

  if (!color) {
    return (
      <Tooltip
        placement="bottom"
        enterTouchDelay={0}
        title={t('labels.noAccessToPremiumPlusColorsInfo')}
      >
        <Box
          sx={{
            width: sizeToUse,
            height: sizeToUse,
            background: 'transparent',
            border: `1px solid ${theme.palette.divider}`,
            margin: '0 16px 16px 0',
          }}
        />
      </Tooltip>
    );
  }

  if (!color.lab) {
    return <PantoneChipPanoramaSkeleton size={size} />;
  }

  if (color && !disabledDrag) {
    drag(drop(ref));
  }

  const opacity = isDragging ? 0 : 1;
  return (
    <Box
      {...props}
      ref={ref}
      className={classNames(classes.root, {
        [classes.hover]: hover,
        [classes.adobeCursor]: isAdobe,
      })}
      style={{
        background: color && `#${color.hex}`,
        width: sizeToUse,
        height: sizeToUse,
        opacity,
        ...(style && style),
      }}
      onClick={handleOnChipClick}
      onKeyDown={handleOnChipClick}
      onContextMenu={handleOnChipClick}
      data-testid={`chip-small-${color?.code}`}
      data-handler-id={handlerId}
    >
      {isPanoramaBook(color) && (
        <div
          className={classes.bottomLeftDot}
          style={{ background: contrastColor }}
        />
      )}
      {isAdobe && (
        <IconButton
          className={classes.iconIndicator}
          size="small"
          style={{
            backgroundColor: 'transparent',
            width: sizeToUse / 3,
            height: sizeToUse / 3,
          }}
          onClick={handleOnAdobeThreeDotsClick}
        >
          <MoreVertIcon
            style={{
              fontSize: sizeToUse / 3,
              color: contrastColor,
            }}
          />
        </IconButton>
      )}
      {children}
    </Box>
  );
}

PantoneChipSmall.defaultProps = {
  color: null,
  size: 60,
  disabledDrag: false,
  hover: false,
  style: null,
  colorId: null,
  index: null,
  children: null,
  moveChip: () => {},
  reorderChips: () => {},
  isInWorkspace: false,
};
PantoneChipSmall.propTypes = {
  color: PropTypes.shape(),
  size: PropTypes.number,
  disabledDrag: PropTypes.bool,
  hover: PropTypes.bool,
  style: PropTypes.shape(),
  children: PropTypes.node,
  moveChip: PropTypes.func,
  reorderChips: PropTypes.func,
  colorId: PropTypes.string,
  index: PropTypes.number,
  isInWorkspace: PropTypes.bool,
};

export default React.memo(PantoneChipSmall);
