import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 auto',
    flexDirection: 'column',
  },
  colorPatch: {
    flex: '1 1 auto',
  },
}));

const ColorsList = ({ colors }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {colors.map((color) => (
        <div
          key={color.code}
          className={classes.colorPatch}
          style={{
            backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
          }}
        />
      ))}
    </div>
  );
};

ColorsList.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ColorsList;
