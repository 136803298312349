const enum Colors {
  black = 'rgba(0, 0, 0, 1)',
  lightBlack = 'rgba(34, 34, 34, 1)',
  mediumBlack = 'rgba(34, 34, 34, 0.8)',
  darkBlack = 'rgba(0, 0, 0, 0.8);',
  white = 'rgba(255, 255, 255, 1)',
  transparentWhite = 'rgba(255, 255, 255, 0.4)',
  lightGrey = 'rgba(239, 239, 239, 1)',
  mediumGrey = 'rgba(128, 128, 128, 1)',
  darkGrey = 'rgba(68, 68, 68, 1)',
  washedOutGrey = 'rgba(245, 245, 245,1)',
  warningRed = 'rgba(233, 59, 36)',
  pantoneLogoBlue = 'rgba(0, 134, 255, 1)',
  mediumBlue = 'rgba(82, 173, 255, 1)',
  lightBlue = 'rgba(0, 134, 255, 0.04)',
  paleGray = 'rgb(234, 236, 240)',
  backgroundGrey = 'rgb(239, 239, 239)',
  gray = 'rgb(242, 242, 242)',
  pantoneChipBlack = 'rgb(61, 61, 61)',
  semiTransparentBlack = 'rgba(0, 0, 0, 0.3)',
  neutralGray = 'rgba(157, 157, 157, 1)',
  whiteGray = 'rgba(243, 243, 243, 1)',
  canvasBackground = 'rgba(250, 250, 250, 1)',
  lightGray = 'rgba(224, 224, 224, 1)',
  orange = 'rgba(236, 106, 55, 1)',
  purple = 'rgba(145, 109, 247, 1)',
  green = 'rgba(103, 172, 92, 1)',
  darkBlue = 'rgba(1, 87, 155,1)',
  translucentSkyBlue = 'rgba(0, 134, 255, 0.1)',
  paleLavender = 'rgba(251, 226, 255, 1)',
  vividPurple = 'rgba(192, 20, 222, 1)',
}

export default Colors;
