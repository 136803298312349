import { create } from 'zustand';
import createSelectors from '~/v3/store/selectors';

type DrawerStore = {
  open: boolean;
  actions: {
    toggleDrawer: (open: boolean) => void;
  };
};

const useDrawerStore = create<DrawerStore>()((set) => ({
  open: true,
  actions: {
    toggleDrawer: (open) => set((state) => ({ ...state, open })),
  },
}));

export const useDrawerActions = () => useDrawerStore((state) => state.actions);

export default createSelectors(useDrawerStore);
