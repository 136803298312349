import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function FullScreenIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{ ...sx, width: 10, height: 10 }}
      {...props}
    >
      <path
        d="M9 1H13M13 1V5M13 1L8.33333 5.66667M5 13H1M1 13V9M1 13L5.66667 8.33333"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default FullScreenIcon;
