import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAnalytics } from '~/v2/components/AnalyticsProvider';
import { getEvent } from '~/v2/analytics/actions';
import useRouter from '~/v3/hooks/use-router';
import usePrevious from '~/v2/hooks/usePrevious';
import routes, { getPreviousRoute } from '~/v2/analytics/routes';
import { selectCurrentUser } from '~/v2/services/authApi';
import { getUnauthenticatedUserId } from '~/v3/utils/get-unauthenticated-user-id';
import { getAddobeApp } from '~/v2/platforms/adobe';
import { getPlatform, isAdobe } from '~/v2/utils/platform';
import { useGetEventSubscriptionData } from '~/v3/analytics/hooks/use-get-event-subscription-data';
import { useCookiesStore } from '~/v3/store/cookies-store';
import { useUserContext } from '~/v3/providers/UserProvider';

const WAIT_SUBSCRIPTIONS_INFO_MS = 10000;
const eventsQueue = new Map();

const removeURLParams = (URL) => {
  return URL.replace(/:([A-ZA-z])\w+/, '');
};

const getCurrentPageURL = (currentURL) =>
  Object.keys(routes).find((routeURL) =>
    currentURL?.includes(removeURLParams(routeURL))
  );

function useTracker() {
  const { ga, xrp } = useAnalytics();
  const { query, location } = useRouter();
  const previousPath = usePrevious(location.pathname);
  const previousPage = routes[previousPath];
  const page = routes[getCurrentPageURL(location.pathname)];
  const { data: user } = useSelector(selectCurrentUser);
  const getUnauthUser = useCallback(() => getUnauthenticatedUserId(), []);
  const unauthUser = getUnauthUser();
  const { PREMIUM, TRENDS } = useGetEventSubscriptionData();
  const { country } = useUserContext();
  const { oneTrustActiveGroups } = useCookiesStore();

  const hasTargetCookiesEnabled =
    oneTrustActiveGroups && oneTrustActiveGroups?.indexOf?.('C0004') >= 0;

  const filterNullProperties = (event) => {
    return Object.fromEntries(
      Object.entries(event).filter(
        ([, value]) => value !== null && value !== undefined
      )
    );
  };

  const internalTrackEvent = useCallback(
    (event) => {
      if (ga && xrp && event) {
        event = filterNullProperties(event);
        const USER_PLATFORM = isAdobe ? getAddobeApp() : getPlatform();

        const commonEventOverrides = {
          ...PREMIUM,
          ...TRENDS,
        };
        const pageEventOverrides =
          typeof page?.getPageOverrides === 'function'
            ? page.getPageOverrides({
                location,
                query,
                page,
                previousPage,
                event,
              })
            : {};
        ga.trackEvent(event.EVENT_LABEL, {
          TERM: 'na',
          EVENT_AREA: 'na',
          USER_PLATFORM,
          ...pageEventOverrides,
          ...event,
          ...commonEventOverrides,
        });

        if (window.analytics) {
          window.analytics.track(event.EVENT_LABEL, {
            userId: user?.attributes?.sub || unauthUser,
            TERM: 'na',
            EVENT_AREA: 'na',
            USER_PLATFORM,
            ...((hasTargetCookiesEnabled || country.isFromUS) && {
              countryId: country.data?.code,
            }),
            ...pageEventOverrides,
            ...event,
            ...commonEventOverrides,
          });
        }

        xrp.sendEvent({
          TERM: 'na',
          EVENT_AREA: 'na',
          USER_PLATFORM,
          ...pageEventOverrides,
          ...event,
          ...commonEventOverrides,
        });
      }
    },
    [
      PREMIUM,
      TRENDS,
      country,
      ga,
      hasTargetCookiesEnabled,
      location,
      page,
      previousPage,
      query,
      unauthUser,
      user?.attributes?.sub,
      xrp,
    ]
  );

  useEffect(() => {
    if (PREMIUM && TRENDS) {
      eventsQueue.forEach(({ timeoutId, event }) => {
        clearTimeout(timeoutId);
        eventsQueue.delete(timeoutId);
        internalTrackEvent(event);
      });
    }
  }, [PREMIUM, TRENDS, internalTrackEvent]);

  const trackEvent = (event) => {
    if (!PREMIUM || !TRENDS) {
      const timeoutId = setTimeout(() => {
        eventsQueue.delete(timeoutId);
        internalTrackEvent({
          ...event,
          CONNECT_SUBSCRIPTION_TYPE: 'unknown',
          CONNECT_SUBSCRIPTION_ID: 'unknown',
          TREND_SUBSCRIPTION_TYPE: 'unknown',
          TREND_SUBSCRIPTION_ID: 'unknown',
        });
      }, WAIT_SUBSCRIPTIONS_INFO_MS);
      eventsQueue.set(timeoutId, { timeoutId, event });
      return;
    }
    internalTrackEvent(event);
  };

  const trackChipEvent = async (eventData) => {
    const event = getEvent({
      type: 'chip',
      page: page?.prefix || previousPage?.prefix,
      section: query?.tab || previousPage?.area,
      ...eventData,
    });
    trackEvent(event);
  };

  const trackWorkspaceEvent = (eventData) => {
    const event = getEvent({
      type: 'workspace',
      page: page || '',
      ...eventData,
    });
    trackEvent(event);
  };

  const trackMenuEvent = (route) => {
    const { event } = routes[route] || {};
    trackEvent(event);
  };

  const trackPaywallEvent = (event) => {
    trackEvent({
      ...event,
      EVENT_AREA: getPreviousRoute({
        query,
        previousPage: previousPage || page,
      }),
    });
  };

  // eslint-disable-next-line consistent-return
  const trackException = (...args) => {
    if (ga?.trackException) {
      return ga.trackException(...args);
    }
  };

  const trackSplitTestEvent = (event) => {
    if (event) {
      trackEvent({
        EVENT_ID: 'split_test',
        EVENT_LABEL: event.label || 'na',
        SPLIT_TEST_NAME: event.name || 'na',
        SPLIT_TEST_VARIANT: event.variant || 'na',
        SPLIT_TEST_CONTROL: event.control !== undefined ? event.control : 'na',
      });
    }
  };

  return {
    trackEvent,
    trackChipEvent,
    trackWorkspaceEvent,
    trackMenuEvent,
    trackPaywallEvent,
    trackException,
    trackSplitTestEvent,
  };
}

export default useTracker;
