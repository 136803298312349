import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { useTheme } from '@mui/material';

function SearchIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 19 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke="black"
      {...props}
      sx={{
        width: 19,
        height: 22,
        fill: 'none',
        stroke: theme.palette.primary.main,
        ...sx,
      }}
    >
      <path
        d="M13.3584 14.3092L18.0001 19.4903M13.2015 14.4498C10.1664 17.1689 5.50185 16.9127 2.78283 13.8776C0.0638108 10.8426 0.319989 6.17803 3.35502 3.45901C6.39005 0.739991 11.0546 0.99617 13.7737 4.0312C16.4927 7.06623 16.2365 11.7308 13.2015 14.4498Z"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}

export default SearchIcon;
