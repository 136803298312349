export const createMediaQuery = (width: number, height: number) => {
  return `@media (width: ${width}px) and (height: ${height}px)`;
};

export const createMediaQueryInBetweenScreens = (
  width: number,
  height: number
) => {
  return `@media (min-width: ${width}px) and (min-height: ${height}px)`;
};
