import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function ShadesIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="11"
      height="12"
      viewBox="0 0 11 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ height: 12, width: 12, ...sx }}
    >
      <path
        d="M9.78571 6.75C9.78571 9.38312 7.69737 11.5 5.14286 11.5C2.58835 11.5 0.5 9.38312 0.5 6.75C0.5 5.14457 1.64721 3.58386 2.91726 2.36755C3.5389 1.77222 4.16259 1.28535 4.63174 0.947024C4.83704 0.798977 5.01187 0.679993 5.14286 0.593436C5.27384 0.679993 5.44868 0.798977 5.65397 0.947024C6.12313 1.28535 6.74682 1.77222 7.36845 2.36755C8.6385 3.58386 9.78571 5.14457 9.78571 6.75Z"
        fill="transparent"
      />
      <path d="M5.64283 11.4727L5.64285 6.75L5.64284 2.00644L5.64283 0.939022C5.64652 0.94168 5.65022 0.944347 5.65394 0.947024C6.12309 1.28535 6.74678 1.77222 7.36842 2.36755C8.63847 3.58386 9.78568 5.14457 9.78568 6.75C9.78568 9.20907 7.9643 11.2179 5.64283 11.4727Z" />
    </SvgIcon>
  );
}

export default ShadesIcon;
