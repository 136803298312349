import { Popover, PopoverProps } from '@mui/material';
import React from 'react';

import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import { leftClickMenuWidth } from '~/v3/constants/chip';
import { ChipInfoArea } from '~/v3/components/core/chips';
import { LeftMenuVariants } from '../model/types';

import BaseMenu from './base-menu';

type LeftClickMenuProps = {
  variant: LeftMenuVariants;
  onClose: () => void;
  color: PantoneColorWithBook | null;
} & Omit<PopoverProps, 'color'>;

function LeftClickMenu({
  variant,
  color,
  open,
  onClose,
  anchorPosition,
  ...rest
}: LeftClickMenuProps) {
  const handleMenuClicked = () => {
    onClose();
  };

  if (!color) return null;

  return (
    <Popover
      open={open}
      anchorPosition={anchorPosition}
      anchorReference="anchorPosition"
      onClose={onClose}
      anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: 'left' }}
      data-id="left-click-menu"
      slotProps={{
        paper: {
          style: {
            borderRadius: 0,
            width: leftClickMenuWidth,
          },
        },
      }}
      {...rest}
    >
      <BaseMenu color={color} onClick={handleMenuClicked} />
      {variant === LeftMenuVariants.COLOR_INFO && (
        <div
          style={{
            width: '100%',
            height: 'fit-content',
          }}
          data-testid="left-menu-info"
        >
          <ChipInfoArea color={color} />
        </div>
      )}
    </Popover>
  );
}

export default LeftClickMenu;
