import React from 'react';
import { useDragLayer } from 'react-dnd';
import { InfoChip } from '~/v3/components/core/chips';
import { dragAndDropVariants } from '~/v3/constants/drag-and-drop';
import PantoneChip from './PantoneChip';

const layerStyles = {
  pointerEvents: 'none',
  position: 'fixed',
  width: '100%',
  '@supports (height: env(safeAreaInsetBottom, 0px))': {
    height: 'calc(100% - env(safe-area-inset-bottom, 0px))',
  },
  height: '100%',
  zIndex: 1700,
  overflow: 'hidden',
  left: 0,
};

function getItemStyles(initialOffset, currentOffset, clientOffset) {
  if (!initialOffset || !currentOffset) {
    return { display: 'none' };
  }

  const { x, y } = clientOffset;
  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

function CustomDragLayer() {
  const {
    item,
    itemType,
    isDragging,
    initialOffset,
    currentOffset,
    clientOffset,
  } = useDragLayer((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
    clientOffset: monitor.getClientOffset(),
  }));

  function renderItem() {
    switch (itemType) {
      case dragAndDropVariants.PANTONE_CHIP_SMALL:
        return <PantoneChip color={item.color} shadow />;
      case dragAndDropVariants.V3_PANTONE_INFO_CHIP:
        return (
          <InfoChip
            code={item.color.code}
            name={item.color.name}
            rgb={item.color.rgb}
          />
        );
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div style={layerStyles}>
      <div style={getItemStyles(initialOffset, currentOffset, clientOffset)}>
        {renderItem()}
      </div>
    </div>
  );
}

export default CustomDragLayer;
