import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
import { SnackbarKey, useSnackbar } from 'notistack';

function SnackbarCloseButton({ id }: Readonly<{ id: SnackbarKey }>) {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      color="inherit"
      data-id="close-btn"
      sx={{
        padding: 0,
      }}
      onClick={() => {
        closeSnackbar(id);
      }}
    >
      <CloseIcon sx={{ height: 11, width: 11 }} />
    </IconButton>
  );
}

export default SnackbarCloseButton;
