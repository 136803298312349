import { ColorSpaceValues } from '~/v3/constants/color-space';
import { LabForm } from '~/v3/types/lab-form';

export const colorStoryMenuEvent = 'colorStory';

export const convertMenuEvent = 'convert';

export const extractMenuEvent = 'extract';

export const measureMenuEvent = 'colorMeasure';

export const visualizeMenuEvent = 'visualize';

export const pickMenuEvent = 'pick';

export const myPalettesEvent = 'myPalettes';

export const trendsMenuEvent = 'explore';

export const colorDataMenuEvent = 'colorData';

export const onTabChangeFloatingToolbarEvent = {
  EVENT_ID: 'e157',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-change-floating-toolbar-tab',
  EVENT_ACTION: 'click',
};

export const onChangeLibraryEvent = {
  EVENT_ID: 'e158',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-change-library',
  EVENT_ACTION: 'click',
};

export const onChangeSpacingEvent = {
  EVENT_ID: 'e159',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-filters-spacing',
  EVENT_ACTION: 'click',
};

export const onChangeFilterSearchEvent = {
  EVENT_ID: 'e160',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'input',
  EVENT_LABEL: 'e-filters-search',
  EVENT_ACTION: 'click',
};

export const onFiltersSortByClickEvent = {
  EVENT_ID: 'e161',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-filters-sort',
  EVENT_ACTION: 'click',
};

export const onChangeHueSpectrumEvent = {
  EVENT_ID: 'e154',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-picker_change-hue-spectrum',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'picker',
};

export const onIncreaseChipSizeEvent = {
  EVENT_ID: 'e155',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-picker_increase-chip-size',
};

export const onDecreaseChipSizeEvent = {
  EVENT_ID: 'e156',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-picker_decrease-chip-size',
};

export const onPinLibraryEvent = {
  EVENT_ID: 'e162',
  EVENT_PATH: '/picker',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-pin-library',
  EVENT_ACTION: 'click',
};

export const onLeftClickMenuEvent = {
  EVENT_ID: 'e163',
  EVENT_PATH: '/chip',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'left-menu',
  EVENT_LABEL: 'e-left-menu-click',
  EVENT_ACTION: 'click',
};

export const colorDetailChangeTabsEvent = {
  EVENT_ID: 'e164',
  EVENT_PAGE: 'color-detail-page',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'color-detail-tabs',
  EVENT_LABEL: 'switched-color-detail-tab',
  EVENT_ACTION: 'click',
};

export const colorDataEvent = {
  EVENT_ID: 'e165',
  EVENT_PAGE: 'color-detail-page',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'color-data-action-button',
  EVENT_ACTION: 'click',
};

export const pickEvent = {
  EVENT_ID: 'e166',
  EVENT_PAGE: 'pick-page',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'pick-floating-toolbar',
  EVENT_ACTION: 'click',
  EVENT_PATH: '/picker',
};

const convertValuesEvent = {
  EVENT_LABEL: 'convert_color_picker_convert',
  EVENT_PAGE: 'convert-page',
  EVENT_PATH: '/convert',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_GROUP: 'na',
  EVENT_BOOK_ID: 'na',
  EVENT_ID: 'e116',
  EVENT_ACTION: 'click',
};

export const customerServiceEvent = {
  EVENT_ID: 'e117',
  EVENT_PLACE: 'header',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'customer_support_request',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'top',
  EVENT_GROUP: 'na',
};

export const headerNavigationEvent = {
  EVENT_ID: 'e118',
  EVENT_PLACE: 'header',
  EVENT_AREA: 'logo_and_profile',
  EVENT_COMPONENT: 'button',
  EVENT_ACTION: 'click',
};

export function getLabConvertValuesEvent({
  lTextField,
  aTextField,
  bTextField,
}: LabForm) {
  return {
    ...convertValuesEvent,
    TERM: `${lTextField},${aTextField},${bTextField}`,
    EVENT_AREA: 'LAB',
  };
}

export const startVisualizingEvent = {
  EVENT_ID: 'e177',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'ocr_started',
  EVENT_ACTION: 'click',
};

export const cancelOnboardingEvent = {
  EVENT_ID: 'e178',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'ocr_cancel_onboarding',
  EVENT_ACTION: 'click',
};

export const goToColorDataEvent = {
  EVENT_ID: 'e179',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'ocr_go_to_color_data',
  EVENT_ACTION: 'click',
};

export const cancelScannedColorEvent = {
  EVENT_ID: 'e180',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'ocr_cancel_scanned_color',
  EVENT_ACTION: 'click',
};

export const importColorsToAdobeEvent = {
  EVENT_ID: 'e181',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'ocr_import_colors_to_adobe',
  EVENT_ACTION: 'click',
};

export const errorOpeningOcrCameraEvent = {
  EVENT_ID: 'e182',
  EVENT_LABEL: 'ocr_error_opening_camera',
};

export const errorLoadingOcrColorsEvent = {
  EVENT_ID: 'e183',
  EVENT_LABEL: 'ocr_error_loading_colors',
};

export const errorRedirectingToColorDataEvent = {
  EVENT_ID: 'e184',
  EVENT_LABEL: 'ocr_error_redirecting',
};

export const scannedOcrColorEvent = {
  EVENT_ID: 'e185',
  EVENT_LABEL: 'ocr_color_scanned',
};

export const wrongColorOcrReadingEvent = {
  EVENT_ID: 'e186',
  EVENT_LABEL: 'ocr_error_wrong_color',
};

export const cameraPermissionDeniedEvent = {
  EVENT_ID: 'e187',
  EVENT_LABEL: 'ocr_camera_permission_denied',
};

export const articlePageLoadEvent = {
  EVENT_ID: 'e188',
  EVENT_AREA: 'trends',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'article-page',
  EVENT_ACTION: 'view',
  EVENT_LABEL: 'entry_view',
};

export const elementImpressionEvent = {
  EVENT_ID: 'e189',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'view',
  EVENT_LABEL: 'asset_view',
};

export const entryCollectionEntryClick = {
  EVENT_ID: 'e190',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'entry_collection_entry_click',
};

export const articlePageBackButtonClickEvent = {
  EVENT_ID: 'e191',
  EVENT_AREA: 'article_page',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'article_page_click',
};

export const similarArticlesViewMoreClickEvent = {
  EVENT_ID: 'e192',
  EVENT_AREA: 'similar_articles',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'similar_articles_view_more_click',
};

export const entryCollectionViewEvent = {
  EVENT_ID: 'e193',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'view',
  EVENT_LABEL: 'entry_collection_view',
};

export const entryCollectionViewMoreEvent = {
  EVENT_ID: 'e194',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'entry_collection_load_next',
};

export const promotionsPopupClosedEvent = {
  EVENT_ID: 'e195',
  EVENT_COMPONENT: 'promotions-popup',
  EVENT_ACTION: 'click',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'popup_closed',
};

export const promotionsPopupViewEvent = {
  EVENT_ID: 'e196',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'promotions-popup',
  EVENT_LABEL: 'popup_view',
  EVENT_ACTION: 'view',
};

export const promotionsPopupExploreEvent = {
  EVENT_ID: 'e197',
  EVENT_COMPONENT: 'promotions-popup',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'popup_explore',
  EVENT_ACTION: 'click',
};

export const promotionsPopupDismissEvent = {
  EVENT_ID: 'e198',
  EVENT_COMPONENT: 'promotions-popup',
  EVENT_ACTION: 'click',
  EVENT_PAGE: 'picker',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'popup_dismissed',
};

export const promotionsBannerViewEvent = {
  EVENT_ID: 'e199',
  EVENT_PLACE: 'header',
  EVENT_COMPONENT: 'promotions-banner',
  EVENT_ACTION: 'view',
  EVENT_LABEL: 'banner_view',
};

export const promotionsBannerExploreEvent = {
  EVENT_ID: 'e200',
  EVENT_COMPONENT: 'promotions-banner',
  EVENT_PLACE: 'header',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'banner_explore',
};

export const openArticleFromCarousel = {
  EVENT_ID: 'e201',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'asset_click',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'carousel',
};

export const similarArticleClickEvent = {
  EVENT_ID: 'e202',
  EVENT_AREA: 'similar_articles',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'similar_article_click',
};

export const onTrendsTabChange = {
  EVENT_ID: 'e203',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'entry_collection_category_nav_click',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'trends-tabs',
};

export const colorDataNavigationEvent = {
  EVENT_ID: 'e204',
  EVENT_LABEL: 'colordata_nav',
  EVENT_AREA: 'color-insider',
  EVENT_COMPONENT: 'trends-tab',
  EVENT_PLACE: 'body',
  EVENT_ACTION: 'click',
};

export const entryBookmarkAddEvent = {
  EVENT_ID: 'e205',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'entry_bookmark_add',
};

export const entryBookmarkRemoveEvent = {
  EVENT_ID: 'e206',
  EVENT_AREA: 'trends',
  EVENT_ACTION: 'click',
  EVENT_LABEL: 'entry_bookmark_remove',
};

export const retentionOfferViewed = {
  EVENT_ID: 'e159',
  EVENT_LABEL: 'retention_offer_viewed',
};

export const retentionOfferAccepted = {
  EVENT_ID: 'e160',
  EVENT_LABEL: 'retention_offer_accepted',
};

export const retentionOfferRejected = {
  EVENT_ID: 'e161',
  EVENT_LABEL: 'retention_offer_rejected',
};

export const retentionOfferClosed = {
  EVENT_ID: 'e162',
  EVENT_LABEL: 'retention_offer_closed',
};

export const shareArticleClick = {
  EVENT_ID: 'e163',
  EVENT_LABEL: 'share_article_click',
  EVENT_ACTION: 'click',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'share-article-icon',
};

export const shareArticleShared = {
  EVENT_ID: 'e164',
  EVENT_LABEL: 'share_article_shared',
  EVENT_ACTION: 'click',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'share-article-dropdown',
};

export function getRgbConvertValuesEvent({ r, g, b }: ColorSpaceValues['rgb']) {
  return {
    ...convertValuesEvent,
    TERM: `${r},${g},${b}`,
    EVENT_AREA: 'sRGB',
  };
}

export function getHexConvertValuesEvent(hex: string) {
  return {
    ...convertValuesEvent,
    TERM: hex,
    EVENT_AREA: 'HEX',
  };
}

export function getCmykConvertValuesEvent({
  c,
  m,
  y,
  k,
}: ColorSpaceValues['cmyk']) {
  return {
    ...convertValuesEvent,
    TERM: `${c},${m},${y},${k}`,
    EVENT_AREA: 'CMYK',
  };
}
