import React from 'react';
import ColorsIcon from '~/v3/components/shared/icons/colors-icon';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MenuProvider, {
  useMenu,
} from '~/v3/components/shared/providers/menu-provider';
import useRouter from '~/v3/hooks/use-router';
import { useFeatureFlags } from '@xrite/feature-flags-client';
import {
  selectedFooterNavigationStyle,
  StyledFooterNavigation,
} from '../../footer.elements';
import SubMenu from '../sub-menu';
import { getSubMenuItems } from '../../lib/utils';
import SubMenuItem from '../sub-menu-item';

function ColorsFooterNavigation() {
  const { isMenuOpen, onOpenMenu, anchorEl } = useMenu();
  const { getFeatureFlags } = useFeatureFlags();
  const isArticlesNewCategoriesEnabled =
    getFeatureFlags?.isArticlesNewCategoriesEnabled;

  const { t } = useTranslation();

  const { pathname: currentRoute } = useRouter();
  const subMenuItems = getSubMenuItems(isArticlesNewCategoriesEnabled);

  const isSubMenuItemSelected = subMenuItems.some((subMenuItem) =>
    currentRoute.includes(subMenuItem.routesTo)
  );

  const containerStyle =
    isMenuOpen || isSubMenuItemSelected ? selectedFooterNavigationStyle : {};

  return (
    <>
      <StyledFooterNavigation
        sx={containerStyle}
        anchorEl={anchorEl}
        onClick={onOpenMenu}
        icon={<ColorsIcon />}
        text={<Typography>{t('routes.colors')}</Typography>}
        data-testid="colors"
      />
      <SubMenu>
        {subMenuItems
          .filter(
            ({ renderConditions }) =>
              renderConditions === undefined || renderConditions
          )
          .map(({ renderConditions, ...item }) => (
            <SubMenuItem {...item} key={item.routesTo} />
          ))}
      </SubMenu>
    </>
  );
}

function ColorsFooterNavigationWithProviders() {
  return (
    <MenuProvider>
      <ColorsFooterNavigation />
    </MenuProvider>
  );
}

export default ColorsFooterNavigationWithProviders;
