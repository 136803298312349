import { useQuery } from '@tanstack/react-query';
import { getSubscription } from '~/v3/api/subscriptions-api';
import { useLazyQuery } from '~/v3/react-query/use-lazy-query';

type UseGetSubscriptionArguments = {
  skip?: boolean;
};

export const getSubscriptionQueryKey = 'get-subscription';

function useGetSubscription({ skip }: UseGetSubscriptionArguments = {}) {
  return useQuery({
    queryKey: [getSubscriptionQueryKey],
    queryFn: getSubscription,
    enabled: !skip,
    staleTime: Infinity,
  });
}

export function useLazyGetSubscriptionQuery() {
  const { trigger } = useLazyQuery(getSubscription);

  return {
    trigger: () => trigger([getSubscriptionQueryKey]),
  };
}

export default useGetSubscription;
