import { amplifyUserPoolSettings, amplifyApiKeySettings } from 'utils/amplify';

const initAuth = async () => {
  const { Auth } = await import('@aws-amplify/auth');
  Auth.configure(amplifyUserPoolSettings);
  return Auth;
};

const initApi = async (authenticatedUser = true) => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');
  API.configure(
    authenticatedUser ? amplifyUserPoolSettings : amplifyApiKeySettings
  );
  return { API, graphqlOperation };
};

const CognitoUserError = {
  NOT_AUTHENTICATED: 'The user is not authenticated',
};

const auth = {
  getCurrentUser: async () => {
    const Auth = await initAuth();

    try {
      const user = await Auth.currentAuthenticatedUser();

      return {
        data: {
          username: user?.username,
          attributes: user?.attributes,
          storage: { ...user.storage['amplify-signin-with-hostedUI'] },
        },
      };
    } catch (error) {
      // we need to modify the error response when user is not authenticated to return valid response
      // so we can reset user cache when the user is signed out. This is a workaround
      // for the optimistic updates with RTQ

      if (error && error === CognitoUserError.NOT_AUTHENTICATED) {
        return {
          error: {
            status: 'CUSTOM_ERROR',
            data: CognitoUserError.NOT_AUTHENTICATED,
            error: CognitoUserError.NOT_AUTHENTICATED,
          },
        };
      }

      return { error };
    }
  },
  createAccount: async (user) => {
    const Auth = await initAuth();

    try {
      const createdUser = await Auth.signUp({
        username: user?.email,
        password: user?.password,
        attributes: {
          given_name: user?.firstName,
          family_name: user?.lastName,
          'custom:marketingAgreement': user?.marketingAgreement
            ? user?.marketingAgreement.toString()
            : 'false',
        },
        validationData: [],
      });

      return {
        data: {
          user: {
            username: createdUser?.user.getUsername(),
          },
        },
      };
    } catch (error) {
      return { error };
    }
  },
  completeAccountCreation: async ({
    email,
    temporaryPassword,
    newPassword,
    firstName,
    lastName,
  }) => {
    let user;
    try {
      const Auth = await initAuth();

      user = await Auth.signIn(email, temporaryPassword);

      const isNewPasswordRequired =
        user?.challengeName === 'NEW_PASSWORD_REQUIRED';

      if (isNewPasswordRequired) {
        await Auth.completeNewPassword(user, newPassword, {
          given_name: firstName,
          family_name: lastName,
        });
      }

      return {
        data: user,
      };
    } catch (error) {
      // Currently, this is a hacky solution, I have created a task to properly fix this:
      // https://veralto.atlassian.net/browse/PC30-3025
      if (error.message.includes('Invalid device key given')) {
        return {
          data: user,
        };
      }

      return {
        error,
      };
    }
  },
  deleteAccount: async () => {
    const Auth = await initAuth();
    const user = await Auth.currentAuthenticatedUser();
    await Auth.forgetDevice();
    const data = await new Promise((resolve, reject) => {
      user.deleteUser((error, result) => {
        if (result) {
          resolve(result);
        } else {
          reject(error);
        }
      });
    });

    return { data };
  },
  deleteAccountData: async () => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `{
            deleteUserData {
              id,
              actions
            }
          }`
        )
      );

      return { data };
    } catch (error) {
      return { error };
    }
  },
  signIn: async (credentials) => {
    const Auth = await initAuth();

    try {
      const user = await Auth.signIn(credentials.email, credentials.password);

      return {
        data: {
          username: user?.username,
          attributes: user?.attributes,
        },
      };
    } catch (error) {
      return { error };
    }
  },
  signOut: async () => {
    const Auth = await initAuth();

    try {
      const data = await Auth.signOut();

      return { data };
    } catch (error) {
      return { error };
    }
  },
  forgotPassowrd: async (email) => {
    const Auth = await initAuth();

    try {
      const data = await Auth.forgotPassword(email);

      return { data };
    } catch (error) {
      return { error };
    }
  },
  forgotPasswordSubmit: async (options) => {
    const Auth = await initAuth();
    const { email, code, password } = options;

    try {
      const data = await Auth.forgotPasswordSubmit(
        email,
        code.toString(),
        password
      );

      return { data };
    } catch (error) {
      return { error };
    }
  },
  changePassword: async (passwords) => {
    const Auth = await initAuth();

    try {
      const user = await Auth.currentAuthenticatedUser();
      const { currentPassword, newPassword } = passwords;
      const data = await Auth.changePassword(
        user,
        currentPassword,
        newPassword
      );

      return { data };
    } catch (error) {
      return { error };
    }
  },
  accountType: async (email) => {
    const { API, graphqlOperation } = await initApi(false);

    try {
      const data = await API.graphql(
        graphqlOperation(`{
            getAccountType(email: "${email}") {
              type
            }
          }`)
      );

      return { data: data?.data?.getAccountType };
    } catch (error) {
      return { error };
    }
  },
  getUserSegment: async () => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          getUserData {
            defaultSegmentation
          }
        }`)
      );

      return { data: data?.data?.getUserData?.defaultSegmentation };
    } catch (error) {
      return { error };
    }
  },
  updateUserSegment: async (payload) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreateUserData($defaultSegmentation: String) {
            createUserData(
              input: {
                defaultSegmentation: $defaultSegmentation
              }
            ) {
              defaultSegmentation
            }
          }`,
          {
            defaultSegmentation: payload.defaultSegmentation,
          }
        )
      );

      return { data: data?.data?.createUserData?.defaultSegmentation };
    } catch (error) {
      return { error };
    }
  },
};

export default auth;
