import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
};

export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
  },
});

export const { setIsLoading } = paymentSlice.actions;

export default paymentSlice.reducer;

export const selectIsLoading = (state) => state.payment.isLoading;
