import Box from '@mui/material/Box';
import React, { ReactNode } from 'react';
import { StyledComponent } from '@mui/styles';

type Props = {
  text: ReactNode;
  icon: ReactNode;
  tag?: ReactNode;
  onClick: () => void;
  id?: string;
  dataId?: string;
  isSelected: boolean;
  as?: StyledComponent<Record<string, unknown>>;
};

function BaseNavigationItem({
  text,
  icon,
  as: Container = Box,
  isSelected,
  onClick,
  id,
  dataId,
  tag,
}: Readonly<Props>) {
  return (
    <Container
      data-testid={dataId}
      type="button"
      onClick={onClick}
      sx={{
        opacity: isSelected ? 1 : 0.625,
      }}
      id={id}
    >
      {icon}

      {text}

      {tag}
    </Container>
  );
}

export default BaseNavigationItem;
