import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import BackIcon from '@mui/icons-material/ArrowBack';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import { useTranslation } from 'react-i18next';

import IconButton from './IconButton';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.fullScreenDialog,
  },
  paperFullScreen: {
    boxShadow: 'unset',
    backgroundColor: 'white',
  },
  dialogTopBar: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(5),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2, 1, 2, 1),
    },
  },
  dialogTitle: {
    overflow: 'hidden',
    display: 'flex',
    flex: '1 1 auto',
    paddingLeft: 0,
    '& h2': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  },
  backIcon: {
    fontSize: theme.typography.pxToRem(35),
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(25),
    },
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
  },
}));

function FullScreenDialog({ title, onClose, children }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleClose = () => {
    setOpen(false);
    if (onClose) onClose();
  };

  return (
    <Dialog
      fullScreen
      open={open}
      classes={{ paperFullScreen: classes.paperFullScreen, root: classes.root }}
      BackdropComponent={() => null}
    >
      <DialogActions className={classes.dialogTopBar}>
        <IconButton
          aria-label={t('labels.back')}
          color="primary"
          tooltip={t('labels.back')}
          onClick={handleClose}
          autoFocus
          data-id="full-screen-back"
          size="large"
        >
          <BackIcon className={classes.backIcon} />
        </IconButton>
        <DialogTitle className={classes.dialogTitle}>{title}</DialogTitle>
      </DialogActions>
      <DialogContent className={classes.dialogContent}>
        {children}
      </DialogContent>
    </Dialog>
  );
}

FullScreenDialog.defaultProps = {
  children: null,
  title: '',
  onClose: null,
};

FullScreenDialog.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func,
};

export default FullScreenDialog;
