import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function TrendNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 32 20"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: 32,
        height: 20,
        fill: 'none',
        ...sx,
      }}
      {...props}
    >
      <path
        className="trends-icon-bar"
        d="M4.35254 4.85C4.35254 3.96634 4.94949 3.25 5.68587 3.25H7.01921C7.75559 3.25 8.35254 3.96634 8.35254 4.85V17.65C8.35254 18.5337 7.75559 19.25 7.01921 19.25H5.68587C4.94949 19.25 4.35254 18.5337 4.35254 17.65V4.85Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        className="trends-icon-bar"
        d="M11.3525 4.85C11.3525 3.96634 11.9495 3.25 12.6859 3.25H14.0192C14.7556 3.25 15.3525 3.96634 15.3525 4.85V17.65C15.3525 18.5337 14.7556 19.25 14.0192 19.25H12.6859C11.9495 19.25 11.3525 18.5337 11.3525 17.65V4.85Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        className="trends-icon-bar"
        d="M18.3525 4.85C18.3525 3.96634 18.9495 3.25 19.6859 3.25H21.0192C21.7556 3.25 22.3525 3.96634 22.3525 4.85V17.65C22.3525 18.5337 21.7556 19.25 21.0192 19.25H19.6859C18.9495 19.25 18.3525 18.5337 18.3525 17.65V4.85Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M30.3525 0.75L17.8298 14.2083L11.2389 8.1875L1.35254 17.75M30.3525 0.75H22.4434M30.3525 0.75V9.25"
        stroke={theme.palette.icon.primary}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default TrendNavigationIcon;
