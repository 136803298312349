import { Products } from '~/v3/types/coupons';
import {
  Subscription,
  SubscriptionProviderType,
  SubscriptionStatus,
} from '../types/subsription';

const filterActiveCouponCodeSubscriptionsByProduct = (
  subscriptions: Subscription[],
  product: Products
) =>
  subscriptions.filter(
    (sub) =>
      sub?.name?.includes(SubscriptionProviderType.COUPON) &&
      sub.active &&
      sub.state !== SubscriptionStatus.CANCELED &&
      sub.product === product
  );

export default filterActiveCouponCodeSubscriptionsByProduct;
