import { useMutation } from '@tanstack/react-query';
import { createFastSpringMultipleProductsSecurePayload } from '~/v3/api/subscriptions-api';

function useCreateFastSpringMultipleProductsSecurePayload() {
  return useMutation({
    mutationFn: createFastSpringMultipleProductsSecurePayload,
  });
}

export default useCreateFastSpringMultipleProductsSecurePayload;
