import { NextStep, ReasonOfCancelation } from './types';

export const REASON_OF_CANCELLATION: ReasonOfCancelation[] = [
  {
    value: 'tehnicalIssues',
  },
  {
    value: 'tooExpensive',
  },
  {
    value: 'notWorthTheCost',
  },
  {
    value: 'tooDifficultToUse',
  },
  {
    value: 'didNotUseEnough',
  },
  {
    value: 'problemWithSupportTeam',
  },
  {
    value: 'neededForSpecificProject',
  },
  {
    value: 'jobHasChanged',
  },
  {
    value: 'other',
    textField: true,
  },
];

export const NEXT_STEP: NextStep[] = [
  {
    value: 'switchingToFree',
  },
  {
    value: 'switchingToDifferentCompany',
  },
  {
    value: 'needShortBreak',
  },
  {
    value: 'noLongerNeedServicesLikeThis',
  },
  {
    value: 'notSure',
  },
];

export default {
  REASON_OF_CANCELLATION,
  NEXT_STEP,
};
