export const createMediaQueryMaxWidthAndMaxHeight = (
  maxWidth: number,
  maxHeight: number
) => {
  return `@media (max-width: ${maxWidth}px) and (max-height: ${maxHeight}px)`;
};

export const createMediaQueryMaxWidth = (maxWidth: number) => {
  return `@media (max-width: ${maxWidth}px)`;
};

export const createMediaQueryMinWidth = (minWidth: number) => {
  return `@media (min-width: ${minWidth}px)`;
};

export const createMediaQueryWidthAndHeight = (
  width: number,
  height: number
) => {
  return `@media (width: ${width}px) and (height: ${height}px)`;
};

export const createMediaQueryMinWidthAndMaxWidth = (
  minWidth: number,
  maxWidth: number
) => {
  return `@media (min-width: ${minWidth}px) and (max-width: ${maxWidth}px)`;
};

export const fluidValue = (
  minValue: number,
  maxValue: number,
  minViewport: number,
  maxViewport: number
) =>
  `calc(${minValue}px + (${maxValue} - ${minValue}) * ((100vw - ${minViewport}px) / (${
    maxViewport - minViewport
  })))`;
