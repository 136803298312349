import { Theme, useMediaQuery } from '@mui/material';

type UseResolutionProps = {
  ssr?: boolean;
};

export type ResolutionResponse = {
  isExtraLargeScreen: boolean;
  isLargeScreen: boolean;
  isMediumScreen: boolean;
  isSmallScreen: boolean;
  isExtraSmallScreen: boolean;
};

function useResolution(props?: UseResolutionProps): ResolutionResponse {
  const options = { noSsr: props?.ssr };

  const isExtraLargeScreen = useMediaQuery(
    (theme: Theme) => theme.breakpoints.up('xl'),
    options
  );

  const isLargeScreen = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('lg'),
    options
  );

  const isMediumScreen = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('md'),
    options
  );

  const isSmallScreen = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('sm'),
    options
  );

  const isExtraSmallScreen = useMediaQuery(
    (theme: Theme) => theme.breakpoints.down('extension'),
    options
  );

  return {
    isExtraLargeScreen,
    isLargeScreen,
    isMediumScreen,
    isSmallScreen,
    isExtraSmallScreen,
  };
}

export default useResolution;
