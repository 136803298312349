import RoutePath from 'constants/route';
import useRouter from '~/v3/hooks/use-router';

function getKeyByValue(object, value) {
  return Object.keys(object).find((key) => object[key] === value);
}

function useGetPageTitle() {
  const { location } = useRouter();

  if (location.pathname.includes('/info/')) {
    return 'colorInfo';
  }

  if (location.pathname.includes('/picker/')) {
    return 'colorPicker';
  }

  if (location.pathname.includes('/pick')) {
    return 'colorPicker';
  }

  if (location.pathname.includes('/info/')) {
    return 'colorInfo';
  }

  if (location.pathname.includes('/auth/welcome')) {
    return 'signInPage';
  }
  if (location.pathname.includes('/auth/sign-in-email')) {
    return 'signInWithEmail';
  }

  if (location.pathname.includes('/auth/review-terms')) {
    return 'privacyPolicy';
  }

  if (location.pathname.includes('/auth/user-segment')) {
    return 'userSegment';
  }
  if (location.pathname.includes('/auth/sign-in-error')) {
    return 'signInError';
  }

  if (
    location.pathname.includes(RoutePath.colorStoryNew) ||
    location.pathname.includes(RoutePath.colorStoryTemplate) ||
    location.pathname.includes(RoutePath.colorStoryCreate)
  ) {
    return 'colorStory';
  }

  if (location.pathname.includes('/colorStory/update/')) {
    return 'colorStory';
  }

  if (location.pathname.includes('/explore')) {
    return 'explore';
  }

  if (location.pathname.toLocaleLowerCase().includes('/activate-benq')) {
    return 'activateBenq';
  }

  if (location.pathname.includes('redeem-code-legacy')) {
    return 'couponCodes';
  }

  return getKeyByValue(RoutePath, location.pathname);
}

export default useGetPageTitle;
