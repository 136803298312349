import { useEffect, useRef } from 'react';
import * as Sentry from '@sentry/react';

const useDetectCognitoCookieDeletion = () => {
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const sendCookieDeletionEvent = () => {
    try {
      Sentry.captureMessage('Possible external cookie deletion detected', {
        level: 'warning',
      });
    } catch (error) {
      console.error('Failed to send Sentry event:', error);
    }
  };

  const checkCookies = () => {
    try {
      if (!document.cookie.includes('CognitoIdentityServiceProvider')) {
        // auth cookies missing, but user had logged in before! possible external deletion
        sendCookieDeletionEvent();

        if (intervalRef.current) {
          clearInterval(intervalRef.current);
          intervalRef.current = null;
        }
      }
    } catch (error) {
      console.error('Error checking cookies:', error);
    }
  };

  useEffect(() => {
    intervalRef.current = setInterval(checkCookies, 60000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default useDetectCognitoCookieDeletion;
