import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router-dom';

import config from 'config';
import RoutePathname from 'constants/route';
import { getLocalStorage, setLocalStorage } from '~/v3/utils/storage';
import { selectCurrentUser } from 'services/authApi';
import useRouter from '~/v3/hooks/use-router';
import useSubscriptionStatus from './useSubscriptionStatus';
import { useGetUserSegmentQuery } from '../services/authApi';

function useRedirectToProtectedRoute() {
  const redirectUrl = getLocalStorage(config.REDIRECT_URL);
  const { data: user } = useSelector(selectCurrentUser);
  const { isBasic } = useSubscriptionStatus();
  const { push, pathname, location, query } = useRouter();
  const isDynamicPath = Object.values(RoutePathname).some((path) =>
    matchPath(pathname, {
      path,
      exact: true,
      strict: true,
    })
  );

  const { data: userSegment, isLoading: isLoadingUserSegment } =
    useGetUserSegmentQuery(undefined, { skip: !user });

  if (query.coupon) {
    setLocalStorage(config.COUPON, query.coupon);
  }

  useEffect(() => {
    if (
      (redirectUrl !== '/' && user && redirectUrl !== RoutePathname.paywall) ||
      (redirectUrl === RoutePathname.paywall && user && isBasic)
    ) {
      if (userSegment && !isLoadingUserSegment) {
        setLocalStorage(config.REDIRECT_URL, '/');

        if (redirectUrl != null && redirectUrl !== pathname) {
          push(`${redirectUrl}${location.search}`);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isBasic, userSegment]);

  useEffect(() => {
    if (
      !user &&
      (Object.values(RoutePathname).includes(pathname) || isDynamicPath)
    ) {
      setLocalStorage(config.REDIRECT_URL, pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

export default useRedirectToProtectedRoute;
