import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import PaletteWorkspace from './index';

const useStyles = makeStyles((theme) => ({
  pageWithPaletteWorkspace: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
    justifyContent: 'flex-end',
    flex: 1,
    flexDirection: 'row',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  page: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'hidden',
  },
}));
function PageWithPaletteWorkspace({ children }) {
  const classes = useStyles();

  return (
    <div id="paletteWorkspace" className={classes.pageWithPaletteWorkspace}>
      <div className={classes.page}>{children}</div>
      <PaletteWorkspace />
    </div>
  );
}

PageWithPaletteWorkspace.propTypes = {
  children: PropTypes.node.isRequired,
};

export default PageWithPaletteWorkspace;
