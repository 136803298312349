/* eslint-disable consistent-return */
import { MutationDefinition, BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { UseMutation } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useSnackbar } from 'notistack';

type MutationWithToastArgs<T> = {
  successMessage?: string;
  errorMessage?: string;
  useMutationHook: UseMutation<
    MutationDefinition<T, BaseQueryFn, never, T, 'api'>
  >;
};

export const useMutatePaletteOrColorStory = <T,>({
  useMutationHook,
}: MutationWithToastArgs<T>) => {
  const { enqueueSnackbar } = useSnackbar();

  const [triggerMutationBase] = useMutationHook();

  const triggerMutation = async (
    {
      successMessage,
      errorMessage,
    }: {
      errorMessage: string;
      successMessage: string;
    },
    ...args: Parameters<typeof triggerMutationBase>
  ) => {
    try {
      const result = await triggerMutationBase(...args);

      if ('data' in result) {
        if (successMessage) {
          enqueueSnackbar({
            message: successMessage,
          });
        }
      }

      if ('error' in result) {
        throw result.error;
      }

      return result;
    } catch (error) {
      if (errorMessage) {
        enqueueSnackbar({
          message: errorMessage,
        });
      }
    }
  };
  return {
    triggerMutation,
  };
};
