import { HslaColor } from 'react-colorful';
import { CSSProperties } from 'react';

export const enum Sorting {
  bookPosition = 'bookPosition',
  hue = 'hue',
  smooth = 'smooth',
}

// Range from 0 to 360
export type HueRange = number extends infer N
  ? N extends number
    ? N extends 0
      ? 0
      : N extends 360
      ? 360
      : N
    : never
  : never;

export const CHIP_SPACING = 3;

export const TEN_DEGREES = 10;

export const enum ChipSize {
  SMALL = 40,
  MEDIUM = 60,
  LARGE = 90,
}

export const redColorHsla: HslaColor = {
  h: 0,
  s: 100,
  l: 50,
  a: 1,
};

export const enum RgbToLuminanceConversion {
  LUMINANCE_THRESHOLD = 0.5,
  RED_WEIGHT = 0.2126,
  GREEN_WEIGHT = 0.7152,
  BLUE_WEIGHT = 0.0722,
}

export enum ViewDropdownOption {
  Grid = 'grid',
  List = 'list',
}

export const FILTERS_TAB_SEARCH_TIMEOUT = 400;

export const chipGapBasedOnChipSize: {
  [key in ChipSize]: CSSProperties['gap'];
} = {
  [ChipSize.LARGE]: '0.175rem',
  [ChipSize.MEDIUM]: '0.125rem',
  [ChipSize.SMALL]: '0.075rem',
};

export const workspaceSmallDataTestId = 'workspace-small';

export const footerHeightMdScreen = 73;

export const footerHeightSmScreen = footerHeightMdScreen - 20;

export const collapsedMobileWorkspaceHeaderHeight = 41;

export const expandedMobileWorkspaceHeight = 265;

export const FIRST_OF_FEBRUARY_2024 = new Date(2024, 1, 1, 0, 0, 0);
