/* eslint-disable compat/compat */
/* eslint-disable no-console */
import adobeConstants from './constants';
import config from '../../config';

const { SystemPath } = window;
const DEFAULT_ADOBE_VERSION = 'ADOBE';

const adobe = {};
let csi;

if (window.CSInterface) csi = new window.CSInterface();
else csi = { hostEnvironment: { appId: DEFAULT_ADOBE_VERSION } };

adobe.csi = csi;

const appId = csi?.getHostEnvironment?.()?.appId || csi?.hostEnvironment?.appId;

export const isPhotoshop = appId === 'PHXS';
export const isIllustrator = appId === 'ILST';
export const isInDesign = appId === 'IDSN';

export const getAddobeApp = () => {
  return appId;
};

adobe.handleColorEvent = (event, color) => {
  if (appId === 'PHXS') {
    if (!event)
      csi.evalScript(
        `$._PHXS.setForegroundColor(${JSON.stringify(color)})`,
        (err) => console.log(err)
      );
    else if (event.altKey)
      csi.evalScript(
        `$._PHXS.setBackgroundColor(${JSON.stringify(color)})`,
        (err) => console.log(err)
      );
    else
      csi.evalScript(
        `$._PHXS.setForegroundColor(${JSON.stringify(color)})`,
        (err) => console.log(err)
      );
  }

  if (appId === 'ILST') {
    if (event.altKey) {
      csi.evalScript(
        `$._ILST.setStrokeColor(${JSON.stringify(color)})`,
        (err) => console.log(err)
      );
    } else if (config.ENABLE_SET_COLOR_ILLUSTRATOR) {
      csi.evalScript(`$._ILST.setColor(${JSON.stringify(color)})`, (err) =>
        console.log(err)
      );
    } else {
      csi.evalScript(`$._ILST.setFillColor(${JSON.stringify(color)})`, (err) =>
        console.log(err)
      );
    }
  }

  if (appId === 'IDSN') {
    if (!event)
      csi.evalScript(`$._IDSN.setFillColor(${JSON.stringify(color)})`, (err) =>
        console.log(err)
      );
    else if (event.altKey)
      csi.evalScript(
        `$._IDSN.setStrokeColor(${JSON.stringify(color)})`,
        (err) => console.log(err)
      );
    else
      csi.evalScript(`$._IDSN.setFillColor(${JSON.stringify(color)})`, (err) =>
        console.log(err)
      );
  }
};

export const createTempFolder = () => {
  const tempFolderName = 'connect/';
  let tempFolder = `/tmp/${tempFolderName}`;
  if (window.navigator.platform.toLowerCase().indexOf('win') > -1) {
    tempFolder = `${csi.getSystemPath(
      SystemPath.USER_DATA
    )}/../Local/Temp/${tempFolderName}`;
  }
  window.cep.fs.makedir(tempFolder);
  return tempFolder;
};

export const saveAndReturnFilePath = (name, imageData) => {
  const downloadedFile = `${createTempFolder() + name.replace(/ /g, '_')}.jpg`;
  const regex = /[^/]*\/..\//gis;
  const base64Data = imageData.replace(/^data:image\/jpeg;base64,/, '');
  const fullDownloadPath = downloadedFile.replace(regex, '');
  const result = window.cep.fs.writeFile(
    fullDownloadPath,
    base64Data,
    window.cep.encoding.Base64
  );
  if (result.err === 0) return fullDownloadPath;
  return result.err;
};

const loadJSX = () => {
  const extensionPath = csi.getSystemPath(SystemPath.EXTENSION);
  const JSONPath = `${extensionPath}/js/lib/json2.js`;
  const appPath = `${extensionPath}/jsx/${appId}/`;

  // Enables usage of JSON methods like JSON.stringify() and JSON.parse() inside of host application
  csi.evalScript(`$._ext.evalFile(${JSON.stringify(JSONPath)})`);

  // Loads files related to the host application
  // Example: if the extension is opened in Photoshop,
  // it will run all files from EXTENSION_PATH/jsx/PHXS
  csi.evalScript(`$._ext.evalFiles(${JSON.stringify(appPath)})`);
};

if (window.CSInterface) loadJSX();

export const addColorToSwatch = (color) =>
  csi.evalScript(
    `$._${appId}.addColorToSwatch(${JSON.stringify(color)})`,
    (err) => console.log(err)
  );

export const addColorToChannel = (color) =>
  csi.evalScript(
    `$._${appId}.addColorToChannel(${JSON.stringify(color)})`,
    (err) => console.log(err)
  );

export const getLatestColor = () =>
  new Promise((resolve) => {
    csi.evalScript(`$._${appId}.getLatestColor()`, resolve);
  });

export const getPhotoshopTheme = () => {
  let darkThemeEnabled;
  if (csi.hostEnvironment.appId !== DEFAULT_ADOBE_VERSION) {
    const hostEnviroment = csi.getHostEnvironment();
    const hostBackground =
      hostEnviroment.appSkinInfo.panelBackgroundColorSRGB.color;
    if (hostBackground.blue === 50 || hostBackground.blue === 83) {
      darkThemeEnabled = true;
    } else {
      darkThemeEnabled = false;
    }
  } else {
    darkThemeEnabled = null;
  }
  return darkThemeEnabled;
};

const makeAdobeEvent = (targetExtension, type, data) => {
  const event = new window.CSEvent();
  event.type = type;
  event.scope = adobeConstants.events.scopes.APPLICATION;
  event.data = data;
  event.extensionId = targetExtension;
  return event;
};

adobe.openAuth = (url, cb) => {
  csi.addEventListener(adobeConstants.events.auth.PANEL_OPENED, () => {
    const event = makeAdobeEvent(
      config.ADOBE_EXTENSION_ID_AUTH,
      adobeConstants.events.auth.START,
      url
    );
    csi.dispatchEvent(event);
  });

  csi.addEventListener(adobeConstants.events.auth.COMPLETE, (event) => {
    window.location.href = event.data;
  });

  csi.addEventListener(adobeConstants.events.auth.ERROR, cb);

  csi.requestOpenExtension(config.ADOBE_EXTENSION_ID_AUTH);
};

export default adobe;
