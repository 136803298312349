import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

function AddToSwatch({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      version="1.1"
      id="Capa_1"
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 443.733 443.733"
      xmlSpace="preserve"
      sx={{ ...sx, height: 12, width: 12 }}
      {...props}
    >
      <g>
        <g>
          <path
            d="M187.733,0H17.067C7.641,0,0,7.641,0,17.067v170.667c0,9.426,7.641,17.067,17.067,17.067h170.667
			c9.426,0,17.067-7.641,17.067-17.067V17.067C204.8,7.641,197.159,0,187.733,0z M170.667,170.667H34.133V34.133h136.533V170.667z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M426.667,0H256c-9.426,0-17.067,7.641-17.067,17.067v170.667c0,9.426,7.641,17.067,17.067,17.067h170.667
			c9.426,0,17.067-7.641,17.067-17.067V17.067C443.733,7.641,436.092,0,426.667,0z M409.6,170.667H273.067V34.133H409.6V170.667z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M187.733,238.933H17.067C7.641,238.933,0,246.574,0,256v170.667c0,9.426,7.641,17.067,17.067,17.067h170.667
			c9.426,0,17.067-7.641,17.067-17.067V256C204.8,246.574,197.159,238.933,187.733,238.933z M170.667,409.6H34.133V273.067h136.533
			V409.6z"
          />
        </g>
      </g>
      <g>
        <g>
          <path
            d="M426.667,324.267H358.4V256c0-9.426-7.641-17.067-17.067-17.067s-17.067,7.641-17.067,17.067v68.267H256
			c-9.426,0-17.067,7.641-17.067,17.067S246.574,358.4,256,358.4h68.267v68.267c0,9.426,7.641,17.067,17.067,17.067
			s17.067-7.641,17.067-17.067V358.4h68.267c9.426,0,17.067-7.641,17.067-17.067S436.092,324.267,426.667,324.267z"
          />
        </g>
      </g>
    </SvgIcon>
  );
}

export default AddToSwatch;
