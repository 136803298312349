/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch, batch } from 'react-redux';

import WorkspaceUI from 'components/WorkspaceUI';
import PaletteCollaborators from 'features/PaletteCollaborators';
import ClearButton from 'components/WorkspaceHeaderButtons/ClearButton';
import RefreshButton from 'components/WorkspaceHeaderButtons/RefreshButton';
import DeleteIcon from '@mui/icons-material/DeleteOutlined';
import routes from 'constants/route';
import { useSnackbar } from 'notistack';
import useRouter from '~/v3/hooks/use-router';
import { useGetPaletteQuery } from 'services/paletteApi';
import LoadingIconButton from '~/v3/components/core/buttons/loading-icon-button';
import {
  useUpdatePaletteMutation,
  useDeletePaletteMutation,
  useCreatePaletteMutation,
} from '~/v2/services/paletteApi';
import events from 'analytics/events';
import { isSidebarEnabled } from '~/v3/feature-flags';
import { useTheme } from '@mui/material';
import { selectCurrentUser } from '~/v2/services/authApi';

import useTracker from 'analytics/useTracker';
import useConfirm from 'features/ConfirmDialog/useConfirm';
import CollaboratorAvatar from '~/v3/components/shared/icons/collaborator-avatar';
import { useMutatePaletteOrColorStory } from '~/v3/hooks/use-mutate-palette-or-color-story';
import usePaletteWS from './usePaletteWS';
import PaletteSave from './PaletteSave';
import {
  setName,
  setColors,
  setUnsavedChanges,
  selectName,
  selectColors,
  selectPalette,
  selectUnshavedChanges,
  selectId,
  setPalette,
} from './paletteSlice';

function PaletteWorkspace() {
  const { t } = useTranslation();
  const { match, query, push } = useRouter();
  const id = useSelector(selectId);
  const name = useSelector(selectName);
  const colors = useSelector(selectColors);
  const palette = useSelector(selectPalette);
  const unsavedChanges = useSelector(selectUnshavedChanges);
  const dispatch = useDispatch();
  const { clearWorkspace } = usePaletteWS();
  const { confirm } = useConfirm();
  const theme = useTheme();
  const ga = useTracker();
  const { currentData, isError } = useGetPaletteQuery(palette?.id || query.id, {
    skip: !(palette?.id || query.id),
  });
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isError) {
      clearWorkspace();
      enqueueSnackbar(
        t('labels.paletteAccessDenided', { palette: palette.name })
      );
      push(routes.colorPicker);
    }
  }, [isError]);

  const { triggerMutation: triggerUpdate } = useMutatePaletteOrColorStory({
    useMutationHook: useUpdatePaletteMutation,
  });

  const { triggerMutation: triggerCreate } = useMutatePaletteOrColorStory({
    useMutationHook: useCreatePaletteMutation,
  });

  const [deletePalette, { isLoading }] = useDeletePaletteMutation();

  const { data: user } = useSelector(selectCurrentUser);

  const currentUserIsOwner = user?.attributes?.email === palette?.owner?.email;

  useEffect(() => {
    if (palette?.id && match.path === routes.colorPicker) {
      push(routes.paletteUpdate.replace(':id', palette.id));
    }
  }, [palette?.id, match.path]);

  // When you click to go back on the browser, after clearing the palette
  // the previous palette will be loaded
  useEffect(() => {
    if (currentData && !id && match.path === routes.paletteUpdate) {
      batch(() => {
        dispatch(setPalette(currentData));
        dispatch(setUnsavedChanges(false));
      });
    }
  }, [query.id, currentData]);

  /**
   * Keeps the palette workspace state in sync with the
   * latest changes from the generic workspace component.
   * @param {{ name: string, colors: Array}} value Workspace value
   */
  const handleSubmit = async (value) => {
    if (isSidebarEnabled && value?.name) {
      const paletteColorCodes = colors?.map((color) => color.code);

      if (!paletteColorCodes?.length) return;

      if (palette?.id) {
        await triggerUpdate(
          {
            successMessage: t('messages.paletteUpdateSuccessMessage', {
              name: value.name,
            }),
            errorMessage: t('messages.paletteUpdateWarningMessage'),
          },
          {
            ...palette,
            name: value.name,
            colors: paletteColorCodes,
          }
        );
      } else {
        const newPalette = await triggerCreate(
          {
            successMessage: t('messages.paletteCreateSuccessMessage', {
              name: value.name,
            }),
            errorMessage: t('messages.paletteCreateWarningMessage'),
          },
          {
            name: value.name,
            colors: paletteColorCodes,
          }
        );
        dispatch(setPalette(newPalette?.data));
      }

      batch(() => {
        dispatch(setName(value.name));
        dispatch(setUnsavedChanges(false));
      });
    }

    if (!isSidebarEnabled && value?.name) {
      batch(() => {
        dispatch(setName(value.name));
        dispatch(setUnsavedChanges(true));
      });
    }

    if (value?.colors) {
      batch(() => {
        dispatch(setColors(value.colors));
        dispatch(setUnsavedChanges(true));
      });
    }

    if (value == null) {
      if (value === null) {
        clearWorkspace(() => {
          if (match.path === routes.paletteUpdate) {
            push(routes.colorPicker);
          }
        });
      } else {
        dispatch(setColors([]));
      }
    }
  };

  const onNameChange = (newName) => {
    dispatch(setUnsavedChanges(true));
    dispatch(setName(newName));
  };

  const handleDeletePalette = async (paletteId) => {
    const isConfirmed = await confirm({
      content: t('labels.deletePaletteConfirmation'),
    });

    if (isConfirmed) {
      try {
        await deletePalette(paletteId);
        enqueueSnackbar(t('messages.paletteSuccessfullyDeleted'));
      } catch (error) {
        enqueueSnackbar({ message: error.message, variant: 'error' });
      }
      ga.trackEvent(events.e43);
    }
  };

  return (
    <WorkspaceUI
      value={{ id, name, colors }}
      onSubmit={handleSubmit}
      onNameChange={onNameChange}
      saveButton={<PaletteSave />}
      shareButton={
        !palette || currentUserIsOwner || !isSidebarEnabled ? (
          <PaletteCollaborators
            palette={currentData}
            disabled={unsavedChanges || colors?.length === 0}
          />
        ) : (
          <CollaboratorAvatar ownerEmail={palette?.owner?.email} />
        )
      }
      clearButton={
        <ClearButton
          onSubmit={handleSubmit}
          tooltipTitle={t('labels.createNewPalette')}
        />
      }
      refreshButton={<RefreshButton onSubmit={handleSubmit} />}
      deleteButton={
        <LoadingIconButton
          sx={{ color: theme.palette.error.light }}
          data-testid="delete-palette-button"
          onClick={() => handleDeletePalette(palette.id)}
          disabled={!palette || !currentUserIsOwner}
          loading={isLoading}
        >
          <DeleteIcon />
        </LoadingIconButton>
      }
    />
  );
}

export default PaletteWorkspace;
