import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { useTranslation } from 'react-i18next';

import colors from 'theme/colors';
import FullScreenDialog from 'components/FullScreenDialog';
import IconButton from 'components/IconButton';

const useStyles = makeStyles((theme) => ({
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
    margin: theme.spacing(1),
    height: 250,
    maxWidth: '100%',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  cardContent: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing(2),
    paddingTop: 0,
    overflow: 'auto',
    height: '100%',
    minHeight: 200,
  },
  dialogTopBar: {
    display: 'flex',
    alignItems: 'center',
  },
  backIcon: {
    fontSize: theme.typography.pxToRem(35),
    color: ({ isDarkColor }) =>
      isDarkColor ? theme.palette.common.white : theme.palette.common.black,
  },
  cardHeaderRoot: {
    width: '100%',
    alignItems: 'center',
    padding: theme.spacing(2, 1.75, 2, 1.75),
    backgroundColor: colors.WhiteGrey[200],
    marginBottom: theme.spacing(3),
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
  },
}));

const DashboardComponent = ({ title, component, children }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [fullscreen, setFullscreen] = useState(false);

  function enableFullscreen() {
    setFullscreen(true);
  }

  function disableFullscreen() {
    setFullscreen(false);
  }

  return (
    <>
      <CardHeader
        classes={{
          root: classes.cardHeaderRoot,
        }}
        action={
          <IconButton
            aria-label={t('labels.fullScreen')}
            color="secondary"
            tooltip={t('labels.fullScreen')}
            onClick={() => enableFullscreen()}
            data-id={`${title.toLowerCase().replace(/\s/g, '-')}-full-screen`}
            size="large"
          >
            <FullscreenIcon />
          </IconButton>
        }
        title={<Typography variant="h6">{title}</Typography>}
      />
      <CardContent className={classes.cardContent}>{children}</CardContent>

      {fullscreen && (
        <FullScreenDialog
          title={title}
          onClose={() => disableFullscreen()}
          analyticsTitle={component}
        >
          {children}
        </FullScreenDialog>
      )}
    </>
  );
};

DashboardComponent.defaultProps = {
  children: null,
  title: '',
  component: '',
};

DashboardComponent.propTypes = {
  title: PropTypes.string,
  component: PropTypes.string,
  children: PropTypes.node,
};

export default DashboardComponent;
