import React from 'react';
import { SvgIcon, SvgIconProps, useTheme } from '@mui/material';

function VisualizeNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
      {...props}
    >
      <path
        d="M12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 17C9.24 17 7 14.76 7 12C7 9.24 9.24 7 12 7C14.76 7 17 9.24 17 12C17 14.76 14.76 17 12 17Z"
        fill={theme.palette.icon.primary}
      />
      <path
        d="M9 12C9 10.34 10.34 9 12 9C13.66 9 15 10.34 15 12C15 13.66 13.66 15 12 15C10.34 15 9 13.66 9 12Z"
        fill={theme.palette.icon.secondary}
      />
    </SvgIcon>
  );
}

export default VisualizeNavigationIcon;
