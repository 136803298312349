export const subscriptionCancelClick = {
  EVENT_ID: 'e153',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_cancel_click',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionCancelationStarted = {
  EVENT_ID: 'e154',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_cancelation_started',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionCancelAbortClick = {
  EVENT_ID: 'e155',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_cancel_abort_click',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionCancelationAborted = {
  EVENT_ID: 'e156',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_cancelation_aborted',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionCancelContinueClick = {
  EVENT_ID: 'e157',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_cancel_continue_click',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionCanceled = {
  EVENT_ID: 'e158',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_canceled',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};
