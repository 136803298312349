import couponCodeFetchers from '../pages/CouponCodes/couponCodeFetcher';
import { api } from './api';
import { subscriptionTag } from './subscriptionApi';

const couponCodeTag = 'couponCodeTag';

const authApi = api.enhanceEndpoints({
  addTagTypes: [couponCodeTag, subscriptionTag],
});

const extendedApi = authApi.injectEndpoints({
  endpoints: (build) => ({
    getCouponCode: build.query({
      queryFn: couponCodeFetchers.getCouponCode,
    }),
    addCouponCodeToUser: build.mutation({
      queryFn: couponCodeFetchers.addCouponCodeToUser,
      invalidatesTags: [subscriptionTag],
    }),
  }),
  overrideExisting: false,
});

export const { useLazyGetCouponCodeQuery, useAddCouponCodeToUserMutation } =
  extendedApi;
