import { useTheme } from '@mui/material';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import React from 'react';

function MeasureNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
      {...props}
    >
      <path
        d="M2.27445 13.7683C2.27445 13.3521 1.93702 13.0146 1.52078 13.0146H1.01833C0.602081 13.0146 0.264648 13.3521 0.264648 13.7683V17.0342C0.264648 18.1396 1.16906 19.044 2.27445 19.044H5.54038C5.95663 19.044 6.29406 18.7066 6.29406 18.2904V17.7879C6.29406 17.3717 5.95663 17.0342 5.54038 17.0342H3.02813C2.61189 17.0342 2.27445 16.6968 2.27445 16.2806V13.7683ZM2.27445 3.71929C2.27445 3.30305 2.61189 2.96561 3.02813 2.96561H5.54038C5.95663 2.96561 6.29406 2.62818 6.29406 2.21194V1.70949C6.29406 1.29324 5.95663 0.955811 5.54038 0.955811H2.27445C1.16906 0.955811 0.264648 1.86022 0.264648 2.96561V6.23155C0.264648 6.64779 0.602081 6.98522 1.01833 6.98522H1.52078C1.93702 6.98522 2.27445 6.64779 2.27445 6.23155V3.71929ZM16.3431 0.955811H13.0772C12.6609 0.955811 12.3235 1.29324 12.3235 1.70949V2.21194C12.3235 2.62818 12.6609 2.96561 13.0771 2.96561H15.5894C16.0056 2.96561 16.3431 3.30305 16.3431 3.71929V6.23155C16.3431 6.64779 16.6805 6.98522 17.0968 6.98522H17.5992C18.0155 6.98522 18.3529 6.64779 18.3529 6.23155V2.96561C18.3529 1.86022 17.4485 0.955811 16.3431 0.955811ZM16.3431 16.2806C16.3431 16.6968 16.0056 17.0342 15.5894 17.0342H13.0772C12.6609 17.0342 12.3235 17.3717 12.3235 17.7879V18.2904C12.3235 18.7066 12.6609 19.044 13.0771 19.044H16.3431C17.4485 19.044 18.3529 18.1396 18.3529 17.0342V13.7683C18.3529 13.3521 18.0155 13.0146 17.5992 13.0146H17.0968C16.6805 13.0146 16.3431 13.3521 16.3431 13.7683V16.2806Z"
        fill={theme.palette.icon.primary}
      />
      <path
        d="M5.91699 9.99994C5.91699 8.12329 7.43188 6.6084 9.30854 6.6084C11.1852 6.6084 12.7001 8.12329 12.7001 9.99994C12.7001 11.8766 11.1852 13.3915 9.30854 13.3915C7.43188 13.3915 5.91699 11.8766 5.91699 9.99994Z"
        fill={theme.palette.icon.secondary}
      />
    </SvgIcon>
  );
}

export default MeasureNavigationIcon;
