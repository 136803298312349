import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import domToImage from 'dom-to-image';
import { saveAs } from 'file-saver';
import { platformLink } from 'utils/links';
import { arrayBufferToBase64 } from 'utils/image';
import Loader from 'components/Loader';
import config from 'config';
import versionConstants from 'constants/version';
import osConstants from 'constants/os';
import { saveAndReturnFilePath } from 'platforms/adobe';
import { isAdobe, isIOS } from 'utils/platform';

const useStyles = makeStyles((theme) => ({
  container: {
    width: ({ size }) => `${size}px`,
    height: ({ size }) => `${size}px`,
    position: 'relative',
    cursor: 'pointer',
    transition: '.3s',

    '&:hover': {
      transform: 'scale(1.03)',
    },
  },
  template: {
    width: config.PICTURE_SIZE,
    height: config.PICTURE_SIZE,
    transformOrigin: 'top left',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  overlay: {
    position: 'absolute',
    top: theme.spacing(0),
    left: theme.spacing(0),
    bottom: theme.spacing(0),
    right: theme.spacing(0),
    zIndex: 10,
  },
}));

function ShareTemplate({ size, overlay, setIOSGeneratedImage }) {
  const classes = useStyles({ size });
  const templateRef = useRef(null);
  const [mobileDragging, setMobileDragging] = useState(false);
  const [isShareLoading, setIsShareLoading] = useState(false);

  const handleMobileDragging = (event) => {
    const { type } = event;
    if (type === 'touchstart') setMobileDragging(false);
    if (type === 'touchmove' && !mobileDragging) setMobileDragging(true);
  };

  const handleSelectedTemplate = async (event) => {
    if (mobileDragging) return;
    event.preventDefault();

    setIsShareLoading(true);

    const width = config.PICTURE_SIZE;
    const height = config.PICTURE_SIZE;
    const title = 'Pantone Connect Palette';
    const cloneNode = templateRef.current.cloneNode(true);
    cloneNode.style.width = width;
    cloneNode.style.height = height;
    cloneNode.style.transform = 'scale(1)';

    document.body.appendChild(cloneNode);

    const overlayNode = cloneNode.querySelector(`.${classes.overlay}`);
    const overlayPng = await domToImage.toPng(overlayNode);
    const overlayImg = document.createElement('img');
    overlayImg.src = overlayPng;
    overlayImg.style.position = 'absolute';
    overlayImg.style.top = 0;
    overlayImg.style.zIndex = 10;
    overlayNode.replaceWith(overlayImg);

    async function createImageFromDom(imgBlob) {
      document.body.removeChild(cloneNode);

      // logic
      const imageData = arrayBufferToBase64(await imgBlob.arrayBuffer());
      if (isIOS) {
        setIOSGeneratedImage(imageData);
      } else if (isAdobe) {
        const filePath = saveAndReturnFilePath(title, imageData);
        // timeout needed to run the code after the image was
        setTimeout(() => {
          platformLink(`file:///${filePath}`, '_blank');
        }, 0);
        //
      } else {
        saveAs(imgBlob, `${title}.png`);
      }
      setIsShareLoading(false);
    }

    setTimeout(() => {
      // Duplicate operations cause on iOS first time domToImage produces a blank picture
      domToImage
        .toBlob(cloneNode, { width, height })
        .then(async (imgBlobAndroid) => {
          if (
            config.VERSION === versionConstants.MOBILE &&
            config.OS === osConstants.ANDROID
          ) {
            createImageFromDom(imgBlobAndroid);
          } else {
            domToImage
              .toBlob(cloneNode, { width, height })
              .then(async (imgBlob) => createImageFromDom(imgBlob));
          }
        });
    }, 0);
  };

  return (
    <div className={classes.container}>
      {isShareLoading ? (
        <Loader big />
      ) : (
        <div
          role="presentation"
          ref={templateRef}
          className={classNames({
            [classes.template]: true,
          })}
          style={{ transform: `scale(${size / config.PICTURE_SIZE})` }}
          onClick={handleSelectedTemplate}
          onTouchStart={handleMobileDragging}
          onTouchMove={handleMobileDragging}
          onTouchEnd={handleSelectedTemplate}
        >
          <div className={classes.overlay}>{overlay}</div>
        </div>
      )}
    </div>
  );
}

ShareTemplate.propTypes = {
  overlay: PropTypes.node.isRequired,
  size: PropTypes.number.isRequired,
  setIOSGeneratedImage: PropTypes.func.isRequired,
};

export default ShareTemplate;
