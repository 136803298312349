import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';

import colors from 'theme/colors';

const useStyles = makeStyles((theme) => ({
  avatarContainer: {
    overflow: 'hidden',
    display: 'flex',
    alignItems: 'center',
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
    color: ({ owner }) =>
      theme.palette.getContrastText(colors.Blue[owner ? 600 : 300]),
    background: ({ owner }) => colors.Blue[owner ? 600 : 300],
    fontSize: `${theme.spacing(0.125)}rem`,
  },
  email: {
    overflow: 'hidden',
    flexGrow: 1,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  removeButton: {
    color: colors.JetBlack[400],
  },
}));

function CollaboratorsListItem({ email, owner, initials, onRemove }) {
  const classes = useStyles({ owner });
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.avatarContainer}>
        <Avatar className={classes.avatar}>{initials}</Avatar>
        <Typography className={classes.email} variant="body1">
          {email}
        </Typography>
      </div>
      {!owner && (
        <div>
          <Button
            onClick={onRemove}
            size="small"
            className={classes.removeButton}
            data-id="remove-collaborator-button"
            data-testid="remove-collaborator-button"
          >
            {t('labels.remove')}
          </Button>
        </div>
      )}
    </>
  );
}

CollaboratorsListItem.defaultProps = {
  owner: false,
  onRemove: () => {},
};

CollaboratorsListItem.propTypes = {
  owner: PropTypes.bool,
  initials: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
};

export default CollaboratorsListItem;
