export default {
  storage: {
    authOpenUrl: 'authOpenUrl',
  },
  events: {
    scopes: {
      APPLICATION: 'APPLICATION',
    },
    auth: {
      PANEL_OPENED: 'pantone.auth.panelOpened',
      START: 'pantone.auth.start',
      COMPLETE: 'pantone.auth.complete',
      ERROR: 'pantone.auth.error',
    },
  },
};
