import config from '~/v2/config';
import { getLocalStorage } from '~/v3/utils/storage';
import { Palette } from '../types/palette';
import { Color } from '../types/pantone-color';

type ParsedPalette = {
  colors: Color[];
  name: string;
  palette: Palette;
  unsavedChanges: boolean;
};

export const getPaletteFromLocalStorage = () => {
  const palette = getLocalStorage<string>(config.paletteWorkspace);
  let parsedPalette: ParsedPalette | null;

  try {
    parsedPalette = JSON.parse(String(palette)) as ParsedPalette;
  } catch {
    parsedPalette = null;
  }

  return parsedPalette ?? null;
};
