import { getUnixTime, isWithinInterval } from 'date-fns';
import { Subscription } from '~/v3/types/subsription';
import { isNotNull } from '~/v3/typeguards/is-not-null';
import i18next from 'i18next';
import filterCouponCodeSubscriptionsByProduct from '~/v3/utils/filter-coupon-code-subscriptions-by-product';
import { CouponCode, CouponCodeType, Products } from '~/v3/types/coupons';

type CouponValidation = {
  isValid: boolean;
  message?: string;
};

function extractUsedCouponIds(subscriptions: Subscription[]): string[] {
  const coupons = subscriptions.flatMap((sub) => sub.coupons).filter(isNotNull);

  return coupons.map((coupon) => coupon.id);
}

export function extractUsedCouponIdsByProduct({
  coupon,
  subscription,
}: {
  coupon: CouponCode;
  subscription: Subscription | undefined;
}): string[] {
  if (!subscription) {
    return [];
  }

  if (!coupon?.products) {
    coupon.products = [Products.CONNECT_PREMIUM];
  }

  const extractedCouponIds: string[] = [];
  coupon?.products?.forEach((product) => {
    const usedCouponsForProduct = filterCouponCodeSubscriptionsByProduct(
      subscription.subscriptions,
      product
    );
    extractedCouponIds.push(...extractUsedCouponIds(usedCouponsForProduct));
  });
  return extractedCouponIds;
}

export function isSingleUseCouponValid(coupon: CouponCode): CouponValidation {
  if (coupon?.isUsed) {
    return { isValid: false, message: i18next.t('labels.alreadyRedeemed') };
  }
  return { isValid: true };
}

export function isMultiUseCouponValid({
  coupon,
  subscription,
}: {
  coupon: CouponCode;
  subscription: Subscription;
}): CouponValidation {
  const today = getUnixTime(Date.now()) * 1000;

  if (coupon.usagesLeft != null && coupon.usagesLeft <= 0) {
    return { isValid: false, message: i18next.t('labels.couponCodeExpired') };
  }

  if (coupon.validFrom != null && coupon.validTo != null) {
    if (
      !isWithinInterval(today, {
        start: Number(coupon.validFrom),
        end: Number(coupon.validTo),
      })
    ) {
      return { isValid: false, message: i18next.t('labels.couponCodeExpired') };
    }
  }

  if (subscription) {
    const extractedCouponIds: string[] = extractUsedCouponIdsByProduct({
      coupon,
      subscription,
    });

    const userHasAlreadyUsedCoupon = extractedCouponIds.includes(coupon.id);

    if (userHasAlreadyUsedCoupon) {
      return {
        isValid: false,
        message: i18next.t('labels.alreadyRedeemed'),
      };
    }
  }

  return { isValid: true };
}

function validateCouponCode({
  coupon,
  subscription,
}: {
  coupon: CouponCode;
  subscription: Subscription;
}): CouponValidation {
  if (coupon.type) {
    if (coupon.type === CouponCodeType.SINGLE_USE) {
      return isSingleUseCouponValid(coupon);
    }

    if (coupon.type === CouponCodeType.MULTI_USE) {
      return isMultiUseCouponValid({
        coupon,
        subscription,
      });
    }
  }

  return isSingleUseCouponValid(coupon);
}

export default validateCouponCode;
