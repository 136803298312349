import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { useFeatureFlags } from '@xrite/feature-flags-client';
import AuthenticatedAppShell from '~/v2/AuthenticatedAppShell';
import Loader from '~/v2/components/Loader';
import RoutePath from '~/v2/constants/route';
import PaletteWorkspace from '~/v2/features/PaletteWorkspace/Page';
import { useGetSubscriptionUrlQuery } from '~/v2/services/subscriptionApi';
import MainLayout from '~/v3/components/shared/layouts/main-layout';
import CCPAPolicy from '~/v3/pages/ccpa-policy';
import CookiePolicy from '~/v3/pages/cookie-policy';
import PrivacyPolicy from '~/v3/pages/privacy-policy';
import { ProductType } from '~/v3/paywall/constants';

const ColorInfo = React.lazy(() =>
  import(/* webpackChunkName: "ColorDetail" */ '~/v3/color-info')
);
const ColorPickerV3 = React.lazy(() =>
  import(/* webpackChunkName: "ColorPickerContainer" */ '~/v3/pick')
);

const ColorStory = React.lazy(() =>
  import(/* webpackChunkName: "ColorStory" */ '~/v2/pages/ColorStory')
);

const ColorExtractV3 = React.lazy(() =>
  import(/* webpackChunkName: "ColorExtract" */ '~/v3/extract')
);
const ConvertV3 = React.lazy(() =>
  import(/* webpackChunkName: "Convert" */ '~/v3/pages/convert')
);

const Statistics = React.lazy(() =>
  import(/* webpackChunkName: "Statistics" */ '~/v3/statistics')
);

const ColorSearch = React.lazy(() =>
  import(/* webpackChunkName: "ColorSearch" */ '~/v2/pages/Search')
);

const Profile = React.lazy(() =>
  import(/* webpackChunkName: "Profile" */ '~/v2/pages/Profile')
);
const Measure = React.lazy(() =>
  import(/* webpackChunkName: "Measure" */ '~/v2/pages/Measure')
);
const Trending = React.lazy(() =>
  import(/* webpackChunkName: "Trending" */ '~/v2/pages/Trending')
);
const About = React.lazy(() =>
  import(/* webpackChunkName: "Faq" */ '~/v2/pages/About')
);
const AppOffline = React.lazy(() =>
  import(/* webpackChunkName: "Faq" */ '~/v2/pages/Offline')
);
const Coupons = React.lazy(() =>
  import(/* webpackChunkName: "CouponCode" */ '~/v2/pages/CouponCodes')
);
const CompanyCouponCodes = React.lazy(() =>
  import(
    /* webpackChunkName: "CompanyCouponCodes" */ '~/v2/pages/CompanyCouponCodes'
  )
);

const CouponsActivation = React.lazy(() =>
  import(/* webpackChunkName: "CouponCode" */ '~/v3/coupons')
);

const Eula = React.lazy(() =>
  import(/* webpackChunkName: "EULA" */ '~/v2/pages/EULA')
);

const V3CancelSubscription = React.lazy(() =>
  import(
    /* webpackChunkName: "Survey" */ '~/v3/pages/cancel-subscription-pages/cancel-subscription'
  )
);
const CancelSubscriptionFeedback = React.lazy(() =>
  import(
    /* webpackChunkName: "CancelSubscriptionFeedback" */ '~/v3/pages/cancel-subscription-pages/cancel-subscription-confirmation'
  )
);
const CancelSubscriptionOfferAppliedConfirmation = React.lazy(() =>
  import(
    /* webpackChunkName: "CancelSubscriptionFeedback" */ '~/v3/pages/cancel-subscription-pages/offer-applied-confirmation'
  )
);

const Visualize = React.lazy(() =>
  import(/* webpackChunkName: "Visualize" */ '~/v3/pages/visualize')
);

const Trends = React.lazy(() =>
  import(/* webpackChunkName: "Trends" */ '~/v3/trends/pages/trends')
);

const ArticleV3 = React.lazy(() =>
  import(/* webpackChunkName: "Article" */ '~/v3/trends/pages/article')
);

const Paywall = React.lazy(() =>
  import(/* webpackChunkName: "Paywall" */ '~/v3/paywall/pages')
);

const MyDashboard = React.lazy(() =>
  import(/* webpackChunkName: "MyPalettes" */ '~/v3/pages/my-dashboard')
);

const PaywallProtectedPage = React.lazy(() =>
  import(
    /* webpackChunkName: "Paywall" */ '~/v3/paywall/paywall-protected-page'
  )
);

const RedirectionHandler = React.lazy(() =>
  import(
    /* webpackChunkName: "Paywall" */ '~/v3/paywall/components/redirection-handler'
  )
);

function V3AppRoutes() {
  const { data: userSubscriptionURL } = useGetSubscriptionUrlQuery(undefined);
  const { getFeatureFlags } = useFeatureFlags();
  const isUnlimitedCouponCodeEnabled =
    getFeatureFlags?.isUnlimitedCouponCodeEnabled;

  return (
    <React.Suspense fallback={<Loader medium />}>
      <Switch>
        <Route path={RoutePath.paletteUpdate}>
          <MainLayout>
            <ColorPickerV3 />
          </MainLayout>
        </Route>
        <Route path={RoutePath.colorPicker}>
          <MainLayout>
            <ColorPickerV3 />
          </MainLayout>
        </Route>
        <Route path={RoutePath.colorStory}>
          <AuthenticatedAppShell>
            <ColorStory />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.colorExtract}>
          <MainLayout>
            <PaletteWorkspace>
              <ColorExtractV3 />
            </PaletteWorkspace>
          </MainLayout>
        </Route>
        <Route path={RoutePath.colorConvert}>
          <MainLayout>
            <ConvertV3 />
          </MainLayout>
        </Route>
        <Route path={RoutePath.colorInfo}>
          <AuthenticatedAppShell>
            <PaywallProtectedPage productType={ProductType.PREMIUM}>
              <PaletteWorkspace>
                <ColorInfo />
              </PaletteWorkspace>
            </PaywallProtectedPage>
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.statistics}>
          <AuthenticatedAppShell>
            <Statistics />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.trending}>
          <AuthenticatedAppShell>
            <PaletteWorkspace>
              <Trending />
            </PaletteWorkspace>
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.search}>
          <AuthenticatedAppShell>
            <PaletteWorkspace>
              <ColorSearch />
            </PaletteWorkspace>
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.myProfile}>
          <AuthenticatedAppShell>
            <Profile />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.myPalettes}>
          <AuthenticatedAppShell>
            <MyDashboard />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.colorMeasure}>
          <AuthenticatedAppShell>
            <PaletteWorkspace>
              <Measure />
            </PaletteWorkspace>
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.about}>
          <AuthenticatedAppShell>
            <About />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.legacyCouponCodes}>
          <AuthenticatedAppShell>
            <Coupons />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.companyCouponCodes}>
          <AuthenticatedAppShell>
            <CompanyCouponCodes />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.bundleCodes}>
          <Redirect to={RoutePath.couponCodeActivation} />
        </Route>
        <Route path={RoutePath.couponCodeActivation}>
          {isUnlimitedCouponCodeEnabled && (
            <MainLayout>
              <CouponsActivation />
            </MainLayout>
          )}
        </Route>
        <Route path={RoutePath.offline}>
          <AuthenticatedAppShell>
            <AppOffline />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.eula}>
          <AuthenticatedAppShell>
            <Eula />
          </AuthenticatedAppShell>
        </Route>
        <Route path={RoutePath.paywall}>
          {/* NOTE: If replacing the Paywall component, replace it in every place that it is used */}
          <MainLayout>
            <Paywall />
          </MainLayout>
        </Route>
        <Route path={RoutePath.privacyPolicy}>
          <MainLayout>
            <PrivacyPolicy />
          </MainLayout>
        </Route>
        <Route path={RoutePath.cookiePolicy}>
          <MainLayout>
            <CookiePolicy />
          </MainLayout>
        </Route>
        <Route path={RoutePath.ccpaPolicy}>
          <MainLayout>
            <CCPAPolicy />
          </MainLayout>
        </Route>
        <Route path={RoutePath.article}>
          <MainLayout>
            <ArticleV3 />
          </MainLayout>
        </Route>
        <Route path={RoutePath.insight}>
          <MainLayout>
            <Trends />
          </MainLayout>
        </Route>
        <Route path={RoutePath.scanGuide}>
          <Visualize />
        </Route>
        {userSubscriptionURL && [
          <Route
            key={RoutePath.cancelSubscription}
            exact
            path={RoutePath.cancelSubscription}
          >
            <AuthenticatedAppShell>
              <V3CancelSubscription />
            </AuthenticatedAppShell>
          </Route>,
          <Route
            key={RoutePath.cancelSubscriptionFeedback}
            exact
            path={RoutePath.cancelSubscriptionFeedback}
          >
            <AuthenticatedAppShell>
              <CancelSubscriptionFeedback />
            </AuthenticatedAppShell>
          </Route>,
          <Route
            key={RoutePath.cancelSubscriptionOfferApplied}
            exact
            path={RoutePath.cancelSubscriptionOfferApplied}
          >
            <AuthenticatedAppShell>
              <CancelSubscriptionOfferAppliedConfirmation />
            </AuthenticatedAppShell>
          </Route>,
        ]}
        <RedirectionHandler />
      </Switch>
    </React.Suspense>
  );
}

export default V3AppRoutes;
