export type FastSpringOrder = {
  id: string;
  reference: string;
};

export enum FastSpringProduct {
  COLOR_PREMIUM_MONTHLY = 'single-seat-monthly-14-99',
  COLOR_PREMIUM_YEARLY = 'single-seat-yearly-89-99',
  INSIDER_PREMIUM_MONTHLY = 'Color-Insight-Single-Seat-Monthly',
  INSIDER_PREMIUM_YEARLY = 'Color-Insight-Single-Seat-Yearly',
  MULTISEAT_YEARLY = 'pantone-connect-yearly-multiseat-89-99',
}
