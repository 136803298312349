import { useMutation } from '@tanstack/react-query';
import { cancelSubscription } from '~/v3/api/subscriptions-api';

function useCancelSubscription() {
  const { mutateAsync: cancelSubscriptionsFn, ...mutation } = useMutation({
    mutationFn: cancelSubscription,
  });

  return { cancelSubscription: cancelSubscriptionsFn, ...mutation };
}

export default useCancelSubscription;
