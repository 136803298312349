import React from 'react';
import Typography from '@mui/material/Typography';
import MUIMenuItem from '@mui/material/MenuItem';
import { CSSProperties } from '@mui/styles';
import useRouter from '~/v3/hooks/use-router';
import useTracker from '~/v2/analytics/useTracker';
import { useMenu } from '~/v3/components/shared/providers/menu-provider';
import { SubMenuItemType } from '../model/types';

const baseSubMenuItemStyle: CSSProperties = {
  opacity: 0.625,
  '&:hover': {
    opacity: 1,
  },
};
const selectedSubMenuItemStyle: CSSProperties = {
  opacity: 1,
};

function SubMenuItem({
  routesTo,
  event,
  icon,
  text,
  dataTestid,
  children,
}: Readonly<SubMenuItemType>) {
  const { push, pathname: currentRoute } = useRouter();
  const ga = useTracker();
  const { onCloseMenu } = useMenu();

  const onNavigate = () => {
    push(routesTo);
    ga.trackMenuEvent(event);
    onCloseMenu();
  };

  const isSelected = currentRoute.includes(routesTo);

  return (
    <MUIMenuItem
      data-testid={dataTestid}
      onClick={onNavigate}
      sx={{
        ...baseSubMenuItemStyle,
        ...(isSelected && selectedSubMenuItemStyle),
      }}
    >
      {icon}
      <Typography>{text}</Typography>
      {children}
    </MUIMenuItem>
  );
}

export default SubMenuItem;
