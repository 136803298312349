import React from 'react';

import Menu, { MenuProps } from '@mui/material/Menu';

import { styled, SxProps, Theme } from '@mui/material';

import { useMenu } from '~/v3/components/shared/providers/menu-provider';

const StyledSubMenu = styled(Menu)(({ theme }: { theme: Theme }) => ({
  '& .MuiPaper-root': {
    backgroundColor: theme.palette.background.paper,
    backgroundImage: 'none',
    borderTopLeftRadius: theme.spacing(0.375),
    borderTopRightRadius: theme.spacing(0.375),
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    boxShadow:
      'rgba(9, 30, 66, 0.125) 0px 1px 1px, rgba(9, 30, 66, 0.065) 0px 0px 1px 1px',
    '& .MuiSvgIcon-root': {
      marginRight: theme.spacing(1.5),
    },
  },
}));

type SubMenuProps = {
  children: React.ReactNode;
  sx?: SxProps;
} & Omit<MenuProps, 'open'>;

function SubMenu({ children, sx, ...rest }: SubMenuProps) {
  const { isMenuOpen, onCloseMenu, anchorEl } = useMenu();

  return (
    <StyledSubMenu
      transitionDuration={250}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'left',
      }}
      anchorEl={anchorEl.current}
      open={isMenuOpen}
      onClose={onCloseMenu}
      sx={sx}
      {...rest}
    >
      {children}
    </StyledSubMenu>
  );
}

export default SubMenu;
