import {
  amplifyApiKeySettings,
  amplifyUserPoolSettings,
} from '../../utils/amplify';

const initApi = async (unAuthUsers) => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');
  API.configure(unAuthUsers ? amplifyApiKeySettings : amplifyUserPoolSettings);
  return { API, graphqlOperation };
};

export const WRONG_COUPON_CODE_MESSAGE = 'Wrong coupon code';

const couponCodeFetchers = {
  getCouponCode: async (code) => {
    const { API, graphqlOperation } = await initApi(true);
    if (!code) return null;
    try {
      const response = await API.graphql(
        graphqlOperation(`{
            getCouponCode(id: "${code}") {
              isUsed
              id
              products
              days
              validFrom
	            validTo
              usagesLeft
	            type
            }
          }`)
      );
      if (response?.data?.getCouponCode === null) {
        throw new Error(WRONG_COUPON_CODE_MESSAGE);
      }
      return { data: response?.data?.getCouponCode };
    } catch (error) {
      return { error };
    }
  },
  addCouponCodeToUser: async (code) => {
    const { API, graphqlOperation } = await initApi();
    if (!code) return null;
    try {
      const response = await API.graphql(
        graphqlOperation(`mutation AddCouponCode {
          addCouponCode(couponCode: "${code}") {
            result
          }
          }`)
      );

      return { data: response?.data?.addCouponCode };
    } catch (error) {
      return { error };
    }
  },
};

export default couponCodeFetchers;
