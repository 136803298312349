import { Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracker from '~/v2/analytics/useTracker';
import RoutePathname from '~/v2/constants/route';
import { headerNavigationEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import TrendNavigationIcon from '~/v3/components/shared/icons/trend-navigation-icon';
import { useFeatureFlags } from '@xrite/feature-flags-client';

import ColorInsiderIcon from '~/v3/components/shared/icons/color-insider-icon';
import BaseNavigationItem from '../base-navigation-item';
import {
  StyledNavigationItem,
  StyledNavigationText,
} from '../../../header.elements';
import { insiderChipColor } from '../../../model/constants';

function InsightNavigationItem() {
  const trendsRoute = RoutePathname.insight;

  const { pathname: currentRoute, push } = useRouter();

  const { getFeatureFlags } = useFeatureFlags();
  const isArticlesNewCategoriesEnabled =
    getFeatureFlags?.isArticlesNewCategoriesEnabled;

  const { t } = useTranslation();

  const ga = useTracker();

  const onNavigateToTrendsPage = () => {
    push(trendsRoute);
    ga.trackEvent({
      ...headerNavigationEvent,
      EVENT_LABEL: 'trend_nav',
    });
  };

  const isSelected = trendsRoute === currentRoute;

  return (
    <BaseNavigationItem
      isSelected={isSelected}
      as={StyledNavigationItem}
      icon={
        isArticlesNewCategoriesEnabled ? (
          <ColorInsiderIcon />
        ) : (
          <TrendNavigationIcon />
        )
      }
      onClick={onNavigateToTrendsPage}
      text={<StyledNavigationText>{t('routes.insight')}</StyledNavigationText>}
      tag={
        !isArticlesNewCategoriesEnabled && (
          <Chip
            label={t('labels.new')}
            size="small"
            variant="outlined"
            sx={(theme) => ({
              marginLeft: theme.spacing(1),
              borderColor: theme.palette.blue.main,
              color: theme.palette.blue.main,
              backgroundColor: insiderChipColor,
            })}
          />
        )
      }
      dataId="insight"
    />
  );
}

export default InsightNavigationItem;
