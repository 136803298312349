import { Promotion } from '~/v3/types/promotion';
import {
  EXPLORE_PROMOTION_BASE_PATH,
  PROMOTION_MODAL_LOCAL_STORAGE_KEY,
  PROMOTION_MODAL_SESSION_STORAGE_KEY,
} from '../model/constants';

export function getSessionStorageKey(promotion: Promotion) {
  return `${promotion}-${PROMOTION_MODAL_SESSION_STORAGE_KEY}`;
}

export function getLocalStorageKey(promotion: Promotion) {
  return `${promotion}-${PROMOTION_MODAL_LOCAL_STORAGE_KEY}`;
}

export function getExplorePromotionUrl(promotion: Promotion) {
  let path;
  switch (promotion) {
    case Promotion.COY:
      path = 'the-story-of-color-of-the-year-2025';
      break;
    case Promotion.INSIDER:
      path = 'the-power-of-color';
      break;
    default:
      path = '';
      break;
  }

  return `${EXPLORE_PROMOTION_BASE_PATH}/${path}`;
}
