/* eslint-disable max-len */
// TODO: REFACTOR THIS IN BETTER WAY
import queryString from 'query-string';
import { gte } from 'semver';
import { addDays } from 'date-fns';
import versionConstants from 'constants/version';
import { isPhotoshop } from 'platforms/adobe';
import { getCurrentLocation } from './utils/urls';
import {
  isMobile,
  checkMinimumVersions,
  PLATFORM_VERSION,
} from './utils/platform';

const DIV_NAME = 'pantoneConnect';
const rootDiv = document.getElementById(DIV_NAME);
const urlParams = queryString.parse(
  window.location.search ||
  window.location.hash.substring(window.location.hash.indexOf('?'))
);

const currentLocation = getCurrentLocation();

let api;
const oauth = {
  scope: ['email', 'profile', 'openid'],
  responseType: 'code',
  redirectSignIn: currentLocation,
  redirectSignOut: currentLocation,
  // urlOpener: () => window.history.go(-1),
};

let GOOGLE_ANALYTICS_TRACKING_ID = 'G-MREHS7JK7Y';

if (process.env.NODE_ENV === 'production') {
  if (process.env.BETA) {
    api = {
      API_URI:
        'https://pkm3na5rsjg6lhjwbbokljb7sq.appsync-api.us-east-1.amazonaws.com/graphql',
      API_REALTIME_URI:
        'wss://pkm3na5rsjg6lhjwbbokljb7sq.appsync-realtime-api.us-east-1.amazonaws.com/graphql',
      REGION: 'us-east-1',
      // API KEY Auth
      API_KEY: 'da2-bm5ffzhhkjbw5mw3gp2qyfctli',
      // IAM Auth
      IDENTITY_POOL_ID: 'us-east-1:0c09a4ec-8817-496f-b37d-3deef005f08b',
      // Cognito User Pool Auth
      USER_POOL_ID: 'us-east-1_Kz8N0hVys',
      APP_CLIENT_ID: '6u00f9dt8k5gsrqrbmq50e5pn0',
      KINESIS_STREAM_NAME: 'dataplatform-pantone_connect-kinesis_analytics',
    };
    oauth.domain = 'authconnect.pantone.com';
  } else if (process.env.UAT) {
    api = {
      API_URI:
        'https://ajwuyywqbzdpvnymnmwg7o3xq4.appsync-api.eu-central-1.amazonaws.com/graphql',
      API_REALTIME_URI:
        'https://ajwuyywqbzdpvnymnmwg7o3xq4.appsync-api.eu-central-1.amazonaws.com/graphql',
      REGION: 'eu-central-1',
      // API KEY Auth
      API_KEY: 'da2-7mzufkpodjf2zarnhlnoyltisi',
      // IAM Auth
      IDENTITY_POOL_ID: 'eu-central-1:ed6e3ac3-eafd-44f9-97a0-0dd3ada6d3ae',
      // Cognito User Pool Auth
      USER_POOL_ID: 'eu-central-1_4sYdprHmX',
      APP_CLIENT_ID: '5r9ivfff68kjavnr1n32lhtuet',
      KINESIS_STREAM_NAME: 'dataplatform-pantone_connect-kinesis_analytics',
    };
    oauth.domain = 'pantone-users.auth.eu-central-1.amazoncognito.com';
  } else {
    // PRODUCTION ENV
    GOOGLE_ANALYTICS_TRACKING_ID = 'G-WS0H472QH3';
    api = {
      API_URI: 'https://d3c0pp0tpli4xy.cloudfront.net',
      API_REALTIME_URI:
        'https://4n6dg5ccsfct3lzfssu34boemq.appsync-api.us-east-1.amazonaws.com/graphql',
      REGION: 'us-east-1',
      // API KEY Auth
      API_KEY: 'da2-sa3lsp2tkzhj3c2ysxbdprl73e',
      // IAM Auth
      IDENTITY_POOL_ID: 'us-east-1:0c09a4ec-8817-496f-b37d-3deef005f08b',
      // Cognito User Pool Auth
      USER_POOL_ID: 'us-east-1_Kz8N0hVys',
      APP_CLIENT_ID: '6u00f9dt8k5gsrqrbmq50e5pn0',
      KINESIS_STREAM_NAME: 'dataplatform-pantone_connect-kinesis_analytics',
    };
    oauth.domain = 'authconnect.pantone.com';
  }
} else if (process.env.PERSONAL) {
  api = {
    API_URI:
      process.env.PERSONAL_APPSYNC_URL ??
      'https://d2eiiklj293bqc.cloudfront.net',
    API_REALTIME_URI:
      process.env.PERSONAL_APPSYNC_URL ??
      'https://2kotxbvfc5dhfmdsywvntcjqnu.appsync-api.eu-central-1.amazonaws.com/graphql',
    REGION: 'eu-central-1',
    // API KEY Auth
    API_KEY: process.env.PERSONAL_APPSYNC_URL
      ? process.env.PERSONAL_APPSYNC_API_KEY
      : 'da2-dzs357pjunhhbetowwyqnrf6xq',
    // IAM Auth
    IDENTITY_POOL_ID: 'eu-central-1:ed6e3ac3-eafd-44f9-97a0-0dd3ada6d3ae',
    // Cognito User Pool Auth
    USER_POOL_ID: 'eu-central-1_4sYdprHmX',
    APP_CLIENT_ID: '6ac97ndpmp15t717khejhtre6n',
    KINESIS_STREAM_NAME: 'dataplatform-pantone_connect-kinesis_analytics',
    PERSONAL_APP_CONFIG_ENV_NAME: process.env.PERSONAL_APP_CONFIG_ENV_NAME,
  };
  oauth.domain = 'pantone-users.auth.eu-central-1.amazoncognito.com';
} else {
  // DEV ENV
  api = {
    API_URI: 'https://d2eiiklj293bqc.cloudfront.net',
    API_REALTIME_URI:
      'https://2kotxbvfc5dhfmdsywvntcjqnu.appsync-api.eu-central-1.amazonaws.com/graphql',
    REGION: 'eu-central-1',
    // API KEY Auth
    API_KEY: 'da2-dzs357pjunhhbetowwyqnrf6xq',
    // IAM Auth
    IDENTITY_POOL_ID: 'eu-central-1:ed6e3ac3-eafd-44f9-97a0-0dd3ada6d3ae',
    // Cognito User Pool Auth
    USER_POOL_ID: 'eu-central-1_4sYdprHmX',
    APP_CLIENT_ID: '6ac97ndpmp15t717khejhtre6n',
    KINESIS_STREAM_NAME: 'dataplatform-pantone_connect-kinesis_analytics',
  };
  oauth.domain = 'pantone-users.auth.eu-central-1.amazoncognito.com';
}

oauth.clientId = api.APP_CLIENT_ID;
api.oauth = oauth;

const MEASURE = urlParams.measure || rootDiv?.getAttribute('measure') || null;

const CORE_VERSION = process.env.APP_VERSION;
const CORE_TIMESTAMP = process.env.BUILD_TIMESTAMP;

const VERSION =
  urlParams.version ||
  rootDiv?.getAttribute('version') ||
  versionConstants.WEBAPP;
const OS = urlParams.os || rootDiv?.getAttribute('os');

const BASE_URL_PATH = '/color-finder';
const BASE_LOCAL_PATH = process.env.BASE_PATH || '';
const ADOBE_EXTENSION_ID_BASE =
  process.env.NODE_ENV === 'production'
    ? 'com.pantone.v1.1'
    : 'com.pantone.v1.1.dev';

const ADOBE_EXTENSION_ID = `${ADOBE_EXTENSION_ID_BASE}.app`;
const ADOBE_EXTENSION_ID_AUTH = `${ADOBE_EXTENSION_ID_BASE}.auth`;

// In order to use the new features, the platform version must be greater than or equal to 1.0.9
export const isEqualOrGreaterThanOneZeroNineVersion = gte(
  PLATFORM_VERSION || '0.0.0',
  '1.0.9'
);

const themeModes = {
  light: 'Light',
  dark: 'Dark',
};

let HOTJAR_ID = '';

if (process.env.NODE_ENV === 'production') {
  HOTJAR_ID = '2225551';
}

if (process.env.UAT || process.env.BETA) {
  HOTJAR_ID = '5124058';
}

const config = {
  THEME_ENABLED: false,
  themeModes,
  V3_ENABLED: true,
  V3_HEADER_ENABLED: true,
  DIV_NAME,
  MEASURE,
  VERSION,
  OS,
  CORE_VERSION,
  CORE_TIMESTAMP,
  HOSTNAME: rootDiv?.getAttribute('hostname') || 'pantone.com',
  SINGLE_SIGN_ON_URL: `https://${oauth.domain}/oauth2/authorize?redirect_uri=${currentLocation}&response_type=code&client_id=${api.APP_CLIENT_ID}`,
  EVENTS: {
    DEVICE_SUPPORTED: 'deviceSupported',
  },
  api,
  ADOBE_EXTENSION_ID_BASE,
  ADOBE_EXTENSION_ID,
  ADOBE_EXTENSION_ID_AUTH,
  GOOGLE_ANALYTICS_TRACKING_ID,
  COY_COLOR_CODE: ['17-1230 TCX', '17-1230 TPG'],
  COY_NAME_2024: 'Peach Fuzz',
  COY_CODE_2024: '13-1023',
  COY_NAME_2025: 'Mocha Mousse',
  COY_CODE_2025: '17-1230',
  HOTJAR_ID,
  BASE_URL_PATH,
  BASE_LOCAL_PATH,
  CARDS_BAR_MAX_COLORS: 40,
  LOCAL_PANTONE_BOOK: 'pantoneBook',
  LOCAL_PLATFORM_VERSION: 'platformVersion',
  LOCAL_COLORS_STORE: 'workspaceColors',
  WORKSPACE_PALETTE_ID_STORE: 'workspacePaletteId',
  LOCAL_LICENSE_AGREEMENT: 'licenseAgreement',
  LOCAL_COOKIES_AGREEMENT: 'cookiesAgreement',
  DARK_THEME_MODE_STORE: 'darkThemeMode',
  PANTONE_OPENED_APP_COUNT: 'pantone_opened_app_count',
  DAYS_OF_USAGE: 'days_of_usage',
  LAST_ACCESSED: 'last_accessed',
  SUBMITTED_RATING: 'SUBMITTED_RATING',
  RATE_US_CLICK_COUNT: 'rate_us_count',
  NEW_NOTIFICATION_MESSAGES: 'notificationsClosed',
  ADOBE_VERSION_STORE: 'extensionVersion',
  MEASURE_VIDEO_STORE: 'measureVideo',
  LOCAL_IS_MOBILE_ONBOARDING_DONE: 'isMobileOnboardingDone',
  LOCAL_IS_ONBOARDING_DONE: 'isOnboardingDone',
  SAMSUNG_INTERNET_CHECK: 'samsungInternetCheck',
  ANALYTIC_COOKIE: 'TRACKER_CONSENT_ANALYTICAL',
  UNAUTH_SESSION_STORAGE: 'UNAUTH_USER_SESSION',
  APP_OPEN_COUNT_FOR_FEEDBACK: 8,
  FEEDBACK_POPUP_TIMEOUT: 7000,
  AUTH_ENABLED: true,
  AUTH_COOKIE_EXPIRES: addDays(new Date(), 32),
  SUBSCRIPTION_ENABLED: false,
  MEASUREMENT_ENABLE: false,
  SAMSUNG_INTERNET_CHECK_ENABLED: true,
  PICTURE_SIZE: 1000,
  DEVICE_SUPPORT_ENABLED:
    isMobile &&
    checkMinimumVersions({
      android: '1.6.4',
      ios: '1.0.112',
    }),
  FASTPRING_PRODUCT: 'pantone-extension',
  // Authentication settings
  PASSWORD_MIN_LENGTH: 8,
  PANTONE_NOREPLAY_EMAIL: 'noreply@pantone.com',
  FASTSPRING_ACCESS_KEY: 'B6WNBHOQT02OVK_MKLSS1G',
  FASTSPRING_STOREFRONT_DOMAIN:
    process.env.NODE_ENV === 'production' && !process.env.UAT
      ? 'pantone.onfastspring.com'
      : 'pantone.test.onfastspring.com',
  FASTSPRING_DEFAULT_STOREFRONT: 'popup-premium',
  FASTSPRING_CHINA_STOREFRONT: 'popup-premium-china',
  FASTSPRING_IS_CHECKOUT_ON_SAME_TAB_ENABLED: true,
  IN_APP_RATING_LINK: 'pantone://rate',
  DEVICE_SUPPORT_DEEP_LINK: 'pantone://devicesupport',
  APP_RATING_DAYS_SINCE_LAST_TRIGGER: 126,
  ACCOUNT_REQUIRED: 'accountRequired',
  ENABLE_EXTESNION_UPDATE_NOTIFICATION: false,
  MAX_NUMBER_OF_ALLOWED_DEVICES: 15,
  ENABLE_DEVICES_CHECK: true,
  DEVELOPMENT: process.env.NODE_ENV !== 'production',
  PAYWALL_ENABLED: true,
  HAS_VISITED_LANDING_PAGE: 'hasVisitedLandingPage',
  ONE_TRUST_API_KEY:
    process.env.UAT || process.env.DEV || process.env.BETA
      ? 'b63c4923-7fe6-4177-a5c6-b945a43c2bd9-test'
      : 'b63c4923-7fe6-4177-a5c6-b945a43c2bd9',
  HAS_USER_DEFINED_LANGUAGE: 'hasUserDefinedLanguage',
  USER_AGREED_WITH_TOU_AND_PRIVACY_POLICY: 'userAgreedWithTOUandPrivacyPolicy',
  REDIRECT_URL: 'redirectUrl',
  COUPON: 'coupon',
  PANTONE_URL: 'https://www.pantone.com/',
  UPDATE_ADOBE_EXTENSION_ARTICLE_URL:
    'https://intercom.help/pantone/en/articles/7038559-updating-pantone-connect-extension-for-adobe-version',
  COLOR_STORY_NOTIFICATION: 'colorStoryNotification',
  SUBSCRIPTION_STATUSES: {
    ACTIVE: 'active',
    COUPON_CODE_TRIAL: 'coupon_code_trial',
    TRIAL: 'trial',
    PREMIUM_PLUS: 'premium-plus',
    USER_NOT_SUBSCRIBED: 'user_not_subscribed',
    CANCELED: 'canceled',
    EXPIRED: 'expired',
    DEACTIVATED: 'deactivated',
  },
  SUBSCRIPTION_TYPE: {
    free: 'free',
    premium: 'premium',
  },
  CANCELLATION_SURVEY_ENABLED: true,
  MAX_FREEMIUM_PALETTES: 10,
  paletteWorkspace: 'paletteWorkspace',
  HAS_AGREED_TO_TERMS_AND_PRIVACY_POLICY: 'hasAgreedToTermsAndPrivacyPolicy',
  ADOBE_STOCK_SEARCH_OPTIONS: {
    limit: 20,
    imgWidth: 1000,
  },
  ADOBE_STOCK_SEARCH_API_KEY: 'baadea64891d4edaba82cfa4c91ce07e',
  ADOBE_STOCK_SEARCH_PRODUCT: 'Pantone',
  ADOBE_STOCK_SEARCH_URL: 'https://stock.adobe.io/Rest/Media/1/Search/Files',
  reCAPTCHASiteKey:
    process.env.BETA || process.env.UAT || process.env.DEV
      ? '6LfZL_MfAAAAAO3_ZFVf9dCPHxy4g3L_vWCf_EH-'
      : '6LdmR84UAAAAANevPMbHpozK5htO5-ErPeyIn4j3',
  reCAPTCHAEnabled: !process.env.BETA && !process.env.DEV,
  INTERCOM_ENABLED: true,
  INTERCOM_API_BASE: 'https://api-iam.intercom.io',
  INTERCOM_APP_ID: 'jl6innv3',
  SHOW_NEW_COLORS_2023: true,
  SEGMENT_ID:
    process.env.NODE_ENV === 'production'
      ? 'HRKtl52vEyyqiKbQG2TwFO2fN87xbYQ0'
      : '65fZ5MjsNvEfYFkSiByJCoNfJ6xknYD7',
  ENABLE_ADD_TO_CHANNEL: isPhotoshop && isEqualOrGreaterThanOneZeroNineVersion,
  ENABLE_SET_COLOR_ILLUSTRATOR: isEqualOrGreaterThanOneZeroNineVersion,
  LICENSE_MANAGER_GUIDE_ARTICLE_URL:
    'https://intercom.help/pantone/en/articles/6733140-subscription-and-license-manager-user-guide',
  MINIMUM_EXTENSION_VERSION: '1.0.10',
  API_REQUESTS_ANALYTICS_ENABLED: true,
  MEASURE_DIALOG_OPENED: 'measureDialogOpened',
};

export default config;
