import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  colors: [],
};

export const imageDownloadSlice = createSlice({
  name: 'imageDownload',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.colors = action.payload;
    },
    close: () => initialState,
  },
});

export const { open, close } = imageDownloadSlice.actions;

export default imageDownloadSlice.reducer;

export const selectColors = (state) => state.imageDownload.colors;
export const selectIsOpen = (state) => state.imageDownload.isOpen;
