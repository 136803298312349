export function readFromLocalStorage<T>(key: string): T | null {
  const data = localStorage.getItem(key);

  if (data) {
    return JSON.parse(data) as T;
  }
  return null;
}

export function removeFromLocalStorage(key: string): void {
  localStorage.removeItem(key);
}

export function writeToLocalStorage<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}
