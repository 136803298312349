import { create } from 'zustand';
import createSelectors from '~/v3/store/selectors';

type SidebarStore = {
  isSidebarOpen: boolean;
  actions: {
    onOpenSidebar: () => void;
    onCloseSidebar: () => void;
  };
};

const useSidebarStore = create<SidebarStore>()((set) => ({
  isSidebarOpen: false,
  actions: {
    onOpenSidebar: () => set({ isSidebarOpen: true }),
    onCloseSidebar: () => set({ isSidebarOpen: false }),
  },
}));

export const useSidebarStoreActions = () =>
  useSidebarStore((state) => state.actions);

export default createSelectors(useSidebarStore);
