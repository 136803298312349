import { Box, styled } from '@mui/material';
import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import { getPromotionBasePath } from '~/v3/components/features/promotions/lib/utils';
import { useMediaQueriesMobileScreen } from './lib/hooks/use-media-queries-mobile-screen';

export interface ContainerProps {
  promotion: Promotion;
  language: Language;
}

const area = PromotionsArea.AUTH;

export const AuthContainer = styled(Box)<ContainerProps>(({
  promotion,
  language,
}) => {
  const mediaQueries = useMediaQueriesMobileScreen({
    promotion,
    language,
    area,
  });

  return {
    overflow: 'hidden',
    height: 'auto',
    width: '100%',
    flex: 1,
    position: 'relative',
    background: `url(${getPromotionBasePath(
      promotion
    )}/images/${area}/${language}/${language}-320x500.png)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'top left',
    ...mediaQueries,
  };
});
