export const chipClickEventIds = {
  add: 'e8',
  info: 'e9',
  shades: 'e10',
  harmonies: 'e11',
  crossReference: 'e12',
  click: 'e15',
};

export const isChipClickEventId = (id) =>
  Object.values(chipClickEventIds).indexOf(id) !== -1;

export default { chipClickEventIds };
