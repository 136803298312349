import { createSlice } from '@reduxjs/toolkit';

export const colorStorySlice = createSlice({
  name: 'colorStory',
  initialState: {
    layoutId: 'template-3-wide-bottom',
    imagesCount: 3,
    images: Array(3).fill(''),
    imageDraggingOffset: Array(3).fill({ x: 0, y: 0 }),
    colorStoryPicture: null,
  },
  reducers: {
    setColorStoryPicture: (state, action) => {
      state.colorStoryPicture = action.payload;
    },
    setSelectedLayout: (state, action) => {
      state.layoutId = action.payload.layoutId;
      state.imagesCount = action.payload.imagesCount;
      state.images = Array(action.payload.imagesCount).fill('');
      state.imageDraggingOffset = Array(action.payload.imagesCount).fill({
        x: 0,
        y: 0,
      });
    },
    setSelectedImage: (state, action) => {
      const { index, image } = action.payload;
      state.images[index] = image;
      state.imageDraggingOffset[index] = { x: 0, y: 0 };
    },
    setImageDraggingOffset: (state, action) => {
      const { index, offset } = action.payload;
      const { offsetX, offsetY } = offset;

      let x = state.imageDraggingOffset[index].x + offsetX;
      let y = state.imageDraggingOffset[index].y + offsetY;

      // We set image dragging offset by CSS object-position property
      // By default, image is displayed with 50%, 50% as centered in img tag
      // User should only be able to drag it 0% ~ 100% to keep image inside the img tag
      // Meaining -50% ~ 50% offset can be applied to base 50%
      if (x > 50) {
        x = 50;
      } else if (x < -50) {
        x = -50;
      }

      if (y > 50) {
        y = 50;
      } else if (y < -50) {
        y = -50;
      }

      state.imageDraggingOffset[index] = { x, y };
    },
  },
});

export const {
  setColorStoryPicture,
  setSelectedLayout,
  setSelectedImage,
  setImageDraggingOffset,
} = colorStorySlice.actions;

export default colorStorySlice.reducer;
