import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

// We can use webpackHash because it is defined in webpack.config.js
// eslint-disable-next-line no-undef, camelcase
const webpackHash = __webpack_hash__;

i18n
  // load translation using http -> public/locales
  .use(Backend)
  // detect user language
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    fallbackLng: 'en',
    debug: process.env.NODE_ENV === 'development',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    backend: {
      loadPath: `/locales/{{lng}}/{{ns}}.json?version=${webpackHash}`,
    },
    ns: [
      'translation',
      'statistics',
      'profile',
      'paywall',
      'cancel-subscription',
    ],
  });

export default i18n;
