import 'core-js/stable';
import 'regenerator-runtime/runtime';
import '~/v3/lib/sentry-tracking';

import React, { Suspense } from 'react';
import { isIOS } from 'utils/platform';
import { keyboardDidShow } from 'utils/events';
import Loader from 'components/Loader';
import config from './config';
import Root from './Root';
import * as serviceWorker from '../PWA/ServiceWorkers/serviceWorker';
import './i18n';

serviceWorker.register();

const rootDiv = document.getElementById(config.DIV_NAME);

if (isIOS) keyboardDidShow();

import('react-dom').then(({ render }) => {
  render(
    <Suspense fallback={<Loader big />}>
      <Root />
    </Suspense>,
    rootDiv
  );
});
