import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { Subscription } from '~/v3/types/subsription';
import { FastSpringProduct } from '~/v3/types/fastspring';
import { cancelationOptionInfo } from '../lib/utils';
import {
  RadioButtonWrapper,
  Subtitle,
} from '../cancel-subscription-form.elements';

interface Props {
  subscriptions: Subscription[];
}

function SubscriptionSelector({ subscriptions }: Readonly<Props>) {
  const { t } = useTranslation();

  const [field] = useField<string>('subscriptionToBeCanceledId');

  return (
    <>
      <Subtitle variant="subtitle2">
        {t('cancelSubscription.selectSubscriptionToCancelText')}
      </Subtitle>
      <RadioButtonWrapper>
        <RadioGroup
          value={field.value}
          onChange={field.onChange}
          name="subscriptionToBeCanceledId"
        >
          {subscriptions.map((fastSpringSubscription) => {
            const fastspringLocales = cancelationOptionInfo.get(
              fastSpringSubscription.product as unknown as FastSpringProduct
            );

            return (
              <FormControlLabel
                key={fastSpringSubscription.product}
                control={<Radio data-testid="cancel-single-subscription" />}
                value={fastSpringSubscription.subscriptionId}
                label={fastspringLocales ? t(fastspringLocales) : ''}
              />
            );
          })}
          <FormControlLabel
            control={<Radio data-testid="cancel-bundle-subscription" />}
            value="bundleSubscription"
            label={t('cancelSubscription.cancelBundle')}
          />
        </RadioGroup>
      </RadioButtonWrapper>
    </>
  );
}

export default SubscriptionSelector;
