import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import { makeStyles } from '@mui/styles';
import PropTypes from 'prop-types';
import { setLocalStorage } from '~/v3/utils/storage';
import { DONT_SHOW_OLD_EXTENSION_VERSION_DIALOG_KEY } from 'constants/local-storage-keys';
import { Trans, useTranslation } from 'react-i18next';
import colors from 'theme/colors';
import config from 'config';
import { platformLink } from 'utils/links';

const useStyles = makeStyles((theme) => ({
  paper: {
    margin: theme.spacing(0.5),
  },
  dialogTitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  dialogContent: {
    paddingBottom: 0,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    '& .MuiTypography-root div': {
      marginBottom: theme.spacing(1),
    },
    '& .MuiTypography-root div:last-child': {
      marginBottom: '0',
    },
  },
  dialogActions: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(2),
  },
  actionButton: {
    fontSize: 12,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  navigateToUpdateAdobeExtensionArticleButtonText: {
    color: colors.Blue[600],
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function OldExtensionVersionDialog({ open, onClose }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const onDontShowDialogAgain = () => {
    setLocalStorage(DONT_SHOW_OLD_EXTENSION_VERSION_DIALOG_KEY, true);
    onClose();
  };

  const navigateToUpdateAdobeExtensionArticle = () => {
    platformLink(config.UPDATE_ADOBE_EXTENSION_ARTICLE_URL, '_blank');
  };

  return (
    <Dialog
      open={open}
      classes={{
        paper: classes.paper,
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {t('oldExtensionVersionDialog.title')}
      </DialogTitle>

      <DialogContent className={classes.dialogContent}>
        <Typography variant="body2">
          <Trans
            i18nKey="oldExtensionVersionDialog.dialogContent"
            components={{
              Box: <Box />,
              NavigateToUpdateAdobeExtensionArticle: (
                <Box
                  component="span"
                  onClick={navigateToUpdateAdobeExtensionArticle}
                  className={
                    classes.navigateToUpdateAdobeExtensionArticleButtonText
                  }
                />
              ),
            }}
          />
        </Typography>
      </DialogContent>

      <DialogActions
        classes={{
          root: classes.dialogActions,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          onClick={onClose}
          className={classes.actionButton}
        >
          {t('oldExtensionVersionDialog.remindMeLaterButton')}
        </Button>
        <Button
          onClick={onDontShowDialogAgain}
          variant="contained"
          size="small"
          className={classes.actionButton}
        >
          {t('oldExtensionVersionDialog.dontShowAgainButton')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

OldExtensionVersionDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
export default OldExtensionVersionDialog;
