import React from 'react';
import PropTypes from 'prop-types';

function PantoneLogo({ color, ...props }) {
  return (
    <svg
      {...props}
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xlinkHref="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 200 31"
      enableBackground="new 0 0 200 31"
      xmlSpace="preserve"
    >
      <g>
        <path
          fill={color}
          d="M7.3,6.2h4.6c1.7,0,3.8,0,5,1.6c0.4,0.5,0.7,1.2,0.8,2.6c0,2.5-1,4-3.5,4.4c-0.7,0.1-1.2,0.1-2.5,0.1H7.3
        L7.3,6.2L7.3,6.2z M0,0.8v29h7.3v-9.5h4.9c4.2-0.1,8.3-0.3,11-4.2c1.6-2.3,1.7-4.8,1.7-5.8c0-2.9-1-5-1.6-5.9
        c-0.5-0.7-1-1.2-1.3-1.4c-2.2-1.9-4.8-2.2-7.6-2.3L0,0.8L0,0.8z"
        />
        <path
          fill={color}
          d="M32.7,20c0.7-2.4,1.5-4.8,2.2-7.2c0.5-1.7,0.9-3.4,1.4-5.1L40.1,20L32.7,20L32.7,20z M40.2,0.8h-7.6
          l-10.8,29h7.7l1.4-4.6h10.9l1.4,4.6h7.7L40.2,0.8z"
        />
        <path
          fill={color}
          d="M60.4,0.8l6.3,10.7c1.5,2.6,3,5.3,4.4,7.9L70.8,0.8h7v29h-7.1l-5.4-9.2c-0.9-1.4-1.8-2.9-2.6-4.4
          c-0.9-1.5-1.6-3.1-2.5-4.6l0.2,18.3h-7v-29L60.4,0.8L60.4,0.8z"
        />
        <path
          fill={color}
          d="M102.7,0.8v5.7h-7.5v23.3h-7.3V6.5h-7.6V0.8H102.7z"
        />
        <path
          fill={color}
          d="M124.6,16c-0.1,2.3-0.7,4.6-2.3,6.4c-1.8,1.9-4.1,2.2-5,2.2c-1.9,0-3.6-0.7-4.9-2.1
          c-1.2-1.3-2.4-3.4-2.4-7.4c0-3.1,1-6.7,3.9-8.3c0.5-0.3,1.6-0.8,3.2-0.8c0.5,0,1.6,0,2.8,0.5c1.6,0.7,2.4,1.7,2.8,2.2
          C123.6,9.9,124.7,12.4,124.6,16z M126.4,27.7c3.6-2.9,5.5-7.7,5.5-12.1c0-4-1.6-8.7-4.4-11.6c-1.5-1.6-4.9-4-10.4-4
          c-6.7,0-10.1,3.7-11.6,5.9c-2.5,3.7-2.8,7.9-2.8,9.4c0,1.7,0.2,7.2,4.2,11.4c3.7,3.9,8.5,4.2,10.3,4.2
          C122,30.7,124.8,29,126.4,27.7z"
        />
        <path
          fill={color}
          d="M142.5,0.8l6.3,10.7c1.5,2.6,3,5.3,4.4,7.9l-0.3-18.6h7v29h-7.1l-5.4-9.2c-0.9-1.4-1.8-2.9-2.6-4.4
          c-0.9-1.5-1.6-3.1-2.5-4.6l0.2,18.3h-7v-29L142.5,0.8L142.5,0.8z"
        />
        <path
          fill={color}
          d="M186.1,0.8v5.6h-14.2v5.3h13.3v5.5h-13.3v6.9h15.5v5.7h-22.8v-29H186.1z"
        />
        <path
          fill={color}
          d="M193.5,5.2V3.5h1.1c0.4,0,0.9,0,1.1,0.4c0.1,0.1,0.1,0.3,0.1,0.4s0,0.3-0.1,0.4c-0.2,0.4-0.5,0.4-1.3,0.4
          h-0.9V5.2z M197.4,9.4c-0.3-0.6-0.5-1.4-0.5-1.6c-0.1-0.5-0.1-1.2-0.5-1.6c-0.1-0.1-0.2-0.2-0.5-0.3c0.3-0.1,0.3-0.1,0.5-0.2
          s0.3-0.2,0.4-0.4c0.2-0.2,0.4-0.5,0.4-1.1c0-0.2,0-0.7-0.4-1.2c-0.6-0.7-1.6-0.7-2.4-0.7H192v7h1.5v-3h0.4c0.5,0,0.7,0,0.9,0.2
          c0.3,0.2,0.4,0.4,0.5,1.1c0.1,0.5,0.1,1.1,0.3,1.6c0,0.1,0.1,0.1,0.1,0.2H197.4z M200,5.9c0-1.2-0.3-2.4-1.1-3.3
          c-1.1-1.4-2.7-2.2-4.4-2.2c-2.3,0-3.7,1.3-4.3,2c-0.4,0.5-1.3,1.7-1.3,3.5c0,2.4,1.4,3.8,2.1,4.4c1,0.7,2.2,1.1,3.4,1.1
          c0.8,0,2.7-0.2,4.2-1.9C199.9,8.1,200,6.6,200,5.9z M199.5,5.9c0,2.1-1.3,4-3.3,4.7c-0.8,0.3-1.4,0.3-1.7,0.3c-2,0-3.9-1.2-4.7-3.1
          c-0.2-0.6-0.4-1.2-0.4-1.9c0-2.3,1.4-3.6,2.1-4.1c1.2-0.9,2.4-1,3-1c2.3,0,3.5,1.3,4,2C199.4,4.1,199.5,5.4,199.5,5.9z"
        />
      </g>
    </svg>
  );
}

PantoneLogo.defaultProps = {
  className: '',
  color: '#010101',
};

PantoneLogo.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default PantoneLogo;
