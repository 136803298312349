import React, { createContext, ReactNode, useContext, useMemo } from 'react';

import { UserCountry } from '../types/user';
import useGetUserCountry from '../react-query/hooks/use-get-user-country';

type UserData = {
  country: {
    data: UserCountry | null | undefined;
    isError: boolean;
    isFromChina: boolean;
    isFromUS: boolean;
  };
};

export const UserContext = createContext<UserData>({
  country: {
    data: undefined,
    isError: false,
    isFromChina: false,
    isFromUS: false,
  },
});

export function useUserContext() {
  const context = useContext(UserContext);

  if (context === undefined) {
    throw new Error('useUserContext must be used within UserContext');
  }

  return context;
}

interface Props {
  children?: ReactNode;
}

export function UserProvider({ children }: Props) {
  const countryData = useGetUserCountry();

  const data = useMemo(
    () => ({
      country: countryData,
    }),
    [countryData]
  );

  return <UserContext.Provider value={data}>{children}</UserContext.Provider>;
}
