import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import InputBase from '@mui/material/InputBase';
import classNames from 'classnames';
import useTracker from 'analytics/useTracker';

const useStyles = makeStyles((theme) => ({
  workspaceName: {
    flex: 1,
    display: 'flex',
    padding: theme.spacing(2),
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(0, 0, 0, 1),
    },
  },
  workspaceNameInput: {
    color: theme.palette.action.disabled,
    fontSize: theme.typography.h6.fontSize,
    fontWeight: theme.typography.h6.fontWeight,
    fontFamily: theme.typography.h6.fontFamily,
  },
  workspaceNameInputHasName: {
    color: theme.palette.text.primary,
  },
  workspaceNameEditing: {
    color: theme.palette.text.primary,
  },
}));

function WorkspaceName({ value, onSubmit, onNameChange }) {
  const { t } = useTranslation();
  const classes = useStyles();
  const inputRef = useRef();
  const ga = useTracker();

  const formik = useFormik({
    initialValues: { name: '' },
    onSubmit: (values) => {
      onSubmit(values.name);
      ga.trackWorkspaceEvent({ action: 'addName' });
      ga.trackWorkspaceEvent({ action: 'confirmName' });
    },
  });

  useEffect(() => {
    formik.setFieldValue('name', value?.name);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value.name]);

  const handleChange = (e) => {
    formik.setFieldValue('name', e.target.value);
    onNameChange(e.target.value);
  };

  const handleFocus = (event) => {
    if (formik.values.name === t('labels.untitled')) {
      event.target.select();
    }

    ga.trackWorkspaceEvent({ action: 'editName' });
  };

  return (
    <form onSubmit={formik.handleSubmit} className={classes.workspaceName}>
      <InputBase
        data-testid="palette-title"
        value={formik.values.name}
        inputRef={inputRef}
        placeholder={t('labels.enterTitle')}
        onFocus={handleFocus}
        onChange={handleChange}
        classes={{
          root: classNames(classes.workspaceNameInput, {
            [classes.workspaceNameInputHasName]:
              formik.values.name !== t('labels.untitled') || value?.id,
          }),
          focused: classes.workspaceNameEditing,
        }}
      />
    </form>
  );
}

WorkspaceName.defaultProps = {
  value: '',
};

WorkspaceName.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  onSubmit: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
};

export default WorkspaceName;
