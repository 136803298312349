import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

type ColorInsiderIconProps = SvgIconProps & {
  isSelected?: boolean;
};

function ColorInsiderIcon({
  isSelected = true,
  sx,
  ...props
}: ColorInsiderIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        fill: 'none',
        width: 18,
        height: 20,
        ...sx,
      }}
      {...props}
    >
      <path
        d="M2.26477 18C1.71477 18 1.24394 17.8042 0.852271 17.4125C0.460604 17.0208 0.264771 16.55 0.264771 16V2C0.264771 1.45 0.460604 0.979167 0.852271 0.5875C1.24394 0.195833 1.71477 0 2.26477 0H13.2648L18.2648 5V16C18.2648 16.55 18.0689 17.0208 17.6773 17.4125C17.2856 17.8042 16.8148 18 16.2648 18H2.26477ZM2.26477 16H16.2648V6H12.2648V2H2.26477V16ZM4.26477 14H14.2648V12H4.26477V14ZM4.26477 6H9.26477V4H4.26477V6ZM4.26477 10H14.2648V8H4.26477V10Z"
        fill={theme.palette.icon.primary}
      />
      <rect
        x="4.26477"
        y="4"
        width="5"
        height="2"
        fill={
          isSelected ? theme.palette.icon.secondary : theme.palette.icon.primary
        }
      />
      <rect
        x="4.26477"
        y="8"
        width="10"
        height="2"
        fill={
          isSelected ? theme.palette.icon.secondary : theme.palette.icon.primary
        }
      />
      <rect
        x="4.26477"
        y="12"
        width="10"
        height="2"
        fill={
          isSelected ? theme.palette.icon.secondary : theme.palette.icon.primary
        }
      />
    </SvgIcon>
  );
}

export default ColorInsiderIcon;
