import React from 'react';
import { useTranslation } from 'react-i18next';
import RoutePathname, { BaseRoutePaths } from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import ColorStoryNavigationIcon from '~/v3/components/shared/icons/color-story-navigation-icon';
import { headerNavigationEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import BaseNavigationItem from '../base-navigation-item';
import {
  StyledNavigationItem,
  StyledNavigationText,
} from '../../../header.elements';

function ColorStoryNavigationItem() {
  const route = RoutePathname.colorStoryNew;

  const { pathname: currentRoute, push } = useRouter();

  const { t } = useTranslation();

  const ga = useTracker();

  const onNavigateToColorStoryPage = () => {
    push(route);
    ga.trackEvent({
      ...headerNavigationEvent,
      EVENT_LABEL: 'colorstory_nav',
    });
  };

  const isSelected = currentRoute?.includes(BaseRoutePaths.colorStory);

  return (
    <BaseNavigationItem
      isSelected={isSelected}
      onClick={onNavigateToColorStoryPage}
      as={StyledNavigationItem}
      icon={<ColorStoryNavigationIcon />}
      text={
        <StyledNavigationText>{t('routes.colorStory')}</StyledNavigationText>
      }
      dataId="color-story"
    />
  );
}

export default ColorStoryNavigationItem;
