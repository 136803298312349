import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isAdobe, isMobile } from 'utils/platform';
import { getLocalStorage, setLocalStorage } from '~/v3/utils/storage';
import config from '../config';
import { selectCurrentUser } from '../services/authApi';

const INTERCOM_POPUP_OPENED_ON_FIRST_VISIT_STORAGE_KEY =
  'INTERCOM_POPUP_OPENED_ON_FIRST_VISIT_STORAGE_KEY';

const useOpenIntercomPopUpOnFirstVisit = () => {
  const { data: user } = useSelector(selectCurrentUser);

  const isAuthenticated = !!user;

  const [
    isIntercomPopUpOpenedOnFirstVisit,
    setIsIntercomPopUpOpenedOnFirstVisit,
  ] = useState(
    getLocalStorage(INTERCOM_POPUP_OPENED_ON_FIRST_VISIT_STORAGE_KEY)
  );

  useEffect(() => {
    const isMobileOrAdobe = isMobile || isAdobe;

    const shouldOpenIntercomPopUp =
      config.INTERCOM_ENABLED &&
      window.Intercom &&
      !isIntercomPopUpOpenedOnFirstVisit &&
      isAuthenticated &&
      !isMobileOrAdobe;

    if (shouldOpenIntercomPopUp) {
      window.Intercom('show');

      setLocalStorage(INTERCOM_POPUP_OPENED_ON_FIRST_VISIT_STORAGE_KEY, true);
      setIsIntercomPopUpOpenedOnFirstVisit(true);
    }
  }, [isIntercomPopUpOpenedOnFirstVisit, isAuthenticated]);
};

export default useOpenIntercomPopUpOnFirstVisit;
