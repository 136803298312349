import useGetCurrentUser from '~/v3/react-query/hooks/use-get-current-user';
import { ProductType } from '~/v3/paywall/constants';
import { Products } from '~/v3/types/coupons';
import { useConfiguredGetSubscriptionQuery } from '../../src/services/subscriptionApi';
import {
  Subscription,
  SubscriptionStatus,
  SubscriptionType,
} from '../types/subsription';
import filterActiveFastSpringSubscriptions from '../utils/filter-active-fastspring-subscriptions';
import filterActiveTrendSubscriptions from '../utils/filter-active-trends-subscriptions';
import filterActiveUncancelledFastSpringSubscriptions from '../utils/filter-active-uncancelled-fastspring-subscriptions';
import filterCouponCodeSubscriptions from '../utils/filter-coupon-code-subscriptions';
import filterActiveCouponCodeSubscriptionsByProduct from '../utils/filter-active-coupon-code-subscriptions-by-product';

export interface UseSubscriptionStatusOutput {
  isLoading: boolean;
  isNotSubscribed: boolean;
  isOnTrialPeriod: boolean;
  isOnCouponCodeTrial: boolean;
  isSubscriptionStateTrial: boolean;
  isActive: boolean;
  isCanceled: boolean;
  isCouponCodeTrialEnding: boolean;
  isEligibleForTrial: boolean;
  isBasic: boolean;
  isPremium: boolean;
  isPremiumPlus: boolean;
  isActiveFastspring: boolean;
  isTrendSubscription: boolean;
  isActiveTrendsSubscription: boolean;
  hasActiveSubscriptionThatIsNotCanceled: boolean;
  isConnectPremiumSubscription: boolean;
  isOnConnectCouponCodeTrial: boolean;
  isOnColorInsiderCouponCodeTrial: boolean;
}

const useSubscriptionStatus = (): UseSubscriptionStatusOutput => {
  const { data: user } = useGetCurrentUser();
  const { data: subscription, isLoading } =
    useConfiguredGetSubscriptionQuery() as {
      data: Subscription;
      isLoading: boolean;
    };

  const isActive = subscription?.active;
  const subscriptions = subscription?.subscriptions || [];

  const isActiveFastspring =
    filterActiveFastSpringSubscriptions(subscriptions).length > 0;

  const isActiveTrendsSubscription =
    filterActiveTrendSubscriptions(subscriptions).length > 0;

  const hasActiveSubscriptionThatIsNotCanceled =
    filterActiveUncancelledFastSpringSubscriptions(subscriptions).length > 0;

  const isEligibleForTrial =
    !user ||
    (!isActive &&
      subscription?.state === SubscriptionStatus.USER_NOT_SUBSCRIBED);

  const isNotSubscribed =
    !user ||
    (!isActive &&
      (subscription?.state === SubscriptionStatus.USER_NOT_SUBSCRIBED ||
        subscription?.state === SubscriptionStatus.EXPIRED));

  const isBasic =
    !isActive && subscription?.subscriptionType === SubscriptionType.Free;

  const isOnCouponCodeTrial =
    filterCouponCodeSubscriptions(subscriptions).length > 0;

  const isOnConnectCouponCodeTrial =
    filterActiveCouponCodeSubscriptionsByProduct(
      subscriptions,
      Products.CONNECT_PREMIUM
    ).length > 0;

  const isOnColorInsiderCouponCodeTrial =
    filterActiveCouponCodeSubscriptionsByProduct(
      subscriptions,
      Products.INSIDER_PREMIUM
    ).length > 0;

  const isSubscriptionStateTrial =
    isActive && subscription?.state === SubscriptionStatus.TRIAL;

  const isOnTrialPeriod = isSubscriptionStateTrial || isOnCouponCodeTrial;

  const isCouponCodeTrialEnding =
    isOnCouponCodeTrial &&
    subscription?.daysLeft > 0 &&
    subscription?.daysLeft < 8;

  const isPremium = subscription?.subscriptionType === SubscriptionType.Premium;

  const isPremiumPlus =
    subscription?.subscriptionType === SubscriptionType.PremiumPlus;

  const isCanceled =
    isActive && subscription?.state === SubscriptionStatus.CANCELED;

  const isTrendSubscription = subscription?.subscriptions?.some(
    (sub) => sub.product === ProductType.TRENDS
  );

  const isConnectPremiumSubscription = subscription?.subscriptions?.some(
    (sub) => sub.product === ProductType.PREMIUM
  );

  return {
    isLoading,
    isNotSubscribed,
    isOnTrialPeriod,
    isOnCouponCodeTrial,
    isSubscriptionStateTrial,
    isActive,
    isCanceled,
    isCouponCodeTrialEnding,
    isEligibleForTrial,
    isBasic,
    isPremium,
    isPremiumPlus,
    isActiveFastspring,
    isTrendSubscription,
    isActiveTrendsSubscription,
    hasActiveSubscriptionThatIsNotCanceled,
    isConnectPremiumSubscription,
    isOnConnectCouponCodeTrial,
    isOnColorInsiderCouponCodeTrial,
  };
};

export default useSubscriptionStatus;
