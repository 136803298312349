import React from 'react';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import MenuProvider, {
  useMenu,
} from '~/v3/components/shared/providers/menu-provider';
import { Box, styled } from '@mui/material';
import useRouter from '~/v3/hooks/use-router';
import QuickStartIcon from '~/v3/components/shared/icons/quick-start-icon';
import ActionButton from '~/v3/components/core/buttons/action-button';
import usePaletteWS from '~/v2/features/PaletteWorkspace/usePaletteWS';
import useTracker from '~/v2/analytics/useTracker';
import { headerNavigationEvent, pickEvent } from '~/v3/events';
import RoutePathname from '~/v2/constants/route';
import sidebarStore, { useSidebarStoreActions } from '~/v3/store/sidebar-store';
import SubMenu from '../sub-menu';
import {
  selectedFooterNavigationStyle,
  StyledFooterNavigation,
} from '../../footer.elements';

const Container = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: theme.spacing(31.375),
  height: theme.spacing(15.75),
  padding: theme.spacing(1.5, 2.5),
  justifyContent: 'center',
  alignItems: 'center',
  flexShrink: 0,
}));

function QuickStartFooterNavigation() {
  const { isMenuOpen, onOpenMenu, anchorEl, onCloseMenu } = useMenu();
  const { clearWorkspace } = usePaletteWS();
  const ga = useTracker();
  const { push } = useRouter();
  const { onCloseSidebar } = useSidebarStoreActions();
  const { isSidebarOpen } = sidebarStore();

  const { t } = useTranslation();

  const containerStyle = isMenuOpen ? selectedFooterNavigationStyle : {};

  const onClickNewPalette = () => {
    clearWorkspace();

    onCloseMenu();

    if (isSidebarOpen) onCloseSidebar();

    ga.trackEvent({
      ...pickEvent,
      EVENT_AREA: 'create-new-palette-button',
      EVENT_LABEL: 'workspace-create-new-palette',
    });
  };

  const onClickNewColorStory = () => {
    push(RoutePathname.colorStoryNew);

    onCloseMenu();

    ga.trackEvent({
      ...headerNavigationEvent,
      EVENT_LABEL: 'colorstory_nav',
    });
  };

  return (
    <>
      <StyledFooterNavigation
        sx={containerStyle}
        anchorEl={anchorEl}
        onClick={onOpenMenu}
        icon={<QuickStartIcon />}
        text={<Typography>{t('routes.quickStart')}</Typography>}
        data-testid="quick-start"
      />
      <SubMenu
        slotProps={{
          paper: {
            sx: {
              right: '0',
              left: 'auto ',
              transform: 'none',
              marginLeft: 'auto',
              width: 'max-content',
            },
          },
        }}
      >
        <Container>
          <ActionButton
            onClick={onClickNewPalette}
            data-testid="new-palette-button"
          >
            <Typography>{t('labels.newPalette')}</Typography>
          </ActionButton>
          <ActionButton
            sx={{ marginBottom: 0 }}
            onClick={onClickNewColorStory}
            data-testid="new-color-story-button"
          >
            <Typography>{t('labels.newColorStory')}</Typography>
          </ActionButton>
        </Container>
      </SubMenu>
    </>
  );
}

function QuickStartFooterNavigationWithProviders() {
  return (
    <MenuProvider>
      <QuickStartFooterNavigation />
    </MenuProvider>
  );
}

export default QuickStartFooterNavigationWithProviders;
