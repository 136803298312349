import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import BackIcon from '@mui/icons-material/ArrowBack';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import IconButton from './IconButton';

const useStyles = makeStyles((theme) => ({
  dialogActions: {
    padding: theme.spacing(5),

    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(2),
    },
  },
  dialogTitle: {
    flex: '1 1 auto',
    display: 'flex',
    overflow: 'hidden',

    '& h2': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  },
  dialogContent: {
    display: 'flex',
    padding: 0,
  },
}));

function FullScreenDialog({ title, children, open, onClose, ...props }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const handleClose = () => {
    if (onClose) onClose();
  };

  return (
    <Dialog open={open} fullScreen {...props} data-id="fullscreen-dialog">
      <DialogActions className={classes.dialogActions}>
        <IconButton
          color="inherit"
          data-id="full-screen-back"
          tooltip={t('labels.back')}
          aria-label={t('labels.back')}
          onClick={handleClose}
          size="large"
        >
          <BackIcon fontSize="large" />
        </IconButton>
        <DialogTitle color="inherit" className={classes.dialogTitle}>
          {title}
        </DialogTitle>
      </DialogActions>
      <DialogContent
        className={classes.dialogContent}
        data-id="full-screen-dialog"
      >
        {children}
      </DialogContent>
    </Dialog>
  );
}

FullScreenDialog.defaultProps = {
  title: null,
  children: null,
};

FullScreenDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string,
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default FullScreenDialog;
