export const paywallClickedMonthly = {
  EVENT_ID: 'e134',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_monthly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallClickedYearly = {
  EVENT_ID: 'e135',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_yearly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallViewedUnauth = {
  EVENT_ID: 'e138',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'na',
  EVENT_LABEL: 'e-paywall_viewed_unauth',
  EVENT_ACTION: 'view',
  EVENT_AREA: 'na',
};

export const paywallViewedBasic = {
  EVENT_ID: 'e139',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'na',
  EVENT_LABEL: 'e-paywall_viewed_basic',
  EVENT_ACTION: 'view',
  EVENT_AREA: 'na',
};

export const paywallDrawerViewed = {
  EVENT_ID: 'e140',
  EVENT_PLACE: 'drawer',
  EVENT_COMPONENT: 'drawer',
  EVENT_LABEL: 'e-paywall_drawer_viewed',
  EVENT_ACTION: 'view',
  EVENT_AREA: 'na',
};

export const paywallDrawerClosed = {
  EVENT_ID: 'e141',
  EVENT_PLACE: 'drawer',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_drawer_closed',
  EVENT_ACTION: 'close',
  EVENT_AREA: 'na',
};

export const paywallClickedBasic = {
  EVENT_ID: 'e144',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_basic',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallClickedTrendsMonthly = {
  EVENT_ID: 'e145',
  EVENT_PLACE: 'drawer',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_trends_monthly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallClickedTrendsYearly = {
  EVENT_ID: 'e146',
  EVENT_PLACE: 'drawer',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_trends_yearly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallClickedBundleMonthly = {
  EVENT_ID: 'e147',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_bundle_monthly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallClickedBundleYearly = {
  EVENT_ID: 'e148',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_clicked_bundle_yearly',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallCheckoutStarted = {
  EVENT_ID: 'e149',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'checkout_started',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const subscriptionStarted = {
  EVENT_ID: 'e150',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'subscription_started',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallMultiSeatSelected = {
  EVENT_ID: 'e151',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_multi_seat_selected',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallSingleSeatSelected = {
  EVENT_ID: 'e152',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_single_seat_selected',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const paywallGoBackToFree = {
  EVENT_ID: 'e157',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'e-paywall_back-to-free',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};

export const checkoutCompleted = {
  EVENT_ID: 'e158',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'button',
  EVENT_LABEL: 'checkout_completed',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'na',
};
