import { useDispatch, useSelector } from 'react-redux';
import {
  open,
  close,
  selectIsOpen,
  selectPalette,
} from './paletteAnalysisSlice';

function usePaletteAnalysis() {
  const palette = useSelector(selectPalette);
  const isOpen = useSelector(selectIsOpen);
  const dispatch = useDispatch();

  return {
    palette,
    isOpen,
    open: (selectedPalette) => dispatch(open(selectedPalette)),
    close: () => dispatch(close()),
  };
}

export default usePaletteAnalysis;
