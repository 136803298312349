import { Box, Stack, styled } from '@mui/material';
import React from 'react';
import { Color, PantoneColorWithBook } from '~/v3/types/pantone-color';

import ChipInfoArea from './chip-info-area';

export type InfoChipProps = {
  onLeftClick?: (
    event: React.MouseEvent<HTMLDivElement | HTMLButtonElement>
  ) => void;
  width?: number;
  style?: React.CSSProperties;
  color?: PantoneColorWithBook;
  children?: React.ReactNode;
} & Color;

const ChipContainer = styled(Stack)<{ width: number }>(({ theme, width }) => ({
  maxWidth: `${width}px`,
  aspectRatio: '2/3',
  boxShadow: theme.palette.shadows.largeChipShadow,
  overflow: 'hidden',
  containerType: 'inline-size',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const InfoChip = React.forwardRef<HTMLDivElement, InfoChipProps>(
  (
    {
      children,
      style,
      code,
      rgb,
      name,
      lab,
      hsl,
      color,
      onLeftClick,
      width = 135,
    }: InfoChipProps,
    ref
  ) => {
    return (
      <ChipContainer
        ref={ref}
        onClick={(event) => {
          onLeftClick?.(event);
        }}
        data-testid="info-chip"
        direction="column"
        width={width}
      >
        <Box
          data-testid="info-chip-colored-area"
          sx={{
            backgroundColor: `rgb(${color?.rgb.r ?? rgb.r}, ${rgb.g}, ${
              rgb.b
            })`,
            aspectRatio: '1/1',
            width: '100%',
          }}
          style={style}
        >
          {children}
        </Box>
        <ChipInfoArea color={{ rgb, code, name, lab, hsl }} />
      </ChipContainer>
    );
  }
);

export default InfoChip;
