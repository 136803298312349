import { BaseRoutePaths } from '~/v2/constants/route';
import { Breakpoint } from '~/v3/types/promotion';

export const COY_URL_BANNER_SLUG = 'the-story-of-color-of-the-year-2025';
export const COY_EVENT_TERM = `${window.location.origin}${BaseRoutePaths.insight}/${COY_URL_BANNER_SLUG}`;

export enum TopBannerBreakpoints {
  sm320 = 320,
  sm360 = 360,
  sm390 = 390,
  sm412 = 412,
  sm460 = 460,
  md600 = 600,
  md960 = 960,
  lg1135 = 1135,
  lg1680 = 1680,
  xl1920 = 1920,
}

export const topBannerBreakpoints: {
  [key in keyof typeof TopBannerBreakpoints]: Breakpoint;
} = {
  sm320: { width: TopBannerBreakpoints.sm320, height: 54 },
  sm360: { width: TopBannerBreakpoints.sm360, height: 54 },
  sm390: { width: TopBannerBreakpoints.sm390, height: 54 },
  sm412: { width: TopBannerBreakpoints.sm412, height: 54 },
  sm460: { width: TopBannerBreakpoints.sm460, height: 54 },
  md600: { width: TopBannerBreakpoints.md600, height: 54 },
  md960: { width: TopBannerBreakpoints.md960, height: 54 },
  lg1135: { width: TopBannerBreakpoints.lg1135, height: 54 },
  lg1680: { width: TopBannerBreakpoints.lg1680, height: 54 },
  xl1920: { width: TopBannerBreakpoints.xl1920, height: 54 },
};
