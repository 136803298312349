import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import Paper from '@mui/material/Paper';

import { Box } from '@mui/material';
import { workspaceLargeTestId } from '~/v3/constants/workspace-large-test-id';
import { isSidebarEnabled } from '~/v3/feature-flags';
import { WorkspaceButtonsContainer } from '~/v2/components/WorkspaceUI/workspace-buttons-container';
import WorkspaceName from '~/v2/components/WorkspaceUI/WorkspaceName';
import WorkspaceColors from '~/v2/components/WorkspaceUI/WorkspaceDropzone';
import DownloadButton from '~/v2/components/WorkspaceUI/DownloadButton';
import AnalyzeButton from '~/v2/components/WorkspaceUI/AnalyzeButton';

const useStyles = makeStyles((theme) => ({
  workspace: {
    width: 380,
    minWidth: 380,
    borderRadius: 0,
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column',
    marginLeft: theme.spacing(0),
  },
  workspaceHeader: {
    display: 'flex',
  },
  workspaceOptions: {
    display: 'flex',
    alignItems: 'center',
    boxShadow: 'none',
  },
  workspaceColors: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    alignContent: 'flex-start',
    padding: theme.spacing(1.5),
    overflowY: 'auto',
  },
  workspaceActions: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2),
  },
  divider: {
    borderLeft: `1px solid ${theme.palette.divider}`,
    paddingTop: theme.spacing(3),
  },
}));

function WorkspaceLarge({
  saveButton,
  shareButton,
  value,
  onSubmit,
  onNameChange,
  clearButton,
  refreshButton,
  deleteButton,
}) {
  const classes = useStyles();
  const [elevation, setElevation] = useState(false);

  const id = value?.id;
  const name = value?.name;
  const colors = value?.colors;
  const disabled = colors && !colors.length;

  const handleDrag = (canDrop) => {
    setElevation(canDrop);
  };

  const handleSubmitName = (newName) => {
    onSubmit({ name: newName });
  };

  const handleSubmitColors = (newColors) => {
    onSubmit({ colors: newColors });
  };

  const handleNameChange = (newName) => {
    onNameChange(newName);
  };

  return (
    <Paper
      elevation={elevation ? 4 : 0}
      className={classes.workspace}
      data-testid={workspaceLargeTestId}
    >
      <div className={classes.workspaceHeader} data-testid="header">
        <WorkspaceName
          value={value}
          onSubmit={handleSubmitName}
          onNameChange={handleNameChange}
        />

        {!isSidebarEnabled && (
          <Paper elevation={1} className={classes.workspaceOptions}>
            {refreshButton}
            {clearButton && <div className={classes.divider} />}
            {clearButton}
          </Paper>
        )}
      </div>

      <WorkspaceColors
        value={value?.colors}
        onDrag={handleDrag}
        onSubmit={handleSubmitColors}
        className={classes.workspaceColors}
        data-testid="dropzone"
      />

      <Box
        className={classes.workspaceActions}
        data-testid="actions"
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <WorkspaceButtonsContainer>
          <AnalyzeButton name={name} colors={colors} disabled={disabled} />
          <DownloadButton
            id={id}
            name={name}
            colors={colors}
            disabled={disabled}
          />
          {shareButton}
          {isSidebarEnabled && deleteButton}
        </WorkspaceButtonsContainer>
        {saveButton}
      </Box>
    </Paper>
  );
}

WorkspaceLarge.defaultProps = {
  value: null,
  saveButton: null,
  shareButton: null,
  clearButton: null,
  deleteButton: null,
};

WorkspaceLarge.propTypes = {
  saveButton: PropTypes.node,
  shareButton: PropTypes.node,
  clearButton: PropTypes.node,
  refreshButton: PropTypes.node.isRequired,
  deleteButton: PropTypes.node,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.shape()),
  }),
  onSubmit: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
};

export default WorkspaceLarge;
