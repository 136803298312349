import { useEffect } from 'react';
import { readFromLocalStorage } from '~/v3/utils/local-storage';
import useSyncSubscription from '~/v3/hooks/use-sync-subscription';
import { shouldStopSyncingHandler7daysTrial } from '~/v3/coupons/utils/should-stop-syncing-handler';
import { removeLocalStorage } from '~/v3/utils/storage';
import { PANTONE_COM_USER } from '~/v3/constants/pantone-com-user';

function useSyncPantoneComSubscription() {
  const pantoneComUser = readFromLocalStorage(PANTONE_COM_USER);

  const { syncSubscription, isSuccess } = useSyncSubscription({
    shouldStopSyncing: (latestData, ignore) =>
      shouldStopSyncingHandler7daysTrial(latestData, Boolean(ignore)),
  });

  useEffect(() => {
    const syncSubscriptionAsync = async () => {
      await syncSubscription(!pantoneComUser);
    };

    syncSubscriptionAsync().catch((error) => {
      console.error('Error syncing subscription:', error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isSuccess) {
      removeLocalStorage(PANTONE_COM_USER);
    }
  }, [isSuccess]);
}

export default useSyncPantoneComSubscription;
