import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import { Avatar } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Loader from 'components/Loader';
import useSnackbar from 'features/Snackbar/useSnackbar';
import useSignOut from 'hooks/useSignOut';
import { selectCurrentUser } from 'services/authApi';
import { useGetSubscriptionUrlQuery } from 'services/subscriptionApi';
import { getPrivacyPolicyURL, platformLink } from 'utils/links';
import { getInitialsOfName } from 'utils/strings';
import useTracker from '~/v2/analytics/useTracker';
import RoutePathname from '~/v2/constants/route';
import useProfileMenu from '~/v2/data/useProfileMenu';
import colors from '~/v2/theme/colors';
import {
  subscriptionCancelClick,
  subscriptionCancelationStarted,
} from '~/v3/components/features/cancel-subscription/cancel-subscription-form';
import userEvents from '~/v3/constants/events';
import {
  AvatarIcon,
  userProfileAvatarWidth,
} from '~/v3/components/shared/avatar-icon';
import useRouter from '~/v3/hooks/use-router';
import { useColorMode } from '~/v3/components/shared/providers/color-mode-provider';
import MainContent from './MainContent';
import SelectLanguageContent from './SelectLanguageContent';
import { SelectSubscriptionContent } from './SelectSubscriptionContent';
import SelectThemeContent from './SelectThemeContent';
import DropdownMenu from './UserProfileDropdownMenu';

const useStyles = makeStyles((theme) => ({
  avatarIcon: {
    position: 'relative',
    width: userProfileAvatarWidth,
    '& button': {
      width: '100%',
      height: '100%',
    },
    [theme.breakpoints.down('sm')]: {
      width: 34,
      height: 34,
    },
  },
  avatar: {
    height: 34,
    width: 34,
    fontFamily: theme.typography.h6.fontFamily,
    color: colors.WhiteGrey[0],
    background: colors.WhiteGrey[850],
    fontWeight: 500,
    fontSize: 14,
  },
}));

function UserProfileMenu() {
  const { t } = useTranslation();
  const { push } = useRouter();
  const { openSnackbar } = useSnackbar();
  const { data: user, isLoading } = useSelector(selectCurrentUser);
  const { data: userSubscriptionURL } = useGetSubscriptionUrlQuery(undefined);
  const classes = useStyles();
  const { setColorMode } = useColorMode();
  const { trackEvent } = useTracker();

  const [openDropdown, setOpenDropdown] = useState(null);
  const [selectedSubMenu, setSelectedSubMenu] = useState(null);
  const { signOutUser } = useSignOut();

  const fullname = `${user?.attributes?.given_name || ''} ${
    user?.attributes?.family_name || ''
  }`;
  const email = user?.attributes?.email || '';
  const initials = getInitialsOfName(fullname);

  const handleClick = (event) => {
    setOpenDropdown(event.currentTarget);
  };

  const clearAppCache = async () => {
    if ('caches' in window && 'serviceWorker' in navigator) {
      const cacheNames = await window.caches.keys();
      await Promise.all(
        cacheNames.map(async (cacheName) => {
          const oneCache = await window.caches.open(cacheName);
          const requests = await oneCache.keys();
          await Promise.all(
            requests.map(() => window.caches.delete(cacheName))
          );
        })
      );
      openSnackbar({ message: t('messages.cacheRemoved') });

      navigator.serviceWorker.getRegistrations().then((registrations) => {
        registrations.forEach((registration) => {
          registration.unregister();
        });
      });
    } else {
      openSnackbar({ message: t('messages.maxColorSupport') });
    }
  };

  const changeTheme = (mode) => {
    setColorMode(mode);
  };

  const handleClickItem = async (
    event,
    { eventName, pathname, subMenu, value } = {}
  ) => {
    if (pathname) {
      if (RoutePathname.cancelSubscription) {
        trackEvent(subscriptionCancelClick);
        trackEvent(subscriptionCancelationStarted);
      }
      push(pathname);
    } else if (eventName && !subMenu) {
      switch (eventName) {
        case userEvents.manageSubscription:
          if (userSubscriptionURL) {
            platformLink(userSubscriptionURL, '_blank');
          }
          break;
        case userEvents.signOut:
          await signOutUser();
          break;
        case userEvents.privacyPolicy:
          getPrivacyPolicyURL();
          break;
        case userEvents.clearAppCache:
          clearAppCache();
          break;
        case userEvents.changeTheme:
          changeTheme(value);
          break;
        default:
      }
    }

    setOpenDropdown(null);
    setSelectedSubMenu(null);
  };

  let userIcon;
  if (isLoading) {
    userIcon = <Loader iconSize={20} />;
  } else if (user) {
    userIcon = <Avatar className={classes.avatar}>{initials}</Avatar>;
  } else {
    userIcon = <PersonOutlineIcon />;
  }

  const { themeSection, subscriptionSection } = useProfileMenu();

  let content;
  switch (selectedSubMenu) {
    case themeSection.changeTheme.eventName:
      content = (
        <SelectThemeContent
          onClickItem={handleClickItem}
          onClose={setSelectedSubMenu}
        />
      );
      break;
    case subscriptionSection?.manageSubscription?.eventName:
      content = (
        <SelectSubscriptionContent
          onClickItem={handleClickItem}
          onClose={setSelectedSubMenu}
        />
      );
      break;
    case themeSection.changeLanguage.eventName:
      content = (
        <SelectLanguageContent
          onClickItem={handleClickItem}
          onClose={setSelectedSubMenu}
        />
      );
      break;
    default:
      content = (
        <MainContent
          fullname={fullname}
          email={email}
          userIcon={userIcon}
          handleClickItem={handleClickItem}
          handleOpenSubMenu={setSelectedSubMenu}
        />
      );
  }

  return (
    <>
      <AvatarIcon onClick={handleClick} />

      {openDropdown && (
        <DropdownMenu
          anchorEl={openDropdown}
          onClose={() => {
            setOpenDropdown(null);
            setSelectedSubMenu(null);
          }}
        >
          {content}
        </DropdownMenu>
      )}
    </>
  );
}

export default UserProfileMenu;
