import { Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracker from '~/v2/analytics/useTracker';
import { BaseRoutePaths } from '~/v2/constants/route';
import useRouter from '~/v3/hooks/use-router';
import { onLeftClickMenuEvent } from '~/v3/events';
import CrossReferenceIcon from '~/v3/components/shared/icons/cross-reference-icon';
import HarmoniesIcon from '~/v3/components/shared/icons/harmonies-icon';
import InfoIcon from '~/v3/components/shared/icons/info-icon';
import ShadesIcon from '~/v3/components/shared/icons/shades-icon';
import { PantoneColor } from '~/v3/types/pantone-color';
import { StyledMenuItem } from '../left-click-menu.elements';
import { BaseMenuKeys } from '../model/types';
import FullScreenMenuItem from './full-screen-menu-item';
import PantoneRedirectMenuItem from './pantone-redirect-menu-item';

function BaseItems({ color }: Readonly<{ color: PantoneColor }>) {
  const { t } = useTranslation();
  const ga = useTracker();
  const { push, pathname } = useRouter();

  function handleItemClick(tab: BaseMenuKeys) {
    const codeFormatted = color?.code.split(' ').join('_');
    ga.trackEvent({
      ...onLeftClickMenuEvent,
      EVENT_PAGE: pathname,
      TERM: tab,
      COLOR: color.code,
      EVENT_BOOK_ID: color?.book?.id,
    });
    push(`${BaseRoutePaths.info}/${codeFormatted}?tab=${tab}`);
  }

  return (
    <>
      <StyledMenuItem
        rgb={color?.rgb}
        key={BaseMenuKeys.info}
        stroke
        onClick={() => handleItemClick(BaseMenuKeys.info)}
      >
        <InfoIcon />
        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.colorData')}
        </Typography>
      </StyledMenuItem>

      <StyledMenuItem
        key={BaseMenuKeys.shades}
        onClick={() => handleItemClick(BaseMenuKeys.shades)}
        rgb={color?.rgb}
        stroke
        fill
      >
        <ShadesIcon />
        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.shades')}
        </Typography>
      </StyledMenuItem>

      <StyledMenuItem
        rgb={color?.rgb}
        key={BaseMenuKeys.harmonies}
        stroke
        fill
        onClick={() => handleItemClick(BaseMenuKeys.harmonies)}
      >
        <HarmoniesIcon />
        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.harmonies')}
        </Typography>
      </StyledMenuItem>

      <StyledMenuItem
        key={BaseMenuKeys.crossReference}
        onClick={() => handleItemClick(BaseMenuKeys.crossReference)}
        rgb={color?.rgb}
        stroke
        fill
      >
        <CrossReferenceIcon />
        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.crossReference')}
        </Typography>
      </StyledMenuItem>

      <FullScreenMenuItem color={color} />
      <PantoneRedirectMenuItem color={color} />
    </>
  );
}

export default BaseItems;
