import { Global } from '@emotion/react';
import { Box, CssBaseline, SwipeableDrawer } from '@mui/material';
import React from 'react';
import useDrawerStore, { useDrawerActions } from '~/v3/store/drawer-store';
import useRouter from '~/v3/hooks/use-router';
import routes from '~/v2/constants/route';

export const drawerBleeding = 263;

function AuthDrawer({ children }: Readonly<{ children: React.ReactNode }>) {
  const { open } = useDrawerStore();
  const { toggleDrawer } = useDrawerActions();
  const { pathname } = useRouter();

  const isWelcomePage = pathname === routes.auth.welcome;

  React.useEffect(() => {
    if (!isWelcomePage) {
      toggleDrawer(true);
    }
  }, [isWelcomePage, toggleDrawer]);

  return (
    <>
      <CssBaseline />
      <Global
        styles={{
          '.MuiDrawer-root > .MuiPaper-root': {
            height: '99%',
            overflow: open ? 'scroll' : 'visible',
          },
        }}
      />
      <SwipeableDrawer
        data-testid="drawer"
        open={open}
        hideBackdrop
        anchor="bottom"
        disableBackdropTransition
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        PaperProps={{
          sx: {
            width: '98vw',
            margin: 'auto',
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            padding: (theme) => theme.spacing(3, 2),
            height: '100%',
            overflowY: 'visible',
          },
        }}
        ModalProps={{
          keepMounted: true,
        }}
        onOpen={() => toggleDrawer(true)}
        onClose={() => toggleDrawer(false)}
        onTouchStart={(event) => {
          if (!isWelcomePage) {
            event.stopPropagation();
          }
        }}
      >
        <Box
          data-testid="drawer-children-container"
          sx={(theme) => ({
            position: 'absolute',
            top: open ? 0 : -drawerBleeding,
            borderTopLeftRadius: theme.spacing(1),
            borderTopRightRadius: theme.spacing(1),
            visibility: 'visible',
            right: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column',
            padding: theme.spacing(3, 2),
            gap: theme.spacing(1),
            pointerEvents: 'all',
            height: 'calc(100% - 20px)',
            background: theme.palette.background.default,
            alignItems: 'center',
            [theme.breakpoints.down('extension')]: {
              gap: theme.spacing(1),
            },
          })}
        >
          {children}
        </Box>
      </SwipeableDrawer>
    </>
  );
}

export default AuthDrawer;
