import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import uniqBy from 'lodash/uniqBy';
import useSnackbar from 'features/Snackbar/useSnackbar';
import {
  reset,
  selectColors,
  setColors,
  setImageUrl,
  setMoodboard,
  setUnsavedChanges,
} from './moodboardSlice';

const MAX_COLORS = 40;

function useMoodboardWS() {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();

  const dispatch = useDispatch();

  const colors = useSelector(selectColors);

  const isInWorkspace = (color) => {
    return colors.some((c) => c.code === color?.code);
  };

  const setMoodboardImage = (imageUrl) => {
    dispatch(setImageUrl(imageUrl));
  };

  const addWorkspaceColors = (newColors) => {
    // Check if the total length of colors and new colors
    // is greater than the allowed maximum.
    if (newColors && newColors.length > MAX_COLORS) {
      openSnackbar({
        message: t('messages.maxColorSupport', { maxColors: MAX_COLORS }),
      });
      return;
    }

    const uniqueNewColors = uniqBy(newColors, 'code');
    dispatch(setColors(uniqueNewColors));
    dispatch(setUnsavedChanges(true));
  };

  const addWorkspaceColor = (color) => {
    if (colors && colors.length > MAX_COLORS) {
      openSnackbar({
        message: t('messages.maxColorSupport', { maxColors: MAX_COLORS }),
      });
      return;
    }

    if (isInWorkspace(color)) {
      openSnackbar({
        message: t('messages.colorCodeExists', { colorCode: color?.code }),
      });
      return;
    }

    dispatch(setColors([...colors, color]));
    dispatch(setUnsavedChanges(true));
  };

  const setWorkspaceMoodboard = (value) => {
    dispatch(setMoodboard(value));
    dispatch(setUnsavedChanges(false));
  };

  const removeWorkspaceColor = (color) => {
    const filteredColors = colors.filter((c) => c.code !== color.code);
    dispatch(setColors(filteredColors));
    dispatch(setUnsavedChanges(true));
  };

  const resetWorkspace = () => {
    dispatch(reset());
  };

  const clearWorkspace = () => {
    dispatch(reset());
  };

  return {
    isInWorkspace,
    setMoodboardImage,
    addWorkspaceColors,
    addWorkspaceColor,
    setWorkspaceMoodboard,
    resetWorkspace,
    clearWorkspace,
    removeWorkspaceColor,
  };
}

export default useMoodboardWS;
