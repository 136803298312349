import i18n from 'i18next';

type ErrorWithErrorsArray = {
  errors: {
    message: string;
  }[];
};

function extractErrorMessageFromError(error: unknown): string {
  const fallbackErrorMessage = i18n.t('messages.unexpectedErrorMessage');

  if (typeof error === 'string') {
    return error;
  }

  return (
    (error as Error)?.message ||
    (error as ErrorWithErrorsArray)?.errors[0]?.message ||
    fallbackErrorMessage
  );
}

export default extractErrorMessageFromError;
