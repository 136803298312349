import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { usePanoramaExpiredDialog } from 'pages/MyPalettes/myPalettesHooks';
import PantoneChipSmallSkeleton from './PantoneChipSmallSkeleton';

const useStyles = makeStyles((theme) => ({
  panoramaCursor: {
    cursor: 'pointer',
  },
  bottomLeftDot: {
    width: 4,
    height: 4,
    opacity: 0.25,
    borderRadius: '50%',
    position: 'absolute',
    left: theme.spacing(0.5),
    bottom: theme.spacing(0.5),
    background: theme.palette.text.primary,
  },
}));

function PantoneChipPanoramaSkeleton({ size }) {
  const classes = useStyles();
  const { openPanoramaExpiredDialog } = usePanoramaExpiredDialog();

  return (
    <PantoneChipSmallSkeleton
      onClick={openPanoramaExpiredDialog}
      size={size}
      className={classes.panoramaCursor}
    >
      <div className={classes.bottomLeftDot} />
    </PantoneChipSmallSkeleton>
  );
}

PantoneChipPanoramaSkeleton.defaultProps = {
  size: 60,
};
PantoneChipPanoramaSkeleton.propTypes = {
  size: PropTypes.number,
};

export default PantoneChipPanoramaSkeleton;
