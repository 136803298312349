import { getColorBasicFields } from 'api/shared/queryFields';
import { amplifyUserPoolSettings } from '../utils/amplify';

const initApi = async () => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');
  API.configure(amplifyUserPoolSettings);
  return { API, graphqlOperation };
};

const moodboardFetchers = {
  createMoodboard: async (moodboard) => {
    const { API, graphqlOperation } = await initApi();
    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreateMoodboard($name: String!, $imageUrl: String!, $colorCodes:[String]!) {
      createMoodboard(
        input: { name: $name, imageUrl: $imageUrl, colorCodes: $colorCodes }
      ) {
            id,
            name,
            colors {
              ${await getColorBasicFields()}
              book {
                id
                title
              }
            }
            collaborators { email }
            creationTimestamp
            lastModifiedTimestamp
            owner { email }
          }
      }`,
          {
            name: moodboard.name,
            colorCodes: moodboard.colors,
            imageUrl: moodboard.imageUrl,
          }
        )
      );

      return { data: data?.data?.createMoodboard };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  updateMoodboard: async (moodboard) => {
    const { API, graphqlOperation } = await initApi();
    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation UpdateMoodboard($id: ID!, $name: String!, $imageUrl: String!, $colorCodes:[String]!) {
        updateMoodboard(
        id: $id,
        input: { name: $name, imageUrl: $imageUrl, colorCodes: $colorCodes }
      ) {
        id,
        name,
        imageUrl,
        colors {
          ${await getColorBasicFields()}
          book {
            id
            title
          }
        }
        collaborators { email }
        creationTimestamp
        lastModifiedTimestamp
        owner { email }
      }
  }`,
          {
            id: moodboard.id,
            name: moodboard.name,
            imageUrl: moodboard.imageUrl,
            colorCodes: moodboard.colors,
          }
        )
      );

      return { data: data?.data?.updateMoodboard };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  getMoodboard: async (moodboardId) => {
    const { API, graphqlOperation } = await initApi();
    try {
      const data = await API.graphql(
        graphqlOperation(`{
      getMoodboard(id:"${moodboardId}" ) {
        id,
          name,
          description,
          imageUrl,
          colors {
            ${await getColorBasicFields()}
            book {
              id
              title
            }
          }
          collaborators { email }
          owner { email }
          creationTimestamp
          lastModifiedTimestamp
      }
    }`)
      );
      return { data: data?.data?.getMoodboard };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  getMoodboards: async (nextToken) => {
    const { API, graphqlOperation } = await initApi();
    const token = nextToken ? `"${nextToken}"` : null;

    try {
      const data = await API.graphql(
        graphqlOperation(`{
    getMoodboards(limit:10 ,  nextToken: ${token} ) {
      moodboards {
        id,
        name,
        description,
        imageUrl,
        colors {
          ${await getColorBasicFields()}
        }
        collaborators { email }
        owner { email }
        creationTimestamp
        lastModifiedTimestamp
      }
      nextToken
    }
  }`)
      );

      return { data: data?.data?.getMoodboards };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  deleteMoodboard: async (id) => {
    const { API, graphqlOperation } = await initApi();
    try {
      const data = await API.graphql(
        graphqlOperation(`mutation DeleteMoodboard{
      deleteMoodboard(
        id: "${id}"
        ) {
        id
      }
      }`)
      );
      return { data };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  getSharedMoodboards: async () => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(`{
        getSharedMoodboards {
        id
        name
        description
        imageUrl,
        colors {
          ${await getColorBasicFields()}
          book {
            id
            title
          }
        }
        collaborators { email }
        owner { email }
        creationTimestamp
        lastModifiedTimestamp
    }
    }`)
      );

      return { data: data?.data?.getSharedMoodboards };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  createCollaborator: async ({ moodboardId, email }) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreateMoodboardCollaborator($id: ID!, $email: String!) {
            createMoodboardCollaborator(id: $id, email: $email) {
              id,
              collaborators { email }
            }
          }`,
          {
            id: moodboardId,
            email,
          }
        )
      );

      return { data: data.data.createMoodboardCollaborator };
    } catch (error) {
      // This is done because we can get an error from Pardot,
      // but it should still mutate the data
      if (error?.data?.createMoodboardCollaborator && error?.errors) {
        return { error: 'Prospect not mailable' };
      }

      if (error.errors[0].message.includes('Error: User not found.'))
        return { error: 'User not found' };

      return { error: error.errors[0].message };
    }
  },

  deleteCollaborator: async ({ moodboardId, email }) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation DeleteMoodboardCollaborator($id: ID!, $email: String!) {
              deleteMoodboardCollaborator(id: $id, email: $email) {
                id,
                collaborators { email }
              }
          }`,
          {
            id: moodboardId,
            email,
          }
        )
      );

      return { data: data.data.deleteMoodboardCollaborator };
    } catch (error) {
      return { error: error.errors };
    }
  },
};

export default moodboardFetchers;
