/* eslint-disable no-await-in-loop */

import { useLazyGetSubscriptionQuery } from '~/v3/react-query/hooks/use-get-subscription';
import { useState, useCallback } from 'react';
import { Subscription } from '../types/subsription';

type UseSyncSubscriptionProps = {
  shouldStopSyncing: (userSubscription: Subscription, arg?: unknown) => boolean;
};

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

function useSyncSubscription({ shouldStopSyncing }: UseSyncSubscriptionProps) {
  const { trigger } = useLazyGetSubscriptionQuery();
  const [isSuccess, setIsSuccess] = useState(false);
  const [isFailure, setIsFailure] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const syncSubscriptionStatus = useCallback(
    async (shouldStopSyncingArg?: unknown) => {
      setIsSuccess(false);
      setIsFailure(false);
      setIsLoading(true);

      const subscriptionIds: string[] = [];

      for (let i = 1; i <= 10; i += 1) {
        const latestData = await trigger();
        const { subscriptions } = latestData;
        subscriptions?.forEach((subscription) => {
          if (subscription.subscriptionId)
            subscriptionIds.push(subscription.subscriptionId);
        });

        const shouldStop = shouldStopSyncing(latestData, shouldStopSyncingArg);

        if (shouldStop) {
          setIsSuccess(true); // mark sync as successful if it finishes before loop ends
          setIsLoading(false);
          return subscriptionIds;
        }

        await delay(200 * i);
      }

      setIsFailure(true); // mark sync as failed if loop completes, meaning sync did not get new data before loop end
      setIsLoading(false);
      return subscriptionIds;
    },
    [shouldStopSyncing, trigger]
  );

  return {
    syncSubscription: syncSubscriptionStatus,
    isSuccess,
    isFailure,
    isLoading,
  };
}

export default useSyncSubscription;
