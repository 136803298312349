import { Document } from '@contentful/rich-text-types';
import { InfiniteQueryObserverResult } from '@tanstack/react-query';

export type ContentfulArticleCarousel = {
  name: string;
  slug: string;
  type: string;
  articles: Article[];
};

export type ContentfulCarousel = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
  photos: ContentfulPhoto[];
};

export type Article = {
  id: string;
  createdAt: string;
  updatedAt: string;
  title: string;
  slug: string;
  keywords: string;
  description: string;
  excerpt: string;
  body: string | null;
  topics: Topic[];
  photo?: ContentfulPhoto;
  curator: ContentfulTag;
  authors: ContentfulTag[];
  carousel: ContentfulCarousel;
  subjectTags: ContentfulTag[];
  industryTags: ContentfulTag[];
  mediumTags: ContentfulTag[];
  paletteTags: ContentfulTag[];
  subcategories: Subcategory[];
  categories?: Category[];
  photoHasPalette: boolean;
  readingTime?: number;
  isSaved?: boolean;
  revision: number;
};

export type GetArticles = {
  items: Article[];
  total: number;
  skip: number;
  limit: number;
};

export type GetCategories = {
  items: Category[];
};

export type GetSubcategories = {
  items: Subcategory[];
};

export type GetTopics = {
  items: Topic[];
};

export type ContentfulTag = {
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
};

export type ContentfulColor = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
  colorCode: string;
  hexCode: string;
};

export type ContentfulPalette = {
  name: string;
  slug: string;
  colors: ContentfulColor[];
  colorStory?: Document;
};

export type ContentfulPhoto = {
  id: string;
  createdAt: string;
  updatedAt: string;
  name: string;
  slug: string;
  image: ContentfulImage;
  palette: ContentfulPalette;
  credit: string;
  revision: number;
};

export type ContentfulImage = {
  title: string;
  description: string;
  url: string;
  width: number;
  height: number;
  fileName: string;
};

export type ContentfulAssetVideo = {
  filename: string;
  url: string;
};

export type ContentfulAssetImage = {
  filename: string;
  url: string;
  width: number;
  height: number;
};

export type EmbeddedAssetNode = {
  video?: ContentfulAssetVideo;
  image?: ContentfulAssetImage;
};

export type ContentfulLink = {
  data: { uri: string };
  content?: { value: string }[];
};

export type UseGetArticlesProperties = {
  limit?: number;
  skip?: number;
  sort: ArticlesSort;
  categories?: string[];
  subcategories?: string[];
  topics?: string[];
};

export type InfiniteQuery<T> = InfiniteQueryObserverResult<
  { data: T } | undefined,
  unknown
>;
export enum ArticlesSort {
  POPULARITY = 'popularity',
  LAST_ADDED = 'createdAt',
}

export type Category = {
  id: string;
  name: string;
  order: number;
  subcategories?: Subcategory[];
  carousel?: ContentfulArticleCarousel;
};

export type Subcategory = {
  id: string;
  name: string;
  order: number;
  topics: Topic[];
};

export type Topic = {
  id: string;
  name: string;
  order: number;
};

export type InnerSlider = {
  state: {
    currentSlide: number;
  };
};

export enum ResolutionJumpStep {
  SmallScreen = 2,
  MediumScreen = 3,
  BigScreen = 5,
}

export enum SocialMedia {
  FACEBOOK = 'Facebook',
  TWITTER = 'X',
  LINKEDIN = 'LinkedIn',
}

export enum ShareChannel {
  FACEBOOK = 'facebook',
  TWITTER = 'twitter',
  LINKEDIN = 'linkedin',
  LINK = 'link',
}

export enum ShareUTMParams {
  utm_campaign = 'share-article',
  utm_source_link = 'share',
  utm_medium_social = 'social',
}

export type ArticleExcerpt = {
  content: {
    content: { value: string }[];
  }[];
};

export enum EventArea {
  ARTICLE_LIST = 'article_list',
  ARTICLE_PAGE = 'article_page',
  SIMILAR_ARTICLES = 'article_similar',
  MY_DASHBOARD = 'mydashboard_page',
}
