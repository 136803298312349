import { Products } from '~/v3/types/coupons';
import { Subscription, SubscriptionProviderType } from '../types/subsription';

const filterCouponCodeSubscriptionsByProduct = (
  subscriptions: Subscription[],
  product: Products
) =>
  subscriptions.filter(
    (sub) =>
      sub?.name?.includes(SubscriptionProviderType.COUPON) &&
      sub.product === product
  );

export default filterCouponCodeSubscriptionsByProduct;
