import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  color: null,
  anchorEl: null,
  anchorPosition: null,
  isInWorkspace: false,
  analytics: null,
};

export const colorMenuSlice = createSlice({
  name: 'colorMenu',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.color = action.payload.color;
      state.anchorEl = action.payload.anchorEl;
      state.anchorPosition = action.payload.anchorPosition;
      state.isInWorkspace = action.payload.isInWorkspace;
      state.analytics = action.payload.analytics;
    },
    close: () => initialState,
  },
});

export const { open, close } = colorMenuSlice.actions;

export default colorMenuSlice.reducer;

export const selectColor = (state) => state.colorMenu.color;
export const selectIsOpen = (state) => state.colorMenu.isOpen;
export const selectAnchorEl = (state) => state.colorMenu.anchorEl;
export const selectAnchorPosition = (state) => state.colorMenu.anchorPosition;
export const selectIsInWorkspace = (state) => state.colorMenu.isInWorkspace;
