import { SvgIcon, SvgIconProps } from '@mui/material';
import React from 'react';

function HarmoniesIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{ width: 11, height: 12, ...sx }}
    >
      <line x1="2.21774" y1="10.3608" x2="5.64631" y2="6.93223" />
      <line x1="5.49638" y1="6.93221" x2="8.92495" y2="10.3608" />
      <line x1="5.64282" y1="3" x2="5.64282" y2="7.28571" />
      <circle cx="5.57132" cy="2.14286" r="2.14286" />
      <rect x="7.71436" y="8.57141" width="3.42857" height="3.42857" />
      <rect y="8.57141" width="3.42857" height="3.42857" />
    </SvgIcon>
  );
}

export default HarmoniesIcon;
