import { BaseRoutePaths } from '~/v2/constants/route';

export enum PaywallPlans {
  BASIC = 'basic',
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  TRENDS_YEARLY = 'trendsYearly',
  TRENDS_MONTHLY = 'trendsMonthly',
  TEAMS = 'teams',
  BUSINESS = 'business',
  TRENDS_AND_MONTHLY = 'monthlyPlusTrends',
  TRENDS_AND_YEARLY = 'yearlyPlusTrends',
  SSO_SETUP_FEE = 'ssoSetupFee',
}

export enum PaywallCases {
  DEFAULT = 'default',
  ADOBE = 'adobe',
  PALETTE_LIMIT = 'paletteLimit',
}

export enum SubscriptionPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export const paywallCaseQueryParam = 'paywallCase';

export enum ProductType {
  PREMIUM = 'PREMIUM',
  TRENDS = 'TRENDS',
}

export const hiddenRoutesPerProduct: Record<ProductType, string[]> = {
  [ProductType.PREMIUM]: [BaseRoutePaths.info],
  [ProductType.TRENDS]: [],
};

export const ssoDescriptionLink =
  'https://intercom.help/pantone/en/collections/3918272-pantone-connect-sso';

export const PAYMENT_DETAILS_STORAGE_KEY = 'paymentDetails';
