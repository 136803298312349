/* eslint-disable react/prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import { ErrorBoundary } from 'react-error-boundary';
import { makeStyles } from '@mui/styles';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import useRouter from '~/v3/hooks/use-router';
import RoutePathname from 'constants/route';
import config from '../config';

const useStyles = makeStyles((theme) => ({
  offlineMessageTitle: {
    fontWeight: 'bold',
    paddingTop: theme.spacing(2),
  },
  errorMessage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    height: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    justifySelf: 'center',
    textAlign: 'center',
  },
  errorData: {
    padding: theme.spacing(4),
  },
}));

function ErrorFallback({ error, resetErrorBoundary }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { push, location } = useRouter();

  const handleReload = () => {
    resetErrorBoundary();
    push(location?.pathname || RoutePathname.myPalettes);
    window.location.reload();
  };

  return (
    <div className={classes.errorMessage}>
      <ErrorOutlineIcon fontSize="large" />
      <Typography variant="button" className={classes.offlineMessageTitle}>
        {t('messages.errorHandler')}
      </Typography>

      {config.DEVELOPMENT && (
        <code className={classes.errorData}>{error?.message}</code>
      )}

      <Button
        variant="outlined"
        size="small"
        color="primary"
        onClick={handleReload}
      >
        {t('labels.reload')}
      </Button>
    </div>
  );
}

function ErrorWrapper({ children }) {
  const onError = (error) => {
    if (error && error.toString().includes('ChunkLoadError'))
      window.location.reload();
  };

  return (
    <ErrorBoundary FallbackComponent={ErrorFallback} onError={onError}>
      {children}
    </ErrorBoundary>
  );
}

ErrorWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

ErrorFallback.defaultProps = {
  error: null,
};

ErrorFallback.propTypes = {
  resetErrorBoundary: PropTypes.func.isRequired,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

export default ErrorWrapper;
