import React from 'react';
import { Box, styled } from '@mui/material';

import LanguagesMenu from '~/v2/components/LanguagesMenu';
import { Language, Promotion } from '~/v3/types/promotion';
import { Image } from '~/v3/components/features/promotions/ui/image';

const Container = styled(Box)(() => ({
  position: 'relative',
  display: 'flex',
  flexGrow: 1,
  overflowY: 'hidden',
}));

const Content = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5, 5),
  width: '30%',
  minWidth: 530,
  overflowY: 'auto',
  display: 'flex',
  flexDirection: 'column',
  borderLeft: `1px solid ${theme.palette.secondary.main}`,
  [theme.breakpoints.down('md')]: {
    minWidth: '45%',
  },
}));

const Header = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

function AuthPage({
  children,
  promotion,
  language,
}: Readonly<{
  children: React.ReactNode;
  promotion: Promotion;
  language: Language;
}>) {
  return (
    <Container data-testid="auth-page-v3">
      <Image promotion={promotion} language={language} />

      <Content>
        <Header>
          <LanguagesMenu />
        </Header>
        {children}
      </Content>
    </Container>
  );
}

export default AuthPage;
