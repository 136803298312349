import { Box, styled, Theme, Typography, Button } from '@mui/material';
import React from 'react';
import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import { getPromotionPath } from '~/v3/components/features/promotions/lib/utils';
import { topBannerBreakpoints, TopBannerBreakpoints } from './model/constants';
import { createTopBannerMediaQuery } from './lib/utils';

const bannerContainerMediaQueries = ({
  promotion,
  area,
  theme,
}: BannerContainerProps & { theme: Theme }) =>
  Object.keys(topBannerBreakpoints).reduce(
    (
      queries: Record<keyof typeof TopBannerBreakpoints, React.CSSProperties>,
      key: string
    ) => {
      const breakpoint =
        topBannerBreakpoints[key as keyof typeof TopBannerBreakpoints];
      return {
        ...queries,
        [createTopBannerMediaQuery(breakpoint.width)]: {
          backgroundImage: `url(${getPromotionPath({
            promotion,
            area,
            language: Language.EN,
            breakpoint,
          })})`,
          ...(breakpoint.width === topBannerBreakpoints.sm390.width && {
            padding: theme.spacing(0.4125, 2.5),
          }),
          ...(breakpoint.width === topBannerBreakpoints.sm412.width && {
            padding: theme.spacing(0.25, 2.5),
          }),
        },
      };
    },
    {} as Record<keyof typeof TopBannerBreakpoints, React.CSSProperties>
  );

type BannerContainerProps = {
  promotion: Promotion;
  area: PromotionsArea;
};

export const BannerContainer = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'promotion' && prop !== 'area',
})<BannerContainerProps>(({ theme, promotion, area }) => ({
  display: 'flex',
  height: 54,
  maxHeight: 54,
  backgroundImage: `url(${getPromotionPath({
    promotion,
    area,
    language: Language.EN,
    breakpoint: topBannerBreakpoints.xl1920,
  })})`,
  backgroundSize: 'cover',
  padding: theme.spacing(0.825, 2.5),
  justifyContent: 'space-between',
  ...bannerContainerMediaQueries({
    promotion,
    area,
    theme,
  }),
}));

export const TextContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  [theme.breakpoints.down(topBannerBreakpoints.md960.width)]: {
    flexDirection: 'column',
    alignItems: 'unset',
  },
  [theme.breakpoints.up(topBannerBreakpoints.md960.width)]: {
    gap: theme.spacing(4),
  },
  [theme.breakpoints.up(topBannerBreakpoints.lg1135.width)]: {
    gap: theme.spacing(5),
  },
  [theme.breakpoints.up(topBannerBreakpoints.lg1680.width)]: {
    gap: theme.spacing(6),
  },
  [theme.breakpoints.down('extension')]: {
    justifyContent: 'center',
  },
}));

export const Headline = styled(Typography)(() => ({
  color: 'white',
  fontSize: 11,
  fontFamily: 'Helvetica Now Display',
  fontWeight: 700,
  lineHeight: '18.6px',
  textTransform: 'capitalize',
  [createTopBannerMediaQuery(topBannerBreakpoints.sm320.width)]: {
    fontSize: 11.9,
    lineHeight: '21.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.sm360.width)]: {
    fontSize: 13.6,
    lineHeight: '23.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.sm390.width)]: {
    fontSize: 14.9,
    lineHeight: '23.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.sm412.width)]: {
    fontSize: 15.9,
    lineHeight: '24.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.sm460.width)]: {
    fontSize: 17.9,
    lineHeight: '24.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.md600.width)]: {
    fontSize: 19,
    lineHeight: '24.6px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.md960.width)]: {
    fontSize: 22.9,
    lineHeight: '57.64px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.lg1135.width)]: {
    fontSize: 27,
    lineHeight: '57.64px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.lg1680.width)]: {
    fontSize: 35,
    lineHeight: '57.64px',
  },
  [createTopBannerMediaQuery(topBannerBreakpoints.xl1920.width)]: {
    fontSize: 38,
    lineHeight: '57.64px',
  },
}));

export const CustomButton = styled(Button)(({ theme }) => ({
  color: 'white',
  backgroundColor: 'black',
  fontFamily: 'Helvetica Now Text',
  borderRadius: '4px',
  height: '40px',
  fontWeight: 500,
  width: '209px',
  fontSize: 13,
  alignSelf: 'center',
  padding: theme.spacing(0, 2),
  ':hover': {
    backgroundColor: 'black',
  },
}));
