import { UseQueryOptions, useMutation } from '@tanstack/react-query';
import { subscribeMeasurements } from '../../api/measure-api';
import { MeasureSubscription } from '../../types/measure';

type UseSubscribeMeasurementsProps = {
  onError?: UseQueryOptions['onError'];
};

function useSubscribeMeasurements({
  onError,
}: UseSubscribeMeasurementsProps = {}) {
  return useMutation(['measurements'], {
    mutationFn: async (
      callback: ({
        colorLAB,
        bookId,
        error,
      }: MeasureSubscription & {
        error?: unknown;
      }) => void
    ) => {
      await subscribeMeasurements(({ colorLAB, bookId }) => {
        callback({ colorLAB, bookId });
      });
    },
    onError,
  });
}

export default useSubscribeMeasurements;
