import { useQuery } from '@tanstack/react-query';
import { syncUserSubscriptions } from '~/v3/api/subscriptions-api';

type UseSyncSubscriptionArguments = {
  skip?: boolean;
};

function useSyncUserSubscription(
  { skip }: UseSyncSubscriptionArguments = { skip: false }
) {
  return useQuery({
    queryKey: ['sync-user-subscriptions'],
    queryFn: syncUserSubscriptions,
    enabled: !skip,
    staleTime: Infinity,
  });
}

export default useSyncUserSubscription;
