import { Box, Button, styled, Typography } from '@mui/material';
import colors from '~/v3/theme/colors';
import Modal from '~/v3/components/core/modal';
import { Promotion } from '~/v3/types/promotion';
import {
  createMediaQueryMaxWidth,
  createMediaQueryMaxWidthAndMaxHeight,
  createMediaQueryWidthAndHeight,
} from '~/v3/utils/media-query';
import { popupBreakpoints } from './ui/breakpoints';

interface ExploreContainerProps {
  promotion: Promotion;
}

export const ExploreContainer = styled(Box)<ExploreContainerProps>(({
  promotion,
}) => {
  let mediaQueries = {};
  if (promotion === Promotion.COY) {
    mediaQueries = {
      [createMediaQueryMaxWidth(popupBreakpoints.maxTablet.width)]: {
        top: 334,
        left: 34,
      },
      [createMediaQueryMaxWidth(popupBreakpoints.maxMobile.width)]: {
        top: 300,
        left: 29,
      },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.iphone16ProMax.width,
        popupBreakpoints.iphone16ProMax.height
      )]: {
        top: 300,
        left: 29,
      },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.iphone14ProMax.width,
        popupBreakpoints.iphone14ProMax.height
      )]: { top: 270, left: 29 },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.pixel7.width,
        popupBreakpoints.pixel7.height
      )]: {
        top: 256,
        left: 28.32,
      },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.iphone12Pro.width,
        popupBreakpoints.iphone12Pro.height
      )]: {
        top: 234,
        left: 28.32,
      },
      [createMediaQueryWidthAndHeight(
        popupBreakpoints.iphoneSe.width,
        popupBreakpoints.iphoneSe.height
      )]: {
        top: 244,
        left: 28.32,
      },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.iphone12Mini.width,
        popupBreakpoints.iphone12Mini.height
      )]: {
        top: 234,
        left: 28.32,
      },
      [createMediaQueryMaxWidthAndMaxHeight(
        popupBreakpoints.extension.width,
        popupBreakpoints.extension.height
      )]: {
        top: 177,
        left: 20,
      },
    };
  }

  return {
    flex: 1,
    alignItems: 'start',
    position: 'absolute',
    top: 402,
    left: 31,
    ...mediaQueries,
  };
});

export const StyledTypography = styled(Typography)(() => ({
  padding: 0,
  fontWeight: 500,
  fontSize: 12.12,
  letterSpacing: '2%',
  fontFamily: 'Helvetica Now Text',
  [createMediaQueryMaxWidth(popupBreakpoints.maxTablet.width)]: {
    fontSize: 11.66,
  },
  [createMediaQueryMaxWidthAndMaxHeight(
    popupBreakpoints.custom3.width,
    popupBreakpoints.custom3.height
  )]: {
    fontSize: 12.12,
  },
  [createMediaQueryMaxWidthAndMaxHeight(
    popupBreakpoints.iphone14ProMax.width,
    popupBreakpoints.iphone14ProMax.height
  )]: { fontSize: 11.41 },
  [createMediaQueryMaxWidthAndMaxHeight(
    popupBreakpoints.pixel7.width,
    popupBreakpoints.pixel7.height
  )]: {
    fontSize: 8.87,
  },
}));

export const StyledButton = styled(Button)(() => ({
  color: colors.white,
  border: '2px solid black',
  borderRadius: '2px',
  backgroundColor: colors.black,
  padding: '0px 13.86px',
  [createMediaQueryMaxWidth(popupBreakpoints.maxTablet.width)]: {
    padding: '0px 13.32px',
  },
  [createMediaQueryMaxWidthAndMaxHeight(
    popupBreakpoints.custom3.width,
    popupBreakpoints.custom3.height
  )]: { padding: '0px 10.13px' },
  '&:hover': {
    color: colors.white,
    border: '2px solid black',
  },
}));

export const ModalContainer = styled(Modal)`
  & > .MuiBox-root {
    width: ${popupBreakpoints.desktop.width}px;
    max-height: ${popupBreakpoints.desktop.height}px;
    height: 100%;
    padding: 0;
    overflow: hidden;

    ${createMediaQueryMaxWidth(popupBreakpoints.maxTablet.width)} {
      width: ${popupBreakpoints.tablet.width}px;
      max-height: ${popupBreakpoints.tablet.height}px;
      height: 100%;
    }

    ${createMediaQueryMaxWidth(popupBreakpoints.maxMobile.width)} {
      width: 100%;
      max-height: 100%;
      height: 100%;
    }
  }
`;

export const DoNotRemindMeButton = styled(Button)({
  color: colors.black,
  background: 'none',
  textDecoration: 'none',
  fontWeight: 700,
  fontSize: 9,
  textTransform: 'none',
  padding: 0,
  cursor: 'pointer',
  '&:hover': {
    textDecoration: 'underline',
    background: 'transparent',
  },
});
