import React from 'react';
import PropTypes from 'prop-types';
import { Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import HourglassEmptyOutlinedIcon from '@mui/icons-material/HourglassEmptyOutlined';

import i18n from '~/v2/i18n';
import { useTranslation } from 'react-i18next';
import useGetActiveNavigation from 'hooks/useGetActiveNavigation';
import useSubscriptionStatus from 'hooks/useSubscriptionStatus';
import languageConstants from 'constants/languages';
import {
  useConfiguredGetSubscriptionQuery,
  useGetSubscriptionUrlQuery,
} from 'services/subscriptionApi';
import { useColorMode } from '~/v3/components/shared/providers/color-mode-provider';
import config from '~/v3/config';
import { useFeatureFlags } from '@xrite/feature-flags-client';
import useProfileMenu from '~/v2/data/useProfileMenu';
import { ClickableMenuItem, MenuDivider } from './UserProfileDropdownMenuItem';

const useStyles = makeStyles((theme) => ({
  userInfoWrapper: {
    display: 'flex',

    padding: theme.spacing(0.5, 3, 1.5, 3),
  },
  userInfoTextWrapper: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    overflow: 'hidden',
  },
  userAvatar: {
    flex: '0 0 auto',
    marginTop: theme.spacing(0.5),
    marginRight: theme.spacing(2),
    width: 40,
    height: 40,
  },
  userInfoText: {
    width: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  subscriptionInfo: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.warning.background,
    padding: theme.spacing(0.5, 1),
    marginTop: theme.spacing(0.875),
    borderRadius: 4,
  },
  daysLeftIcon: {
    color: theme.palette.warning.main,
    fontSize: theme.spacing(2.3),
    marginRight: theme.spacing(1.125),
  },
  daysLeftText: {
    color: theme.palette.warning.main,
  },
}));

function MainContent({
  fullname,
  email,
  userIcon,
  handleClickItem,
  handleOpenSubMenu,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isNavigationItemActive] = useGetActiveNavigation();
  const { data: subscriptionStatus } = useConfiguredGetSubscriptionQuery(
    undefined,
    {
      refetchOnMountOrArgChange: true,
    }
  );
  const { data: userSubscriptionURL } = useGetSubscriptionUrlQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { isOnTrialPeriod, isActiveTrendsSubscription, isActive } =
    useSubscriptionStatus();
  const {
    profileSection,
    themeSection,
    appSection,
    subscriptionSection,
    couponCodeActivationSection,
  } = useProfileMenu();
  const isTrialDaysBannerVisible =
    subscriptionStatus?.daysLeft > 0 && isOnTrialPeriod;
  const hasActiveFastSpringSubscription =
    userSubscriptionURL && (isActive || isActiveTrendsSubscription);

  // TODO: display correct data for selected language and theme
  const selectedLanguage = i18n.languages[0];
  const { currentMode } = useColorMode();
  const { getFeatureFlags } = useFeatureFlags();

  return (
    <>
      <div className={classes.userInfoWrapper}>
        <div className={classes.userAvatar}>{userIcon}</div>
        <div className={classes.userInfoTextWrapper}>
          <Typography className={classes.userInfoText} variant="subtitle1">
            {fullname}
          </Typography>
          <Typography className={classes.userInfoText} variant="body2">
            {email}
          </Typography>
          {config.PAYWALL_ENABLED && isTrialDaysBannerVisible && (
            <div className={classes.subscriptionInfo}>
              <HourglassEmptyOutlinedIcon className={classes.daysLeftIcon} />
              <Typography variant="caption" className={classes.daysLeftText}>
                {t('labels.daysLeft', {
                  days: subscriptionStatus?.daysLeft,
                })}
              </Typography>
            </div>
          )}
        </div>
      </div>

      <MenuDivider />

      <ClickableMenuItem
        key={profileSection.eventName}
        menuItem={profileSection}
        selected={isNavigationItemActive(profileSection)}
        onClick={(...args) => {
          if (typeof profileSection.onClick === 'function') {
            profileSection.onClick();
          }
          handleClickItem(...args);
        }}
      />
      {getFeatureFlags?.isUnlimitedCouponCodeEnabled && (
        <ClickableMenuItem
          menuItem={couponCodeActivationSection}
          selected={isNavigationItemActive(couponCodeActivationSection)}
          onClick={(...args) => {
            if (typeof couponCodeActivationSection.onClick === 'function') {
              couponCodeActivationSection.onClick();
            }
            handleClickItem(...args);
          }}
        />
      )}

      {hasActiveFastSpringSubscription && (
        <ClickableMenuItem
          menuItem={subscriptionSection?.manageSubscription}
          onClick={() =>
            handleOpenSubMenu(subscriptionSection?.manageSubscription.eventName)
          }
          hasSecondLabel
        />
      )}

      {getFeatureFlags?.isThemeEnabled && (
        <ClickableMenuItem
          menuItem={themeSection.changeTheme}
          selected={isNavigationItemActive(themeSection.changeTheme)}
          onClick={() =>
            handleOpenSubMenu(themeSection?.changeTheme?.eventName)
          }
          hasSecondLabel
          secondLabel={t(`labels.${currentMode}Theme`)}
        />
      )}

      <ClickableMenuItem
        menuItem={themeSection.changeLanguage}
        selected={isNavigationItemActive(themeSection.changeLanguage)}
        onClick={() => {
          handleOpenSubMenu(themeSection.changeLanguage.eventName);
          themeSection.changeLanguage.onClick();
        }}
        hasSecondLabel
        secondLabel={languageConstants[selectedLanguage]}
      />
      <MenuDivider />
      {appSection.map((nav) =>
        nav.type === 'divider' ? (
          <MenuDivider key={nav.eventName} />
        ) : (
          <ClickableMenuItem
            key={nav.eventName}
            menuItem={nav}
            selected={isNavigationItemActive(nav)}
            onClick={(...args) => {
              if (typeof nav.onClick === 'function') {
                nav.onClick();
              }
              handleClickItem(...args);
            }}
            id={nav.id}
            className={nav.class}
          />
        )
      )}
    </>
  );
}

MainContent.propTypes = {
  fullname: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  userIcon: PropTypes.node.isRequired,
  handleClickItem: PropTypes.func.isRequired,
  handleOpenSubMenu: PropTypes.func.isRequired,
};

export default MainContent;
