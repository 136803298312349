// depricated
// use v3/constants/colors.ts instead
export default {
  JetBlack: {
    1000: '#000000',
    900: '#161921',
    850: '#222222',
    800: '#222630',
    700: '#292D38',
    600: '#303541',
    500: '#363B48',
    400: '#575F74',
    300: '#7B849C',
    200: '#9FA9C0',
    100: '#BBBECB',
  },
  WhiteGrey: {
    900: '#212121',
    850: '#444444',
    800: '#424242',
    700: '#616161',
    650: '#808080',
    600: '#757575',
    500: '#ADADAD',
    400: '#E0E0E0',
    300: '#F3F3F3',
    200: '#FAFAFA',
    100: '#F5F5F5',
    50: '#FAFAFA',
    0: '#FFFFFF',
  },
  Illuminating: {
    900: '#EECA25',
    800: '#F0D234',
    700: '#F2D73D',
    600: '#F4DB46',
    500: '#F5DF4D',
    400: '#F7E468',
    300: '#F8E982',
    200: '#FAEFA6',
    100: '#FCF5CA',
  },
  LightBlue: {
    900: '#01579B',
    800: '#0277BD',
    700: '#0288D1',
    600: '#039BE5',
    500: '#2196F3',
    400: '#29B6F6',
    300: '#4FC3F7',
    200: '#81D4FA',
    100: '#B3E5FC',
  },
  Purple: {
    300: '#916DF7',
    600: '#7649F5',
    900: '#4920BC',
  },
  Blue: {
    600: '#52ADFF',
    300: '#0086FF',
    100: '#ECF4FE',
  },
  Red: {
    background: '#FEECEB',
    border: '#F9A19A',
  },
  Green: {
    500: '#67AC5C',
  },
  Orange: {
    500: '#F29C39',
  },
};
