export enum Promotion {
  COY = 'coy',
  INSIDER = 'insider',
}

export enum Language {
  CHS = 'chs',
  CHT = 'cht',
  EN = 'en',
  DE = 'de',
  ES = 'es',
  FR = 'fr',
  IT = 'it',
  JA = 'ja',
  KO = 'ko',
  PT = 'pt',
}

export enum PromotionsArea {
  AUTH = 'auth',
  POPUP = 'popup',
  BANNER = 'banner',
}

export type Breakpoint = { width: number; height: number };
