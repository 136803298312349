import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import { getPromotionPath } from '~/v3/components/features/promotions/lib/utils';
import { MobileBreakpoint } from '../../model/types';
import { createMediaQuery, createMediaQueryInBetweenScreens } from '../utils';
import { mobileScreenBreakpoints } from '../../model/constants';

export function useMediaQueriesMobileScreen({
  promotion,
  language,
  area,
}: {
  promotion: Promotion;
  language: Language;
  area: PromotionsArea;
}) {
  const mobileMediaQueries = Object.keys(mobileScreenBreakpoints).reduce(
    (queries, key) => {
      const isExtension =
        (key as MobileBreakpoint) === MobileBreakpoint.extension;
      const breakpoint = mobileScreenBreakpoints[key as MobileBreakpoint];
      return {
        ...queries,
        [createMediaQuery(breakpoint.width, breakpoint.height)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint,
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
        },
        ...(isExtension && {
          [createMediaQueryInBetweenScreens(
            mobileScreenBreakpoints.extension.width,
            mobileScreenBreakpoints.extension.height
          )]: {
            background: `url(${getPromotionPath({
              promotion,
              language,
              breakpoint,
              area,
            })})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'top left',
          },
        }),
      };
    },
    {}
  );

  return mobileMediaQueries;
}
