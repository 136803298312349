import { create } from 'zustand';

type CookiesStore = {
  oneTrustActiveGroups: string;
  areCookiesLoaded: boolean;
  actions: {
    onOneTrustActiveGroupsChanged: (oneTrustActiveGroups: string) => void;
  };
};

export const useCookiesStore = create<CookiesStore>()((set) => ({
  oneTrustActiveGroups: '',
  areCookiesLoaded: false,
  actions: {
    onOneTrustActiveGroupsChanged: (oneTrustActiveGroups) =>
      set({
        oneTrustActiveGroups,
        areCookiesLoaded: true,
      }),
  },
}));
