import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  message: null,
  type: 'default',
  autoHideDuration: 3000,
  style: {
    marginBottom: 0,
  },
};

export const snackbar = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.message = action.payload.message;
      state.type = action.payload.type || initialState.type;
      state.autoHideDuration =
        action.payload.autoHideDuration || initialState.autoHideDuration;
    },
    close: () => initialState,
    setStyle: (state, action) => {
      state.style = action.payload || initialState.style;
    },
  },
});

export const { open, close, setStyle } = snackbar.actions;

export default snackbar.reducer;

export const selectIsOpen = (state) => state.snackbar.isOpen;
export const selectMessage = (state) => state.snackbar.message;
export const selectType = (state) => state.snackbar.type;
export const selectAutoHideDuration = (state) =>
  state.snackbar.autoHideDuration;
export const selectStyle = (state) => state.snackbar.style;
