import { useFeatureFlags } from '@xrite/feature-flags-client';
import { Promotion } from '~/v3/types/promotion';

type PromotionFeatureFlag = {
  promotion: Promotion;
  enabled: boolean;
};

function useGetActivePromotion(): Promotion {
  const { getFeatureFlags } = useFeatureFlags();

  const activePromotion = (
    getFeatureFlags?.activePromotion as unknown as PromotionFeatureFlag
  )?.promotion;

  return activePromotion
    ? (activePromotion.toLowerCase() as Promotion)
    : Promotion.COY;
}
export default useGetActivePromotion;
