import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function ExtractNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
    >
      <path
        d="M2.66859 0.955813C2.41497 0.955783 2.16797 0.955754 1.95879 0.972844C1.72908 0.991612 1.45802 1.0359 1.18527 1.17487C0.807099 1.36756 0.499638 1.67502 0.306951 2.05319C0.167976 2.32594 0.123692 2.597 0.104924 2.82671C0.0878338 3.03589 0.0878631 3.28288 0.0878931 3.53649L0.0878931 4.40453C0.0878631 4.65815 0.0878338 4.90515 0.104924 5.11433C0.123692 5.34404 0.167976 5.6151 0.306951 5.88785C0.499638 6.26602 0.807099 6.57348 1.18527 6.76617C1.45802 6.90514 1.72908 6.94943 1.95879 6.9682C2.16797 6.98529 2.41497 6.98526 2.66859 6.98523H5.54642C5.80004 6.98526 6.04703 6.98529 6.25621 6.9682C6.48593 6.94943 6.75698 6.90514 7.02974 6.76617C7.4079 6.57348 7.71537 6.26602 7.90805 5.88785C8.04703 5.6151 8.09131 5.34404 8.11008 5.11433C8.12717 4.90516 8.12714 4.65818 8.12711 4.40457V3.53651C8.12714 3.2829 8.12717 3.03588 8.11008 2.82671C8.09131 2.597 8.04703 2.32594 7.90805 2.05319C7.71537 1.67502 7.4079 1.36756 7.02974 1.17487C6.75698 1.0359 6.48593 0.991612 6.25621 0.972844C6.04704 0.955754 5.80005 0.955783 5.54643 0.955813L2.66859 0.955813Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M12.7176 13.0146C12.464 13.0146 12.217 13.0146 12.0078 13.0317C11.7781 13.0504 11.507 13.0947 11.2343 13.2337C10.8561 13.4264 10.5487 13.7338 10.356 14.112C10.217 14.3848 10.1727 14.6558 10.1539 14.8855C10.1368 15.0947 10.1369 15.3417 10.1369 15.5953V16.4634C10.1369 16.717 10.1368 16.964 10.1539 17.1731C10.1727 17.4029 10.217 17.6739 10.356 17.9467C10.5487 18.3248 10.8561 18.6323 11.2343 18.825C11.507 18.964 11.7781 19.0082 12.0078 19.027C12.217 19.0441 12.464 19.0441 12.7176 19.044H15.5954C15.8491 19.0441 16.096 19.0441 16.3052 19.027C16.5349 19.0082 16.806 18.964 17.0787 18.825C17.4569 18.6323 17.7644 18.3248 17.9571 17.9467C18.096 17.6739 18.1403 17.4029 18.1591 17.1731C18.1762 16.964 18.1762 16.717 18.1761 16.4634V15.5953C18.1762 15.3417 18.1762 15.0947 18.1591 14.8855C18.1403 14.6558 18.096 14.3848 17.9571 14.112C17.7644 13.7338 17.4569 13.4264 17.0787 13.2337C16.806 13.0947 16.5349 13.0504 16.3052 13.0317C16.096 13.0146 15.8491 13.0146 15.5954 13.0146H12.7176Z"
        fill={theme.palette.icon.secondary}
      />
      <path
        d="M12.7176 0.955813C12.464 0.955783 12.217 0.955754 12.0078 0.972844C11.7781 0.991612 11.507 1.0359 11.2343 1.17487C10.8561 1.36756 10.5487 1.67502 10.356 2.05319C10.217 2.32594 10.1727 2.597 10.1539 2.82671C10.1368 3.03589 10.1369 3.28289 10.1369 3.53651V8.42414C10.1369 8.67776 10.1368 8.92476 10.1539 9.13393C10.1727 9.36365 10.217 9.6347 10.356 9.90746C10.5487 10.2856 10.8561 10.5931 11.2343 10.7858C11.507 10.9247 11.7781 10.969 12.0078 10.9878C12.217 11.0049 12.464 11.0049 12.7176 11.0048H15.5954C15.849 11.0049 16.096 11.0049 16.3052 10.9878C16.5349 10.969 16.806 10.9247 17.0788 10.7858C17.4569 10.5931 17.7644 10.2856 17.9571 9.90746C18.096 9.6347 18.1403 9.36365 18.1591 9.13393C18.1762 8.92476 18.1762 8.67776 18.1761 8.42414V3.53651C18.1762 3.28289 18.1762 3.03589 18.1591 2.82671C18.1403 2.597 18.096 2.32594 17.9571 2.05319C17.7644 1.67502 17.4569 1.36756 17.0788 1.17487C16.806 1.0359 16.5349 0.991612 16.3052 0.972844C16.0961 0.955754 15.8491 0.955783 15.5954 0.955813L12.7176 0.955813Z"
        fill={theme.palette.icon.primary}
      />
      <path
        d="M2.66859 8.99502C2.41497 8.99499 2.16797 8.99497 1.95879 9.01206C1.72908 9.03082 1.45802 9.07511 1.18527 9.21408C0.807099 9.40677 0.499638 9.71423 0.306951 10.0924C0.167976 10.3652 0.123692 10.6362 0.104924 10.8659C0.0878338 11.0751 0.0878631 11.3221 0.0878931 11.5757L0.0878931 16.4634C0.0878631 16.717 0.0878338 16.964 0.104924 17.1731C0.123692 17.4029 0.167976 17.6739 0.306951 17.9467C0.499638 18.3248 0.807099 18.6323 1.18527 18.825C1.45802 18.964 1.72908 19.0082 1.95879 19.027C2.16797 19.0441 2.41498 19.0441 2.6686 19.044H5.5464C5.80003 19.0441 6.04703 19.0441 6.25621 19.027C6.48593 19.0082 6.75698 18.964 7.02974 18.825C7.4079 18.6323 7.71536 18.3248 7.90805 17.9467C8.04703 17.6739 8.09131 17.4029 8.11008 17.1731C8.12717 16.964 8.12714 16.717 8.12711 16.4634V11.5757C8.12714 11.3221 8.12717 11.0751 8.11008 10.8659C8.09131 10.6362 8.04703 10.3652 7.90805 10.0924C7.71536 9.71423 7.4079 9.40677 7.02974 9.21408C6.75698 9.07511 6.48593 9.03082 6.25621 9.01206C6.04704 8.99497 5.80005 8.99499 5.54643 8.99502H2.66859Z"
        fill={theme.palette.icon.primary}
      />
    </SvgIcon>
  );
}

export default ExtractNavigationIcon;
