import { GraphQLSubscription } from '@aws-amplify/api';
import { API, Amplify, Auth, PubSub, graphqlOperation } from 'aws-amplify';
import config from '../../src/config';
import { amplifyUserPoolSettings } from '../../src/utils/amplify';
import { MeasureSubscription } from '../types/measure';

let subscription: { unsubscribe: () => void } | null = null;

export const subscribeMeasurements = async (
  callback: ({
    colorLAB,
    bookId,
    error,
  }: MeasureSubscription & { error?: unknown }) => void
) => {
  Amplify.configure(amplifyUserPoolSettings);
  PubSub.configure(amplifyUserPoolSettings);
  Auth.configure(amplifyUserPoolSettings);

  const currentUser = (await Auth.currentAuthenticatedUser()) as {
    attributes: {
      sub: string;
    };
  };

  if (!currentUser) return;

  const { sub } = currentUser.attributes;

  API.configure({
    aws_appsync_graphqlEndpoint: config.api.API_REALTIME_URI,
  });

  subscription = API.graphql<
    GraphQLSubscription<{
      onMeasurementUpdate: MeasureSubscription & { error?: unknown };
    }>
  >(
    graphqlOperation(
      `subscription TriggerMeasurementUpdate($userId: ID!) {
        onMeasurementUpdate(userId: $userId) {
              colorLAB {
                l
                a
                b
              }
              bookId
            }
          }`,
      {
        userId: sub,
      }
    )
  ).subscribe({
    next: (measurement) => {
      if (!measurement?.value?.data?.onMeasurementUpdate) return;

      const { colorLAB, bookId } = measurement.value.data.onMeasurementUpdate;

      callback({ colorLAB, bookId });
    },
    error: (error: unknown) => {
      callback({ error });
    },
  });

  API.configure({
    aws_appsync_graphqlEndpoint: config.api.API_URI,
  });
};

export const unsubscribeMeasurements = () => {
  if (subscription) {
    subscription.unsubscribe();
  }
};
