import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

const DOT_WIDTH_MIN = 10;

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
  columns: {
    display: 'flex',
    width: '100%',
    height: '100%',
  },
  column: {
    minWidth: 30,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: '1 1 auto',
  },
  dot: {},
  value: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
}));

const LightDarkTheme = ({ colors }) => {
  const classes = useStyles();
  const targetRef = useRef();
  const [componentDimensions, setComponentDimensions] = useState(null);

  const handleResize = () => {
    if (targetRef.current)
      setComponentDimensions(targetRef.current.getBoundingClientRect());
  };
  useLayoutEffect(handleResize, [targetRef]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const themes = [
    {
      key: 'light',
      hex: '#fafafa',
    },
    {
      key: 'dark',
      hex: '#303030',
    },
  ];

  let dotWidth;
  let dotHeight;
  if (componentDimensions) {
    dotWidth = componentDimensions.width / themes.length / 3;
    dotHeight =
      componentDimensions.height / (colors.length + colors.length * 0.5);
    const dotSize = Math.min(dotWidth, dotHeight);
    dotWidth = dotSize;
    dotHeight = dotSize;
    if (dotWidth < DOT_WIDTH_MIN) dotWidth = DOT_WIDTH_MIN;
  }

  return (
    <div className={classes.container}>
      <div className={classes.columns} ref={targetRef}>
        {themes.map((theme) => (
          <div
            key={theme.key}
            className={classes.column}
            style={{
              backgroundColor: theme.hex,
            }}
          >
            {colors.map((color) => (
              <div
                key={color.code}
                className={classes.dot}
                style={{
                  backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
                  width: dotWidth,
                  height: dotHeight,
                }}
              />
            ))}
          </div>
        ))}
      </div>
    </div>
  );
};

LightDarkTheme.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LightDarkTheme;
