import { useEffect, useMemo } from 'react';
import {
  GoogleAnalyticsClient,
  HotjarClient,
  SegmentClient,
  XrpAnalyticsClient,
} from '@xrite/analytics-client';
import config from '~/v2/config';
import { useUserContext } from '~/v3/providers/UserProvider';
import useRouter from '~/v3/hooks/use-router';
import { selectCurrentUser } from '~/v2/services/authApi';
import { useConfiguredGetSubscriptionQuery } from '~/v2/services/subscriptionApi';
import { getIntercomClient, updateIntercomClient } from '~/v2/utils/intercom';
import { addThirdPartyLibraryScript } from '~/v2/utils/oneTrustCookies';
import {
  changeXMLHttpRequestState,
  getXmlHttpRequestHandlerState,
  sendAnalyticsEvent,
} from '~/v3/events/analyticsSlice';
import { useCookiesStore } from '~/v3/store/cookies-store';
import { useDispatch, useSelector } from 'react-redux';
import { isProduction } from '~/v3/config';

function getUserId(user) {
  if (user?.attributes) return user.attributes.sub;
  return user;
}

export function useInitGaTracker() {
  const { location } = useRouter();

  const currentRoute = location?.pathname;

  const { oneTrustActiveGroups, areCookiesLoaded } = useCookiesStore();

  const hasTargetCookiesEnabled =
    oneTrustActiveGroups && oneTrustActiveGroups?.indexOf?.('C0004') >= 0;

  const gaClient = useMemo(() => {
    if (!hasTargetCookiesEnabled || !areCookiesLoaded) {
      return undefined;
    }

    const ga = new GoogleAnalyticsClient({
      gtagId: config.GOOGLE_ANALYTICS_TRACKING_ID,
      gtagSettings: {
        app_name: 'Pantone Connect',
      },
      getPageTitle: () => currentRoute,
      extendGtagScript: addThirdPartyLibraryScript,
    });

    ga.initialize();

    return ga;
  }, [currentRoute, hasTargetCookiesEnabled, areCookiesLoaded]);

  return gaClient;
}
export function useInitHotjar() {
  const { oneTrustActiveGroups, areCookiesLoaded } = useCookiesStore();

  const hasTargetCookiesEnabled =
    oneTrustActiveGroups && oneTrustActiveGroups?.indexOf?.('C0004') >= 0;

  useEffect(() => {
    if (!config.HOTJAR_ID || !areCookiesLoaded) {
      return;
    }

    const hotjarScript = document.querySelector(
      `script[src*="${config.HOTJAR_ID}"]`
    );

    const shouldInitializeHotjarScript = !!(
      hasTargetCookiesEnabled && !hotjarScript
    );

    if (shouldInitializeHotjarScript) {
      const hotjarClient = new HotjarClient({
        hjId: config.HOTJAR_ID,
        extendInitScript: addThirdPartyLibraryScript,
      });

      hotjarClient.initialize();

      return;
    }

    const shouldRemoveHotjarScript = !hasTargetCookiesEnabled && !!hotjarScript;

    if (shouldRemoveHotjarScript) {
      hotjarScript.remove();
    }
  }, [hasTargetCookiesEnabled, areCookiesLoaded]);
}

if (config.SEGMENT_ID) {
  const segmentClient = new SegmentClient({
    segmentId: config.SEGMENT_ID,
  });

  segmentClient.initialize();
}

const anonymousUserId = `anonymous-user-${Date.now()}`;

export function useInitSegment() {
  const { data: user } = useSelector(selectCurrentUser);
  const { analytics } = window;

  const { country } = useUserContext();

  const { oneTrustActiveGroups, areCookiesLoaded } = useCookiesStore();

  const hasTargetCookiesEnabled =
    oneTrustActiveGroups && oneTrustActiveGroups?.indexOf?.('C0004') >= 0;

  const hasDeclinedAllCookies =
    oneTrustActiveGroups.split(',').filter(Boolean).length === 1;

  const username =
    user?.attributes?.sub || user?.attributes?.email || anonymousUserId;
  const email = user?.attributes?.email;
  const name =
    user?.attributes ??
    `${user?.attributes?.given_name} ${user?.attributes?.family_name}`;

  useEffect(() => {
    if (!areCookiesLoaded || (hasDeclinedAllCookies && !country.isFromUS)) {
      return;
    }

    if (!analytics) {
      return;
    }

    if (!hasTargetCookiesEnabled && !country.isFromUS) {
      analytics.identify(anonymousUserId);

      return;
    }

    if (hasTargetCookiesEnabled || country.isFromUS) {
      if (!country) return;

      if (username) {
        analytics.identify(username, {
          email,
          name,
          countryId: country.data?.code,
        });
      } else {
        analytics.identify({
          countryId: country.data?.code,
        });
      }
    }
  }, [
    areCookiesLoaded,
    analytics,
    email,
    hasTargetCookiesEnabled,
    name,
    username,
    hasDeclinedAllCookies,
    country,
  ]);
}

const transformEventToXRPEvent = (event, userId) => {
  return {
    event_name: event.EVENT_ID,
    page_path: event.EVENT_PATH || 'na',
    page_title: event.EVENT_PAGE || 'na',
    session_id: event.session_id,
    ...(userId && { user_id: userId }),
    event_params: {
      ...event,
      ...(userId && { userId }),
    },
  };
};

const xrpAnalyticsClient = new XrpAnalyticsClient({
  isProduction,
  target: 'pantone_connect.webapp',
  type: 'client-event',
});

export function useInitXrpEvents() {
  const { data: user } = useSelector(selectCurrentUser);
  const { data: country } = useUserContext();
  const { data: subscription } = useConfiguredGetSubscriptionQuery(undefined);

  const sendEvent = (event) => {
    try {
      return xrpAnalyticsClient.trackEvent(
        transformEventToXRPEvent(
          {
            PAGE_LOAD:
              window.performance.timing.domContentLoadedEventEnd -
              window.performance.timing.navigationStart,
            USER_COUNTRY: country?.code,
            USER_AGENT: navigator.userAgent,
            CONNECT_SUBSCRIPTION_TYPE: subscription?.subscriptionType,
            ...event,
          },
          getUserId(user)
        )
      );
    } catch (error) {
      console.error(error);
    }

    return null;
  };

  return { sendEvent };
}

export function useInitIntercom() {
  const { data: user, isLoading } = useSelector(selectCurrentUser);

  useEffect(() => {
    if (config.INTERCOM_ENABLED) {
      const head = document.getElementsByTagName('head')[0];
      const frag = document
        .createRange()
        .createContextualFragment(getIntercomClient());
      head.appendChild(frag);
    }
  }, []);

  useEffect(() => {
    if (config.INTERCOM_ENABLED && getUserId(user) && !isLoading) {
      updateIntercomClient({
        name: `${user?.attributes?.given_name} ${user?.attributes?.family_name}`,
        email: user?.attributes?.email,
        createdAt: new Date(),
      });
    }
  }, [user, isLoading]);
}

export function useInitApiRequestsAnalytics() {
  const dispatch = useDispatch();
  const xmlHttpRequestHandlerState = useSelector(getXmlHttpRequestHandlerState);

  useEffect(() => {
    if (config.API_REQUESTS_ANALYTICS_ENABLED) {
      dispatch(
        changeXMLHttpRequestState({
          status: true,
        })
      );
    }
  });

  useEffect(() => {
    if (xmlHttpRequestHandlerState) {
      const oldXHROpen = window.XMLHttpRequest.prototype.open;

      // eslint-disable-next-line no-unused-vars,func-names
      window.XMLHttpRequest.prototype.open = function (method, url) {
        const before = new Date();

        this.addEventListener('loadend', (event) =>
          handleLoadEndEvent(event, before, dispatch)
        );

        return oldXHROpen.apply(this, arguments);
      };
    }
  }, [dispatch, xmlHttpRequestHandlerState]);
}

function handleLoadEndEvent(event, before, dispatch) {
  if (
    event.currentTarget.readyState === 4 &&
    event.currentTarget.status === 200 &&
    event.currentTarget.responseURL.includes(config.api.API_URI)
  ) {
    const now = new Date();
    const diff = now - before;

    const eventResponse = JSON.parse(event.currentTarget.response);
    const responseFieldName = eventResponse.data
      ? Object.keys(eventResponse.data)[0]
      : null;

    triggerAnalyticsEvent(dispatch, responseFieldName, diff);
  }
}

function triggerAnalyticsEvent(dispatch, responseFieldName, duration) {
  setTimeout(() => {
    dispatch(
      sendAnalyticsEvent({
        type: 'XRP',
        data: {
          path: responseFieldName,
          duration,
        },
      })
    );
  }, 5000);
}
