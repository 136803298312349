import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import colors from 'theme/colors';
import CollaboratorsListItem from './CollaboratorsListItem';

const useStyles = makeStyles((theme) => ({
  collaboratorsSection: {
    marginTop: theme.spacing(3),
  },
  collaboratorsContainer: {
    overflow: 'auto',
    maxHeight: 280,
    [theme.breakpoints.down('sm')]: {
      paddingBottom: theme.spacing(1),
    },
  },
  collaboratorContainer: {
    display: 'flex',
    flexGrow: 1,
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(3),
  },
  lastCollaborator: {
    marginBottom: 0,
  },
  subtitle2: {
    marginBottom: theme.spacing(3),
    color: colors.WhiteGrey[500],
  },
}));

function CollaboratorsList({ collaborators, removeCollaborator }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {collaborators.length > 0 && (
        <div className={classes.collaboratorsSection}>
          <Typography className={classes.subtitle2} variant="subtitle2">
            {t('labels.collaborators')}:
          </Typography>
          <div className={classes.collaboratorsContainer}>
            {collaborators.map((collaborator, index) => (
              <div
                key={collaborator.email}
                className={classNames(classes.collaboratorContainer, {
                  [classes.lastCollaborator]:
                    index === collaborators.length - 1,
                })}
              >
                <CollaboratorsListItem
                  initials={collaborator.email.charAt(0).toUpperCase()}
                  email={collaborator.email}
                  onRemove={() => {
                    removeCollaborator(collaborator.email);
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </>
  );
}

CollaboratorsList.defaultProps = {
  collaborators: [],
};

CollaboratorsList.propTypes = {
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
    })
  ),
  removeCollaborator: PropTypes.func.isRequired,
};

export default CollaboratorsList;
