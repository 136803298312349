import uniqBy from 'lodash/uniqBy';
import { api } from './api';
import moodboardGraphql from './moodboardGraphql';

const moodboardsTag = 'Moodboard';
export const sharedMoodboardsTag = 'SharedMoodboard';

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    createMoodboard: build.mutation({
      queryFn: moodboardGraphql.createMoodboard,
      invalidatesTags: [moodboardsTag],
    }),
    updateMoodboard: build.mutation({
      queryFn: moodboardGraphql.updateMoodboard,
      invalidatesTags: [moodboardsTag],
    }),
    deleteMoodboard: build.mutation({
      queryFn: moodboardGraphql.deleteMoodboard,
      invalidatesTags: [moodboardsTag],
    }),
    getMoodboard: build.query({
      queryFn: moodboardGraphql.getMoodboard,
      providesTags: [moodboardsTag],
    }),
    getMoodboards: build.query({
      queryFn: moodboardGraphql.getMoodboards,
      providesTags: [moodboardsTag],
      async onQueryStarted(item, { dispatch, queryFulfilled }) {
        const { data: nextData } = await queryFulfilled;
        // The getMoodboards(undefined) is considered as combined data for all pages.
        // When the 'nextData' Moodboards arrive, we add them to the existing ones.
        const patchResult = dispatch(
          api.util.updateQueryData('getMoodboards', undefined, (draft) => {
            const moodboards = [...draft.moodboards, ...nextData.moodboards];
            draft.moodboards = uniqBy(moodboards, 'id');
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getSharedMoodboards: build.query({
      queryFn: moodboardGraphql.getSharedMoodboards,
      providesTags: [moodboardsTag, sharedMoodboardsTag],
    }),
    createMoodboardCollaborator: build.mutation({
      queryFn: moodboardGraphql.createCollaborator,
      invalidatesTags: [moodboardsTag],
    }),
    deleteMoodboardCollaborator: build.mutation({
      queryFn: moodboardGraphql.deleteCollaborator,
      invalidatesTags: [moodboardsTag],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateMoodboardMutation,
  useUpdateMoodboardMutation,
  useDeleteMoodboardMutation,
  useGetMoodboardQuery,
  useGetMoodboardsQuery,
  useGetSharedMoodboardsQuery,
  useCreateMoodboardCollaboratorMutation,
  useDeleteMoodboardCollaboratorMutation,
  useLazyGetMoodboardQuery,
} = extendedApi;
