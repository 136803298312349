import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function PickNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();
  return (
    <SvgIcon
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: 20,
        height: 20,
        ...sx,
      }}
    >
      <path
        d="M11.1035 2.97026C11.8393 2.29475 13.0323 2.29475 13.7681 2.97026L16.285 5.28084C17.0208 5.95635 17.0208 7.05157 16.285 7.72708L6.7832 16.45V6.9364L11.1035 2.97026Z"
        fill={theme.palette.icon.primary}
      />
      <path
        d="M8.86258 19.0445C8.44292 19.0445 8.23275 18.5787 8.5295 18.3063L15.1242 12.2522C15.2125 12.1711 15.3323 12.1255 15.4573 12.1255H16.2042C17.2448 12.1255 18.0884 12.8999 18.0884 13.8552V17.3148C18.0884 18.2701 17.2448 19.0445 16.2042 19.0445H8.86258Z"
        fill={theme.palette.icon.primary}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.80882 0.955566C0.809837 0.955566 0 1.76521 0 2.76396V17.2311C0 18.2299 0.809837 19.0395 1.80882 19.0395H5.42647C6.42545 19.0395 7.23529 18.2299 7.23529 17.2311V2.76396C7.23529 1.76521 6.42545 0.955566 5.42647 0.955566H1.80882ZM3.61764 16.3269C4.11714 16.3269 4.52206 15.9221 4.52206 15.4227C4.52206 14.9234 4.11714 14.5185 3.61764 14.5185C3.11815 14.5185 2.71323 14.9234 2.71323 15.4227C2.71323 15.9221 3.11815 16.3269 3.61764 16.3269Z"
        fill={theme.palette.icon.secondary}
      />
    </SvgIcon>
  );
}

export default PickNavigationIcon;
