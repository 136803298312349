import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import React from 'react';
import config from '~/v2/config';
import useSubscriptionStatus from '~/v2/hooks/useSubscriptionStatus';
import usePaywall from '~/v2/hooks/usePaywall';
import { Typography } from '@mui/material';
import AddToSwatchIcon from '~/v3/components/shared/icons/add-to-swatch-icon';
import { addColorToChannel, addColorToSwatch } from '~/v2/platforms/adobe';
import { onLeftClickMenuEvent } from '~/v3/events';
import useTracker from '~/v2/analytics/useTracker';
import { useTranslation } from 'react-i18next';
import AddToChannel from '~/v3/components/shared/icons/add-to-channel-icon';
import { StyledMenuItem } from '../left-click-menu.elements';

const enum AdobeKeys {
  addToSwatch = 'addToSwatch',
  addToChannel = 'addToChannel',
}

type AdobeItemsProps = { color: PantoneColorWithBook };
function AdobeItems({ color }: Readonly<AdobeItemsProps>) {
  const ga = useTracker();
  const { t } = useTranslation();
  const { isBasic } = useSubscriptionStatus();
  const { triggerPaywall, paywallCases } = usePaywall();

  function addToSwatchAction() {
    if (isBasic) {
      triggerPaywall(paywallCases.adobe.id);
    } else {
      addColorToSwatch(color);
      ga.trackEvent({
        ...onLeftClickMenuEvent,
        EVENT_LABEL: 'add-to-swatch',
        TERM: color.code,
        EVENT_BOOK_ID: color.book.id,
      });
    }
  }

  function addToChannelAction() {
    if (isBasic) {
      triggerPaywall(paywallCases.adobe.id);
    } else {
      addColorToChannel(color);
      ga.trackEvent({
        ...onLeftClickMenuEvent,
        EVENT_LABEL: 'add-to-channel',
        TERM: color.code,
        EVENT_BOOK_ID: color.book.id,
      });
    }
  }

  return (
    <>
      <StyledMenuItem
        rgb={color?.rgb}
        key={AdobeKeys.addToSwatch}
        stroke
        fill
        onClick={() => addToSwatchAction()}
      >
        <AddToSwatchIcon />

        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.addToSwatch')}
        </Typography>
      </StyledMenuItem>

      {config.ENABLE_ADD_TO_CHANNEL && (
        <StyledMenuItem
          rgb={color?.rgb}
          key={AdobeKeys.addToChannel}
          stroke
          fill
          onClick={() => addToChannelAction()}
        >
          <AddToChannel />

          <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
            {t('labels.addToChannel')}
          </Typography>
        </StyledMenuItem>
      )}
    </>
  );
}

export default AdobeItems;
