import useConfirm from 'features/ConfirmDialog/useConfirm';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'services/authApi';

function useSessionExpired() {
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { error } = useSelector(selectCurrentUser);

  const openAlert = async () => {
    const isConfirmed = await confirm({
      content: t('labels.sessionExpired'),
      confirmLabel: t('labels.reload'),
      cancelButton: false,
      disableBackdropClick: true,
    });

    if (isConfirmed) window.location.reload();
  };

  useEffect(() => {
    if (error === 'The user is not authenticated') {
      openAlert();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);
}

export default useSessionExpired;
