import { useEffect } from 'react';
import useTracker from '~/v2/analytics/useTracker';
import { useAnalytics } from '~/v2/components/AnalyticsProvider';
import { GoogleAnalyticsClient } from '@xrite/analytics-client';
import { useGetEventSubscriptionData } from './use-get-event-subscription-data';
import { AnalyticsEvent } from '../types';

type UseTrackOnSubscriptionDataReadyProps = {
  events: AnalyticsEvent[];
  skip?: boolean;
};

function useTrackOnSubscriptionDataReady({
  events,
  skip = false,
}: UseTrackOnSubscriptionDataReadyProps) {
  const { trackEvent } = useTracker();
  const { PREMIUM, TRENDS } = useGetEventSubscriptionData();
  const { ga } = useAnalytics() as {
    ga: GoogleAnalyticsClient;
  };

  useEffect(() => {
    if (ga && PREMIUM && TRENDS && events?.length && !skip) {
      events.forEach((event) => {
        trackEvent(event);
      });
    }
    // There is no need for adding extra dependencies since the core properties are covered in this dependency array
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    ga,
    PREMIUM?.CONNECT_SUBSCRIPTION_ID,
    TRENDS?.TREND_SUBSCRIPTION_ID,
    skip,
  ]);
}

export default useTrackOnSubscriptionDataReady;
