import { Avatar, IconButton, IconButtonProps } from '@mui/material';
import React from 'react';
import useGetCurrentUser from '~/v3/react-query/hooks/use-get-current-user';
import Colors from '~/v3/theme/colors';
import SubscriptionStatus from './subscription-status';

function AvatarIcon({ onClick }: IconButtonProps) {
  const { data: user } = useGetCurrentUser();

  const initials = `${user?.attributes?.given_name?.charAt(
    0
  )}${user?.attributes?.family_name?.charAt(0)}`;

  return (
    <IconButton
      sx={{
        marginLeft: 1,
      }}
      onClick={onClick}
      data-testid="avatar-button"
    >
      <Avatar
        sx={{
          width: 34,
          height: 34,
          fontSize: 14,
          background: Colors.darkGrey,
        }}
      >
        {initials}
      </Avatar>
      <SubscriptionStatus />
    </IconButton>
  );
}

export default AvatarIcon;
