import React from 'react';
import { styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSubscriptionData } from '~/v3/hooks/use-subscription-data';
import Colors from '~/v3/theme/colors';

const StyledChip = styled('span')(({ theme }) => ({
  top: 38,
  padding: theme.spacing(0.25, 0.5),
  position: 'absolute',
  fontSize: 8,
  background: Colors.pantoneLogoBlue,
  fontWeight: 500,
  borderRadius: 3,
  color: Colors.white,
  width: 'max-content',
}));

function SubscriptionStatus() {
  const { t } = useTranslation(['profile']);
  const subscriptionData = useSubscriptionData();

  if (!subscriptionData) {
    return null;
  }

  const { labelKey, backgroundColor } = subscriptionData;

  return <StyledChip sx={{ backgroundColor }}>{t(labelKey)}</StyledChip>;
}

export default SubscriptionStatus;
