import React from 'react';
import PropTypes from 'prop-types';
import blinder from '@hexorialstudio/color-blinder';
import { makeStyles } from '@mui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flex: '1 1 auto',
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    flex: '1 1 auto',
  },
  titlesContainer: {
    fontSize: 12,
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    justifyContent: 'space-around',
    marginRight: theme.spacing(1),
    flexShrink: 0,
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    lineHeight: '100%',
  },
  subtitle: {
    fontSize: 12,
    color: theme.palette.text.secondary,
  },
  colorPatch: {
    flex: '1 1 auto',
    minWidth: 30,
  },
}));

function ColorBlind({ colors }) {
  const { t } = useTranslation();
  const classes = useStyles();

  const deuteranomalyRgbArray = colors.map((color) =>
    blinder.deuteranomaly(color.hex)
  );

  const deuteranopiaRgbArray = colors.map((color) =>
    blinder.deuteranopia(color.hex)
  );

  const protanomalyRgbArray = colors.map((color) =>
    blinder.protanomaly(color.hex)
  );

  const protanopiaRgbArray = colors.map((color) =>
    blinder.protanopia(color.hex)
  );

  return (
    <div className={classes.container}>
      <div className={classes.titlesContainer}>
        <div className={classes.title}>
          {t('labels.paletteAnalysis.original')}
          <div className={classes.subtitle}>
            {t('labels.paletteAnalysis.noColorDeficit')}
          </div>
        </div>
        <div className={classes.title}>
          {t('labels.paletteAnalysis.deuteranomaly')}
          <div className={classes.subtitle}>
            <span role="img" aria-label="males">
              ♂️
            </span>
            5.0%{' '}
            <span role="img" aria-label="females">
              ♀️
            </span>
            0.35%
          </div>
        </div>
        <div className={classes.title}>
          {t('labels.paletteAnalysis.deuteranopia')}
          <div className={classes.subtitle}>
            <span role="img" aria-label="males">
              ♂️
            </span>
            1.2%{' '}
            <span role="img" aria-label="females">
              ♀️
            </span>
            0.01%
          </div>
        </div>
        <div className={classes.title}>
          {t('labels.paletteAnalysis.protanomaly')}
          <div className={classes.subtitle}>
            <span role="img" aria-label="males">
              ♂️
            </span>
            1.3%{' '}
            <span role="img" aria-label="females">
              ♀️
            </span>
            0.02%
          </div>
        </div>
        <div className={classes.title}>
          {t('labels.paletteAnalysis.protanopia')}
          <div className={classes.subtitle}>
            <span role="img" aria-label="males">
              ♂️
            </span>
            1.3%{' '}
            <span role="img" aria-label="females">
              ♀️
            </span>
            0.02%
          </div>
        </div>
      </div>
      {colors.map((color, index) => (
        <div key={color.code} className={classes.column}>
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
            }}
          />
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `${deuteranomalyRgbArray[index]}`,
            }}
          />
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `${deuteranopiaRgbArray[index]}`,
            }}
          />
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `${protanomalyRgbArray[index]}`,
            }}
          />
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `${protanopiaRgbArray[index]}`,
            }}
          />
        </div>
      ))}
    </div>
  );
}

ColorBlind.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default ColorBlind;
