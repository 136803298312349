import useRouter from '~/v3/hooks/use-router';

function useGetActiveNavigation() {
  const { pathname } = useRouter();
  // State and setters for debounced value
  function isNavigationItemActive(navigation) {
    if (navigation.pathname === pathname) return true;
    if (
      navigation?.subMenu &&
      Object.values(navigation?.subMenu).find(
        (nav) => nav.pathname === pathname
      )
    ) {
      return true;
    }
    if (
      navigation?.menuPaths &&
      Object.values(navigation?.menuPaths).find((nav) =>
        pathname.includes(nav.pathname)
      )
    ) {
      return true;
    }

    return false;
  }

  function getActiveItemIndex(navigation) {
    return Object.values(navigation).findIndex((index) => {
      if (index?.subMenu) {
        return Object.values(index.subMenu).some(
          (e) => e.pathname === pathname
        );
      }
      return index.pathname === pathname;
    });
  }

  return [isNavigationItemActive, getActiveItemIndex];
}

export default useGetActiveNavigation;
