import { Promotion } from '~/v3/types/promotion';
import { COY_EVENT_TERM } from '../model/constants';

export function getEventTerm(promotion: Promotion) {
  switch (promotion) {
    case Promotion.COY:
      return COY_EVENT_TERM;
    case Promotion.INSIDER:
      return 'Pantone Color Insider';
    default:
      return 'na';
  }
}

export const createTopBannerMediaQuery = (width: number, height?: number) => {
  if (!height) {
    return `@media (min-width: ${width}px)`;
  }

  return `@media (width: ${width}px) and (height: ${height}px)`;
};
