import {
  Box,
  ListItem,
  ListItemButton,
  ListItemText,
  Tooltip,
} from '@mui/material';
import React from 'react';
import Circle from '~/v3/components/core/circle';
import colors from '~/v3/theme/colors';

type SidebarMenuListItemProps = {
  name: string;
  isSelected?: boolean;
  onClick?: () => void;
};

function SidebarMenuListItem({
  name,
  onClick,
  isSelected,
}: Readonly<SidebarMenuListItemProps>) {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
      <Circle
        size={6}
        sx={{
          opacity: isSelected ? 1 : 0,
          transition: 'all 0.15s linear',
          position: 'absolute',
          left: -16,
        }}
      />
      <ListItem
        sx={{
          width: '90%',
          padding: 0,
          '& .MuiTypography-root': {
            fontSize: 12,
            paddingTop: 0,
            fontStyle: 'normal',
            fontWeight: 400,
            lineHeight: '145%',
          },
        }}
      >
        <Tooltip title={name} placement="bottom">
          <ListItemButton
            sx={{
              padding: 0,
            }}
            onClick={onClick}
          >
            <ListItemText
              data-testid="palette-title"
              sx={{
                '& span': {
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  color: isSelected ? colors.black : colors.mediumGrey,
                },
              }}
              primary={name}
            />
          </ListItemButton>
        </Tooltip>
      </ListItem>
    </Box>
  );
}

export default SidebarMenuListItem;
