export const FASTSPRING_SINGLESEAT_PRODUCTS = {
  monthly: 'pantone-connect-monthly-subscription',
  yearly: 'single-seat-yearly-with-30-day-trial',
  yearlyCH: 'single-seat-yearly-with-30-day-trial',
  yearlyLimitedTime: 'pantone-connect-yearly-subscription-limited-time',
  yearlyNoRenewal: 'yearly-full-access-no-renewal-and-payment',
  singleSeatMonthly: 'single-seat-monthly-14-99',
  singleSeatYearly: 'single-seat-yearly-89-99',
  trendsMonthly: 'Color-Insight-Single-Seat-Monthly',
  trendsYearly: 'Color-Insight-Single-Seat-Yearly',
} as const;

export const FASTSPRING_MULTISEAT_PRODUCTS = {
  yearlyMultiSeatDiscounted:
    'pantone-connect-yearly-subscription-multi-seat-discounted',
  yearlyMultiSeatNoVolume: 'multi-seat-yearly-no-volume-discount',
  yearlyMultiSeat: 'pantone-connect-yearly-multi-seat-licensing',
  multiSeatYearly: 'pantone-connect-yearly-multiseat-89-99',
  businessYearly: 'Business-yearly-149-99',
  ssoSetupFee: 'sso-set-up-fee',
} as const;

export type FastSpringProducts =
  | (typeof FASTSPRING_SINGLESEAT_PRODUCTS)[keyof typeof FASTSPRING_SINGLESEAT_PRODUCTS]
  | (typeof FASTSPRING_MULTISEAT_PRODUCTS)[keyof typeof FASTSPRING_MULTISEAT_PRODUCTS];

export const productName: Partial<
  Record<FastSpringProducts | 'no-active-product', string>
> = {
  'no-active-product': 'labels.basic',
  'Color-Insight-Single-Seat-Monthly': 'paywall:plans.insightPremiumMonthly',
  'Color-Insight-Single-Seat-Yearly': 'paywall:plans.insightPremiumYearly',
  'single-seat-yearly-89-99': 'paywall:plans.connectPremiumYearly',
  'single-seat-monthly-14-99': 'paywall:plans.connectPremiumMonthly',
  'pantone-connect-yearly-multiseat-89-99':
    'paywall:plans.connectPremiumYearly',
};
