export function keyboardDidShow() {
  window.addEventListener('keyboardDidShow', () => {
    if (document.activeElement.nodeName === 'INPUT') {
      document.activeElement.scrollIntoViewIfNeeded();
    }
  });
}

export default {
  keyboardDidShow,
};
