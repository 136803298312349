import { useGetEventConnectSubscriptionType } from '~/v3/analytics/hooks/use-get-event-connect-subscription-type';
import { useGetEventTrendSubscriptionType } from '~/v3/analytics/hooks/use-get-event-trend-subscription-type';
import useSubscriptions from '~/v3/hooks/use-subscriptions';
import { ProductType } from '~/v3/paywall/constants';

export type UseGetEventSubscriptionDataResponse = {
  PREMIUM:
    | {
        CONNECT_SUBSCRIPTION_TYPE: string;
        CONNECT_SUBSCRIPTION_ID?: string;
      }
    | undefined;
  TRENDS:
    | {
        TREND_SUBSCRIPTION_TYPE: string;
        TREND_SUBSCRIPTION_ID?: string;
      }
    | undefined;
};

export const useGetEventSubscriptionData =
  (): UseGetEventSubscriptionDataResponse => {
    const {
      subscriptions: premiumSubscriptions,
      isLoading: isLoadingPremiumSubscriptions,
    } = useSubscriptions(ProductType.PREMIUM);
    const {
      subscriptions: trendsSubscriptions,
      isLoading: isLoadingTrendsSubscriptions,
    } = useSubscriptions(ProductType.TRENDS);

    const premiumSubscriptionType = useGetEventConnectSubscriptionType();
    const trendsSubscriptionType = useGetEventTrendSubscriptionType();

    const isLoadingSubscriptions =
      isLoadingPremiumSubscriptions ||
      isLoadingTrendsSubscriptions ||
      !premiumSubscriptionType ||
      !trendsSubscriptionType;

    if (isLoadingSubscriptions) {
      return {
        [ProductType.PREMIUM]: undefined,
        [ProductType.TRENDS]: undefined,
      };
    }

    return {
      [ProductType.PREMIUM]: {
        CONNECT_SUBSCRIPTION_TYPE: premiumSubscriptionType,
        CONNECT_SUBSCRIPTION_ID:
          premiumSubscriptions[0]?.subscriptionId ?? 'na',
      },
      [ProductType.TRENDS]: {
        TREND_SUBSCRIPTION_TYPE: trendsSubscriptionType,
        TREND_SUBSCRIPTION_ID: trendsSubscriptions[0]?.subscriptionId ?? 'na',
      },
    };
  };
