import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme) => ({
  skeleton: {
    background: 'transparent',
    border: `1px solid ${theme.palette.divider}`,
    cursor: 'auto',
    position: 'relative',
  },
}));

function PantoneChipSmallSkeleton({ size, className, onClick, children }) {
  const classes = useStyles();

  return (
    <div
      role="presentation"
      style={{
        width: size,
        height: size,
      }}
      onClick={onClick}
      className={classNames(classes.skeleton, className)}
    >
      {children}
    </div>
  );
}

PantoneChipSmallSkeleton.defaultProps = {
  size: 60,
  children: null,
  onClick: null,
  className: null,
};
PantoneChipSmallSkeleton.propTypes = {
  size: PropTypes.number,
  children: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

export default PantoneChipSmallSkeleton;
