import { Stack, StackProps, SxProps, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import PantoneLogo from '~/v3/components/shared/icons/pantone-logo';
import { PantoneColor } from '~/v3/types/pantone-color';

import colors from '~/v3/theme/colors';
import {
  colorCodeFontRatio,
  colorCodeMarginRatio,
  colorNameFontRatio,
  pantoneChipInfoAreaPaddingRatio,
  pantoneLogoHeightRatio,
  pantoneLogoMarginRatio,
  pantoneLogoWidthRatio,
} from '~/v3/constants/chip';

type ColorInfoProps = {
  color: PantoneColor;
} & Omit<StackProps, 'color'>;

const textStyles: SxProps = {
  fontFamily: 'Helvetica Now Text',
  lineHeight: '85%',
  letterSpacing: 0,
};

function ChipInfoArea({ color: { code, name }, sx, ...rest }: ColorInfoProps) {
  const refEl = useRef<HTMLDivElement>(null);
  const [chipInfoAreaWidth, setChipInfoAreaWidth] = useState(0);

  useEffect(() => {
    if (refEl?.current?.offsetWidth) {
      setChipInfoAreaWidth(refEl?.current?.offsetWidth);
    }
  }, [refEl]);

  return (
    <Stack
      direction="column"
      data-testid="chip-info-area"
      ref={refEl}
      sx={{
        padding: `${chipInfoAreaWidth * pantoneChipInfoAreaPaddingRatio}px`,
        paddingBottom: 0,
        backgroundColor: colors.white,
        width: '100%',
        aspectRatio: '2',
        ...sx,
      }}
      {...rest}
    >
      <PantoneLogo
        data-testid="chip-info-area-pantone-logo"
        sx={{
          width: `${chipInfoAreaWidth * pantoneLogoWidthRatio}px`,
          height: `${chipInfoAreaWidth * pantoneLogoHeightRatio}px`,
          marginBottom: `${chipInfoAreaWidth * pantoneLogoMarginRatio}px`,
        }}
      />

      <Typography
        data-testid="chip-info-area-color-code"
        sx={{
          ...textStyles,
          color: colors.pantoneChipBlack,
          fontSize: `${chipInfoAreaWidth * colorCodeFontRatio}px`,
          marginBottom: `${chipInfoAreaWidth * colorCodeMarginRatio}px`,
        }}
      >
        {code}
      </Typography>

      <Typography
        data-testid="chip-info-area-color-name"
        sx={{
          ...textStyles,
          color: colors.pantoneChipBlack,
          fontSize: `${chipInfoAreaWidth * colorNameFontRatio}px`,
          fontWeight: 'bold',
        }}
      >
        {name}
      </Typography>
    </Stack>
  );
}

export default ChipInfoArea;
