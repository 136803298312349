/* eslint-disable prettier/prettier */
import {
  amplifyUserPoolSettings,
  amplifyApiKeySettings,
} from '../utils/amplify';

const initApi = async (options) => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');
  API.configure(options);
  return { API, graphqlOperation };
};

const subscriptionFetchers = {
  getProducts: async () => {
    const { API, graphqlOperation } = await initApi(amplifyApiKeySettings);

    try {
      const data = await API.graphql(
        graphqlOperation(
          `query getProducts {
            getProducts {
              monthly {
                currency
                display
                price
              }
              yearly {
                currency
                display
                price
              }
              teams {
                currency
                display
                price
                storefrontURL
              }
              business {
                currency
                display
                price
                storefrontURL
              }
              ssoSetupFee {
                currency
                display
                price
                storefrontURL
              }
            }
          }`
        )
      );
      return { data: data?.data?.getProducts };
    } catch (error) {
      throw new Error({ error: error?.errors });
    }
  },
  getSubscriptionUrl: async () => {
    const { API, graphqlOperation } = await initApi(amplifyUserPoolSettings);

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          getSubscriptionManagement {
            url
          }
        }`)
      );

      return { data: data?.data?.getSubscriptionManagement?.url };
    } catch (error) {
      return { error };
    }
  },
  cancelSubscription: async ({ deleteNow = false, subscriptionId = '' }) => {
    const { API, graphqlOperation } = await initApi(amplifyUserPoolSettings);

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          cancelSubscription(deleteNow: ${deleteNow}, subscriptionId: "${subscriptionId}") {
              active
              state
            }
          }`)
      );

      return { data: data?.data?.getSubscription };
    } catch (error) {
      return { error };
    }
  },
  createFastSpringSecurePayload: async ({
    subscriptionType,
    userData,
    quantity,
  }) => {
    const { API, graphqlOperation } = await initApi(
      !userData ? amplifyApiKeySettings : amplifyUserPoolSettings
    );

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreateFastSpringSecurePayload {
            createFastSpringSecurePayload(
              type: "${subscriptionType}"
              quantity: ${quantity || 1}
              ${
                userData
                  ? `userData: {
                email: "${userData.email}",
                firstName: "${userData.firstName}",
                lastName: "${userData.lastName}",
              }`
                  : ''
              }
            ) {
              securePayload,
              secureKey
            }
          }`
        )
      );
      return { data: data?.data?.createFastSpringSecurePayload };
    } catch (error) {
      return { error };
    }
  },
};

export default subscriptionFetchers;
