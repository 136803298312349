import React from 'react';
import { useTheme } from '@mui/material';

import PaletteAnalysis from 'components/PaletteAnalysis';
import FullScreenDialog from 'components/FullScreenDialogNew';
import usePaletteAnalysis from './usePaletteAnalysis';

function PaletteAnalysisDialog() {
  const { isOpen, palette, close } = usePaletteAnalysis();
  const theme = useTheme();

  return (
    <FullScreenDialog
      open={isOpen}
      title={palette?.name}
      style={{ zIndex: theme.zIndex.popover }}
      onClose={close}
    >
      {palette && <PaletteAnalysis colors={palette?.colors} />}
    </FullScreenDialog>
  );
}

export default PaletteAnalysisDialog;
