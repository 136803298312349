import { getColorBasicFields } from 'api/shared/queryFields';
import { amplifyUserPoolSettings } from 'utils/amplify';

const initApi = async () => {
  const { API, graphqlOperation } = await import('@aws-amplify/api');

  API.configure(amplifyUserPoolSettings);

  return { API, graphqlOperation };
};

const paletteFetchers = {
  createPalette: async (palette) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreatePalette($name: String!, $description: String, $tags: [String], $colorCodes:[String]!) {
            createPalette(
              input: { name: $name, description: $description, tags: $tags, colorCodes: $colorCodes }
            ) {
                id,
                name,
                description,
                tags,
                colors {
                  ${await getColorBasicFields()}
                  book {
                    id
                    title
                  }
                }
                collaborators { email }
                creationTimestamp
                lastModifiedTimestamp
                owner { email }
              }
          }`,
          {
            tags: palette.tags,
            name: palette.name,
            description: palette.description,
            colorCodes: palette.colors,
          }
        )
      );
      return { data: data?.data?.createPalette };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  updatePalette: async (palette) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation UpdatePalette($id: ID!, $name: String!, $description: String, $tags: [String], $colorCodes:[String]!) {
            updatePalette(
              id: $id,
              input: { name: $name, description: $description, tags: $tags, colorCodes: $colorCodes }
            ) {
                id,
                name,
                description,
                tags,
                colors {
                  ${await getColorBasicFields()}
                  book {
                    id
                    title
                  }
                }
                collaborators { email }
                creationTimestamp
                lastModifiedTimestamp
                owner { email }
              }
          }`,
          {
            id: palette.id,
            tags: palette.tags,
            name: palette.name,
            description: palette.description,
            colorCodes: palette.colors,
          }
        )
      );

      return { data: data?.data?.updatePalette };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  deletePalette: async (paletteId) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(`
          mutation DeletePalette {
            deletePalette(id: "${paletteId}") {
              id
            }
          }`)
      );

      return { data };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  getPalette: async (paletteId) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          getPalette(id:"${paletteId}" ) {
            id,
            name,
            description,
            tags,
            colors {
              ${await getColorBasicFields()}
              book {
                id
                title
              }
            }
            collaborators { email }
            owner { email }
            creationTimestamp
            lastModifiedTimestamp
          }
        }`)
      );

      return { data: data?.data?.getPalette };
    } catch (error) {
      return { error: error?.errors };
    }
  },

  getPalettes: async (nextToken) => {
    const { API, graphqlOperation } = await initApi();
    const token = nextToken ? `"${nextToken}"` : null;

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          getPalettes(limit:10, nextToken: ${token}) {
            palettes {
              id,
              name,
              description,
              tags,
              colors {
                ${await getColorBasicFields()}
                book {
                  id
                  title
                }
              }
              collaborators { email }
              owner { email }
              creationTimestamp
              lastModifiedTimestamp
            }
            nextToken
          }
        }`)
      );

      return { data: data?.data?.getPalettes };
    } catch (error) {
      return { error: error.errors };
    }
  },

  getSharedPalettes: async () => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(`{
          getSharedPalettes {
            id
            name
            description
            tags
            colors {
              ${await getColorBasicFields()}
              book {
                id
                title
              }
            }
            collaborators { email }
            owner { email }
            creationTimestamp
            lastModifiedTimestamp
          }
        }`)
      );

      return { data: data?.data?.getSharedPalettes };
    } catch (error) {
      return { error: error.errors };
    }
  },

  createCollaborator: async ({ paletteId, email }) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation CreatePaletteCollaborator($id: ID!, $email: String!) {
            createPaletteCollaborator(id: $id, email: $email) {
              id,
              collaborators { email }
            }
          }`,
          { id: paletteId, email }
        )
      );

      return { data: data?.data?.createPaletteCollaborator };
    } catch (error) {
      // This is done because we can get an error from Pardot,
      // but it should still mutate the data
      if (error?.data?.createPaletteCollaborator && error?.errors) {
        return { error: 'Prospect not mailable' };
      }

      if (error.errors[0].message.includes('Error: User not found.'))
        return { error: 'User not found' };

      return { error: error.errors[0].message };
    }
  },

  deleteCollaborator: async ({ paletteId, email }) => {
    const { API, graphqlOperation } = await initApi();

    try {
      const data = await API.graphql(
        graphqlOperation(
          `mutation DeletePaletteCollaborator($id: ID!, $email: String!) {
            deletePaletteCollaborator(id: $id, email: $email) {
              id,
              collaborators { email }
            }
          }`,
          { id: paletteId, email }
        )
      );

      return { data: data?.data?.deletePaletteCollaborator };
    } catch (error) {
      return { error: error.errors[0].message };
    }
  },
};

export default paletteFetchers;
