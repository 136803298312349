import { useTheme } from '@mui/material';
import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import { getPromotionPath } from '~/v3/components/features/promotions/lib/utils';
import { createMediaQuery } from '../utils';
import { desktopScreenBreakpoints } from '../../model/constants';
import { DesktopBreakpoint } from '../../model/types';

export function useGetMediaQueriesDesktopScreen({
  promotion,
  language,
  area,
}: {
  promotion: Promotion;
  language: Language;
  area: PromotionsArea;
}) {
  const theme = useTheme();

  const desktopMediaQueries = Object.keys(desktopScreenBreakpoints).reduce(
    (queries, key) => {
      const breakpoint = desktopScreenBreakpoints[key as DesktopBreakpoint];
      return {
        ...queries,
        [theme.breakpoints.up(breakpoint.width)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint,
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
        },
        [createMediaQuery(breakpoint.width, breakpoint.height)]: {
          background: `url(${getPromotionPath({
            promotion,
            language,
            breakpoint,
            area,
          })})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'top left',
        },
      };
    },
    {} as Record<string, React.CSSProperties>
  );

  return desktopMediaQueries;
}
