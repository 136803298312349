import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CollaboratorsUI from 'components/CollaboratorsUI';
import events from 'analytics/events';
import useTracker from 'analytics/useTracker';
import { hasPanoramaColors } from 'utils/palette';
import {
  useCreateCollaboratorMutation,
  useDeleteCollaboratorMutation,
} from 'services/paletteApi';

function PaletteCollaborators({ palette, disabled }) {
  const ga = useTracker();
  const { t } = useTranslation();
  const [validationError, setValidationError] = useState('');
  const [createCollaborator, { isLoading: isCreateLoading, error }] =
    useCreateCollaboratorMutation();
  const [deleteCollaborator, { isLoading: isDeleteLoading }] =
    useDeleteCollaboratorMutation();

  const handleClearValidationError = () => {
    setValidationError('');
  };

  const handleSharePalette = async (value) => {
    const paletteId = value?.palette?.id;

    if (value.removeEmail) {
      await deleteCollaborator({ paletteId, email: value.removeEmail });
      ga.trackEvent(events.e46);
    } else {
      await createCollaborator({ paletteId, email: value.email });
      ga.trackEvent(events.e42);
    }
  };

  useEffect(() => {
    if (error === 'Prospect not mailable') {
      setValidationError(t('labels.prospectNotMailable'));
    } else if (error === 'User not found')
      setValidationError(t('messages.collaboratorUserNotFound'));
    else {
      setValidationError(error);
    }
  }, [error, t]);

  return (
    <CollaboratorsUI
      palette={palette}
      disabled={!palette || disabled}
      isLoading={isCreateLoading || isDeleteLoading}
      collaborators={palette?.collaborators}
      validationError={validationError}
      hasPanoramaColors={hasPanoramaColors(palette)}
      onClose={handleClearValidationError}
      onChange={handleSharePalette}
    />
  );
}

PaletteCollaborators.defaultProps = {
  disabled: false,
  palette: {},
};

PaletteCollaborators.propTypes = {
  palette: PropTypes.shape(),
  disabled: PropTypes.bool,
};

export default PaletteCollaborators;
