import React from 'react';
import { SnackbarProvider } from 'notistack';

import Snackbar from '~/v3/components/features/snackbar';

function NotificationsProvider({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  return (
    <SnackbarProvider
      variant="info"
      Components={{
        info: Snackbar,
        error: Snackbar,
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default NotificationsProvider;
