import React from 'react';
import { useField } from 'formik';
import { useTranslation } from 'react-i18next';
import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { NEXT_STEP } from '~/v2/pages/CancelSubscription/surveyConstants';
import {
  RadioButtonWrapper,
  Subtitle,
} from '../cancel-subscription-form.elements';

function CancelActions() {
  const { t } = useTranslation();
  const [field] = useField<string>('cancelationAction');

  return (
    <>
      <Subtitle variant="subtitle2">
        {t('cancelSubscription.cancelationActionText')}
      </Subtitle>
      <RadioButtonWrapper>
        <RadioGroup
          value={field.value}
          data-testid="premium"
          name="cancelationAction"
          onChange={field.onChange}
        >
          {NEXT_STEP.map((item) => (
            <FormControlLabel
              key={item.value}
              control={<Radio value={item.value} color="primary" />}
              label={t(`cancelSubscription.actions.${item.value}`)}
            />
          ))}
        </RadioGroup>
      </RadioButtonWrapper>
    </>
  );
}

export default CancelActions;
