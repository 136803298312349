/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/no-named-as-default */
import { StyledEngineProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter } from 'react-router-dom';

import { QueryClientProvider } from '@tanstack/react-query';
import {
  FeatureFlagsEnvironments,
  FeatureFlagsProvider,
} from '@xrite/feature-flags-client';
import { AnalyticsProvider } from './components/AnalyticsProvider';
import ErrorWrapper from './components/ErrorWrapper';
import { isProduction } from '~/v3/config';
import { ColorContext } from '~/v3/components/shared/providers/color-mode-provider';
import NotificationsProvider from '~/v3/components/shared/providers/notifications-provider';
import { queryClient } from '~/v3/react-query';
import { DndProvider } from 'react-dnd-multi-backend';
import { UserProvider } from '~/v3/providers/UserProvider';
import { HTML5toTouch } from '~/v3/dndConfig';
import App from './App';
import AppModals from './AppModals';
import store from './store';
import config from './config';

if (!isProduction) {
  console.log(
    'FeatureFlags Environment:',
    config.api.PERSONAL_APP_CONFIG_ENV_NAME || 'development'
  );
}

function DataProviders({ children }) {
  return (
    <Provider store={store}>
      <DndProvider options={HTML5toTouch}>{children}</DndProvider>
    </Provider>
  );
}

function StyleProviders({ children }) {
  return (
    <StyledEngineProvider injectFirst>
      <ColorContext>{children}</ColorContext>
    </StyledEngineProvider>
  );
}

function Root() {
  return (
    <HashRouter>
      <QueryClientProvider client={queryClient}>
        <UserProvider>
          <DataProviders>
            <AnalyticsProvider>
              <StyleProviders>
                <ErrorWrapper>
                  <NotificationsProvider>
                    <FeatureFlagsProvider
                      settings={{
                        awsEnviroment: isProduction
                          ? FeatureFlagsEnvironments.Production
                          : FeatureFlagsEnvironments.Develop,
                        deploymentEnviroment: isProduction
                          ? 'production'
                          : config.api.PERSONAL_APP_CONFIG_ENV_NAME ||
                            'development',
                        applicationName: 'pantone-connect-feature-flags',
                        configurationName: 'connect-release-flags-client',
                      }}
                    >
                      <App />
                      <AppModals />
                    </FeatureFlagsProvider>
                  </NotificationsProvider>
                </ErrorWrapper>
              </StyleProviders>
            </AnalyticsProvider>
          </DataProviders>
        </UserProvider>
      </QueryClientProvider>
    </HashRouter>
  );
}

DataProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

StyleProviders.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Root;
