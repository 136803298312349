import useRouter from '~/v3/hooks/use-router';
import useSubscriptionStatus from '~/v3/hooks/use-subscription-status';
import RoutePath from '~/v2/constants/route';

function useShouldShow7DaysBanner() {
  const { isBasic } = useSubscriptionStatus();
  const router = useRouter();

  const isPaywall = router.pathname.includes(RoutePath.paywall);

  return isBasic && !isPaywall;
}

export default useShouldShow7DaysBanner;
