import { Box, styled, Theme, useMediaQuery } from '@mui/material';
import React from 'react';

import useTracker from '~/v2/analytics/useTracker';
import RoutePath from '~/v2/constants/route';
import useRouter from '~/v3/hooks/use-router';
import {
  promotionsBannerExploreEvent,
  promotionsBannerViewEvent,
} from '~/v3/events';
import { PromotionsArea } from '~/v3/types/promotion';
import useTrackOnSubscriptionDataReady from '~/v3/analytics/hooks/use-track-on-subscription-data-ready';
import useGetActivePromotion from '~/v3/components/features/promotions/lib/hooks/use-get-active-promotion';
import { COY_URL_BANNER_SLUG, topBannerBreakpoints } from '../model/constants';
import ExploreButton from './explore-button';
import { getEventTerm } from '../lib/utils';
import useGetPromotionTitlesText from '../lib/hooks/use-get-promotion-titles-text';
import { Headline, BannerContainer, TextContainer } from '../banner.elements';

const ColorContainer = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'baseline',
}));

function Banner() {
  const { trackEvent } = useTracker();
  const router = useRouter();
  const { getPromotionTitleText, getPromotionSubtitleText } =
    useGetPromotionTitlesText();

  const currentActivePromotion = useGetActivePromotion();

  const eventTerm = getEventTerm(currentActivePromotion);

  const isLargeScreen = useMediaQuery((th: Theme) =>
    th.breakpoints.up(topBannerBreakpoints.lg1680.width)
  );

  useTrackOnSubscriptionDataReady({
    events: [
      {
        ...promotionsBannerViewEvent,
        TERM: eventTerm,
      },
    ],
  });

  const handleExploreButtonClick = () => {
    trackEvent({
      ...promotionsBannerExploreEvent,
      TERM: eventTerm,
    });
    router.push(`${RoutePath.insight}/${COY_URL_BANNER_SLUG}`);
  };

  return (
    <BannerContainer
      onClick={!isLargeScreen ? handleExploreButtonClick : undefined}
      sx={{
        ...(!isLargeScreen && { cursor: 'pointer' }),
      }}
      promotion={currentActivePromotion}
      area={PromotionsArea.BANNER}
    >
      <TextContainer>
        <Headline>{getPromotionTitleText(currentActivePromotion)}</Headline>
        <ColorContainer>
          <Headline>
            {getPromotionSubtitleText(currentActivePromotion)}
          </Headline>
        </ColorContainer>
      </TextContainer>
      {isLargeScreen && (
        <ExploreButton
          promotion={currentActivePromotion}
          onClick={handleExploreButtonClick}
        />
      )}
    </BannerContainer>
  );
}

export default Banner;
