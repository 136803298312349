export const enum PaletteActions {
  addToPalette = 'addToPalette',
  removeFromPalette = 'removeFromPalette',
}

export const enum BaseMenuKeys {
  info = 'info',
  shades = 'shades',
  harmonies = 'harmonies',
  crossReference = 'crossReference',
  fullScreen = 'fullScreen',
  pantoneRedirect = 'pantoneRedirect',
}

export const enum LeftMenuVariants {
  BASIC = 'basic',
  COLOR_INFO = 'color_info',
}
