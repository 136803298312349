import { API, graphqlOperation } from 'aws-amplify';
import {
  amplifyApiKeySettings,
  amplifyUserPoolSettings,
} from '~/v2/utils/amplify';
import { PaywallPlans, ProductSeatsMap, ProductType } from '~/v3/paywall/types';
import { Subscription } from '../types/subsription';
import { UserData } from '../types/user';

// quantity and type to be removed when the new paywall is fully implemented and productsSeatsMap to be required
type CreateFastSpringMultipleProductsPayloadInput = {
  email: string;
  firstName: string;
  lastName: string;
  quantity?: number;
  type?: string;
  productSeatsMap?: ProductSeatsMap;
};

type CreateFastSpringPayloadInput = {
  email: string;
  firstName: string;
  lastName: string;
  quantity: number;
  type: PaywallPlans;
};

type ApplySubscriptionOfferInput = {
  offerId: string;
};

export async function createFastSpringMultipleProductsSecurePayload({
  email,
  firstName,
  lastName,
  productSeatsMap,
}: CreateFastSpringMultipleProductsPayloadInput) {
  API.configure(!email ? amplifyApiKeySettings : amplifyUserPoolSettings);

  const hasEmail = !!email;
  const hasProductSeatsMap = !!productSeatsMap;

  const payload =
    hasEmail && hasProductSeatsMap
      ? `
    userData: {
      email: "${email}",
      firstName: "${firstName}",
      lastName: "${lastName}",
    }
    productSeatsMap: {
      PREMIUM: ${productSeatsMap[ProductType.PREMIUM] ?? 0}
      TRENDS: ${productSeatsMap[ProductType.TRENDS] ?? 0}
      subscriptionPeriod: "${productSeatsMap.subscriptionPeriod}"
      ssoProductIncluded: ${productSeatsMap.ssoProductIncluded}
    }
    `
      : '';

  const data = (await API.graphql(
    graphqlOperation(
      `mutation CreateFastSpringSecurePayload {
            createFastSpringSecurePayload(
              ${payload}
            ) {
              securePayload,
              secureKey,
              productIds
            }
          }`
    )
  )) as {
    data: {
      createFastSpringSecurePayload: {
        securePayload: string;
        secureKey: string;
        productIds: string[];
      };
    };
  };

  return data?.data?.createFastSpringSecurePayload;
}

export async function createFastSpringSecurePayload({
  email,
  firstName,
  lastName,
  quantity,
  type,
}: CreateFastSpringPayloadInput) {
  API.configure(!email ? amplifyApiKeySettings : amplifyUserPoolSettings);

  const hasEmail = !!email;

  const payload = hasEmail
    ? `
    userData: {
      email: "${email}",
      firstName: "${firstName}",
      lastName: "${lastName}",
    }
    `
    : '';

  const data = (await API.graphql(
    graphqlOperation(
      `mutation CreateFastSpringSecurePayload {
            createFastSpringSecurePayload(
              type: "${type}"
              quantity: ${quantity || 1}
              ${payload}
            ) {
              securePayload,
              secureKey
            }
          }`
    )
  )) as {
    data: {
      createFastSpringSecurePayload: {
        securePayload: string;
        secureKey: string;
      };
    };
  };

  return data?.data?.createFastSpringSecurePayload;
}

export async function getSubscription() {
  API.configure(amplifyUserPoolSettings);

  const data = (await API.graphql(
    graphqlOperation(`{
            getSubscription {
              subscriptionId
              active
              state
              daysLeft
              subscriptionType
              subscriptionProvider
              providerCode
              product
              subscriptions{
                subscriptionId
                name
                active
                product
                state
                daysLeft
                subscriptionType
                coupons{
                  company
                  days
                  id
                  redeemedAt
                }
              }
            }
          }`)
  )) as {
    data: {
      getSubscription: Subscription;
    };
  };

  return data?.data?.getSubscription;
}

export async function syncUserSubscriptions() {
  API.configure(amplifyUserPoolSettings);

  try {
    const data = (await API.graphql(
      graphqlOperation(
        `query syncUserSubscriptions {
            syncUserSubscriptions {
              status
            }
          }`
      )
    )) as {
      data: { syncUserSubscriptions: { status: string } };
    };

    return { data: data?.data?.syncUserSubscriptions?.status };
  } catch (error) {
    return { error };
  }
}

export async function cancelSubscription({
  deleteNow = false,
  subscriptionId = '',
}) {
  API.configure(amplifyUserPoolSettings);

  try {
    const data = (await API.graphql(
      graphqlOperation(`{
        cancelSubscription(deleteNow: ${deleteNow}, subscriptionId: "${subscriptionId}") {
            active
            state
          }
        }`)
    )) as {
      data: {
        cancelSubscription: Subscription;
      };
    };

    return data?.data?.cancelSubscription;
  } catch (error) {
    return { error };
  }
}

export async function applySubscriptionOffer({
  offerId,
}: ApplySubscriptionOfferInput) {
  API.configure(amplifyUserPoolSettings);

  try {
    const data = (await API.graphql(
      graphqlOperation(`
        mutation ApplySubscriptionOffer {
          applySubscriptionOffer(offerId: "${offerId}") {
            offers {
              id
              createdAt
            }
          }
        }
      `)
    )) as {
      data: {
        applySubscriptionOffer: UserData;
      };
    };

    return data?.data?.applySubscriptionOffer;
  } catch (error) {
    return { error };
  }
}
