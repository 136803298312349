import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/CloseOutlined';
import ShareIcon from '@mui/icons-material/ShareOutlined';

import useTracker from 'analytics/useTracker';
import events from 'analytics/events';
import colors from 'theme/colors';
import Loader from 'components/Loader';
import { selectCurrentUser } from 'services/authApi';
import { isSidebarEnabled } from '~/v3/feature-flags';
import { paletteEngaged } from '~/v3/pages/my-dashboard/events';
import CollaboratorsForm from './CollaboratorsForm';
import CollaboratorsList from './CollaboratorsList';
import CollaboratorsListItem from './CollaboratorsListItem';
import CollaboratorCount from './CollaboratorCount';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    flex: '0 0 auto',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  icon: {
    color: theme.palette.secondary.main,
    padding: 0,
  },
  popoverContainer: {
    padding: theme.spacing(4),
    minWidth: 500,
    [theme.breakpoints.down('sm')]: {
      minWidth: '90%',
    },
    [theme.breakpoints.down('xs')]: {
      minWidth: 0,
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(7),
      width: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(2),
  },

  subtitle2: {
    marginBottom: theme.spacing(3),
    color: colors.WhiteGrey[500],
  },
}));

function CollaboratorsUI({
  palette,
  validationError,
  isLoading,
  disabled,
  onChange,
  onClose,
  collaborators,
  hasPanoramaColors,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation();
  const theme = useTheme();
  const classes = useStyles();
  const [hasAddedCollaborators, setHasAddedCollaborators] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const ga = useTracker();
  const { data: user } = useSelector(selectCurrentUser);

  const handleOpenPopover = (e) => {
    e.stopPropagation();
    setAnchorEl(
      isMobile ? document.getElementById('pantoneConnect') : e.currentTarget
    );
    ga.trackEvent(events.e39);
    ga.trackEvent({
      event_timestamp: new Date().toISOString(),
      ...paletteEngaged,
      PALETTE_NAME: palette.name,
      PALETTE_ID: palette.id,
      EVENT_COMPONENT: 'share_button',
    });
  };

  const addCollaborator = (email) => {
    const nextCollaborators = onChange({ palette, email });

    if (nextCollaborators?.length > 0) {
      setHasAddedCollaborators(true);
    }
  };

  const removeCollaborator = (removeEmail) => {
    onChange({ palette, removeEmail });
  };

  const handleClose = (e) => {
    e.stopPropagation();
    setAnchorEl(null);
    onClose();

    if (hasAddedCollaborators) {
      ga.trackEvent(events.e41);
    } else {
      ga.trackEvent(events.e40);
    }
  };

  const isOwner = palette?.owner?.email === user?.attributes?.email;
  const isSharingEnabled = isOwner && !hasPanoramaColors;
  const isDisabled = disabled || !isSharingEnabled || !palette?.colors?.length;

  const paletteOwnerTooltip = hasPanoramaColors
    ? t('labels.sharingPanoramaComingSoon')
    : t('labels.savePaletteToShare');

  const paletteSharableTooltip = isOwner
    ? paletteOwnerTooltip
    : t('labels.onlyOwnerCanShare');

  return (
    <Tooltip
      enterTouchDelay={0}
      title={
        isSidebarEnabled || !palette?.id
          ? t('labels.savePaletteToShare')
          : paletteSharableTooltip
      }
      placement="top"
      disableFocusListener={!isDisabled}
      disableHoverListener={!isDisabled}
      disableTouchListener={!isDisabled}
    >
      <div className={classes.wrapper}>
        <IconButton
          className={classes.icon}
          onClick={handleOpenPopover}
          data-testid="share-palette-button"
          disabled={isDisabled}
          size="large"
        >
          <ShareIcon />
          {anchorEl && (
            <div className={classes.root}>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{ paper: classes.popoverContainer }}
                anchorOrigin={{
                  vertical: isMobile ? 'center' : 'bottom',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: isMobile ? 'center' : 'top',
                  horizontal: 'center',
                }}
              >
                {!palette ? (
                  <Loader />
                ) : (
                  <>
                    <div className={classes.titleContainer}>
                      <Typography variant="h6">
                        {t('labels.sharePalette')}
                      </Typography>
                      <IconButton
                        size="small"
                        color="primary"
                        onClick={handleClose}
                      >
                        <CloseIcon color="secondary" fontSize="default" />
                      </IconButton>
                    </div>
                    <CollaboratorsForm
                      error={validationError}
                      disabled={isLoading}
                      addCollaborator={addCollaborator}
                    />
                    <div className={classes.collaboratorsWrapper}>
                      <Typography
                        className={classes.subtitle2}
                        variant="subtitle2"
                      >
                        {t('labels.owner')}:
                      </Typography>
                      <CollaboratorsListItem
                        owner
                        initials={palette?.owner.email.charAt(0).toUpperCase()}
                        email={palette?.owner.email}
                      />
                    </div>
                    <CollaboratorsList
                      collaborators={collaborators}
                      removeCollaborator={removeCollaborator}
                    />
                  </>
                )}
              </Popover>
            </div>
          )}
        </IconButton>

        {!!collaborators?.length && (
          <CollaboratorCount count={collaborators.length} />
        )}
      </div>
    </Tooltip>
  );
}

CollaboratorsUI.defaultProps = {
  validationError: '',
  palette: null,
  isLoading: false,
  disabled: false,
  collaborators: [],
  hasPanoramaColors: false,
};

CollaboratorsUI.propTypes = {
  validationError: PropTypes.string,
  palette: PropTypes.PropTypes.shape(),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  collaborators: PropTypes.arrayOf(
    PropTypes.shape({
      email: PropTypes.string.isRequired,
    })
  ),
  hasPanoramaColors: PropTypes.bool,
};

export default CollaboratorsUI;
