import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';
import classnames from 'classnames';

import PantoneChip from 'components/PantoneChip';
import Watermark from '../Watermark';

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.grey['50'],
    padding: theme.spacing(4),
  },
  mouseDisabler: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 10,
  },
  paletteTitle: {
    width: '100%',
    textAlign: 'center',
    fontSize: theme.spacing(4.25),
    minHeight: theme.spacing(6),
    margin: 0,
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    color: theme.palette.common.black,
  },
  colorsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(13),
  },
  colorsWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
  },
  colorWrapper: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  colorValueBase: {
    marginLeft: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  colorValueSmaller: {
    fontSize: theme.spacing(1),
  },
  colorValueLarger: {
    fontSize: theme.spacing(1.5),
  },
  colorUnitLabel: {
    color: theme.palette.grey['700'],
    marginRight: theme.spacing(0.5),
  },
  trademarkBase: {
    position: 'absolute',
    bottom: 0,
    right: -40,
    color: theme.palette.common.black,
  },
  trademarkSmaller: {
    marginBottom: theme.spacing(5),
  },
  trademarkLarger: {
    marginBottom: theme.spacing(6.5),
  },
}));

function StyleGuide({ colors, paletteTitle }) {
  const classes = useStyles();
  const smallBatch = colors.length < 9;
  const colorCardSize = smallBatch ? 'l' : 'm';

  const trademarkClass = smallBatch
    ? classes.trademarkLarger
    : classes.trademarkSmaller;

  const colorValueClass = smallBatch
    ? classes.colorValueLarger
    : classes.colorValueSmaller;

  return (
    <div className={classes.container}>
      <div className={classes.mouseDisabler} />
      {paletteTitle && <p className={classes.paletteTitle}>{paletteTitle}</p>}
      <div className={classes.colorsWrapper}>
        <div className={classes.colorsContainer}>
          {colors?.map((color, index) => (
            <div className={classes.colorWrapper} key={color.code}>
              <PantoneChip color={color} size={colorCardSize} />

              <div className={classes.colorValueBase}>
                <div className={classnames(colorValueClass)}>
                  <span className={classes.colorUnitLabel}>RGB</span>
                  {` ${color.rgb.r} ${color.rgb.g} ${color.rgb.b}`}
                </div>
                <div className={classnames(colorValueClass)}>
                  <span className={classes.colorUnitLabel}>HEX</span>
                  {`#${color.hex}`}
                </div>
              </div>

              {index === colors.length - 1 && (
                <div
                  className={classnames(classes.trademarkBase, trademarkClass)}
                >
                  TM
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
      <Watermark />
    </div>
  );
}

StyleGuide.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paletteTitle: PropTypes.string,
};

StyleGuide.defaultProps = {
  paletteTitle: null,
};

export default StyleGuide;
