import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';

function LinkIcon({ sx, ...props }: SvgIconProps) {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      sx={{
        ...sx,
        fill: 'transparent',
        width: 10,
        height: 10,
      }}
      {...props}
    >
      <path
        d="M9.33333 6.55556V9.88889C9.33333 10.1836 9.21627 10.4662 9.0079 10.6746C8.79952 10.8829 8.51691 11 8.22222 11H2.11111C1.81643 11 1.53381 10.8829 1.32544 10.6746C1.11706 10.4662 1 10.1836 1 9.88889V3.77778C1 3.48309 1.11706 3.20048 1.32544 2.9921C1.53381 2.78373 1.81643 2.66667 2.11111 2.66667H5.44444M7.66667 1H11M11 1V4.33333M11 1L4.88889 7.11111"
        strokeLinecap="square"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default LinkIcon;
