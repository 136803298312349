import { createSlice } from '@reduxjs/toolkit';

import { paletteApi } from '~/v2/services/paletteApi';
import { getPaletteFromLocalStorage } from '~/v3/utils/get-palette-from-local-storage';

const initialState = {
  name: '',
  colors: [],
  palette: null,
  unsavedChanges: false,
};

const localStoragePalette = getPaletteFromLocalStorage();

export const paletteWorkspaceSlice = createSlice({
  name: 'paletteWorkspace',
  initialState: localStoragePalette || initialState,
  reducers: {
    setName: (state, action) => {
      state.name = action.payload;
    },
    setColors: (state, action) => {
      state.colors = action.payload;
    },
    setPalette: (state, action) => {
      state.name = action.payload?.name;
      state.colors = action.payload?.colors;
      state.palette = action.payload;
    },
    setUnsavedChanges: (state, action) => {
      state.unsavedChanges = action.payload;
    },
    reset: () => initialState,
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      paletteApi.endpoints.deletePalette.matchFulfilled,
      (state, { payload }) => {
        const currentPalette =
          state.palette?.id === payload?.data?.deletePalette?.id;

        return currentPalette ? initialState : state;
      }
    );
  },
});

export const {
  reset,
  clear,
  setName,
  setColors,
  setPalette,
  setUnsavedChanges,
} = paletteWorkspaceSlice.actions;

export default paletteWorkspaceSlice.reducer;

export const selectId = (state) => state.paletteWorkspace.palette?.id;
export const selectName = (state) => state.paletteWorkspace.name;
export const selectColors = (state) => state.paletteWorkspace.colors;
export const selectPalette = (state) => state.paletteWorkspace.palette;
export const selectUnshavedChanges = (state) =>
  state.paletteWorkspace.unsavedChanges;
