import { isProduction } from '~/v3/config';
import {
  Language,
  Promotion,
  PromotionsArea,
  Breakpoint,
} from '~/v3/types/promotion';

export const getPromotionBasePath = (promotion: Promotion) =>
  isProduction
    ? `https://d2f3oyqk6v8zwm.cloudfront.net/promotions/${promotion}`
    : `https://d329wigkzdwgzh.cloudfront.net/promotions/${promotion}`;

export const getPromotionPath = ({
  promotion,
  language,
  breakpoint,
  area,
}: {
  promotion: Promotion;
  language: Language;
  breakpoint: Breakpoint;
  area: PromotionsArea;
}) => {
  return `${getPromotionBasePath(
    promotion
  )}/images/${area}/${language}/${language}-${breakpoint.width}x${
    breakpoint.height
  }.png`;
};
