import { Alert } from '@mui/material';
import React from 'react';

type SidebarAlertPropsProps = Readonly<{
  children: string;
}>;

function SidebarAlertProps({ children, ...rest }: SidebarAlertPropsProps) {
  return (
    <Alert
      severity="error"
      sx={(theme) => ({
        marginBottom: theme.spacing(1),
        padding: theme.spacing(1, 1),
        lineHeight: theme.spacing(2),
      })}
      {...rest}
    >
      {children}
    </Alert>
  );
}

export default SidebarAlertProps;
