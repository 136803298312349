import { useMemo } from 'react';
import {
  useParams,
  useLocation,
  useHistory,
  useRouteMatch,
} from 'react-router-dom';
import queryString from 'query-string';
import { updateURLQuery } from '~/v2/utils/urls';
import { LocationDescriptor } from 'history';

function useRouter() {
  const params = useParams();
  const location = useLocation<{
    from: string;
  }>();
  const history = useHistory();
  const match = useRouteMatch();

  const goToPreviousPath = () => {
    history.goBack();
  };

  return useMemo(() => {
    return {
      push: (args: LocationDescriptor) => history.push(args),
      replace: (args: LocationDescriptor) => history.replace(args),
      pathname: location.pathname,
      query: {
        ...queryString.parse(location.search),
        ...params,
      },
      match,
      location,
      history,
      updateURLQuery: updateURLQuery({
        push: (args: LocationDescriptor) => history.push(args),
        pathname: location.pathname,
        search: location.search,
      }),
      goToPreviousPath,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, match, location, history]);
}

export default useRouter;
