import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import colors from '~/v3/theme/colors';

function Plus({ sx, fill = colors.white, ...props }: SvgIconProps) {
  return (
    <SvgIcon sx={{ ...sx, fill, height: 12, width: 12 }} {...props}>
      <path
        fillRule="evenodd"
        d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z"
        clipRule="evenodd"
      />
    </SvgIcon>
  );
}
export default Plus;
