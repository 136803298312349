import { isProduction } from '~/v3/config';

const PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE_DEV =
  'ECOM7-QFViHtKOJhI7aXMS81Idt70FejrIj4jN';

const PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE_PROD =
  'ECOM7-aB3dE5fG7hI9jK1lMnOpQrStUvWxYz2';

export const PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE = isProduction
  ? PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE_PROD
  : PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE_DEV;
