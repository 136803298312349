import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddOutlined from '@mui/icons-material/AddOutlined';
import { pickEvent } from '~/v3/events';
import { useSelector } from 'react-redux';
import useTracker from '~/v2/analytics/useTracker';
import {
  selectId,
  selectName,
} from '~/v2/features/PaletteWorkspace/paletteSlice';

function ClearButton({ tooltipTitle, onSubmit }) {
  const ga = useTracker();
  const name = useSelector(selectName);
  const id = useSelector(selectId);

  const handleClick = () => {
    ga.trackEvent({
      ...pickEvent,
      EVENT_AREA: 'create-new-palette-button',
      EVENT_LABEL: 'workspace-create-new-palette',
      PALETTE_ID: id ?? 'na',
      PALETTE_NAME: name ?? 'na',
    });
    onSubmit(null);
  };

  return (
    <Tooltip title={tooltipTitle}>
      <IconButton
        color="secondary"
        data-testid="create-new-palette-button"
        onClick={handleClick}
      >
        <AddOutlined />
      </IconButton>
    </Tooltip>
  );
}

ClearButton.propTypes = {
  tooltipTitle: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default ClearButton;
