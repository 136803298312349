import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function CustomerServiceIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 22 22"
      xmlns="http://www.w3.org/2000/svg"
      stroke="black"
      {...props}
      sx={{
        width: 22,
        height: 22,
        fill: 'none',
        stroke: theme.palette.primary.main,
        ...sx,
      }}
    >
      <path
        d="M16.9241 10.5V7.84658C16.9241 4.34146 14.0827 1.5 10.5775 1.5V1.5C7.07242 1.5 4.23096 4.34146 4.23096 7.84658V10.5"
        strokeWidth="1.5"
      />
      <path
        d="M11.6355 19.5L13.9243 19.5C15.5812 19.5 16.9243 18.1568 16.9243 16.5V16.5"
        strokeWidth="1.5"
      />
      <path
        d="M4 10.25L5.59658 10.25L5.59658 16.75L4 16.75C2.20507 16.75 0.75 15.2949 0.75 13.5C0.75 11.7051 2.20507 10.25 4 10.25Z"
        strokeWidth="1.5"
      />
      <path
        d="M17.1553 16.75L15.5587 16.75L15.5587 10.25L17.1553 10.25C18.9502 10.25 20.4053 11.7051 20.4053 13.5C20.4053 15.2949 18.9502 16.75 17.1553 16.75Z"
        strokeWidth="1.5"
      />
      <path
        d="M10.5778 21.25C11.5359 21.25 12.3855 20.5056 12.3855 19.5C12.3855 18.4944 11.5359 17.75 10.5778 17.75C9.61962 17.75 8.77002 18.4944 8.77002 19.5C8.77002 20.5056 9.61962 21.25 10.5778 21.25Z"
        strokeWidth="1.5"
      />
    </SvgIcon>
  );
}

export default CustomerServiceIcon;
