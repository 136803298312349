import { Box, Container, styled } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import NotificationBanner from 'components/NotificationBanner';
import routes from 'constants/route';
import PropTypes from 'prop-types';
import React, { Suspense } from 'react';
import Loader from '~/v2/components/Loader';
import SidebarDrawer from '~/v3/components/features/sidebar';
import { isSidebarEnabled } from '~/v3/feature-flags';
import useRouter from '~/v3/hooks/use-router';
import RoutePathname from './constants/route';
import { Banner7DaysTrial } from '~/v3/components/features/promotions/7-days-trial-banner';

const Header = React.lazy(() =>
  import('~/v3/components/shared/layouts/header')
);
const Footer = React.lazy(() =>
  import('~/v3/components/shared/layouts/footer')
);

const Shell = styled('div')({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  width: '100vw',
  '@supports (bottom: env(safe-area-inset-bottom, 0px))': {
    marginBottom: 'env(safe-area-inset-bottom, 0px)', // needed for IOS safe-area
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
    paddingRight: 0,
    [theme.breakpoints.down('md')]: {
      margin: 0,
      padding: 0,
    },
  },
  noPadding: {
    '&$root': {
      paddingLeft: 0,
      paddingRight: 0,
    },
    '&$appRoutes': {
      paddingTop: 0,
    },
  },
  content: {
    display: 'flex',
    width: '100%',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
  appRoutes: {
    display: 'flex',
    flex: 1,
    width: '100%',
    [theme.breakpoints.down('md')]: {
      paddingTop: theme.spacing(0),
      overflow: 'auto',
    },
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
  },
}));

function AuthenticatedAppShell({ children }) {
  const classes = useStyles();
  const { location } = useRouter();
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down('md'));

  const isOnCouponsPage =
    location.pathname.toLocaleLowerCase() ===
      routes.legacyCouponCodes.toLocaleLowerCase() ||
    location.pathname.toLocaleLowerCase() ===
      routes.companyCouponCodes.toLocaleLowerCase() ||
    location.pathname.toLocaleLowerCase() ===
      routes.bundleCodes.toLocaleLowerCase();

  const maxWidthBlocklist = [
    '/explore',
    '/explore/blog',
    `${RoutePathname.colorStory}/create`,
    `${RoutePathname.colorStory}/update`,
  ];

  const maxWidth = maxWidthBlocklist.some((el) => {
    const reg = new RegExp(el, 'g');
    return reg.test(location.pathname);
  })
    ? false
    : 'lg';

  if (isOnCouponsPage || location.pathname === routes.paywall) {
    return children;
  }

  return (
    <Shell>
      <Banner7DaysTrial />
      <Suspense fallback={<Loader />}>
        <Header />
      </Suspense>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          height: '100%',
          overflow: 'auto',
        }}
      >
        {isSidebarEnabled && <SidebarDrawer />}

        <Container
          disableGutters={Boolean(!maxWidth)}
          className={classNames({
            [classes.root]: true,
          })}
          maxWidth={false}
        >
          <main className={classes.content}>
            <div
              className={classNames({
                [classes.appRoutes]: true,
                [classes.noPadding]: Boolean(!maxWidth),
              })}
            >
              <div className={classes.wrapper}>
                <NotificationBanner />
                {children}
              </div>
            </div>
          </main>
        </Container>
      </Box>
      {isMediumScreen && <Footer />}
    </Shell>
  );
}

AuthenticatedAppShell.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthenticatedAppShell;
