import React from 'react';
import { CircularProgress, IconButton, IconButtonProps } from '@mui/material';

function LoadingIconButton({
  children,
  disabled,
  onClick,
  loading,
  ...rest
}: IconButtonProps & { loading: boolean }) {
  return (
    <IconButton color="primary" disabled={disabled} onClick={onClick} {...rest}>
      {loading ? <CircularProgress size={22} color="primary" /> : children}
    </IconButton>
  );
}

export default LoadingIconButton;
