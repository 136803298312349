import React from 'react';
import { styled } from '@mui/material';
import { ButtonProps as MUIButtonProps } from '@mui/material/Button';

import LoadingButton from '~/v2/components/LoadingButton';

type ButtonProps = {
  selected?: boolean;
  loading?: boolean;
} & MUIButtonProps;

const StyledButton = styled(LoadingButton)(({ theme }) => ({
  textTransform: 'none',
  marginRight: theme.spacing(1.5),
  alignSelf: 'center',
  fontSize: 12,
  transition: 'all 0.2s ease-out',
  borderRadius: theme.spacing(0.375),
  padding: theme.spacing(0.5, 1.25),
  fontWeight: 400,
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
  },
}));

export default function Button({
  onClick,
  children,
  loading,
  selected,
  disabled,
  endIcon,
  startIcon,
  ...props
}: ButtonProps) {
  return (
    <StyledButton
      {...props}
      onClick={onClick}
      loading={loading}
      disabled={disabled}
      startIcon={startIcon}
      endIcon={endIcon}
      variant="contained"
      color={selected ? 'blue' : props.color}
      disableElevation
    >
      {children}
    </StyledButton>
  );
}
