export enum MobileBreakpoint {
  extension = 'extension',
  iphone12Mini = 'iphone12Mini',
  iphoneSe = 'iphoneSe',
  iphone12Pro = 'iphone12Pro',
  pixel7 = 'pixel7',
  iphone14ProMax = 'iphone14ProMax',
  iphone16ProMax = 'iphone16ProMax',
}

export enum TabletBreakpoint {
  iPad = 'iPad',
  microsoftSurfacePro8 = 'microsoftSurfacePro8',
  iPadPro = 'iPadPro',
}

export enum DesktopBreakpoint {
  Desktop = 'Desktop',
  MacBook13 = 'MacBook13',
  WSXGA = 'WSXGA',
  FullHD = 'FullHD',
  UltraWideFullHD = 'UltraWideFullHD',
  QHD = 'QHD',
  UltraWideQHD = 'UltraWideQHD',
  UltraWide4K = 'UltraWide4K',
  FourKUltraHD = 'FourKUltraHD',
  DualQHD = 'DualQHD',
}
