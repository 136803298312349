export const myPalettesNav = {
  EVENT_ID: 'e248',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'my-palettes-button',
  EVENT_LABEL: 'mypalettes_nav',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'nav-menu',
};

export const myPalettesOpenColorStory = {
  EVENT_ID: 'e249',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'open-color-story-button',
  EVENT_LABEL: 'mypalettes_open_colorstory',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const myPalettesDeletePaletteOpen = {
  EVENT_ID: 'e250',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'delete-palette-button',
  EVENT_LABEL: 'delete_palette_opened',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const myPalettesDeletePaletteCanceled = {
  EVENT_ID: 'e251',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'delete-palette-button',
  EVENT_LABEL: 'delete_palette_canceled',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const myPalettesDeleteColorStoryOpen = {
  EVENT_ID: 'e252',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'delete-color-story-button',
  EVENT_LABEL: 'delete_color_story_opened',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const myPalettesDeleteColorStoryCanceled = {
  EVENT_ID: 'e253',
  EVENT_PLACE: 'body',
  EVENT_COMPONENT: 'delete-color-story-button',
  EVENT_LABEL: 'delete_color_story_canceled',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const paletteEngaged = {
  EVENT_ID: 'e254',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_palette_engaged',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
};

export const createColorStoryVideoStarted = {
  EVENT_ID: 'e255',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_video_started',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Create Color Story',
};

export const convertVideoStarted = {
  EVENT_ID: 'e256',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_vid_lib_convert',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Convert',
};

export const measureVideoStarted = {
  EVENT_ID: 'e257',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_vid_lib_measure',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Measure',
};

export const extractVideoStarted = {
  EVENT_ID: 'e258',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_vid_lib_extract',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Extract',
};

export const pickVideoStarted = {
  EVENT_ID: 'e259',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_vid_lib_pick',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Pick',
};

export const workspaceVideoStarted = {
  EVENT_ID: 'e260',
  EVENT_PLACE: 'body',
  EVENT_LABEL: 'mypalettes_vid_lib_workspace',
  EVENT_ACTION: 'click',
  EVENT_AREA: 'personal_palettes',
  EVENT_COMPONENT: 'Workspace',
};
