import extendedGamut from '../../public/images/books/extended-gamut.png';
import fhiCotton from '../../public/images/books/fhi-cotton.png';
import fhiPaper from '../../public/images/books/fhi-paper.png';
import skinTone from '../../public/images/books/skin-tone.jpg';
import fhiCottonExtended from '../../public/images/books/fhi-cotton-extended.png';
import fhiPaperExtended from '../../public/images/books/fhi-paper-extended.png';

export const BOOKS = {
  pantoneSolidCoated: {
    id: 'pantoneSolidCoated',
    color: 'rgb(255,255,255)',
    background: '#FF5C39',
  },
  pantoneSolidUncoated: {
    id: 'pantoneSolidUncoated',
    color: 'rgb(255,255,255)',
    background: '#FF8D6D',
  },
  pantoneColorBridgeCoated: {
    id: 'pantoneColorBridgeCoated',
    color: 'rgb(255,255,255)',
    background: '#2CC84D',
  },
  pantoneColorBridgeUncoated: {
    id: 'pantoneColorBridgeUncoated',
    color: 'rgb(255,255,255)',
    background: '#79D97C',
  },
  pantoneExtendedGamutCoated: {
    id: 'pantoneExtendedGamutCoated',
    color: 'rgb(0,0,0)',
    background: `#FFFFFF url(${extendedGamut}) no-repeat right`,
  },
  pantoneMetallicsSolidCoated: {
    id: 'pantoneMetallicsSolidCoated',
    color: 'rgb(255,255,255)',
    background: '#94609A',
  },
  pantonePastelsNeonsCoated: {
    id: 'pantonePastelsNeonsCoated',
    color: 'rgb(255,255,255)',
    background: '#FC76D9',
  },
  pantonePastelsNeonsUncoated: {
    id: 'pantonePastelsNeonsUncoated',
    color: 'rgb(255,255,255)',
    background: '#FC76D9',
  },
  pantoneFhCottonTcx: {
    id: 'pantoneFhCottonTcx',
    color: 'rgb(255,255,255)',
    background: `#8784A2 url(${fhiCotton}) right no-repeat`,
  },
  pantoneFhCottonTcxExtended: {
    id: 'pantoneFhCottonTcxExtended',
    color: 'rgb(255,255,255)',
    background: `#8784A2 url(${fhiCottonExtended}) right no-repeat`,
  },
  pantoneFhiPaperTpg: {
    id: 'pantoneFhiPaperTpg',
    color: 'rgb(255,255,255)',
    background: `#3B3D3F url(${fhiPaper}) right no-repeat`,
  },
  pantoneFhiPaperTpgExtended: {
    id: 'pantoneFhiPaperTpgExtended',
    color: 'rgb(255,255,255)',
    background: `#3B3D3F url(${fhiPaperExtended}) right no-repeat`,
  },
  pantoneFhNylonBrightsTn: {
    id: 'pantoneFhNylonBrightsTn',
    color: 'rgb(255,255,255)',
    background: '#A8B0AE',
  },
  pantoneFhiPolyesterTsx: {
    id: 'pantoneFhiPolyesterTsx',
    color: 'rgb(255,255,255)',
    background: '#5F95BE',
  },
  pantoneFhiMetallicShimmersTpm: {
    id: 'pantoneFhiMetallicShimmersTpm',
    color: 'rgb(255,255,255)',
    background: '#69645B',
  },
  pantoneCmykCoated: {
    id: 'pantoneCmykCoated',
    color: 'rgb(255,255,255)',
    background: '#F8485E',
  },
  pantoneCmykUncoated: {
    id: 'pantoneCmykUncoated',
    color: 'rgb(255,255,255)',
    background: '#FF8DA1',
  },
  pantoneSkinToneGuide: {
    id: 'pantoneSkinToneGuide',
    color: 'rgb(255,255,255)',
    background: `#CBAB99 url(${skinTone}) right no-repeat`,
  },
  pantoneFhiColorPanorama: {
    id: 'pantoneFhiColorPanorama',
    color: 'rgb(255,255,255)',
    background: '#E7559B',
  },
} as const;
