import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { SvgIconProps } from '@mui/material/SvgIcon/SvgIcon';
import { useTheme } from '@mui/material';

function ColorStoryNavigationIcon({ sx, ...props }: SvgIconProps) {
  const theme = useTheme();

  return (
    <SvgIcon
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      sx={{
        width: 20,
        height: 18,
        ...sx,
      }}
    >
      <circle
        cx="9.63184"
        cy="4.79736"
        r="4.20264"
        fill={theme.palette.icon.primary}
      />
      <circle
        cx="4.37891"
        cy="13.2026"
        r="4.20264"
        fill={theme.palette.icon.secondary}
      />
      <circle
        cx="14.8853"
        cy="13.2026"
        r="4.20264"
        fill={theme.palette.icon.primary}
      />
    </SvgIcon>
  );
}

export default ColorStoryNavigationIcon;
