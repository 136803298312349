import React from 'react';
import AuthPageV3 from '~/v2/pages/Auth/auth-page';
import { useTranslation } from 'react-i18next';
import { Language } from '~/v3/types/promotion';
import useGetActivePromotion from '~/v3/components/features/promotions/lib/hooks/use-get-active-promotion';

function AuthContainerBigScreen({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const translation = useTranslation();

  const activePromotion = useGetActivePromotion();
  const language =
    (translation.i18n.language.split('-')[0] as Language) || Language.EN;

  if (!children) {
    return <div />;
  }

  return (
    <AuthPageV3 language={language} promotion={activePromotion}>
      {children}
    </AuthPageV3>
  );
}

export default AuthContainerBigScreen;
