import React from 'react';
import { Remove } from '@mui/icons-material';
import { SvgIconProps } from '@mui/material';

type RemoveIconProps = {
  contrastColor?: string;
} & SvgIconProps;

function RemoveIcon({ contrastColor, ...rest }: RemoveIconProps) {
  return (
    <Remove
      sx={{
        color: contrastColor,
        width: 12,
        height: 12,
        ...rest.sx,
      }}
      {...rest}
    />
  );
}

export default RemoveIcon;
