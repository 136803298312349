import useSubscriptionStatus from '~/v2/hooks/useSubscriptionStatus';
import { Subscription } from '~/v3/types/subsription';

export const useGetEventConnectSubscriptionType = () => {
  const { isLoading, subscription, isOnTrialPeriod, isBasic } =
    useSubscriptionStatus() as {
      isLoading: boolean;
      subscription: Subscription | null;
      isOnTrialPeriod: boolean;
      isBasic: boolean;
    };

  if (isLoading) return undefined;

  if (!subscription) {
    return 'na';
  }

  if (isOnTrialPeriod) {
    return 'trial';
  }

  if (isBasic) {
    return 'free';
  }

  return 'premium';
};
