import config from 'config';
import { setLocalStorage } from '~/v3/utils/storage';
import { getPaletteFromLocalStorage } from '~/v3/utils/get-palette-from-local-storage';

import { isPanoramaBook } from './color';

// When the user has panorama colors in a palette but isn't premium plus, we get null from the backend
// This function removes the null colors from the palette
export const removeNullColors = (palette) => {
  return {
    ...palette,
    colors: palette?.colors?.filter?.((color) => color?.lab),
  };
};

export const hasProtectedPanoramaColors = (palette) =>
  palette?.colors?.some((color) => !color?.lab);

export const hasPanoramaColors = (palette) =>
  palette?.colors?.some((color) => isPanoramaBook(color)) ||
  hasProtectedPanoramaColors(palette);

export const updatePaletteFromLocalStorage = (nextPalette) => {
  const palette = getPaletteFromLocalStorage();

  setLocalStorage(
    config.paletteWorkspace,
    JSON.stringify({
      ...palette,
      ...nextPalette,
    })
  );
};

export const palettesLimitReached = (palettes) =>
  palettes?.length >= config.MAX_FREEMIUM_PALETTES;

export default {
  removeNullColors,
  hasPanoramaColors,
  palettesLimitReached,
  hasProtectedPanoramaColors,
};
