import React from 'react';
import { Alert, useTheme } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { getRGBstring } from 'utils/color';
import FullScreenDialog from 'components/FullScreenDialogNew';
import useFullScreenColor from '~/v3/hooks/use-full-screen-color';
import extractErrorMessageFromError from '~/v3/utils/extract-error-message-from-error';
import Loader from '~/v2/components/Loader';
import FullScreenColor from './FullScreenColor';

const useStyles = makeStyles((theme) => ({
  fullScreenColor: {
    background: ({ color }) => (color ? getRGBstring(color) : 'white'),
    color: ({ color }) =>
      theme.palette.isDark(color)
        ? theme.palette.common.white
        : theme.palette.common.black,
  },
}));

function FullScreenColorDialog() {
  const {
    isOpen,
    color,
    close,
    rgb,
    error,
    isLoading: isLoadingV3,
  } = useFullScreenColor();

  const isLoading = isLoadingV3 && !color;

  const classes = useStyles({
    color: color?.rgb ?? rgb,
  });

  const theme = useTheme();

  if (error) {
    return (
      <Alert
        data-id="libraries-error-alert"
        severity="error"
        sx={{
          display: 'flex',
          alignItems: 'center',
          height: 60,
        }}
      >
        {extractErrorMessageFromError(error)}
      </Alert>
    );
  }

  return (
    <FullScreenDialog
      open={isOpen}
      title={color?.code}
      style={{ zIndex: theme.zIndex.popover }}
      classes={{ paperFullScreen: classes.fullScreenColor }}
      onClose={close}
    >
      {isLoading && <Loader />}
      {color && <FullScreenColor color={color} />}
    </FullScreenDialog>
  );
}

export default FullScreenColorDialog;
