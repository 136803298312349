import config from '~/v3/config';
import light from '~/v2/theme/light';
import dark from '~/v3/theme/dark';

function getTheme(mode: 'light' | 'dark') {
  const theme = mode === config.themeModes.light ? light : dark;

  return {
    palette: {
      ...theme?.palette,
      mode,
    },
    components: {
      ...theme?.components,
    },
  };
}

export default getTheme;
