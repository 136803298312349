import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import UnauthHeader from 'components/UnauthHeader';
import RoutePath from './constants/route';

const Auth = React.lazy(() =>
  import(/* webpackChunkName: "Auth" */ './pages/Auth')
);

const Coupons = React.lazy(() =>
  import(/* webpackChunkName: "CouponCode" */ './pages/CouponCodes')
);
const CompanyCouponCodes = React.lazy(() =>
  import(
    /* webpackChunkName: "CompanyCouponCodes" */ './pages/CompanyCouponCodes'
  )
);
const EULA = React.lazy(() =>
  import(/* webpackChunkName: "EULA" */ './pages/EULA')
);

const Paywall = React.lazy(() =>
  import(/* webpackChunkName: "Paywall" */ '~/v3/paywall/pages')
);

const PrivacyPolicy = React.lazy(() =>
  import(/* webpackChunkName: "PrivacyPolicy" */ '~/v3/pages/privacy-policy')
);

const CookiePolicy = React.lazy(() =>
  import(/* webpackChunkName: "CookiePolicy" */ '../v3/pages/cookie-policy')
);

const CCPAPolicy = React.lazy(() =>
  import(/* webpackChunkName: "CCPAPolicy" */ '~/v3/pages/ccpa-policy')
);

function UnauthenticatedApp() {
  return (
    <Switch>
      <Route path={RoutePath.legacyCouponCodes}>
        <Coupons />
      </Route>
      <Route path={RoutePath.companyCouponCodes}>
        <CompanyCouponCodes />
      </Route>
      <Route path={RoutePath.bundleCodes}>
        <Redirect to={RoutePath.couponCodeActivation} />
      </Route>
      <Route path={RoutePath.eula}>
        <UnauthHeader>
          <EULA />
        </UnauthHeader>
      </Route>
      <Route path={RoutePath.paywall}>
        {/* NOTE: If replacing the Paywall component, replace it in every place that it is used */}
        <Paywall />
      </Route>
      <Route path={RoutePath.privacyPolicy}>
        <PrivacyPolicy />
      </Route>
      <Route path={RoutePath.cookiePolicy}>
        <CookiePolicy />
      </Route>
      <Route path={RoutePath.ccpaPolicy}>
        <CCPAPolicy />
      </Route>
      <Route path={RoutePath.auth.root}>
        <Auth />
      </Route>
    </Switch>
  );
}

export default UnauthenticatedApp;
