import filterCouponCodeSubscriptionsByProduct from '~/v3/utils/filter-coupon-code-subscriptions-by-product';
import { extractUsedCouponIdsByProduct } from './validate-coupon-code';
import { Subscription } from '../../types/subsription';
import { CouponCode, Products } from '../../types/coupons';
import { PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE } from '../constants/7-days-trial';

export const shouldStopSyncingHandler = (
  subscription: Subscription | undefined,
  coupon: CouponCode | undefined
) => {
  if (!coupon || !subscription?.subscriptions.length) {
    return false;
  }

  const extractedCouponIds: string[] = extractUsedCouponIdsByProduct({
    coupon,
    subscription,
  });

  const hasCouponBeenRedeemed = extractedCouponIds.filter(
    (extractedCouponId) => extractedCouponId === coupon.id
  );

  if (hasCouponBeenRedeemed.length === coupon.products?.length) {
    return true;
  }

  return false;
};

export const shouldStopSyncingHandler7daysTrial = (
  subscription: Subscription | undefined,
  ignore: boolean
) => {
  if (ignore) {
    return true;
  }

  if (!subscription) {
    return false;
  }

  const hasCouponBeenAppliedForBothProducts =
    [Products.CONNECT_PREMIUM, Products.INSIDER_PREMIUM]
      .flatMap((product) =>
        filterCouponCodeSubscriptionsByProduct(
          subscription.subscriptions,
          product
        )
      )
      .filter(
        (sub) =>
          sub.coupons?.some(
            (coupon) => coupon.id === PANTONE_COM_7_DAYS_TRIAL_COUPON_CODE
          )
      ).length === 2;

  if (hasCouponBeenAppliedForBothProducts) {
    return true;
  }

  return false;
};
