import React from 'react';
import { Box, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useRouter from '~/v3/hooks/use-router';
import RoutePath from '~/v2/constants/route';
import useShouldShow7DaysBanner from '~/v3/components/features/promotions/7-days-trial-banner/lib/hooks/use-should-show-7-days-banner';
import useTracker from '~/v2/analytics/useTracker';
import useTrackOnSubscriptionDataReady from '~/v3/analytics/hooks/use-track-on-subscription-data-ready';
import {
  promotionsBannerExploreEvent,
  promotionsBannerViewEvent,
} from '~/v3/events';

const TERM = '7 days trial';

const BannerContainer = styled(Box)(() => ({
  cursor: 'pointer',
  minHeight: 54,
  overflow: 'hidden',
  background: '#0086FF',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const Text = styled(Typography)(() => ({
  color: 'white',
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '22px',
}));

function Banner7DaysTrial() {
  const { t } = useTranslation();
  const { trackEvent } = useTracker();

  const router = useRouter();
  const shouldShow7DaysBanner = useShouldShow7DaysBanner();

  useTrackOnSubscriptionDataReady({
    events: [
      {
        ...promotionsBannerViewEvent,
        TERM,
      },
    ],
  });

  const handleRedirect = () => {
    trackEvent({
      ...promotionsBannerExploreEvent,
      TERM,
    });
    router.push(`${RoutePath.paywall}`);
  };

  if (!shouldShow7DaysBanner) {
    return null;
  }

  return (
    <BannerContainer onClick={handleRedirect}>
      <Text>{t('7dayTrial.bannerTitle')}</Text>
    </BannerContainer>
  );
}

export default Banner7DaysTrial;
