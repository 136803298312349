/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@mui/material';
import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';

import useSubscriptionStatus from 'hooks/useSubscriptionStatus';
import config from 'config';
import usePaywall from 'hooks/usePaywall';
import { getLocalStorage, setLocalStorage } from '~/v3/utils/storage';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    flex: 1,
    justifyContent: 'space-between',
    padding: theme.spacing(1.625, 3),
    background: theme.palette.warning.background,
    borderRadius: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1.625, 2),
    },
  },
  notificationBannerWrapper: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
  warningText: {
    color: theme.palette.warning.main,
    cursor: 'pointer',
  },
  closeIcon: {
    color: theme.palette.warning.dark,
  },
}));

function NotificationBanner() {
  const [open, setOpen] = useState(false);
  const { isCouponCodeTrialEnding } = useSubscriptionStatus();
  const classes = useStyles();
  const { t } = useTranslation();
  const { triggerPaywall } = usePaywall();

  useEffect(() => {
    const status = getLocalStorage('trialWarningNotification');
    if (!status) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    setLocalStorage('trialWarningNotification', true);
  };

  if (config.PAYWALL_ENABLED && open && isCouponCodeTrialEnding) {
    return (
      <div className={classes.notificationBannerWrapper}>
        <div className={classes.root}>
          <Typography
            variant="body2"
            className={classes.warningText}
            onClick={() => triggerPaywall()}
          >
            {t('labels.notificationBannerMessage')}
          </Typography>
          <IconButton size="small" color="primary" onClick={handleClose}>
            <CloseIcon className={classes.closeIcon} fontSize="small" />
          </IconButton>
        </div>
      </div>
    );
  }

  return null;
}

export default NotificationBanner;
