import { styled } from '@mui/material';
import Box from '@mui/material/Box';
import { getPromotionPath } from '~/v3/components/features/promotions/lib/utils';
import { Language, Promotion, PromotionsArea } from '~/v3/types/promotion';
import {
  createMediaQueryMaxWidth,
  createMediaQueryMaxWidthAndMaxHeight,
  createMediaQueryWidthAndHeight,
} from '~/v3/utils/media-query';
import { popupBreakpoints } from './breakpoints';

interface ModalBackgroundImageProps {
  promotion: Promotion;
  language: Language;
}

const area = PromotionsArea.POPUP;

export const ModalBackgroundImage = styled(Box)<ModalBackgroundImageProps>(
  ({ promotion, language }) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${getPromotionPath({
      promotion,
      language,
      breakpoint: popupBreakpoints.desktop,
      area,
    })})`,
    [createMediaQueryMaxWidth(popupBreakpoints.maxTablet.width)]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.tablet,
        area,
      })})`,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.custom3.width,
      popupBreakpoints.custom3.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.custom3,
        area,
      })})`,
      width: popupBreakpoints.custom3.width,
      height: popupBreakpoints.custom3.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.custom2.width,
      popupBreakpoints.custom2.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.custom2,
        area,
      })})`,
      width: popupBreakpoints.custom2.width,
      height: popupBreakpoints.custom2.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.custom1.width,
      popupBreakpoints.custom1.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.custom1,
        area,
      })})`,
      width: popupBreakpoints.custom1.width,
      height: popupBreakpoints.custom1.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.iphone16ProMax.width,
      popupBreakpoints.iphone16ProMax.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.iphone16ProMax,
        area,
      })})`,
      width: popupBreakpoints.iphone16ProMax.width,
      height: popupBreakpoints.iphone16ProMax.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.iphone14ProMax.width,
      popupBreakpoints.iphone14ProMax.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.iphone14ProMax,
        area,
      })})`,
      width: popupBreakpoints.iphone14ProMax.width,
      height: popupBreakpoints.iphone14ProMax.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.pixel7.width,
      popupBreakpoints.pixel7.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.pixel7,
        area,
      })})`,
      width: popupBreakpoints.pixel7.width,
      height: popupBreakpoints.pixel7.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.iphone12Pro.width,
      popupBreakpoints.iphone12Pro.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.iphone12Pro,
        area,
      })})`,
      width: popupBreakpoints.iphone12Pro.width,
      height: popupBreakpoints.iphone12Pro.height,
    },
    [createMediaQueryWidthAndHeight(
      popupBreakpoints.iphoneSe.width,
      popupBreakpoints.iphoneSe.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.iphoneSe,
        area,
      })})`,
      width: popupBreakpoints.iphoneSe.width,
      height: popupBreakpoints.iphoneSe.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.iphone12Mini.width,
      popupBreakpoints.iphone12Mini.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.iphone12Mini,
        area,
      })})`,
      width: popupBreakpoints.iphone12Mini.width,
      height: popupBreakpoints.iphone12Mini.height,
    },
    [createMediaQueryMaxWidthAndMaxHeight(
      popupBreakpoints.extension.width,
      popupBreakpoints.extension.height
    )]: {
      backgroundImage: `url(${getPromotionPath({
        promotion,
        language,
        breakpoint: popupBreakpoints.extension,
        area,
      })})`,
      width: popupBreakpoints.extension.width,
      height: popupBreakpoints.extension.height,
    },
  })
);
