import React from 'react';
import Box, { BoxProps } from '@mui/material/Box';

type ModalFooterProps = {
  children: React.ReactNode;
} & BoxProps;

function ModalFooter({ children, ...rest }: ModalFooterProps) {
  return <Box {...rest}>{children}</Box>;
}

export default ModalFooter;
