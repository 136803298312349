import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import GetAppOutlinedIcon from '@mui/icons-material/GetAppOutlined';
import useImageDownload from 'features/ImageDownload/useImageDownload';
import { Box } from '@mui/material';
import useTracker from 'analytics/useTracker';
import events from 'analytics/events';
import { paletteEngaged } from '~/v3/pages/my-dashboard/events';

function DownloadButton({ id, name, colors, disabled, label }) {
  const { t } = useTranslation();
  const { open } = useImageDownload();
  const ga = useTracker();

  const handleDownload = () => {
    if (colors) {
      ga.trackEvent({
        ...events.e170,
        EVENT_TERM: name,
        PALETTE_NAME: name,
        PALETTE_ID: id,
      });
      ga.trackEvent({
        event_timestamp: new Date().toISOString(),
        ...paletteEngaged,
        PALETTE_NAME: name,
        PALETTE_ID: id,
        EVENT_COMPONENT: 'download_button',
      });

      open(colors);
    }
  };

  return (
    <Tooltip title={t('labels.downloadAsImage')}>
      <Box>
        {label ? (
          <Button
            disabled={disabled}
            onClick={handleDownload}
            data-testid="download-palette-button"
            startIcon={<GetAppOutlinedIcon color="secondary" />}
          >
            {label}
          </Button>
        ) : (
          <IconButton
            disabled={disabled}
            onClick={handleDownload}
            data-testid="download-palette-button"
            color="secondary"
            sx={{ padding: 0 }}
          >
            <GetAppOutlinedIcon />
          </IconButton>
        )}
      </Box>
    </Tooltip>
  );
}

DownloadButton.defaultProps = {
  disabled: false,
  label: null,
};

DownloadButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  disabled: PropTypes.bool,
  label: PropTypes.string,
};

export default DownloadButton;
