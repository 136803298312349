import { InfiniteData } from '@tanstack/react-query';

import { SavedArticlesOutput } from '~/v3/types/article';
import {
  Article,
  GetArticles,
  ArticlesSort,
  ResolutionJumpStep,
} from './types';
import { SelectOption } from './components/dropdown-select';
import { ResolutionResponse } from '../hooks/use-resolution';
import {
  FASTSPRING_MULTISEAT_PRODUCTS,
  FASTSPRING_SINGLESEAT_PRODUCTS,
  FastSpringProducts,
} from '../fastspring-products';

export const defaultSelectOption: SelectOption<ArticlesSort> = {
  key: ArticlesSort.LAST_ADDED,
  value: 'labels.lastAdded',
};

export const options = [
  {
    key: ArticlesSort.LAST_ADDED,
    value: 'labels.lastAdded',
  },
  { key: ArticlesSort.POPULARITY, value: 'labels.mostPopular' },
];

export function flatPaginatedArticles(
  pages?: ({ data: GetArticles } | undefined)[],
  savedArticlesData?: InfiniteData<SavedArticlesOutput>
) {
  const savedArticles = savedArticlesData?.pages?.flatMap(
    (page) => page.savedArticles
  );
  const savedArticlesSet = new Set(savedArticles);

  const savedArticleIds = Array.from(savedArticlesSet).map(
    (item) => item.articleId
  );

  return (
    pages?.reduce<Article[]>((acc, curr) => {
      if (!curr) return acc;
      const updatedArticles = curr.data.items.map((article) => ({
        ...article,
        isSaved: savedArticleIds.includes(article.id),
      }));
      return [...acc, ...updatedArticles];
    }, []) ?? []
  );
}

export const formatDate = (dateString: string) => {
  const date = new Date(dateString);

  const formattedDate = date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  return formattedDate;
};

export function getSlidesToShow(resolution: ResolutionResponse) {
  if (resolution.isSmallScreen) return ResolutionJumpStep.SmallScreen;
  if (resolution.isMediumScreen) return ResolutionJumpStep.MediumScreen;
  return ResolutionJumpStep.BigScreen;
}

export const trendsProducts: FastSpringProducts[] = [
  FASTSPRING_SINGLESEAT_PRODUCTS.trendsMonthly,
  FASTSPRING_SINGLESEAT_PRODUCTS.trendsYearly,
];

export const premiumProducts: FastSpringProducts[] = [
  FASTSPRING_SINGLESEAT_PRODUCTS.singleSeatMonthly,
  FASTSPRING_SINGLESEAT_PRODUCTS.singleSeatYearly,
  FASTSPRING_MULTISEAT_PRODUCTS.multiSeatYearly,
];

export type GetImageWithSize = {
  url?: string;
  width: number;
  height: number;
};

export const getImageWithSize = ({ url, width, height }: GetImageWithSize) => {
  if (!url) return '';
  return `${url}?w=${width}&h=${height}`;
};

export const getImageWithWidth = ({
  url,
  width,
}: Partial<GetImageWithSize>) => {
  if (!url) return '';
  return `${url}?w=${width}`;
};
