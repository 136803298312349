import { Subscription, SubscriptionStatus } from '../types/subsription';
import { filterFastSpringSubscriptions } from './filter-fastspring-subscriptions';

export const getActiveFastSpringSubscriptions = (
  subscription: Subscription
): Subscription[] => {
  const fastSpringSubscriptions = filterFastSpringSubscriptions(
    subscription
  ).filter((sub) => sub.active && sub.state !== SubscriptionStatus.CANCELED);
  return fastSpringSubscriptions || [];
};
