import React from 'react';
import { t } from 'i18next';
import { Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import {
  Subtitle,
  StyledButton,
  ButtonWrapper,
  FeedbackWrapper,
  StyledTextField,
} from '../cancel-subscription-form.elements';
import { CancelSubscriptionFormValues } from '../model/types';
import { isCancelButtonDisabled } from '../lib/utils';

interface Props {
  handleAbortCancelation: () => void;
}

function FeedbackForm({ handleAbortCancelation }: Readonly<Props>) {
  const { values, handleChange } =
    useFormikContext<CancelSubscriptionFormValues>();

  const isCancelSubscriptionDisabled = isCancelButtonDisabled(values);

  return (
    <>
      <Subtitle variant="subtitle2">
        {t('cancelSubscription.cancelationFeedbackText')}
      </Subtitle>
      <FeedbackWrapper>
        <StyledTextField
          multiline
          rows={4}
          onChange={handleChange}
          name="cancelationFeedback"
          value={values.cancelationFeedback}
        />
        <Typography variant="caption">
          {t('labels.canLeaveFieldEmpty')}
        </Typography>
        <ButtonWrapper>
          <StyledButton
            type="submit"
            color="primary"
            variant="contained"
            disabled={!isCancelSubscriptionDisabled}
          >
            {t('labels.continueToCancel')}
          </StyledButton>
          <StyledButton variant="outlined" onClick={handleAbortCancelation}>
            {t('labels.dontCancelSubscription')}
          </StyledButton>
        </ButtonWrapper>
      </FeedbackWrapper>
    </>
  );
}

export default FeedbackForm;
