import { QuantityDiscount } from '~/v3/api/products-api';

export enum ProductType {
  PREMIUM = 'PREMIUM',
  TRENDS = 'TRENDS',
}

export enum PlanProduct {
  BASIC = 'basic',
  FASTSPRING = 'fastspring',
  FASTSPRING_TRENDS = 'fastspring_trends',
  BUSINESS = 'business',
  TEAMS = 'teams',
  BUNDLE = 'fastspring_bundle',
}

export enum PaywallProducts {
  ConnectPremium = 'Connect',
  ConnectPlusInsight = 'Connect + Color Insider',
  InsightPremium = 'Color Insider',
}

export enum PaywallPlans {
  BASIC = 'basic',
  YEARLY = 'yearly',
  MONTHLY = 'monthly',
  TRENDS_YEARLY = 'trendsYearly',
  TRENDS_MONTHLY = 'trendsMonthly',
  TEAMS = 'teams',
  BUSINESS = 'business',
  TRENDS_AND_MONTHLY = 'monthlyPlusTrends',
  TRENDS_AND_YEARLY = 'yearlyPlusTrends',
  SSO_SETUP_FEE = 'ssoSetupFee',
}

export enum SubscriptionPeriod {
  MONTHLY = 'monthly',
  YEARLY = 'yearly',
}

export type ProductSeatsMap = {
  [ProductType.PREMIUM]: number;
  [ProductType.TRENDS]: number;
  subscriptionPeriod: SubscriptionPeriod;
  ssoProductIncluded: boolean;
};

export type Plan = {
  productTypes: ProductType[];
  isMultiSeat: boolean;
  displayName: string;
  description: string;
  monthlyPrice: number;
  monthlyDisplayPrice: string;
  annualPrice: number;
  annualDisplayPrice: string;
  ssoPrice?: number;
  trendsMonthlyPrice?: number;
  premiumMonthlyPrice?: number;
  quantityDiscounts?: QuantityDiscount[];
  connectBundleDiscount?: number;
  insiderBundleDiscount?: number;
  annualDisplayPriceWithoutDiscount: string;
  id: PlanProduct;
  name: PaywallProducts;
  features: string[];
  subscriptionLabel: string | null;
  subscriptionLabelNonEligibleTrial?: string;
  getPrice?: ({
    PREMIUM,
    TRENDS,
    subscriptionPeriod,
  }: Omit<ProductSeatsMap, 'ssoProductIncluded'>) => number;
  getFSIds: (
    productSeatsMap: ProductSeatsMap
  ) => { id: string; quantity: number }[];
  isActive?: boolean;
};

export type PricingPlanProps = {
  plan: Plan;
  handlePayment: (
    productSeatsMap: ProductSeatsMap,
    plan: PaywallProducts
  ) => void;
  isPurchasing: boolean;
  isOpen: boolean;
  onClose: () => void;
};

export enum ProductTypeSeatNameEnum {
  Basic = 'Basic',
  ConnectPremium = 'Connect Premium',
  ConnectPlusInsight = 'Connect + Insider Premium',
  InsightPremium = 'Insider Premium',
}

export const ProductTypeSeatName = {
  [ProductType.PREMIUM]: ProductTypeSeatNameEnum.ConnectPremium,
  [ProductType.TRENDS]: ProductTypeSeatNameEnum.InsightPremium,
};
