import config from 'config';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'services/authApi';
import { useConfiguredGetSubscriptionQuery } from 'services/subscriptionApi';
import { FASTSPRING_SINGLESEAT_PRODUCTS } from '~/v3/fastspring-products';
import { getActiveFastSpringSubscriptions } from '~/v3/utils/get-active-fastspring-subscriptions';

const useSubscriptionStatus = (passedSubscription) => {
  const { data: user } = useSelector(selectCurrentUser);
  const { data: subscriptionData, isLoading } =
    useConfiguredGetSubscriptionQuery(undefined);
  const subscription = passedSubscription || subscriptionData;

  const isActive = subscription?.active;

  const isActiveFastspring =
    subscription?.subscriptions?.filter(
      (sub) => sub.name === 'fastspring' && sub.active
    ).length > 0;

  const isSingleSeatMonthly =
    subscription?.subscriptions?.filter(
      (sub) =>
        sub.name === 'fastspring' &&
        sub.active &&
        sub.product === 'single-seat-monthly-14-99'
    ).length > 0;

  const isSingleSeatYearly =
    subscription?.subscriptions?.filter(
      (sub) =>
        sub.name === 'fastspring' &&
        sub.active &&
        sub.product === 'single-seat-yearly-89-99'
    ).length > 0;

  const isEigibleForTrial =
    !user ||
    (!isActive &&
      subscription?.state === config.SUBSCRIPTION_STATUSES.USER_NOT_SUBSCRIBED);

  const isNotSubscribed =
    !user ||
    (!isActive &&
      (subscription?.state ===
        config.SUBSCRIPTION_STATUSES.USER_NOT_SUBSCRIBED ||
        subscription?.state === config.SUBSCRIPTION_STATUSES.EXPIRED));

  const isBasic =
    !isActive &&
    subscription?.subscriptionType === config.SUBSCRIPTION_TYPE.free;

  const isOnCouponCodeTrial =
    isActive &&
    subscription?.state === config.SUBSCRIPTION_STATUSES.COUPON_CODE_TRIAL;

  const isSubscriptionStateTrial =
    isActive && subscription?.state === config.SUBSCRIPTION_STATUSES.TRIAL;

  const isOnTrialPeriod = isSubscriptionStateTrial || isOnCouponCodeTrial;

  const isCouponCodeTrialEnding =
    isOnCouponCodeTrial &&
    subscription?.daysLeft > 0 &&
    subscription?.daysLeft < 8;

  const isPremiumPlus =
    subscription?.subscriptionType ===
    config.SUBSCRIPTION_STATUSES.PREMIUM_PLUS;

  const isCanceled =
    isActive && subscription?.state === config.SUBSCRIPTION_STATUSES.CANCELED;

  const isActiveTrendsSubscription =
    subscription?.subscriptions?.filter(
      (sub) => sub.name === 'fastspring_trends' && sub.active
    ).length > 0;

  const hasActiveSubscriptionThatIsNotCanceled =
    getActiveFastSpringSubscriptions(subscription).length > 0;

  const isMonthlySubscription =
    subscription?.product === FASTSPRING_SINGLESEAT_PRODUCTS.monthly ||
    subscription?.product ===
      FASTSPRING_SINGLESEAT_PRODUCTS.singleSeatMonthly ||
    subscription?.product === FASTSPRING_SINGLESEAT_PRODUCTS.trendsMonthly;

  return {
    isLoading,
    subscription,
    isNotSubscribed,
    isOnTrialPeriod,
    isOnCouponCodeTrial,
    isSubscriptionStateTrial,
    isActive,
    isPremiumPlus,
    isCanceled,
    isCouponCodeTrialEnding,
    isEigibleForTrial,
    isBasic,
    isActiveFastspring,
    isSingleSeatMonthly,
    isSingleSeatYearly,
    isActiveTrendsSubscription,
    hasActiveSubscriptionThatIsNotCanceled,
    isMonthlySubscription,
  };
};

export default useSubscriptionStatus;
