import React from 'react';

import Snackbar from 'features/Snackbar';
import ImageDownload from 'features/ImageDownload';
import ConfirmDialog from 'features/ConfirmDialog';
import PaletteAnalysis from 'features/PaletteAnalysis';
import FullScreenColor from 'features/FullScreenColor';
import ColorMenuPopover from 'features/ColorMenu';
import OldExtensionVersionContainer from 'components/OldExtensionVersion/OldExtensionVersionContainer';
import { isUserAdobeExtensionVersionLessThan } from 'utils/platform';
import config from './config';

function AppModals() {
  return (
    <>
      <Snackbar />
      <ImageDownload />
      <ConfirmDialog />
      <PaletteAnalysis />
      <FullScreenColor />
      <ColorMenuPopover />

      {/* <COYModal /> */}
      {isUserAdobeExtensionVersionLessThan(
        config.MINIMUM_EXTENSION_VERSION
      ) && <OldExtensionVersionContainer />}
    </>
  );
}

export default AppModals;
