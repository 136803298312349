import colors from '~/v3/theme/colors';

export default {
  palette: {
    mode: 'dark',
    shadows: {
      dropShadow: 'drop-shadow(4px 4px 16px rgba(16, 24, 40, 0.16))',
      listChipShadow: {
        chipInfoAreaShadow: 'rgba(0, 0, 0, 0.1) 1.5px 1px 4px 0px',
        chipColorAreaShadow: 'rgba(0, 0, 0, 0.1) 2px 0px 4px 0px',
      },
      pickPageChipHoverMenuShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
      colorInfoLeftClickMenu: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    },
    primary: {
      main: colors.white,
    },
    secondary: {
      main: colors.pantoneLogoBlue,
    },
    text: {
      primary: colors.white,
    },
    background: {
      default: colors.lightBlack,
      paper: colors.lightBlack,
      primary: colors.darkGrey,
      secondary: colors.pantoneLogoBlue,
    },
    icon: {
      primary: colors.white,
      secondary: colors.pantoneLogoBlue,
    },
    error: {
      main: colors.warningRed,
      contrastText: colors.white,
    },
    info: {
      main: colors.lightGrey,
      contrastText: colors.black,
    },
    divider: colors.lightGrey,
  },
  components: {
    MuiTypography: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
      },
    },
  },
};
