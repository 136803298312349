import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import { getColorBrightness } from 'utils/color';

const useStyles = makeStyles(() => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
  column: {
    flex: '1 1 auto',
    minWidth: 30,
    fontSize: 10,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    flexShrink: 0,
  },
  colorPatch: {
    width: '100%',
    height: 30,
  },
  value: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1 1 auto',
  },
}));

const LuminanceMap = ({ colors }) => {
  const classes = useStyles();
  const luminanceArray = colors.map((color) => color.hsl.l);
  const rgbArray = luminanceArray.map((l) => {
    const value = Math.round((255 / 100) * l);
    return { r: value, g: value, b: value };
  });
  const isDarkColorArray = rgbArray.map(getColorBrightness);

  return (
    <div className={classes.container}>
      {colors.map((color, index) => (
        <div
          key={color.code}
          className={classes.column}
          style={{
            backgroundColor: `rgb(${rgbArray[index].r},${rgbArray[index].g},${rgbArray[index].b})`,
            color: isDarkColorArray[index] ? 'white' : 'black',
          }}
        >
          <div
            className={classes.colorPatch}
            style={{
              backgroundColor: `rgb(${color.rgb.r},${color.rgb.g},${color.rgb.b})`,
            }}
          />
          <div className={classes.value}>{color.hsl.l}%</div>
        </div>
      ))}
    </div>
  );
};

LuminanceMap.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default LuminanceMap;
