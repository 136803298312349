import React from 'react';
import { Divider, styled, useTheme } from '@mui/material';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import colors from '~/v3/theme/colors';
import SnackbarCloseButton from './ui/snackbar-close-button';
import { SnackbarProps, Variant } from './model/types';

const CustomSnackbar = styled('div')<{ variant: Variant }>(
  ({ theme, variant }) => ({
    '& .MuiStack-root': {
      background: theme.palette[`${variant}`].main,
      color: theme.palette[`${variant}`].contrastText,
      padding: theme.spacing(0, 1),
      borderRadius: 3,
      width: 'fit-content',
      height: 33,
    },
  })
);

export default React.forwardRef<HTMLDivElement, SnackbarProps>(
  function Snackbar(props, ref) {
    const theme = useTheme();
    const {
      key,
      message,
      action = <SnackbarCloseButton id={key} />,
      variant = 'info',
    } = props;

    return (
      <CustomSnackbar
        ref={ref}
        data-id="snackbar"
        theme={theme}
        variant={variant}
      >
        <Stack
          data-id="snackbar-content"
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
          gap={theme.spacing(1)}
        >
          <Typography
            variant="v3.body2"
            sx={{
              fontSize: 12,
            }}
          >
            {message}
          </Typography>
          <Divider
            orientation="vertical"
            sx={{ height: 17, borderColor: colors.mediumGrey }}
          />
          {action}
        </Stack>
      </CustomSnackbar>
    );
  }
);
