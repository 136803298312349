import { API, graphqlOperation } from '@aws-amplify/api';
import {
  getColorBasicFields,
  getColorFreeFields,
} from '~/v2/api/shared/queryFields';
import i18n from '~/v2/i18n';
import { amplifyUserPoolSettings } from '~/v2/utils/amplify';
import ColorSpace from '~/v3/constants/color-space';
import {
  ColorHarmonies,
  ColorShades,
  Lab,
  PantoneColor,
  PantoneColorWithBook,
} from '~/v3/types/pantone-color';
import { Harmonies } from '../constants/color';
import { BookId } from '../types/book';
import { ColorSpaceValues } from '../types/color-space';
import getColorSpaceValues from '../utils/get-color-space-values';

export async function getColor({
  colorCode,
}: {
  colorCode?: string;
}): Promise<PantoneColorWithBook> {
  API.configure(amplifyUserPoolSettings);

  const colorBasicFields = getColorBasicFields();

  const response = (await API.graphql(
    graphqlOperation(`{
        getColor(code: "${colorCode}") {
          ${colorBasicFields}
          book {
            id
            title
            url
            info {
              ${i18n.languages[0]} {
                target
                description
              }
            }
          }
        }
      }`)
  )) as { data: { getColor: PantoneColorWithBook } };

  return response.data.getColor;
}

export async function getClosestColors({
  colorSpace,
  colorSpaceValues,
  bookId,
}: {
  bookId: BookId;
  colorSpaceValues?: ColorSpaceValues;
  colorSpace: ColorSpace;
}) {
  API.configure(amplifyUserPoolSettings);
  const stringifedColorSpaceValues = getColorSpaceValues({
    colorSpace,
    colorSpaceValues,
  });

  const colorBasicFields = getColorBasicFields();

  const data = (await API.graphql(
    graphqlOperation(`{
          getClosestColors(bookId: "${bookId}" ${stringifedColorSpaceValues}) {
            ${colorBasicFields}
            book {
              id
              title
              url
              info {
                en { target description }
              }
            }
          }
        }`)
  )) as { data: { getClosestColors: PantoneColorWithBook[] } };

  return data?.data?.getClosestColors;
}

export async function getShades({
  code,
}: {
  code: string;
}): Promise<ColorShades> {
  API.configure(amplifyUserPoolSettings);

  const response = (await API.graphql(
    graphqlOperation(`{
      getShades(colorCode: "${code}") {
        hasColorShades
          hue {
            ${getColorFreeFields()}
            rgb{
              r
              g
              b
            }
            lab{
              l
              a
              b
            }
            hex
          }
          lightness {
            ${getColorFreeFields()}
            rgb{
              r
              g
              b
            }
            lab{
              l
              a
              b
            }
            hex
          }
          saturation {
            ${getColorFreeFields()}
            rgb{
              r
              g
              b
            }
            lab{
              l
              a
              b
            }
            hex
          }
        }
      }`)
  )) as { data: { getShades: ColorShades } };

  return response.data.getShades;
}

export async function getHamornies({
  code,
}: {
  code: string;
}): Promise<ColorHarmonies> {
  API.configure(amplifyUserPoolSettings);

  const harmonyColors: ColorHarmonies = {
    analogous: {
      colors: [],
    },
    complementary: {
      colors: [],
    },
    monochromatic: {
      colors: [],
    },
    split_complementary: {
      colors: [],
    },
    tetradic: {
      colors: [],
    },
    triadic: {
      colors: [],
    },
  };

  const promises = Object.keys(Harmonies).map(
    async (harmony) =>
      API.graphql(
        graphqlOperation(`{
          getHarmony(colorCode: "${code}", harmony: ${harmony.toUpperCase()}) {
           ${getColorFreeFields()}
            rgb{
              r
              g
              b
            }
            lab{
              l
              a
              b
            }
            hex
          }
        }`)
      ) as Promise<{ data: { getHarmony: PantoneColorWithBook[] } }>
  );

  const harmoniesData = await Promise.all(promises);

  if (harmoniesData) {
    Object.keys(Harmonies).forEach((harmony, index) => {
      harmonyColors[`${harmony}` as Harmonies] = {
        colors: harmoniesData[index]?.data?.getHarmony,
      };
    });
  }

  return harmonyColors;
}

export const triggerMeasureUpdate = async ({
  labValues,
  measureBook,
}: {
  labValues: Lab;
  measureBook: string;
}): Promise<{
  data: {
    colorLAB: {
      l: number;
      a: number;
      b: number;
    };
  };
}> => {
  API.configure(amplifyUserPoolSettings);

  const data = (await API.graphql(
    graphqlOperation(
      `mutation triggerMeasurementUpdate($l: Float, $a: Float, $b: Float, $bookId: String){
      triggerMeasurementUpdate(colorLAB:{l: $l, a: $a, b: $b }, bookId: $bookId){
        colorLAB{
          l
          a
          b
        }
        userId
        bookId
      }
    }`,
      {
        l: labValues?.l,
        a: labValues?.a,
        b: labValues?.b,
        bookId: measureBook,
      }
    )
  )) as {
    data: {
      triggerMeasurementUpdate: {
        colorLAB: Lab;
        bookId: BookId;
      };
    };
  };

  return {
    data: data.data.triggerMeasurementUpdate,
  };
};

export async function getColorsByCode(
  colorCodes: string[]
): Promise<PantoneColor[]> {
  API.configure(amplifyUserPoolSettings);

  const colorBasicFields = getColorBasicFields();

  const data = (await API.graphql(
    graphqlOperation(`{
        getColorsByCode(codes: ${JSON.stringify(colorCodes)}) {
          ${colorBasicFields}
          book {
            id
            title
            group
            sortIndex
            url
            info {
              ${i18n.languages[0]} {
                target
                description
              }
            }
          }
        }
      }`)
  )) as { data: { getColorsByCode: PantoneColorWithBook[] } };

  return data?.data?.getColorsByCode;
}
