import {
  FetchQueryOptions,
  QueryFunction,
  QueryKey,
  useQueryClient,
} from '@tanstack/react-query';
import { useCallback, useMemo, useState } from 'react';

export function useLazyQuery<
  TQueryFnData = unknown,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryFn: QueryFunction<TQueryFnData, TQueryKey>,
  options?: Omit<
    FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryKey' | 'queryFn'
  >
) {
  const queryClient = useQueryClient();

  const [data, setData] = useState<TData>();
  const [isLoading, setIsLoading] = useState(false);

  const trigger = useCallback(
    async (queryKey: TQueryKey) => {
      setIsLoading(true);

      const response = await queryClient.fetchQuery(queryKey, queryFn, options);

      setData(response);
      setIsLoading(false);

      return response;
    },
    [options, queryClient, queryFn]
  );

  const result = useMemo(
    () => ({
      trigger,
      isLoading,
      data,
    }),
    [data, isLoading, trigger]
  );

  return result;
}
