import React from 'react';
import { t } from 'i18next';
import routes from '~/v2/constants/route';

import useRouter from '~/v3/hooks/use-router';
import { useGetSharedMoodboardsQuery } from '~/v2/services/moodboardApi';
import { useWorkspaceStoreActions } from '~/v3/store/workspace-store';
import { ColorStory } from '~/v3/types/color-story';
import { useSelector } from 'react-redux';
import { selectId } from '~/v2/features/MoodboardWorkspace/moodboardSlice';
import AccordionGroup from '~/v3/components/shared/accordion-group';

const testIds = {
  alert: 'get-shared-color-stories-error',
  loadingSkeleton: 'get-shared-color-stories-loading',
  list: 'shared-color-stories-list',
};

function SharedColorStories() {
  const {
    data: sharedColorStories,
    isLoading,
    isError,
    error,
  } = useGetSharedMoodboardsQuery(null, {
    refetchOnMountOrArgChange: true,
  }) as {
    data: ColorStory[];
    isLoading: boolean;
    isError: boolean;
    error: string;
  };

  const { onExpandWorkspace } = useWorkspaceStoreActions();

  const { push } = useRouter();

  const handleOpenColorStory = (colorStory: ColorStory) => {
    push(routes.colorStoryUpdate.replace(':id', colorStory.id));
    onExpandWorkspace();
  };

  const selectedColorStoryId = useSelector(selectId) as string;

  return (
    <AccordionGroup
      isError={isError}
      error={error}
      isLoading={isLoading}
      list={sharedColorStories}
      selectedId={selectedColorStoryId}
      onClickListItem={(colorStory) => handleOpenColorStory(colorStory)}
      testIds={testIds}
      label={t('labels.sharedColorStory')}
    />
  );
}
export default SharedColorStories;
