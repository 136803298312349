import React from 'react';
import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';

export const StyledNavigationItem = styled((props) => (
  <ButtonBase disableRipple disableTouchRipple {...props} />
))(({ theme }) => ({
  height: '100%',
  paddingRight: theme.spacing(5),
  [theme.breakpoints.down(1320)]: {
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
  '&:last-child': {
    paddingRight: 0,
  },
  '& svg': {
    marginRight: theme.spacing(0.75),
    [theme.breakpoints.down('lg')]: {
      marginRight: theme.spacing(0.5),
    },
  },
  '&:hover': {
    opacity: 1,
  },
}));

export const StyledNavigationText = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('xl')]: {
    fontSize: 13.7,
  },
  color: theme.palette.text.primary,
}));
