import { createSlice } from '@reduxjs/toolkit';

export const pickerSlice = createSlice({
  name: 'colorExtract',
  initialState: {
    activeTab: 'upload',
    localPicture: null,
    localColorPicker: null,
    stockPicture: null,
    stockColorPicker: null,
    stockQuery: '',
    stockIndex: 0,
    stockImages: [],
  },
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setLocalPicture: (state, action) => {
      state.localPicture = action.payload;
    },
    setLocalColorPicker: (state, action) => {
      state.localColorPicker = action.payload;
    },
    setStockPicture: (state, action) => {
      state.stockPicture = action.payload;
    },
    setStockColorPicker: (state, action) => {
      state.stockColorPicker = action.payload;
    },
    setStockQuery: (state, action) => {
      state.stockQuery = action.payload;
    },
    setStockIndex: (state, action) => {
      state.stockIndex = action.payload;
    },
    setStockImages: (state, action) => {
      state.stockImages = action.payload;
    },
  },
});

export const {
  setActiveTab,
  setLocalPicture,
  setLocalColorPicker,
  setStockPicture,
  setStockColorPicker,
  setStockQuery,
  setStockIndex,
  setStockImages,
} = pickerSlice.actions;

export default pickerSlice.reducer;
