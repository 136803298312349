import uniqBy from 'lodash/uniqBy';
import { api } from './api';
import paletteGraphql from './paletteGraphql';

const palettesTag = 'Palettes';

export const paletteApi = api.injectEndpoints({
  endpoints: (build) => ({
    createPalette: build.mutation({
      queryFn: paletteGraphql.createPalette,
      invalidatesTags: [palettesTag],
    }),
    updatePalette: build.mutation({
      queryFn: paletteGraphql.updatePalette,
      invalidatesTags: [palettesTag],
    }),
    deletePalette: build.mutation({
      queryFn: paletteGraphql.deletePalette,
      invalidatesTags: [palettesTag],
    }),
    getPalette: build.query({
      queryFn: paletteGraphql.getPalette,
      providesTags: [palettesTag],
    }),
    getPalettes: build.query({
      queryFn: paletteGraphql.getPalettes,
      providesTags: [palettesTag],
      async onQueryStarted(item, { dispatch, queryFulfilled }) {
        const { data: nextData } = await queryFulfilled;
        // The getPalettes(undefined) is considered as combined data for all pages.
        // When the 'nextData' palettes arrive, we add them to the existing ones.
        const patchResult = dispatch(
          api.util.updateQueryData('getPalettes', undefined, (draft) => {
            const palettes = [...draft.palettes, ...nextData.palettes];
            draft.palettes = uniqBy(palettes, 'id');
          })
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),
    getSharedPalettes: build.query({
      queryFn: paletteGraphql.getSharedPalettes,
      providesTags: [palettesTag],
    }),
    createCollaborator: build.mutation({
      queryFn: paletteGraphql.createCollaborator,
      invalidatesTags: [palettesTag],
    }),
    deleteCollaborator: build.mutation({
      queryFn: paletteGraphql.deleteCollaborator,
      invalidatesTags: [palettesTag],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreatePaletteMutation,
  useUpdatePaletteMutation,
  useDeletePaletteMutation,
  useGetPaletteQuery,
  useGetPalettesQuery,
  useGetSharedPalettesQuery,
  useCreateCollaboratorMutation,
  useDeleteCollaboratorMutation,
} = paletteApi;
