/* eslint-disable no-plusplus */
/* eslint-disable no-bitwise */
/* eslint-disable no-cond-assign */
/* eslint-disable compat/compat */

// Adds arrayBuffer method if not present
// (Needed for Safari)
function myArrayBuffer() {
  // this: File or Blob
  return new Promise((resolve) => {
    const fr = new FileReader();
    fr.onload = () => {
      resolve(fr.result);
    };
    fr.readAsArrayBuffer(this);
  });
}
File.prototype.arrayBuffer = File.prototype.arrayBuffer || myArrayBuffer;
Blob.prototype.arrayBuffer = Blob.prototype.arrayBuffer || myArrayBuffer;

export const getImageDimensions = (src) =>
  new Promise((resolve, reject) => {
    const img = new Image();
    img.onload = () => resolve({ width: img.width, height: img.height });
    img.onerror = () => reject(Error('error loading img'));
    img.src = src;
  });

export const resizeImage = async (base64Str, options) => {
  const img = new Image();
  img.src = base64Str;
  let { width, height } = await getImageDimensions(base64Str);
  const canvas = document.createElement('canvas');
  const { minWidth, minHeight, maxWidth, maxHeight } = options;

  if (minWidth && width < minWidth) {
    height *= minWidth / width;
    width = minWidth;
  }

  if (minHeight && height < minHeight) {
    width *= minHeight / height;
    height = minHeight;
  }

  if (maxWidth && width > maxWidth) {
    height *= maxWidth / width;
    width = maxWidth;
  }

  if (maxHeight && height > maxHeight) {
    width *= maxHeight / height;
    height = maxHeight;
  }

  canvas.width = width;
  canvas.height = height;
  const ctx = canvas.getContext('2d');
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL();
};

export const arrayBufferToBase64 = (buffer) => {
  let binary = '';
  const bytes = new Uint8Array(buffer);
  const len = bytes?.byteLength;
  if (len) {
    for (let i = 0; i < len; i += 1) {
      binary += String.fromCharCode(bytes[i]);
    }
    return `data:image/jpeg;base64,${window.btoa(binary)}`;
  }
  return null;
};

// Algorithm from:
// https://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side/20600869
export const getOrientation = (imageData) => {
  const view = new DataView(imageData);
  if (view.getUint16(0, false) !== 0xffd8) return -2;

  const length = view.byteLength;
  let offset = 2;
  while (offset < length) {
    if (view.getUint16(offset + 2, false) <= 8) return -1;
    const marker = view.getUint16(offset, false);
    offset += 2;
    if (marker === 0xffe1) {
      if (view.getUint32((offset += 2), false) !== 0x45786966) {
        return -1;
      }

      const little = view.getUint16((offset += 6), false) === 0x4949;
      offset += view.getUint32(offset + 4, little);
      const tags = view.getUint16(offset, little);
      offset += 2;
      for (let i = 0; i < tags; i += 1) {
        if (view.getUint16(offset + i * 12, little) === 0x0112) {
          return view.getUint16(offset + i * 12 + 8, little);
        }
      }
    } else if ((marker & 0xff00) !== 0xff00) {
      break;
    } else {
      offset += view.getUint16(offset, false);
    }
  }
  return -1;
};

export const rotateBase64Image = (base64Image, isClockwise) => {
  const offScreenCanvas = document.createElement('canvas');
  const offScreenCanvasCtx = offScreenCanvas.getContext('2d');
  const img = new Image();
  img.src = base64Image;
  offScreenCanvas.height = img.width;
  offScreenCanvas.width = img.height;

  if (isClockwise) {
    offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
  } else {
    offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
    offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
  }
  offScreenCanvasCtx.drawImage(img, 0, 0);

  return offScreenCanvas.toDataURL();
};

export async function getResizedBase64Image(arrayBuffer) {
  let imageData = arrayBufferToBase64(arrayBuffer);
  const orientation = getOrientation(arrayBuffer);
  const imageDimensions = await getImageDimensions(imageData);

  if (orientation === 6 && imageDimensions.width > imageDimensions.height) {
    imageData = rotateBase64Image(imageData, true);
    const { width, height } = imageDimensions;
    imageDimensions.width = height;
    imageDimensions.height = width;
  }

  if (orientation === 8 && imageDimensions.width > imageDimensions.height) {
    imageData = rotateBase64Image(imageData, true);
    imageData = rotateBase64Image(imageData, true);
    imageData = rotateBase64Image(imageData, true);
    const { width, height } = imageDimensions;
    imageDimensions.width = height;
    imageDimensions.height = width;
  }

  const resizedBase64Image = await resizeImage(imageData, {
    maxWidth: 700,
    maxHeight: 700,
  });
  const { width, height } = await getImageDimensions(resizedBase64Image);

  return {
    base64: resizedBase64Image,
    width,
    height,
  };
}

// Algorithm from:
// https://gist.github.com/suuuzi/06a3b0b6741e6a90d83548aa8ac9666a
export function dataURItoBlob(dataURI) {
  // convert base64/URLEncoded data component to raw binary data held in a string
  let byteString;
  if (dataURI.split(',')[0].indexOf('base64') >= 0)
    byteString = atob(dataURI.split(',')[1]);
  else byteString = unescape(dataURI.split(',')[1]);

  // separate out the mime component
  const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  // write the bytes of the string to a typed array
  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export default {
  resizeImage,
  getImageDimensions,
  getOrientation,
  arrayBufferToBase64,
  rotateBase64Image,
  getResizedBase64Image,
  dataURItoBlob,
};
