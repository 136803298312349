import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import React, { Suspense } from 'react';
import Loader from '~/v2/components/Loader';
import useSessionExpired from '~/v2/hooks/useSessionExpired';
import useCouponCode from '~/v2/pages/CouponCodes/useCouponCode';
import Header from '~/v3/components/shared/layouts/header';
import Footer from '~/v3/components/shared/layouts/footer';
import useSyncUserSubscription from '~/v3/react-query/hooks/use-sync-user-subscriptions';
import { isSidebarEnabled } from '~/v3/feature-flags';
import SidebarDrawer from '~/v3/components/features/sidebar';
import { workspaceLargeTestId } from '~/v3/constants/workspace-large-test-id';
import { Banner7DaysTrial } from '../../features/promotions/7-days-trial-banner';

export const workspaceWidth = 380;

const Container = styled(Box)({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  width: '100vw',
  overflow: 'hidden',
  '@supports (bottom: env(safe-area-inset-bottom, 0px))': {
    marginBottom: 'env(safe-area-inset-bottom, 0px)', // needed for IOS safe-area
  },
  [`& div[data-testid=${workspaceLargeTestId}]`]: {
    width: `${workspaceWidth}px`,
  },
});

type MainLayoutProps = {
  children: React.ReactNode;
};

function MainLayout({ children }: Readonly<MainLayoutProps>) {
  const isMediumScreen = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  useCouponCode();
  useSessionExpired();
  useSyncUserSubscription();

  return (
    <Container>
      <Banner7DaysTrial />
      <Suspense fallback={<Loader />}>
        <Header />
      </Suspense>
      <main
        style={{
          flexGrow: 1,
          minHeight: 0,
          display: 'flex',
        }}
      >
        {isSidebarEnabled && <SidebarDrawer />}
        {children}
      </main>
      {isMediumScreen && (
        <Footer
          sx={{
            flexShrink: 0,
            minHeight: 0,
            position: 'initial',
            bottom: 'unset',
          }}
        />
      )}
    </Container>
  );
}

export default MainLayout;
