import { Typography } from '@mui/material';
import useTracker from '~/v2/analytics/useTracker';
import { onLeftClickMenuEvent } from '~/v3/events';
import { PantoneColor } from '~/v3/types/pantone-color';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getColorURL } from '~/v2/utils/links';
import LinkIcon from '~/v3/components/shared/icons/link-icon';
import { StyledMenuItem } from '../left-click-menu.elements';
import { BaseMenuKeys } from '../model/types';

function PantoneRedirectMenuItem({ color }: Readonly<{ color: PantoneColor }>) {
  const ga = useTracker();
  const { t } = useTranslation();
  function handlePanetoneRedirect() {
    getColorURL(color?.code);
    ga.trackEvent({
      ...onLeftClickMenuEvent,
      EVENT_LABEL: 'color_chip_redirect_ecom',
      TERM: color.code,
      EVENT_BOOK_ID: color?.book?.id,
    });
  }
  return (
    <StyledMenuItem
      rgb={color?.rgb}
      stroke
      key={BaseMenuKeys.pantoneRedirect}
      onClick={() => handlePanetoneRedirect()}
    >
      <LinkIcon />
      <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
        {t('labels.pantoneRedirect')}
      </Typography>
    </StyledMenuItem>
  );
}

export default PantoneRedirectMenuItem;
