import React from 'react';
import classNames from 'classnames';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import SnackbarContent from '@mui/material/SnackbarContent';
import CloseIcon from '@mui/icons-material/Close';
import { makeStyles } from '@mui/styles';

import colors from 'theme/colors';
import useSnackbar from './useSnackbar';

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: 0,

    [theme.breakpoints.down('sm')]: {
      marginBottom: 64,
    },
  },
  content: {
    flexWrap: 'nowrap',
  },
  error: {
    color: colors.WhiteGrey[0],
    background: theme.palette.error.light,
  },
  warning: {
    color: theme.palette.text.primary,
    background: theme.palette.warning.light,
  },
  success: {
    color: theme.palette.text.primary,
    background: theme.palette.success.main,
  },
}));

function SnackbarDialog() {
  const { isOpen, message, type, autoHideDuration, style, closeSnackbar } =
    useSnackbar();
  const classes = useStyles();

  return (
    isOpen && (
      <Snackbar
        className={classes.root}
        open={isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackbar}
        style={style}
      >
        <SnackbarContent
          className={classNames(classes.content, classes[type])}
          message={message}
          data-testid="snackbar-message"
          action={
            <IconButton size="small" color="primary" onClick={closeSnackbar}>
              <CloseIcon
                color={type === 'default' ? 'secondary' : 'action'}
                fontSize="small"
              />
            </IconButton>
          }
        />
      </Snackbar>
    )
  );
}

export default SnackbarDialog;
