// eslint-disable-next-line no-shadow
enum UserEvents {
  changeTheme = 'changeTheme',
  clearAppCache = 'clearAppCache',
  privacyPolicy = 'privacyPolicy',
  signOut = 'signOut',
  cancelSubscription = 'cancelSubscription',
  subscription = 'subscription',
  manageSubscription = 'manageSubscription',
  myProfile = 'myProfile',
  faq = 'faq',
  about = 'about',
  manageCookies = 'manageCookies',
  changeLanguage = 'changeLanguage',
  statistics = 'statistics',
  codeActivation = 'codeActivation',
}

export default UserEvents;
