import colors from '~/v2/theme/colors';
import { styled } from '@mui/material';
import { CSSProperties } from '@mui/styles';
import BaseFooterNavigation from './ui/base-footer-navigation';

export const footerItemTransition = 'all 0.5s ease';

export const selectedFooterItemColor = colors.Blue[300];

export const selectedFooterNavigationStyle: CSSProperties = {
  color: selectedFooterItemColor,
  borderTop: `2px solid ${selectedFooterItemColor}`,
};

export const StyledFooterNavigation = styled(BaseFooterNavigation)(
  ({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: 1,
    cursor: 'pointer',
    fontFamily: 'Helvetica Now Display',
    textTransform: 'uppercase',
    transition: footerItemTransition,
    color: theme.palette.text.primary,
    letterSpacing: '0.025em',
    borderTop: `2px solid ${colors.WhiteGrey[650]}`,
    '& .MuiTypography-root': {
      lineHeight: '100%',
      fontSize: 12,
      fontWeight: 500,
      marginTop: theme.spacing(1),
      [theme.breakpoints.down(375)]: {
        display: 'none',
      },
    },
  })
);
