import { BOOKS } from '~/v3/constants/books';

export function isPanoramaBook(color) {
  return color?.book?.id === BOOKS.pantoneFhiColorPanorama.id;
}

export function isCoY(currentCoY, color) {
  return currentCoY?.includes(color?.code);
}

export function getColorBrightness({ r, g, b }) {
  const brightnessLevel = (r * 299 + g * 587 + b * 114) / 1000;
  return brightnessLevel <= 128;
}

export function getRGBstring(rgb) {
  return `rgb(${Math.round(rgb?.r)}, ${Math.round(rgb?.g)}, ${Math.round(
    rgb?.b
  )})`;
}

export function getKeyFromColorRGB(rgb) {
  return `${Math.round(rgb?.r)}-${Math.round(rgb?.g)}-${Math.round(rgb?.b)}`;
}

export function removeDuplicateColors(uniqueColorCodes, colors) {
  return uniqueColorCodes.map((code) => {
    return colors.find((color) => color.code === code);
  });
}

export const hasDuplicateColors = (colors) => {
  const colorSet = new Set(colors.map((color) => color.code));
  const uniqueColorCodes = Array.from(colorSet);
  return uniqueColorCodes.length !== colors.length;
};

export default {
  isPanoramaBook,
  getColorBrightness,
  getRGBstring,
  getKeyFromColorRGB,
  removeDuplicateColors,
  hasDuplicateColors,
};
