import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import { makeStyles } from '@mui/styles';
import { isSidebarEnabled } from '~/v3/feature-flags';
import IconButton from '@mui/material/IconButton';
import ExpandMoreIcon from '@mui/icons-material/ExpandMoreOutlined';
import ExpandLessIcon from '@mui/icons-material/ExpandLessOutlined';
import {
  workspaceSmallDataTestId,
  collapsedMobileWorkspaceHeaderHeight,
  expandedMobileWorkspaceHeight,
} from '~/v3/pick/components/constants';
import { dragAndDropVariants } from '~/v3/constants/drag-and-drop';
import { useDrag } from 'react-dnd';
import useWorkspaceStore, {
  useWorkspaceStoreActions,
} from '~/v3/store/workspace-store';
import { WorkspaceButtonsContainer } from '~/v2/components/WorkspaceUI/workspace-buttons-container';
import WorkspaceName from '~/v2/components/WorkspaceUI/WorkspaceName';
import WorkspaceColors from '~/v2/components/WorkspaceUI/WorkspaceDropzone';
import AnalyzeButton from '~/v2/components/WorkspaceUI/AnalyzeButton';
import DownloadButton from '~/v2/components/WorkspaceUI/DownloadButton';

const useStyles = makeStyles((theme) => ({
  workspace: {
    display: 'flex',
    flexShrink: 0,
    flexDirection: 'column',
    overflow: 'hidden',
    background: theme.palette.background.secondary,
    zIndex: theme.zIndex.appBar,
  },
  workspaceHeader: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    height: collapsedMobileWorkspaceHeaderHeight,
    maxHeight: collapsedMobileWorkspaceHeaderHeight,
  },
  workspaceExpand: {
    display: 'flex',
    padding: theme.spacing(0, 2.5),
    borderRight: `1px solid ${theme.palette.grey[200]}`,
  },
  workspaceContent: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: ({ expanded }) => (expanded ? 'column' : 'row'),
  },
  workspaceColors: {
    flexGrow: 1,
    display: 'flex',
    overflow: 'auto',
    alignItems: 'center',
    alignContent: 'flex-start',
  },
  workspaceColorsExpanded: {
    flexWrap: 'wrap',
    alignItems: 'flex-start',
    padding: theme.spacing(1.5),
    overflowY: 'auto',
    maxHeight: 160,
  },
  workspaceOptions: {
    height: 40,
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 2.5),
  },
  workspaceActions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: theme.spacing(1.5),
    marginTop: 'auto',
  },
}));

function WorkspaceSmall({
  saveButton,
  shareButton,
  value,
  onSubmit,
  onNameChange,
  clearButton,
  refreshButton,
  deleteButton,
}) {
  const { isExpanded: expanded } = useWorkspaceStore();
  const { onExpandWorkspace, onCollapseWorkspace } = useWorkspaceStoreActions();
  const classes = useStyles({ expanded });
  const height = expanded
    ? expandedMobileWorkspaceHeight
    : collapsedMobileWorkspaceHeaderHeight;
  const chipSize = expanded ? 60 : 32;

  const id = value?.id;
  const name = value?.name;
  const colors = value?.colors;
  const disabled = colors && !colors.length;

  const [{ isDragging }] = useDrag(() => ({
    type: dragAndDropVariants.V3_PANTONE_INFO_CHIP,
    collect: (monitor) => ({
      isDragging: !!monitor.getItem(),
    }),
  }));

  useEffect(() => {
    if (isDragging && !expanded) onExpandWorkspace();
  }, [isDragging, expanded, onExpandWorkspace]);

  const handleExpand = () => {
    if (expanded) onCollapseWorkspace();
    else onExpandWorkspace();
  };

  const handleSubmitName = (newName) => {
    onSubmit({ name: newName });
  };

  const handleSubmitColors = (newColors) => {
    onSubmit({ colors: newColors });
  };

  const handleNameChange = (newName) => {
    onNameChange(newName);
  };

  return (
    <motion.div
      animate={{ height }}
      className={classes.workspace}
      data-testid={workspaceSmallDataTestId}
    >
      <div className={classes.workspaceHeader} data-testid="header">
        <div className={classes.workspaceExpand}>
          <IconButton
            size="small"
            onClick={handleExpand}
            data-testid="expand-button"
          >
            {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </IconButton>
        </div>

        <WorkspaceName
          value={value}
          onSubmit={handleSubmitName}
          onNameChange={handleNameChange}
        />

        {!isSidebarEnabled && (
          <div className={classes.workspaceOptions}>
            {refreshButton}
            {clearButton}
          </div>
        )}
      </div>

      <div className={classes.workspaceContent}>
        <WorkspaceColors
          value={value?.colors}
          chipSize={chipSize}
          onSubmit={handleSubmitColors}
          className={classNames(classes.workspaceColors, {
            [classes.workspaceColorsExpanded]: expanded,
          })}
          data-testid="dropzone"
        />

        <div className={classes.workspaceActions} data-testid="actions">
          {expanded && (
            <WorkspaceButtonsContainer>
              <AnalyzeButton name={name} colors={colors} disabled={disabled} />
              <DownloadButton
                id={id}
                name={name}
                colors={colors}
                disabled={disabled}
              />
              {shareButton}
              {isSidebarEnabled && deleteButton}
            </WorkspaceButtonsContainer>
          )}
          {saveButton}
        </div>
      </div>
    </motion.div>
  );
}

WorkspaceSmall.defaultProps = {
  value: null,
  saveButton: null,
  shareButton: null,
  clearButton: null,
  deleteButton: null,
};

WorkspaceSmall.propTypes = {
  saveButton: PropTypes.node,
  shareButton: PropTypes.node,
  clearButton: PropTypes.node,
  refreshButton: PropTypes.node.isRequired,
  deleteButton: PropTypes.node,
  value: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    colors: PropTypes.arrayOf(PropTypes.shape()),
  }),
  onSubmit: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
};

export default WorkspaceSmall;
