import { useDispatch, useSelector } from 'react-redux';
import { open, close, selectColors, selectIsOpen } from './imageDownloadSlice';

function useImageDownload() {
  const dispatch = useDispatch();
  const isOpen = useSelector(selectIsOpen);
  const colors = useSelector(selectColors);
  return {
    isOpen,
    colors,
    open: (imageDownloadColors) => dispatch(open(imageDownloadColors)),
    close: () => dispatch(close()),
  };
}

export default useImageDownload;
