import { useEffect } from 'react';
import { vwoScript } from '~/v3/utils/vwo-script';
import { VWO_ACCOUNT_ID } from '~/v3/config';

function useVwoScriptLoader() {
  useEffect(() => {
    const vwoScriptId = 'vwoCode';
    if (document.getElementById(vwoScriptId)) {
      return undefined;
    }

    const link = document.createElement('link');
    link.rel = 'preconnect';
    link.href = 'https://dev.visualwebsiteoptimizer.com';
    document.head.appendChild(link);

    const script = document.createElement('script');
    script.id = vwoScriptId;
    script.type = 'text/javascript';
    script.textContent = vwoScript(VWO_ACCOUNT_ID);
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return null;
}

export default useVwoScriptLoader;
