import React from 'react';
import PropTypes from 'prop-types';

// Load logo here so wabpack can load it and we can use it in index.html for splash screen
import '../../public/images/pantone-connect-logo.svg';

function PantoneLogoWithIcon({ color, ...props }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1044 301"
      style={{ enableBackground: 'new 0 0 1044 301' }}
      xmlSpace="preserve"
      {...props}
    >
      <path
        id="Wordmark"
        className="st0"
        style={{ fill: color || '#151C2C' }}
        d="M438,239.4c-3.5,3.9-8.4,5.8-14.7,5.8c-4.6,0-8.6-0.9-11.8-2.6c-3.2-1.8-5.9-4.3-7.9-7.2
	c-2-3-3.5-6.3-4.5-10c-0.9-3.8-1.3-7.6-1.3-11.6c0-4.1,0.4-8.1,1.3-12c1-3.9,2.4-7.3,4.5-10.3c2-3.1,4.6-5.5,7.9-7.2
	c3.2-1.8,7.2-2.8,11.8-2.8c2.5,0,4.9,0.4,7.2,1.3c2.4,0.8,4.5,1.9,6.3,3.4c1.8,1.5,3.4,3.2,4.6,5.3c1.2,1.9,2,4.1,2.4,6.5h19.9
	c-0.6-5.4-2.1-10.3-4.5-14.5c-2.4-4.2-5.4-7.7-9.1-10.5c-3.6-2.9-7.7-5.1-12.3-6.6c-4.5-1.5-9.4-2.2-14.6-2.2
	c-7.2,0-13.6,1.3-19.4,3.8c-5.7,2.5-10.5,6.1-14.4,10.5c-3.9,4.5-7,9.7-9.1,15.8c-2.1,6-3.1,12.5-3.1,19.5c0,6.9,1,13.3,3.1,19.2
	c2.1,5.9,5.1,11,9.1,15.4c3.9,4.4,8.7,7.9,14.4,10.4c5.8,2.5,12.2,3.7,19.4,3.7c5.8,0,11.1-0.9,15.9-2.6c4.8-1.8,9-4.3,12.6-7.6
	c3.6-3.3,6.5-7.4,8.7-12.1c2.2-4.7,3.5-10.1,4.1-15.9h-19.9C443.8,230.4,441.6,235.5,438,239.4z M408.4,114.1h15.6
	c13.7-0.3,27.2-0.9,35.6-13.2c3.8-5.3,5.8-11.7,5.6-18.2c0.2-6.6-1.6-13.1-5.2-18.6c-1.1-1.7-2.5-3.3-4-4.7
	c-7-5.2-15.7-7.7-24.5-6.9h-47V144h23.8V114.1z M408.4,69.1h15c5.6,0,12.5,0,15.6,5.2c1.8,2.5,2.7,5.5,2.8,8.6
	c0.2,1.6,0,3.3-0.4,4.9c-0.5,1.6-1.3,3.1-2.3,4.3c-1.1,1.3-2.4,2.3-3.8,3.1c-1.5,0.8-3.1,1.2-4.7,1.3c-2.7,0.4-5.4,0.6-8.1,0.6h-14
	V69.1z M579.2,86.4c2.5,4.7,5.6,9.8,8.1,14.5c2.5,4.7,5.3,9.4,8.4,14.1l17.5,29h23.1V52.4h-22.5l0.9,58.9c-4.3-8.4-9.4-17-14.1-25.2
	l-20.2-33.7h-22.9V144h22.6L579.2,86.4z M991.6,126.1h-50.4V104h43.2V86.7h-43.2V70h46V52.4h-69.5V144h73.8V126.1z M669.4,144h23.8
	V70.4h24.5v-18H645v18h24.3V144z M844.9,86.4c2.9,4.7,5.6,9.8,8.1,14.5c2.6,4.7,5.7,9.4,8.5,14.1l17.5,29h23.2V52.4h-22.5l0.9,58.9
	c-4.4-8.4-8.9-17-14.1-25.2l-20.3-33.7h-23.1V144h22.8L844.9,86.4z M480.6,144l4.7-14.5h34.7l4.7,14.5h25L515,52.4h-24.4l-35,91.7
	H480.6z M497.8,90.2c1.6-5.2,3.2-10.7,4.4-16.1l12.5,38.7h-24.1C493.2,105.3,495.9,97.7,497.8,90.2z M915.7,183.7h28.1v76.7h20.6
	v-76.7h28.1v-17.4h-76.8V183.7z M1026.8,73.5c0.7-0.3,1.4-0.8,2-1.4c0.5-0.6,1-1.3,1.3-2c0.3-0.8,0.4-1.6,0.4-2.4
	c0-5.6-3.6-7.4-10-7.4h-8.8l-0.1,24.2h5.2V74.2h2.7l5.5,10.4h5.7l-6.2-10.7C1025.3,74,1026.1,73.8,1026.8,73.5z M1021.2,70.4h-4.3
	v-6h3.3c2,0,4.8,0,4.8,2.8C1025.1,69.9,1023.5,70.4,1021.2,70.4z M1042.2,63.8c-1.8-4.2-4.8-7.9-8.6-10.4c-3.8-2.5-8.3-3.9-12.9-3.9
	c-6.2,0-12.1,2.5-16.4,6.8c-4.4,4.4-6.8,10.3-6.8,16.4c0,4.6,1.4,9.1,3.9,12.9c2.6,3.8,6.2,6.8,10.4,8.6c4.2,1.8,8.9,2.2,13.4,1.3
	c4.5-0.9,8.7-3.1,11.9-6.4c3.2-3.3,5.5-7.4,6.4-11.9C1044.4,72.8,1044,68.1,1042.2,63.8z M1036.6,79.4c-1.3,3.1-3.5,5.7-6.4,7.6
	c-2.8,1.8-6.1,2.8-9.5,2.7c-2.2,0-4.4-0.4-6.5-1.2c-2.1-0.8-3.9-2.1-5.5-3.7c-1.6-1.6-2.8-3.5-3.6-5.5c-0.8-2.1-1.2-4.3-1.1-6.5
	c0-3.4,1-6.7,2.9-9.5c1.9-2.8,4.5-5,7.7-6.3c3.1-1.3,6.6-1.6,9.9-0.9c3.3,0.7,6.3,2.3,8.7,4.7c2.4,2.4,4,5.5,4.6,8.8
	C1038.3,72.9,1037.9,76.3,1036.6,79.4z M548.7,178.4c-3.9-4.5-8.7-8-14.4-10.5c-5.7-2.5-12.2-3.8-19.4-3.8c-7.2,0-13.6,1.3-19.4,3.8
	c-5.7,2.5-10.5,6.1-14.4,10.5c-3.9,4.5-7,9.7-9.1,15.8c-2.1,6-3.1,12.5-3.1,19.5c0,6.9,1,13.3,3.1,19.2c2.1,5.9,5.1,11,9.1,15.4
	c3.9,4.4,8.7,7.9,14.4,10.4c5.8,2.5,12.2,3.7,19.4,3.7c7.3,0,13.7-1.2,19.4-3.7c5.7-2.5,10.5-6,14.4-10.4c3.9-4.4,7-9.5,9.1-15.4
	c2.1-6,3.1-12.4,3.1-19.2c0-7-1.1-13.5-3.1-19.5C555.6,188.1,552.6,182.9,548.7,178.4z M538.8,225.3c-0.9,3.7-2.3,7-4.3,10
	c-2,3-4.6,5.4-7.9,7.2c-3.2,1.8-7.2,2.6-11.8,2.6c-4.6,0-8.6-0.9-11.8-2.6c-3.2-1.8-5.9-4.3-7.9-7.2c-2-3-3.5-6.3-4.5-10
	c-0.9-3.8-1.3-7.6-1.3-11.6c0-4.1,0.4-8.1,1.3-12c1-3.9,2.4-7.3,4.5-10.3c2-3.1,4.6-5.5,7.9-7.2c3.2-1.8,7.2-2.8,11.8-2.8
	c4.6,0,8.6,0.9,11.8,2.8c3.2,1.8,5.9,4.2,7.9,7.2c2,3,3.5,6.4,4.3,10.3c1,3.9,1.4,7.9,1.4,12C540.3,217.7,539.8,221.5,538.8,225.3z
	 M888.2,239.4c-3.5,3.9-8.4,5.8-14.7,5.8c-4.6,0-8.6-0.9-11.8-2.6c-3.2-1.8-5.9-4.3-7.9-7.2c-2-3-3.5-6.3-4.5-10
	c-0.9-3.8-1.3-7.6-1.3-11.6c0-4.1,0.4-8.1,1.3-12c1-3.9,2.4-7.3,4.5-10.3c2-3.1,4.6-5.5,7.9-7.2c3.2-1.8,7.2-2.8,11.8-2.8
	c2.5,0,4.9,0.4,7.2,1.3c2.4,0.8,4.5,1.9,6.3,3.4c1.8,1.5,3.4,3.2,4.6,5.3c1.2,1.9,2,4.1,2.4,6.5h19.9c-0.6-5.4-2.1-10.3-4.5-14.5
	c-2.4-4.2-5.4-7.7-9.1-10.5c-3.6-2.9-7.7-5.1-12.3-6.6c-4.5-1.5-9.4-2.2-14.6-2.2c-7.2,0-13.6,1.3-19.4,3.8
	c-5.7,2.5-10.5,6.1-14.4,10.5c-3.9,4.5-7,9.7-9.1,15.8c-2.1,6-3.1,12.5-3.1,19.5c0,6.9,1,13.3,3.1,19.2c2.1,5.9,5.1,11,9.1,15.4
	c3.9,4.4,8.7,7.9,14.4,10.4c5.8,2.5,12.2,3.7,19.4,3.7c5.8,0,11.1-0.9,15.9-2.6c4.8-1.8,9-4.3,12.6-7.6c3.6-3.3,6.5-7.4,8.7-12.1
	c2.2-4.7,3.5-10.1,4.1-15.9h-19.9C894,230.4,891.8,235.5,888.2,239.4z M720.5,229.4h-0.3l-39.1-63.1h-20.5v94.1H680v-63h0.3l39,63
	h20.6v-94.1h-19.3V229.4z M629,229.4h-0.3l-39.1-63.1h-20.5v94.1h19.3v-63h0.3l39,63h20.6v-94.1H629V229.4z M772.8,219.9h45.4v-16.1
	h-45.4v-20.2h49.5v-17.4h-70.1v94.1h71.1V243h-50.5V219.9z M793.9,137.3l0.1-0.2c5.6-4.7,10.1-10.6,13.2-17.2
	c3.1-6.6,4.7-13.9,4.6-21.2c0-13.5-5-26.5-14.1-36.5c-4.5-4.3-9.9-7.6-15.7-9.8c-5.8-2.2-12-3.2-18.2-3c-7.3-0.3-14.6,1.3-21.2,4.6
	c-6.6,3.3-12.2,8.2-16.3,14.3c-5.8,8.8-8.8,19.1-8.8,29.6c-0.2,13.2,4.6,26.1,13.5,35.9c4.4,4.3,9.6,7.6,15.3,9.9
	c5.7,2.3,11.8,3.4,17.9,3.2C775,147.5,785.5,144,793.9,137.3z M755.8,125.7c-2.8-1.1-5.2-2.9-7.3-5c-2.8-3.2-4.9-6.9-6.2-10.9
	c-1.3-4-1.9-8.2-1.6-12.4c0-9.8,3.1-20.9,12.8-26.1c3.2-1.7,6.8-2.5,10.4-2.5c3.5-0.1,6.9,0.6,10.1,2.1c3.2,1.5,5.9,3.7,8.1,6.4
	c4.5,6.8,6.6,14.9,5.9,23l-0.1-0.1c0.2,3.7-0.3,7.3-1.6,10.8c-1.2,3.5-3.1,6.6-5.6,9.3c-2.1,2.2-4.6,3.9-7.4,5.1
	c-2.8,1.2-5.8,1.8-8.8,1.8C761.6,127.4,758.6,126.9,755.8,125.7z"
      />
      <g id="Chips">
        <defs>
          <path
            id="SVGID_1_"
            d="M16.7,0h267.2c9.1,0,16.4,7.3,16.4,16.4v267.2c0,9.1-7.3,16.4-16.4,16.4H16.7c-9.1,0-16.4-7.3-16.4-16.4
			V16.4C0.3,7.3,7.7,0,16.7,0z"
          />
        </defs>
        <clipPath id="SVGID_00000067222931821220277870000009958928410340985783_">
          <use xlinkHref="#SVGID_1_" style={{ overflow: 'visible' }} />
        </clipPath>
        <g
          style={{
            clipPath:
              'url(#SVGID_00000067222931821220277870000009958928410340985783_)',
          }}
        >
          <rect
            x="100"
            y="0"
            style={{ fill: '#00E4E9' }}
            width="100"
            height="100"
          />
          <rect
            x="0"
            y="0"
            style={{ fill: '#50F6D4' }}
            width="100"
            height="100"
          />
          <rect
            x="100"
            y="100"
            style={{ fill: '#00C9F6' }}
            width="100"
            height="100"
          />
          <rect
            x="0"
            y="100"
            style={{ fill: '#00E1ED' }}
            width="100"
            height="100"
          />
          <rect
            x="100"
            y="200"
            style={{ fill: '#009CFF' }}
            width="100"
            height="100"
          />
          <rect
            x="0"
            y="200"
            style={{ fill: '#00BCFA' }}
            width="100"
            height="100"
          />
          <rect
            x="200"
            y="0"
            style={{ fill: '#00D7F1' }}
            width="100"
            height="100"
          />
          <rect
            x="200"
            y="100"
            style={{ fill: '#00B1FF' }}
            width="100"
            height="100"
          />
          <rect
            x="200"
            y="200"
            style={{ fill: '#0086FF' }}
            width="100"
            height="100"
          />
        </g>
      </g>
    </svg>
  );
}

PantoneLogoWithIcon.defaultProps = {
  className: '',
  color: '#151C2C',
};

PantoneLogoWithIcon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
};

export default PantoneLogoWithIcon;
