import React from 'react';
import { useGetSharedPalettesQuery } from '~/v2/services/paletteApi';
import { Palette } from '~/v3/types/palette';
import { t } from 'i18next';
import { usePalettesNavigation } from '~/v2/pages/MyPalettes/myPalettesHooks';
import { getPaletteFromLocalStorage } from '~/v3/utils/get-palette-from-local-storage';
import AccordionGroup from '~/v3/components/shared/accordion-group';

const testIds = {
  alert: 'get-shared-palettes-error',
  loadingSkeleton: 'get-shared-palettes-loading',
  list: 'shared-palettes-list',
};

function SharedPalettes() {
  const {
    data: sharedPalettes,
    error,
    isError,
    isLoading,
  } = useGetSharedPalettesQuery(null, {
    refetchOnMountOrArgChange: true,
  }) as {
    data: Palette[];
    isLoading: boolean;
    isError: boolean;
    error: string;
  };
  const { goToEditPalette } = usePalettesNavigation();
  const localPalette = getPaletteFromLocalStorage();

  return (
    <AccordionGroup
      isError={isError}
      error={error}
      isLoading={isLoading}
      list={sharedPalettes}
      selectedId={localPalette?.palette?.id}
      onClickListItem={(palette) => goToEditPalette(palette)}
      testIds={testIds}
      label={t('labels.shared')}
    />
  );
}
export default SharedPalettes;
