import { MenuItem, styled } from '@mui/material';
import colors from '~/v3/theme/colors';
import { Rgb } from '~/v3/types/pantone-color';

export const StyledMenuItem = styled(MenuItem)<{
  rgb: Rgb;
  fill?: boolean;
  stroke?: boolean;
}>(({ fill = false, stroke = false, rgb, theme }) => ({
  color: theme.palette.isDark(rgb) ? colors.white : colors.black,
  minHeight: theme.spacing(3),
  width: '100%',
  gap: theme.spacing(1),
  padding: theme.spacing(0, 0.9),
  '&:hover': {
    backgroundColor: colors.transparentWhite,
  },
  '& .MuiTypography-root': {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
    textAlign: 'left',
  },
  '&:first-of-type': {
    paddingTop: theme.spacing(1.125),
    paddingBottom: theme.spacing(0.625),
  },
  '&:last-of-type': {
    paddingBottom: theme.spacing(1.125),
    paddingTop: theme.spacing(0.625),
  },
  '& .MuiSvgIcon-root': {
    color: theme.palette.isDark(rgb) ? colors.white : colors.black,
    minWidth: theme.spacing(1.375),
    width: theme.spacing(1.375),
    height: theme.spacing(1.375),
    ...(fill && {
      fill: theme.palette.isDark(rgb) ? colors.white : colors.black,
    }),
    ...(stroke && {
      stroke: theme.palette.isDark(rgb) ? colors.white : colors.black,
      strokeWidth: 1,
    }),
  },
}));
