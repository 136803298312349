import Box from '@mui/material/Box';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';

import events from 'analytics/events';
import useTracker from 'analytics/useTracker';
import PantoneChipSmallSkeleton from 'components/PantoneChipSmallSkeleton';
import useSnackbar from 'features/Snackbar/useSnackbar';
import { useSelector } from 'react-redux';
import { getKeyFromColorRGB } from 'utils/color';
import {
  selectId,
  selectName,
} from '~/v2/features/PaletteWorkspace/paletteSlice';
import { dragAndDropVariants } from '~/v3/constants/drag-and-drop';
import PantoneChipSmall from '../PantoneChipSmall';
import useWorkspace from './useWorkspace';

const MAX_COLORS = 40;

function WorkspaceDropzone({ chipSize, value, onSubmit, onDrag, ...props }) {
  const { t } = useTranslation();
  const { openSnackbar } = useSnackbar();
  const { workingColor, setColor } = useWorkspace();
  const ga = useTracker();
  const name = useSelector(selectName);
  const id = useSelector(selectId);

  const visibleColors = value?.filter((color) => color?.lab);

  const isInWorkspace = (color) => !!value.find((c) => c.code === color.code);

  const handleAddChip = (color) => {
    if (value && value.length >= MAX_COLORS) {
      openSnackbar({
        message: t('messages.maxColorSupport', { maxColors: MAX_COLORS }),
      });
      return;
    }

    if (isInWorkspace(color)) {
      openSnackbar({
        message: t('messages.colorCodeExists', { colorCode: color?.code }),
      });
      return;
    }

    if (value && color) {
      const newColors = [...value, color];
      setColor(null);
      onSubmit(newColors);
    }
  };

  const handleRemoveChip = ({ color }) => {
    if (value && color) {
      const newColors = value.filter((c) => c.code !== color.code);
      setColor(null);
      onSubmit(newColors);
    }
  };

  const handleFindChip = (code) => {
    if (code) {
      const color = value?.find((c) => c.code === code);
      const index = value?.indexOf(color);
      return { color, index };
    }
    return null;
  };

  const reorderChips = (findChipData, atIndex) => {
    const { color, index } = findChipData;

    const reorderedChips = update(value, {
      $splice: [
        [index, 1],
        [atIndex, 0, color],
      ],
    });

    onSubmit(reorderedChips);
  };

  const handleChipReorder = (colorId, atIndex) => {
    const findChipData = handleFindChip(colorId);
    if (findChipData) reorderChips(findChipData, atIndex);
  };

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: [
      dragAndDropVariants.PANTONE_CHIP_SMALL,
      dragAndDropVariants.V3_PANTONE_INFO_CHIP,
    ],
    drop: ({ color, colorId }) => {
      if (isOver && !colorId) {
        handleAddChip(color);
        ga.trackEvent({
          ...events.e8,
          TERM: color.code,
          PALETTE_ID: id ?? 'na',
          PALETTE_NAME: name !== '' ? name : 'na',
        });
      }
    },
    collect: (monitor) => ({
      drop: monitor.getDropResult(),
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  useEffect(() => {
    if (!workingColor) return;

    if (isInWorkspace(workingColor)) {
      handleRemoveChip({ color: workingColor });
    } else {
      handleAddChip(workingColor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workingColor]);

  useEffect(() => {
    onDrag(canDrop);
  }, [canDrop, onDrag]);

  return (
    <div ref={drop} {...props}>
      {visibleColors &&
        visibleColors.map((color, index) => {
          const key = getKeyFromColorRGB(color?.rgb);
          return (
            <PantoneChipSmall
              key={key}
              mr={0.5}
              mb={0.5}
              isInWorkspace
              data-id="workspace-small-chip"
              size={chipSize}
              color={color}
              index={index}
              colorId={color.code}
              moveChip={handleRemoveChip}
              reorderChips={handleChipReorder}
            />
          );
        })}
      <Box mr={0.5} mb={0.5}>
        <PantoneChipSmallSkeleton size={chipSize} />
      </Box>
    </div>
  );
}

WorkspaceDropzone.defaultProps = {
  value: null,
  chipSize: 60,
  onDrag: () => { },
};

WorkspaceDropzone.propTypes = {
  chipSize: PropTypes.number,
  value: PropTypes.arrayOf(PropTypes.shape()),
  onSubmit: PropTypes.func.isRequired,
  onDrag: PropTypes.func,
};

export default WorkspaceDropzone;
