import { useEffect } from 'react';
import { isWeb } from 'utils/platform';
import { useGetCurrentUserQuery } from 'services/authApi';
import { setSessionStorage, getSessionStorage } from '~/v3/utils/storage';
import {
  PANTONE_WEB_REDIRECT,
  REDIRECT_PANTONE_USER_TO_EXPLORE,
  USER_REDIRECTED_FROM_PANTONE,
} from '~/v3/constants/storage';

import { getColorFromInfoURL } from 'utils/urls';
import { isPantoneDomainURL } from 'utils/links';
import RoutePathname, { BaseRoutePaths } from 'constants/route';
import useRouter from '~/v3/hooks/use-router';

function useRedirectPage() {
  const { isAuthenticated, isLoading: isLoadingAuthentication } =
    useGetCurrentUserQuery(undefined, {
      selectFromResult: ({ data }) => ({
        isAuthenticated: !!data && !isLoadingAuthentication,
      }),
    });
  const { query, location, push } = useRouter();
  const source = query?.source;
  const comesFromPantone = isWeb && isPantoneDomainURL(source);
  const isInfoPage =
    comesFromPantone && location.pathname.includes(BaseRoutePaths.info);
  const isDefaultCase = location.pathname === RoutePathname.paywall;
  const isExplorePage =
    comesFromPantone && location.pathname.includes(RoutePathname.explore);

  useEffect(() => {
    if (!isLoadingAuthentication && !isAuthenticated) {
      if (isInfoPage) {
        const colorCode = getColorFromInfoURL(location.pathname);
        setSessionStorage(USER_REDIRECTED_FROM_PANTONE, true);
        setSessionStorage(PANTONE_WEB_REDIRECT, colorCode);
      } else if (isExplorePage) {
        setSessionStorage(REDIRECT_PANTONE_USER_TO_EXPLORE, true);
      } else if (comesFromPantone || isDefaultCase) {
        setSessionStorage(USER_REDIRECTED_FROM_PANTONE, true);
      }
    } else {
      if (getSessionStorage(REDIRECT_PANTONE_USER_TO_EXPLORE)) {
        push(RoutePathname.explore);
        setSessionStorage(REDIRECT_PANTONE_USER_TO_EXPLORE, false);
      }
      const colorCode = getSessionStorage(PANTONE_WEB_REDIRECT);

      if (colorCode) {
        push(`${BaseRoutePaths.info}/${colorCode}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isAuthenticated,
    isLoadingAuthentication,
    comesFromPantone,
    isDefaultCase,
  ]);
}

export default useRedirectPage;
