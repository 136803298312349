import { Skeleton } from '@mui/material';
import React from 'react';

function SidebarLoadingSkeleton({ ...rest }) {
  return (
    <Skeleton
      variant="text"
      sx={(theme) => ({
        height: theme.spacing(2.25),
        marginBottom: theme.spacing(2),
      })}
      {...rest}
    />
  );
}

export default SidebarLoadingSkeleton;
