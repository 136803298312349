import { useEffect, useState } from 'react';

function usePrevious(value) {
  const [stash, setStash] = useState([null, null]);
  const [first, second] = stash;

  useEffect(() => {
    if (value !== second) {
      setStash([second, value]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return stash.length >= 1 ? first : null;
}

export default usePrevious;
