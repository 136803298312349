import { Color } from '~/v3/types/pantone-color';

export function isCoY({
  currentCoY,
  color,
}: {
  currentCoY: string[];
  color: Omit<Color, 'name'>;
}) {
  return currentCoY?.includes(color?.code);
}
