import React from 'react';
import { useTranslation } from 'react-i18next';
import useTracker from '~/v2/analytics/useTracker';
import RoutePathname from '~/v2/constants/route';
import { headerNavigationEvent } from '~/v3/events';
import useRouter from '~/v3/hooks/use-router';
import TrendNavigationIcon from '~/v3/components/shared/icons/trend-navigation-icon';
import { useFeatureFlags } from '@xrite/feature-flags-client';
import BaseNavigationItem from '../base-navigation-item';
import {
  StyledNavigationItem,
  StyledNavigationText,
} from '../../../header.elements';

function ColorDataNavigationItem() {
  const colorDataRoute = RoutePathname.statistics;
  const { getFeatureFlags } = useFeatureFlags();
  const isArticlesNewCategoriesEnabled =
    getFeatureFlags?.isArticlesNewCategoriesEnabled;

  const { pathname: currentRoute, push } = useRouter();

  const { t } = useTranslation();

  const ga = useTracker();

  const onNavigateToColorData = () => {
    push(RoutePathname.statistics);
    ga.trackEvent({
      event_timestamp: new Date().toISOString(),
      ...headerNavigationEvent,
      EVENT_LABEL: 'colordata_nav',
    });
  };

  const isSelected = colorDataRoute === currentRoute;

  return isArticlesNewCategoriesEnabled ? (
    <BaseNavigationItem
      isSelected={isSelected}
      as={StyledNavigationItem}
      icon={<TrendNavigationIcon />}
      onClick={onNavigateToColorData}
      text={
        <StyledNavigationText>{t('routes.colorData')}</StyledNavigationText>
      }
      dataId="color-data"
    />
  ) : null;
}

export default ColorDataNavigationItem;
