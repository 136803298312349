import React from 'react';
import { Box, Theme, styled, useMediaQuery } from '@mui/material';
import {
  footerHeightMdScreen,
  footerHeightSmScreen,
} from '~/v3/pick/components/constants';
import colors from '~/v3/theme/colors';
import Typography from '@mui/material/Typography';
import useRouter from '~/v3/hooks/use-router';
import RoutePathname from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import { headerNavigationEvent, pickEvent } from '~/v3/events';
import usePaletteWS from '~/v2/features/PaletteWorkspace/usePaletteWS';
import { useTranslation } from 'react-i18next';
import ActionButton from '~/v3/components/core/buttons/action-button';
import SavedPalettes from '../saved-palettes';
import SharedPalettes from '../shared-palettes';
import SavedColorStories from '../saved-color-stories';

import SharedColorStories from '../shared-color-stories';

const Container = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2, 2, 2, 2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  height: '100%',
  [theme.breakpoints.down('md')]: {
    height: `calc(100% - ${footerHeightMdScreen}px)`,
    overflowY: 'auto',
  },
  [theme.breakpoints.down('sm')]: {
    height: `calc(100% - ${footerHeightSmScreen}px)`,
  },
  backgroundColor: colors.white,
}));

const QuickStartButtons = styled(Box)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  paddingBottom: theme.spacing(2),
  backgroundColor: colors.white,
}));

function SidebarContent() {
  const { push } = useRouter();
  const ga = useTracker();
  const { clearWorkspace } = usePaletteWS();
  const { t } = useTranslation();

  const isDesktop = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const onClickNewPalette = () => {
    clearWorkspace();

    ga.trackEvent({
      ...pickEvent,
      EVENT_AREA: 'create-new-palette-button',
      EVENT_LABEL: 'workspace-create-new-palette',
    });
  };

  const onClickNewColorStory = () => {
    push(RoutePathname.colorStoryNew);

    ga.trackEvent({
      ...headerNavigationEvent,
      EVENT_LABEL: 'colorstory_nav',
    });
  };

  return (
    <Container>
      <Box sx={(theme) => ({ paddingLeft: theme.spacing(2) })}>
        <SavedPalettes />
        <SharedPalettes />
        <SavedColorStories />
        <SharedColorStories />
      </Box>

      {isDesktop && (
        <QuickStartButtons data-testid="quick-start-buttons">
          <ActionButton
            onClick={onClickNewPalette}
            data-testid="new-palette-button"
          >
            <Typography>{t('labels.newPalette')}</Typography>
          </ActionButton>
          <ActionButton
            sx={{ marginBottom: 0 }}
            onClick={onClickNewColorStory}
            data-testid="new-color-story-button"
          >
            <Typography>{t('labels.newColorStory')}</Typography>
          </ActionButton>
        </QuickStartButtons>
      )}
    </Container>
  );
}

export default SidebarContent;
