import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';

import workspaceMiddleware from 'middlewares/workspaceMiddleware';
import analyticsReducer from '~/v3/events/analyticsSlice';
import workspaceReducer from './components/WorkspaceUI/workspaceSlice';
import colorMenuReducer from './features/ColorMenu/colorMenuSlice';
import confirmDialogReducer from './features/ConfirmDialog/confirmSlice';
import fullScreenColorReducer from './features/FullScreenColor/fullScreenColorSlice';
import imageDownloadReducer from './features/ImageDownload/imageDownloadSlice';
import moodboardWorkspaceReducer from './features/MoodboardWorkspace/moodboardSlice';
import paletteAnalysisReducer from './features/PaletteAnalysis/paletteAnalysisSlice';
import paletteWorkspaceReducer from './features/PaletteWorkspace/paletteSlice';
import snackbarReducer from './features/Snackbar/snackbarSlice';
import colorExtractReducer from './pages/ColorExtract/extractSlice';
import colorStoryReducer from './pages/ColorStory/colorStorySlice';
import paymentReducer from './pages/Paywall/paymentSlice';
import { api } from './services/api';

export const rootReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  colorStory: colorStoryReducer,
  colorExtract: colorExtractReducer,
  workspace: workspaceReducer,
  paletteWorkspace: paletteWorkspaceReducer,
  moodboardWorkspace: moodboardWorkspaceReducer,
  fullScreenColor: fullScreenColorReducer,
  paletteAnalysis: paletteAnalysisReducer,
  snackbar: snackbarReducer,
  imageDownload: imageDownloadReducer,
  confirmDialog: confirmDialogReducer,
  colorMenu: colorMenuReducer,
  payment: paymentReducer,
  analytics: analyticsReducer,
});

export const store = configureStore({
  reducer: rootReducer,
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    // TODO: don't store non-serializable data in redux
    // this is a work-around, non-serializable data should eventually be stored either
    // in an API cache or in a memoized selector cache
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'colorMenu/open',
          'colorMenu/close',
          'confirmDialog/open',
          'confirmDialog/close',
        ],
        ignoredPaths: [
          'colorMenu.anchorEl',
          'confirmDialog.content',
          'snackbar.message',
        ],
      },
    })
      .concat(api.middleware)
      .concat(workspaceMiddleware),
});

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch);

export default store;
