import newColors from '~/v3/theme/colors';
import colors from '~/v2/theme/colors';

export default {
  palette: {
    mode: 'light',
    shadows: {
      dropShadow: 'drop-shadow(4px 4px 16px rgba(16, 24, 40, 0.16))',
      listChipShadow: {
        chipInfoAreaShadow: 'rgba(0, 0, 0, 0.1) 1.5px 1px 4px 0px',
        chipColorAreaShadow: 'rgba(0, 0, 0, 0.1) 2px 0px 4px 0px',
      },
      largeChipShadow: 'rgba(0, 0, 0, 0.1) 1px 1px 5px 0px',
      pickPageChipHoverMenuShadow:
        'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px',
      colorInfoLeftClickMenu: '0px 10px 15px -3px rgba(0,0,0,0.1)',
    },
    primary: {
      light: colors.JetBlack['300'],
      main: colors.JetBlack['500'],
      dark: colors.JetBlack['800'],
      contrastText: newColors.white,
    },
    secondary: {
      light: colors.Blue['600'],
      main: colors.Blue['300'],
      dark: '#375FFF',
      contrastText: '#FFFFFF',
    },
    gray: {
      main: newColors.darkGrey,
      dark: newColors.darkGrey,
      light: newColors.lightGrey,
      contrastText: newColors.white,
    },
    blue: {
      main: newColors.pantoneLogoBlue,
      contrastText: newColors.white,
    },
    text: {
      primary: colors.JetBlack[850],
      secondary: colors.JetBlack['400'],
      disabled: colors.WhiteGrey['500'],
      hint: colors.WhiteGrey['500'],
    },
    background: {
      default: colors.WhiteGrey[0],
      primary: colors.WhiteGrey[850],
      secondary: colors.WhiteGrey[200],
      paper: colors.WhiteGrey[0],
    },
    icon: {
      primary: colors.JetBlack[850],
      secondary: colors.Blue[300],
    },
    error: {
      main: newColors.warningRed,
      contrastText: newColors.white,
    },
    info: {
      main: newColors.black,
      contrastText: newColors.white,
    },
    divider: colors.WhiteGrey['400'],
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: 'white',
          borderBottom: `2px solid ${colors.WhiteGrey['300']}`,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WhiteGrey['200'],
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${colors.WhiteGrey['400']}`,
        },
      },
    },
    MuiAccordion: {
      styleOverrides: {
        root: {
          '&:not(:last-child)': {
            borderBottom: `1px solid ${colors.WhiteGrey['400']}`,
          },
          '&.Mui-expanded': {
            backgroundColor: colors.WhiteGrey['200'],
            margin: 0,
          },
        },
      },
    },
    MuiAccordionSummary: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: colors.WhiteGrey['200'],
          },

          '&$expanded': {
            backgroundColor: colors.WhiteGrey['200'],
          },
        },
        content: {
          margin: '27px 0',

          '&$expanded': {
            margin: '27px 0',
          },
        },
      },
    },
    MuiAccordionDetails: {
      styleOverrides: {
        root: {
          backgroundColor: colors.WhiteGrey['200'],
        },
      },
    },
  },
};
