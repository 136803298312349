enum ColorSpace {
  LAB = 'lab',
  RGB = 'rgb',
  CMYK = 'cmyk',
  HEX = 'hex',
  HSL = 'hsl',
}

export type ColorSpaceValues = {
  [ColorSpace.LAB]: { l: string; a: string; b: string };
  [ColorSpace.RGB]: { r: string; g: string; b: string };
  [ColorSpace.CMYK]: {
    c: string;
    m: string;
    y: string;
    k: string;
  };
  [ColorSpace.HEX]: string;
};

export default ColorSpace;
