import { ProductType } from '~/v3/paywall/constants';
import { Subscription, SubscriptionProviderType } from '~/v3/types/subsription';
import { useConfiguredGetSubscriptionQuery } from '~/v2/services/subscriptionApi';

type UseSubscriptionsResponse = {
  isLoading: boolean;
  subscriptions: Subscription[];
};

const useSubscriptions = (
  productType?: ProductType
): UseSubscriptionsResponse => {
  const { data: subscription, isLoading } =
    useConfiguredGetSubscriptionQuery() as {
      data: Subscription;
      isLoading: boolean;
    };

  if (isLoading || !subscription || subscription?.subscriptions.length === 0) {
    return {
      isLoading,
      subscriptions: [],
    };
  }

  if (!productType) {
    return {
      isLoading,
      subscriptions: subscription.subscriptions,
    };
  }

  const subscriptions: Subscription[] = [];

  if (productType === ProductType.PREMIUM) {
    const premiumSubscriptions = subscription?.subscriptions.filter(
      (sub) => sub.name === SubscriptionProviderType.FASTSPRING
    );

    subscriptions.push(...premiumSubscriptions);
  }

  if (productType === ProductType.TRENDS) {
    const trendsSubscriptions = subscription?.subscriptions.filter(
      (sub) => sub.name === SubscriptionProviderType.FASTSPRING_TRENDS
    );

    subscriptions.push(...trendsSubscriptions);
  }

  return {
    subscriptions,
    isLoading,
  };
};

export default useSubscriptions;
