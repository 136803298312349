import { CssBaseline } from '@mui/material';
import React, { createContext, useContext, useMemo, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import { getLocalStorage, setLocalStorage } from '~/v3/utils/storage';
import config from '~/v3/config';
import { deepmerge } from '@mui/utils';
import common from '~/v2/theme/common';
import getTheme from '~/v3/theme/utils';
import { DefaultTheme } from '@mui/styles';
import { ThemeMode } from '~/v3/theme/theme-mode';

type ColorModeContextProps = {
  setColorMode: (mode: 'light' | 'dark') => void;
  currentMode: string;
  theme: DefaultTheme;
};

type ColorContextProps = {
  children: JSX.Element;
};

export const ColorModeContext = createContext<ColorModeContextProps>({
  setColorMode: () => null,
  currentMode: getLocalStorage('theme')
    ? String(getLocalStorage('theme'))
    : config.themeModes.light,
  theme: common,
});

export function ColorContext({ children }: Readonly<ColorContextProps>) {
  const [mode, setMode] = useState<ThemeMode>(
    (getLocalStorage('theme') as ThemeMode) ?? config.themeModes.light
  );

  const colorMode = useMemo<ColorModeContextProps>(
    () => ({
      setColorMode: (newMode: ThemeMode) => {
        setMode(newMode);
        setLocalStorage('theme', newMode);
      },
      currentMode: mode,
      theme: createTheme(deepmerge<DefaultTheme>(common, getTheme(mode))),
    }),
    [mode]
  );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={colorMode.theme}>
        <CssBaseline />
        {children}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export function useColorMode() {
  const context = useContext(ColorModeContext);
  if (context === undefined) {
    throw new Error('useColorMode must be used within a ColorModeContext');
  }

  return context;
}
