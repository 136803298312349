/* eslint-disable no-await-in-loop */

import { useLazyGetSubscriptionQuery } from '~/v3/react-query/hooks/use-get-subscription';
import { Subscription } from '~/v3/types/subsription';

type UseSyncSubscriptionProps = {
  shouldStopSyncing: (
    userSubscription: Subscription,
    productIds: string[] | null
  ) => boolean;
};

export const delay = (ms: number) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

function useSyncSubscription({ shouldStopSyncing }: UseSyncSubscriptionProps) {
  const { trigger } = useLazyGetSubscriptionQuery();

  const syncSubscriptionStatus = async (productIds: string[] | null) => {
    let subscriptionId;
    for (let i = 1; i <= 10; i += 1) {
      const latestData = await trigger();
      subscriptionId = latestData.subscriptionId;
      const shouldStop = shouldStopSyncing(latestData, productIds);

      if (!shouldStop) {
        await delay(200 * i);
      } else {
        break;
      }
    }
    return subscriptionId;
  };

  return {
    syncSubscription: syncSubscriptionStatus,
  };
}

export default useSyncSubscription;
