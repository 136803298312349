import fullScreenStore, {
  useFullScreenActions,
} from '~/v3/store/full-screen-store';
import useGetColor from '~/v3/react-query/hooks/use-get-color';
import usePaywall from '~/v2/hooks/usePaywall';
import { useLazyGetSubscriptionQuery } from '~/v3/react-query/hooks/use-get-subscription';
import { COY_COLOR_CODE } from '~/v3/config';
import { isCoY } from '~/v3/utils/is-color-of-the-year';
import { Color } from '../types/pantone-color';

function useFullScreenColor() {
  const { openColorFullScreen, close } = useFullScreenActions();
  const { isOpen, color } = fullScreenStore();
  const { triggerPaywall } = usePaywall();
  const { trigger } = useLazyGetSubscriptionQuery();

  const {
    data: colorWithData,
    error,
    isLoading,
  } = useGetColor({ colorCode: color?.code });

  return {
    rgb: color?.rgb,
    color: colorWithData,
    isOpen,
    open: async (fullScreenColor: Omit<Color, 'name'>) => {
      const subscription = await trigger();
      const isColorOfTheYear = isCoY({
        currentCoY: COY_COLOR_CODE,
        color: fullScreenColor,
      });

      if (subscription?.active || isColorOfTheYear) {
        openColorFullScreen(fullScreenColor);
      } else {
        triggerPaywall();
      }
    },
    error,
    isLoading,
    close,
  };
}

export default useFullScreenColor;
