import {
  Avatar,
  Box,
  IconButton,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { t } from 'i18next';
import colors from '~/v2/theme/colors';
import React from 'react';

const TooltipText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.contrastText,
}));

const TooltipContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',
});

const AvatarIcon = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3.5),
  height: theme.spacing(3.5),
  backgroundColor: colors.JetBlack[300],
  fontSize: `${theme.spacing(0.125)}rem`,
}));

type CollaboratorIconProps = {
  ownerEmail: string;
};

function CollaboratorAvatar({ ownerEmail }: Readonly<CollaboratorIconProps>) {
  return (
    <Tooltip
      placement="bottom"
      enterTouchDelay={0}
      title={
        <TooltipContainer>
          <TooltipText variant="caption">{t('labels.owner')}</TooltipText>
          <TooltipText variant="caption">{ownerEmail}</TooltipText>
        </TooltipContainer>
      }
    >
      <IconButton size="large">
        <AvatarIcon>{ownerEmail.charAt(0).toUpperCase()}</AvatarIcon>
      </IconButton>
    </Tooltip>
  );
}

export default CollaboratorAvatar;
