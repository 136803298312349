import {
  Subscription,
  SubscriptionProviderType,
  SubscriptionStatus,
} from '../types/subsription';

const filterActiveUncancelledFastSpringSubscriptions = (
  subscriptions: Subscription[]
) =>
  subscriptions.filter(
    (sub) =>
      (sub.name === SubscriptionProviderType.FASTSPRING ||
        sub.name === SubscriptionProviderType.FASTSPRING_TRENDS) &&
      sub.active &&
      sub.state !== SubscriptionStatus.CANCELED
  );

export default filterActiveUncancelledFastSpringSubscriptions;
