import { useState } from 'react';
import { useGetPalettesQuery } from '~/v2/services/paletteApi';
import { Palette } from '../types/palette';

export const usePalettesInfiniteScroll = () => {
  const [nextToken, setNextToken] = useState<string | null>();
  const { data: combinedPages } = useGetPalettesQuery(undefined) as {
    data: {
      palettes: Palette[];
    };
  };
  const {
    data: currentPage,
    isFetching,
    error,
  } = useGetPalettesQuery(nextToken, {
    skip: nextToken === null,
  }) as {
    data: {
      palettes: Palette[];
      nextToken: string | null;
    };
    isFetching: boolean;
    error: unknown;
  };

  return {
    palettes: combinedPages?.palettes,
    isFetching,
    hasMoreData: !!currentPage?.nextToken,
    loadMoreData: () => setNextToken(currentPage?.nextToken),
    error,
  };
};
