import React from 'react';
import { Form, Formik } from 'formik';
import useRouter from '~/v3/hooks/use-router';
import RoutePathname from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import type { Subscription } from '~/v3/types/subsription';
import {
  subscriptionCancelAbortClick,
  subscriptionCancelationAborted,
} from '../events';
import CancelActions from './cancel-actions';
import CancelReasons from './cancel-reasons';
import SubscriptionSelector from './subscription-selector';
import FeedbackForm from './feedback-form';
import { shouldSelectSubscriptionsForCancelation } from '../lib/utils';
import { useCancelSubscriptionForm } from '../lib/hooks/use-cancel-subscription-form';

type CancelSubscriptionFormProps = {
  subscriptions: Subscription[];
  onOpenDialog: () => void;
  onCloseDialog: () => void;
};
function CancelSubscriptionForm({
  subscriptions,
  onOpenDialog,
  onCloseDialog,
}: Readonly<CancelSubscriptionFormProps>) {
  const { push } = useRouter();
  const { trackEvent } = useTracker();
  const { initialValues, handleSubmit } = useCancelSubscriptionForm({
    onOpenDialog,
    onCloseDialog,
    activeSubscriptions: subscriptions,
  });

  const shouldSelectSubscriptionForCanceling =
    shouldSelectSubscriptionsForCancelation(subscriptions);

  const handleAbortCancelation = () => {
    trackEvent(subscriptionCancelAbortClick);
    trackEvent(subscriptionCancelationAborted);
    push(RoutePathname.myPalettes);
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit}>
      <Form>
        {shouldSelectSubscriptionForCanceling && (
          <SubscriptionSelector subscriptions={subscriptions} />
        )}
        <CancelReasons />
        <CancelActions />
        <FeedbackForm handleAbortCancelation={handleAbortCancelation} />
      </Form>
    </Formik>
  );
}

export default CancelSubscriptionForm;
