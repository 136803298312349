export function getLocalStorage<T>(storageName: string): T | null | boolean {
  const localStorageData = localStorage.getItem(storageName);
  if (localStorageData) {
    if (localStorageData === 'true') return true;
    if (localStorageData === 'false') return false;
    return localStorageData as T;
  }
  return null;
}

export function setLocalStorage(
  storageName: string,
  storageData: string | boolean
) {
  if (typeof storageData === 'string') {
    localStorage.setItem(storageName, storageData);
  } else {
    const stringifyData = JSON.stringify(storageData);
    localStorage.setItem(storageName, stringifyData);
  }
}

export function setSessionStorage<T>(storageName: string, storageData: T) {
  if (typeof storageData === 'string') {
    sessionStorage.setItem(storageName, storageData);
  } else {
    const stringifyData = JSON.stringify(storageData);
    sessionStorage.setItem(storageName, stringifyData);
  }
}

export function getSessionStorage<T>(storageName: string, parsed?: boolean) {
  const sessionStorageData = sessionStorage.getItem(storageName);
  if (sessionStorageData) {
    if (parsed) return JSON.parse(sessionStorageData) as T;
    if (sessionStorageData === 'true') return true;
    if (sessionStorageData === 'false') return false;
    return sessionStorageData;
  }
  return null;
}

export function readSessionStorage<T>(key: string, parsed?: boolean) {
  const sessionStorageData = sessionStorage.getItem(key);
  if (sessionStorageData) {
    if (parsed) return JSON.parse(sessionStorageData) as T;
    return sessionStorageData;
  }
  return null;
}

export const removeLocalStorage = (storageName: string) =>
  localStorage.removeItem(storageName);

export const removeSessionStorage = (storageName: string) =>
  sessionStorage.removeItem(storageName);
