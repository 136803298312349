import useRouter from '~/v3/hooks/use-router';
import RoutePathname from '~/v2/constants/route';
import useTracker from '~/v2/analytics/useTracker';
import { Subscription } from '~/v3/types/subsription';
import { ProductType } from '~/v3/paywall/constants';
import useSyncSubscription from '~/v3/hooks/use-sync-subscription';
import useCancelSubscription from '~/v3/react-query/hooks/use-cancel-subscription';
import useCreateSurveyMutation from '~/v3/react-query/hooks/use-create-survey-mutation';
import {
  shouldStopSyncing,
  getCancelationReasons,
  getSubscriptionsToBeCanceled,
  getSubscriptionCancellationDetails,
} from '../utils';
import {
  subscriptionCanceled,
  subscriptionCancelContinueClick,
} from '../../events';
import { CancelSubscriptionFormValues } from '../../model/types';

type UseCancelSubscriptionFormArguments = {
  activeSubscriptions: Subscription[];
  onOpenDialog: () => void;
  onCloseDialog: () => void;
};

export function useCancelSubscriptionForm({
  activeSubscriptions,
  onOpenDialog,
  onCloseDialog,
}: UseCancelSubscriptionFormArguments) {
  const { push } = useRouter();
  const { trackEvent } = useTracker();
  const { createSurvey } = useCreateSurveyMutation();
  const { cancelSubscription } = useCancelSubscription();

  const { syncSubscription } = useSyncSubscription({
    shouldStopSyncing: (userSubscription, args) =>
      shouldStopSyncing(userSubscription, args as string[]),
  });

  const { subscriptionId } = activeSubscriptions[0] || {};

  const handleSubmit = async (values: CancelSubscriptionFormValues) => {
    onOpenDialog();

    const cancelationReasons = getCancelationReasons(values);

    const { isSubscriptionABundle, subscriptionToBeCancelledId } =
      getSubscriptionCancellationDetails(values);

    const subscriptionsToBeCanceled = getSubscriptionsToBeCanceled(
      activeSubscriptions,
      isSubscriptionABundle,
      subscriptionToBeCancelledId
    );
    const product = subscriptionsToBeCanceled
      .map((item) => item.product)
      .filter((item): item is ProductType => !!item);

    const subscriptionType = subscriptionsToBeCanceled.map(
      (sub) => sub.subscriptionType
    );

    const response = await createSurvey({
      product,
      subscriptionType,
      cancelationReasons,
      cancelationAction: values.cancelationAction,
      cancelationFeedback: values.cancelationFeedback,
    });

    await cancelSubscription({
      deleteNow: false,
      subscriptionId: subscriptionToBeCancelledId,
    });

    subscriptionsToBeCanceled.forEach((sub) => {
      trackEvent({
        ...subscriptionCancelContinueClick,
        subscription_id: sub.subscriptionId,
        FEEDBACK: values.cancelationFeedback,
        REASON_LIST: cancelationReasons.join(', '),
      });
    });

    await syncSubscription(
      subscriptionsToBeCanceled.map((sub) => sub.subscriptionId)
    );

    onCloseDialog();

    if (response) {
      push(RoutePathname.cancelSubscriptionFeedback);
    }

    subscriptionsToBeCanceled.forEach((sub) => {
      trackEvent({
        ...subscriptionCanceled,
        subscription_id: sub.subscriptionId,
        cancelation_reasons: cancelationReasons.join(', '),
      });
    });
  };

  const initialValues: CancelSubscriptionFormValues = {
    tehnicalIssues: false,
    tooExpensive: false,
    notWorthTheCost: false,
    didNotUseEnough: false,
    tooDifficultToUse: false,
    problemWithSupportTeam: false,
    neededForSpecificProject: false,
    jobHasChanged: false,
    other: false,
    otherText: '',
    cancelationAction: false,
    cancelationFeedback: '',
    subscriptionToBeCanceledId: subscriptionId,
  };

  return { initialValues, handleSubmit };
}
