import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Typography from '@mui/material/Typography';
import { makeStyles, useTheme } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTranslation } from 'react-i18next';

import useWindow from 'hooks/useWindow';
import ColorsVerticall from './overlays/ColorsVertical';
import ShareTemplate from './templates/ShareTemplate';
import StyleGuide from './overlays/StyleGuide';

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    padding: theme.spacing(1),
    paddingTop: theme.spacing(0),
    width: '100%',
    overflowY: 'scroll',
  },
  template: {
    margin: theme.spacing(0.5),
  },
  generatedImageContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    paddingBottom: theme.mixins.toolbar.minHeight,
  },
  generatedImage: {
    width: '100%',
  },
  instructionsIOS: {
    marginBottom: theme.spacing(2),
  },
  '@global': {
    html: {
      scrollBehavior: 'auto',
    },
    body: {
      userSelect: ({ iosGeneratedImage }) =>
        iosGeneratedImage ? 'unset' : 'none',
      WebkitUserSelect: ({ iosGeneratedImage }) =>
        iosGeneratedImage ? 'unset' : 'none',
      WebkitTouchCallout: ({ iosGeneratedImage }) =>
        iosGeneratedImage ? 'unset' : 'none',
    },
  },
}));

function ImageDownloadDialog({ colors, paletteTitle }) {
  const [iosGeneratedImage, setIOSGeneratedImage] = useState();
  const classes = useStyles({ iosGeneratedImage });
  const theme = useTheme();
  const smUp = useMediaQuery(theme.breakpoints.up('sm'));
  const lgUp = useMediaQuery(theme.breakpoints.up('lg'));
  const [templateSize, setTemplateSize] = useState(200);
  const dialogPadding = 8;
  const templateMargin = 4;
  const { t } = useTranslation();

  const windowSize = useWindow();

  const contentRef = useCallback(
    (node) => {
      let templatesPerRow = 2;
      if (smUp) templatesPerRow = 3;
      if (lgUp) templatesPerRow = 4;

      if (node !== null) {
        setTemplateSize(
          (node.clientWidth -
            dialogPadding * 2 -
            templateMargin * 2 * templatesPerRow) /
            templatesPerRow
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [windowSize, dialogPadding, templateMargin, lgUp, smUp]
  );

  const colorTemplates = [
    { name: 'color-template', overlay: <ColorsVerticall colors={colors} /> },
    {
      name: 'color-template-rotated',
      overlay: <ColorsVerticall colors={colors} verticalTemplate />,
    },
    {
      name: 'color-template-with-primary-color',
      overlay: <ColorsVerticall colors={colors} primaryColor />,
    },
    {
      name: 'color-template-with-primary-color-rotated',
      overlay: (
        <ColorsVerticall colors={colors} primaryColor verticalTemplate />
      ),
    },
    {
      name: 'style-guide',
      overlay: <StyleGuide colors={colors} paletteTitle={paletteTitle} />,
    },
  ];

  return (
    <div className={classes.dialogContent} ref={contentRef}>
      {iosGeneratedImage ? (
        <div className={classes.generatedImageContainer}>
          <Typography variant="body2" className={classes.instructionsIOS}>
            {t('labels.howToDownloadGeneratedImage')}
          </Typography>
          <img
            className={classes.generatedImage}
            alt="palette"
            src={iosGeneratedImage}
            data-enablecontextmenu
          />
        </div>
      ) : (
        colorTemplates.map(({ name, overlay }) => (
          <div
            className={classes.template}
            key={`${name}-colorTemplate`}
            data-id={`${name}-colorTemplate`}
          >
            <ShareTemplate
              overlay={overlay}
              size={templateSize}
              setIOSGeneratedImage={setIOSGeneratedImage}
            />
          </div>
        ))
      )}
    </div>
  );
}

ImageDownloadDialog.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  paletteTitle: PropTypes.string,
};

ImageDownloadDialog.defaultProps = {
  paletteTitle: '',
};

export default ImageDownloadDialog;
