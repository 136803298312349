import { Button, SxProps, styled } from '@mui/material';
import React from 'react';
import colors from '~/v3/theme/colors';

type ActionButtonProps = Readonly<{
  children: React.ReactNode;
  onClick: () => void;
  sx?: SxProps;
}>;

const StyledButton = styled(Button)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  textTransform: 'inherit',
  marginBottom: theme.spacing(1.5),
  backgroundColor: colors.darkGrey,
  color: colors.white,
  borderRadius: theme.spacing(0.375),
  height: theme.spacing(4.625),
  '&:hover': {
    backgroundColor: colors.darkGrey,
  },
  '&>p': {
    fontFamily: 'Helvetica Now Display',
  },
}));

function ActionButton({ children, onClick, sx, ...rest }: ActionButtonProps) {
  return (
    <StyledButton sx={sx} onClick={onClick} {...rest}>
      {children}
    </StyledButton>
  );
}

export default ActionButton;
