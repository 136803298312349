import { QueryCache, QueryClient } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import extractErrorMessageFromError from '~/v3/utils/extract-error-message-from-error';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
  queryCache: new QueryCache({
    onError: (error, query) => {
      // NOTE: when using useQuery, and an error is thrown from the API,
      // multiple error-snackbars will be displayed if the `useQuery`
      // is called in multiple places in the application
      // This is the preferred solution:
      // Reference: https://tkdodo.eu/blog/react-query-error-handling
      if (query.meta?.displayErrorSnackbar)
        enqueueSnackbar(extractErrorMessageFromError(error), {
          variant: 'error',
        });
    },
  }),
});
