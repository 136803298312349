import React from 'react';
import { useTranslation } from 'react-i18next';
import { Typography } from '@mui/material';
import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import RoutePathname from '~/v2/constants/route';
import RemoveIcon from '~/v3/components/shared/icons/remove-icon';
import Plus from '~/v3/components/shared/icons/plus-icon';

import useRouter from '~/v3/hooks/use-router';
import { StyledMenuItem } from '../left-click-menu.elements';
import useGetPaletteAction from '../lib/hooks/use-get-palette-action';
import { PaletteActions } from '../model/types';

function PaletteItem({ color }: Readonly<{ color: PantoneColorWithBook }>) {
  const { t } = useTranslation();
  const { pathname } = useRouter();

  const { action, type } = useGetPaletteAction(color);

  const isOnMyPalettesPage = pathname.includes(RoutePathname.myPalettes);

  if (isOnMyPalettesPage) {
    return null;
  }

  if (type === PaletteActions.removeFromPalette) {
    return (
      <StyledMenuItem rgb={color?.rgb} key={type} onClick={action}>
        <RemoveIcon />
        <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
          {t('labels.removeFromPalette')}
        </Typography>
      </StyledMenuItem>
    );
  }

  return (
    <StyledMenuItem rgb={color?.rgb} stroke fill key={type} onClick={action}>
      <Plus />
      <Typography lineHeight="100%" variant="v3.label1" fontSize={11}>
        {t('labels.addToPalette')}
      </Typography>
    </StyledMenuItem>
  );
}
export default PaletteItem;
