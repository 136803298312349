import RoutePathname from '~/v2/constants/route';
import React from 'react';
import { Chip } from '@mui/material';
import { t } from 'i18next';
import { isMobile } from '~/v2/utils/platform';
import {
  pickMenuEvent,
  extractMenuEvent,
  convertMenuEvent,
  measureMenuEvent,
  colorStoryMenuEvent,
  visualizeMenuEvent,
  colorDataMenuEvent,
} from '~/v3/events';
import PickNavigationIcon from '~/v3/components/shared/icons/pick-navigation-icon';
import ExtractNavigationIcon from '~/v3/components/shared/icons/extract-navigation-icon';
import ConvertNavigationIcon from '~/v3/components/shared/icons/convert-navigation-icon';
import MeasureNavigationIcon from '~/v3/components/shared/icons/measure-navigation-icon';
import ColorStoryNavigationIcon from '~/v3/components/shared/icons/color-story-navigation-icon';
import VisualizeNavigationIcon from '~/v3/components/shared/icons/visualize-navigation-icon';
import ColorDataMobileIcon from '~/v3/components/shared/icons/color-data-mobile-icon';
import { testIds } from '../model/constants';
import { SubMenuItemType } from '../model/types';

export const getSubMenuItems = (
  isArticlesNewCategoriesEnabled?: boolean
): SubMenuItemType[] => [
  {
    routesTo: RoutePathname.colorPicker,
    event: pickMenuEvent,
    text: t('routes.colorPicker'),
    dataTestid: testIds.pick,
    icon: <PickNavigationIcon />,
  },
  {
    routesTo: RoutePathname.colorExtract,
    event: extractMenuEvent,
    text: t('routes.colorExtract'),
    dataTestid: testIds.extract,
    icon: <ExtractNavigationIcon />,
  },
  {
    routesTo: RoutePathname.colorConvert,
    event: convertMenuEvent,
    text: t('routes.colorConvert'),
    dataTestid: testIds.convert,
    icon: <ConvertNavigationIcon />,
  },
  {
    routesTo: RoutePathname.colorMeasure,
    event: measureMenuEvent,
    text: t('routes.colorMeasure'),
    dataTestid: testIds.measure,
    icon: <MeasureNavigationIcon />,
  },
  {
    routesTo: RoutePathname.colorStoryNew,
    event: colorStoryMenuEvent,
    text: t('routes.colorStory'),
    dataTestid: testIds['color-story'],
    icon: <ColorStoryNavigationIcon />,
  },
  ...(isArticlesNewCategoriesEnabled
    ? [
        {
          routesTo: RoutePathname.statistics,
          event: colorDataMenuEvent,
          text: t('routes.colorData'),
          dataTestid: testIds['color-data'],
          icon: <ColorDataMobileIcon />,
        },
      ]
    : []),
  {
    routesTo: RoutePathname.scanGuide,
    event: visualizeMenuEvent,
    text: t('routes.scanGuide'),
    dataTestid: testIds.visualize,
    renderConditions: isMobile,
    icon: <VisualizeNavigationIcon />,
    children: (
      <Chip
        label={t('labels.beta')}
        color="secondary"
        sx={{
          textTransform: 'uppercase',
          height: 24,
          p: 0,
          ml: 1,
        }}
      />
    ),
  },
];
