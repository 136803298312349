import Box, { BoxProps } from '@mui/material/Box';
import React from 'react';

import { styled } from '@mui/material';
import {
  footerHeightMdScreen,
  footerHeightSmScreen,
} from '~/v3/pick/components/constants';
import { isSidebarEnabled } from '~/v3/feature-flags';
import ColorsFooterNavigation from './colors';
import InsightFooterNavigation from './insight';
import MyPalettesFooterNavigation from './my-palettes';
import QuickStartFooterNavigation from './quick-start';

const StyledFooterContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexShrink: 0,
  [theme.breakpoints.up('md')]: {
    display: 'none',
  },
  width: '100%',
  height: footerHeightMdScreen,
  backgroundColor: theme.palette.background.default,
  zIndex: 1100,
  [theme.breakpoints.down(380)]: {
    height: footerHeightSmScreen,
  },
}));

function Footer(props: BoxProps) {
  return (
    <StyledFooterContainer {...props}>
      {isSidebarEnabled && <MyPalettesFooterNavigation />}

      <ColorsFooterNavigation />

      <InsightFooterNavigation />

      {!isSidebarEnabled && <MyPalettesFooterNavigation />}

      {isSidebarEnabled && <QuickStartFooterNavigation />}
    </StyledFooterContainer>
  );
}

export default Footer;
