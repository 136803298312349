import React from 'react';
import { useTranslation } from 'react-i18next';
import FaceOutlinedIcon from '@mui/icons-material/FaceOutlined';
import Brightness4OutlinedIcon from '@mui/icons-material/Brightness4Outlined';
import TranslateOutlinedIcon from '@mui/icons-material/TranslateOutlined';
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined';
import CookieIcon from '@mui/icons-material/Cookie';
// import RateReviewOutlinedIcon from '@mui/icons-material/RateReviewOutlined';
import SettingsIcon from '@mui/icons-material/Settings';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import PolicyOutlinedIcon from '@mui/icons-material/PolicyOutlined';
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined';
import ExitToAppOutlinedIcon from '@mui/icons-material/ExitToAppOutlined';
import CardMembershipOutlinedIcon from '@mui/icons-material/CardMembershipOutlined';
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined';
import RedeemIcon from '@mui/icons-material/Redeem';
import useTracker from 'analytics/useTracker';
import events from 'analytics/events';
import RoutePath from 'constants/route';
import languageConstants from 'constants/languages';
import { FaqURL } from 'utils/links';
import UserEvents from '~/v3/constants/events';
import config from '~/v3/config';
import { onOpenCookiesDialog } from '~/v3/utils/on-open-cookies-dialog';

const languages = Object.keys(languageConstants).map((key) => ({
  key,
  name: languageConstants[key],
}));

function useProfileMenu() {
  const { t } = useTranslation(['translation', 'statistics']);
  const ga = useTracker();

  return {
    profileSection: {
      eventName: UserEvents.myProfile,
      icon: <FaceOutlinedIcon />,
      label: t('routes.myProfile'),
      pathname: RoutePath.myProfile,
      subMenu: null,
      onClick: () => {
        ga.trackEvent(events.e100);
      },
    },
    subscriptionSection: {
      manageSubscription: {
        eventName: UserEvents.subscription,
        icon: <CardMembershipOutlinedIcon />,
        label: t('labels.subscription'),
        pathname: null,
        subMenu: [
          {
            eventName: UserEvents.manageSubscription,
            icon: <SettingsIcon />,
            pathname: null,
            label: t('labels.fastSpringAccount'),
          },
          {
            eventName: UserEvents.cancelSubscription,
            icon: <CancelPresentationOutlinedIcon />,
            pathname: RoutePath.cancelSubscription,
            label: t('labels.cancelSubscription'),
          },
        ],
      },
    },
    themeSection: {
      changeTheme: {
        eventName: UserEvents.changeTheme,
        icon: <Brightness4OutlinedIcon />,
        label: t('labels.theme'),
        pathname: null,
        subMenu: [
          {
            eventName: UserEvents.changeTheme,
            value: config.themeModes.light,
            icon: <Brightness4OutlinedIcon />,
            label: t('labels.lightTheme'),
            pathname: null,
          },
          {
            eventName: UserEvents.changeTheme,
            value: config.themeModes.dark,
            icon: <Brightness4OutlinedIcon />,
            label: t('labels.darkTheme'),
            pathname: null,
          },
        ],
      },
      changeLanguage: {
        eventName: UserEvents.changeLanguage,
        icon: <TranslateOutlinedIcon />,
        label: t('labels.language'),
        pathname: null,
        subMenu: languages.map((lang) => ({
          eventName: `changeTo-${lang.key}`,
          value: lang.key,
          label: lang.name,
        })),
        onClick: () => {
          ga.trackEvent(events.e101);
        },
      },
    },
    appSection: [
      {
        eventName: UserEvents.faq,
        icon: <ForumOutlinedIcon />,
        label: t('routes.faq'),
        pathname: null,
        subMenu: null,
        onClick: () => {
          ga.trackEvent(events.e103);
          FaqURL();
        },
      },
      {
        eventName: UserEvents.about,
        icon: <InfoOutlinedIcon />,
        label: t('routes.about'),
        pathname: RoutePath.about,
        subMenu: null,
        onClick: () => {
          ga.trackEvent(events.e104);
        },
      },
      {
        eventName: UserEvents.privacyPolicy,
        icon: <PolicyOutlinedIcon />,
        label: t('routes.privacyPolicy'),
        pathname: null,
        subMenu: null,
        onClick: () => {
          ga.trackEvent(events.e105);
        },
      },
      {
        type: 'divider',
        eventName: 'dividerPrivacyPolicy',
      },
      {
        eventName: UserEvents.clearAppCache,
        icon: <CachedOutlinedIcon />,
        label: t('labels.clearAppCache'),
        pathname: null,
        subMenu: null,
        onClick: () => {
          ga.trackEvent(events.e106);
        },
      },
      {
        type: 'divider',
        eventName: 'dividerClearAppCache',
      },
      {
        eventName: UserEvents.manageCookies,
        icon: <CookieIcon />,
        label: 'Manage Cookies',
        onClick: onOpenCookiesDialog,
      },
      {
        type: 'divider',
        eventName: 'dividerManageCookies',
      },
      {
        eventName: UserEvents.signOut,
        icon: <ExitToAppOutlinedIcon />,
        label: t('labels.signOut'),
        pathname: null,
        subMenu: null,
        onClick: () => {
          ga.trackEvent(events.e3);
        },
      },
    ],
    couponCodeActivationSection: {
      eventName: UserEvents.codeActivation,
      icon: <RedeemIcon />,
      label: t('routes.codeActivation'),
      pathname: RoutePath.couponCodeActivation,
      subMenu: null,
      onClick: () => {
        ga.trackEvent(events.e172);
      },
    },
  };
}

export default useProfileMenu;
