import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { getAndStoreUserCountry } from '~/v3/api/user-api';
import { Language } from '~/v3/types/promotion';
import useGetCurrentUser from '~/v3/react-query/hooks/use-get-current-user';
import { UserCountry } from '~/v3/types/user';

function useGetUserCountry() {
  const [data, setData] = useState<UserCountry | null>();
  const [error, setError] = useState<unknown>();
  const { data: user } = useGetCurrentUser();
  const location = window?.OneTrust?.getGeolocationData();

  useQuery({
    queryKey: ['get-user-country'],
    queryFn: () => getAndStoreUserCountry(),
    staleTime: Infinity,
    enabled: !!user,
    onSuccess: (country) => {
      setData(country);
    },
    onError: (err) => {
      setError(err);
    },
  });

  useEffect(() => {
    if (!user) {
      if (location) {
        setData({
          code: location.country,
          name: location.stateName,
          region: location.state,
          language: Language.EN,
        });
      }
    }
  }, [user, location]);

  const isFromChina =
    data?.code === 'CN' ||
    data?.code === 'TW' ||
    data?.code === 'HK' ||
    data?.code === 'MO';

  const isFromUS = data?.code === 'US';

  return {
    data,
    isError: !!error,
    isFromChina,
    isFromUS,
  };
}

export default useGetUserCountry;
