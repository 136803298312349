import { Breakpoint } from '~/v3/types/promotion';

type PopupBreakpointsKey =
  | 'extension'
  | 'iphone12Mini'
  | 'iphoneSe'
  | 'iphone12Pro'
  | 'pixel7'
  | 'iphone14ProMax'
  | 'iphone16ProMax'
  | 'desktop'
  | 'tablet'
  | 'custom1'
  | 'custom2'
  | 'custom3'
  | 'maxMobile'
  | 'maxTablet';

export const popupBreakpoints: {
  [key in PopupBreakpointsKey]: Breakpoint;
} = {
  extension: {
    width: 320,
    height: 500,
  },
  iphone12Mini: { width: 360, height: 780 },
  iphoneSe: { width: 375, height: 667 },
  iphone12Pro: { width: 390, height: 844 },
  pixel7: { width: 412, height: 915 },
  iphone14ProMax: { width: 430, height: 932 },
  iphone16ProMax: { width: 460, height: 1030 },
  desktop: { width: 700, height: 750 },
  tablet: { width: 600, height: 500 },
  custom1: { width: 500, height: 1120 },
  custom2: { width: 550, height: 1231 },
  custom3: { width: 600, height: 1344 },
  maxMobile: { width: 600, height: 0 },
  maxTablet: { width: 1280, height: 0 },
};
