import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useFeatureFlags } from '@xrite/feature-flags-client';
import Loader from 'components/Loader';
import useDidUserSignInWithSSO from 'hooks/useDidUserSignInWithSSO';
import usePreferredLanguage from 'hooks/usePreferredLanguage';
import useRedirectPage from 'hooks/useRedirectPage';
import useRedirectToProtectedRoute from 'hooks/useRedirectToProtectedRoute';
import React from 'react';
import {
  useGetCurrentUserQuery,
  useGetUserSegmentQuery,
} from 'services/authApi';
import { rootElementIdSelector } from '~/v3/constants/root-element-id-selector';
import useVwoScriptLoader from '~/v3/hooks/use-vwo-script-loader';
import AuthenticatedApp from './AuthenticatedApp';
import UnauthenticatedApp from './UnauthenticatedApp';
import Auth from './pages/Auth';

function WebKitWrapper({ children }) {
  return (
    <>
      <style>
        {`
        div[id="${rootElementIdSelector}"] {
          overflow: auto;
          display: flex;
        }

        @supports (-webkit-appearance:none) {
          div[id="${rootElementIdSelector}"] {
            height: -webkit-fill-available !important;
          }
        }
      `}
      </style>
      {children}
    </>
  );
}

function App() {
  useVwoScriptLoader();
  useRedirectPage();
  usePreferredLanguage();
  useRedirectToProtectedRoute();
  useDidUserSignInWithSSO();

  const { isFeatureFlagsLoading } = useFeatureFlags();

  const { isAuthenticated, isLoading: isLoadingAuthentication } =
    useGetCurrentUserQuery(undefined, {
      selectFromResult: ({ data }) => ({ isAuthenticated: !!data }),
    });

  const { data: userSegment, isLoading: isLoadingUserSegment } =
    useGetUserSegmentQuery(undefined, { skip: !isAuthenticated });

  if (
    isLoadingAuthentication ||
    isLoadingUserSegment ||
    isFeatureFlagsLoading
  ) {
    return <Loader medium />;
  }

  if (!isAuthenticated) {
    return (
      <WebKitWrapper>
        <UnauthenticatedApp />
      </WebKitWrapper>
    );
  }

  if (isAuthenticated && !userSegment) {
    return (
      <WebKitWrapper>
        <Auth />
      </WebKitWrapper>
    );
  }

  return (
    <WebKitWrapper>
      <AuthenticatedApp />
    </WebKitWrapper>
  );
}

WebKitWrapper.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Sentry.withProfiler(App);
