import React from 'react';
import { t } from 'i18next';
import routes from '~/v2/constants/route';

import useRouter from '~/v3/hooks/use-router';
import { useWorkspaceStoreActions } from '~/v3/store/workspace-store';

import { ColorStory } from '~/v3/types/color-story';
import { useSelector } from 'react-redux';
import { selectId } from '~/v2/features/MoodboardWorkspace/moodboardSlice';
import { useMoodboardsInfiniteScroll } from '~/v3/hooks/use-moodboard-infinite-scroll';
import AccordionGroup from '~/v3/components/shared/accordion-group';

const testIds = {
  alert: 'get-saved-color-stories-error',
  loadingSkeleton: 'get-saved-color-stories-loading',
  list: 'saved-color-stories-list',
};

function SavedColorStories() {
  const selectedColorStoryId = useSelector(selectId) as string;
  const {
    moodboards: savedColorStories,
    hasMoreData,
    isFetching,
    error,
  } = useMoodboardsInfiniteScroll();

  const { onExpandWorkspace } = useWorkspaceStoreActions();
  const { push } = useRouter();

  const handleOpenColorStory = (colorStory: ColorStory) => {
    push(routes.colorStoryUpdate.replace(':id', colorStory.id));
    onExpandWorkspace();
  };

  return (
    <AccordionGroup
      isError={!!error}
      error={error}
      isLoading={isFetching}
      list={savedColorStories}
      selectedId={selectedColorStoryId}
      onClickListItem={(colorStory) => handleOpenColorStory(colorStory)}
      testIds={testIds}
      label={t('labels.colorStory')}
      hasMoreData={hasMoreData}
    />
  );
}
export default SavedColorStories;
