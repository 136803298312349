import * as React from 'react';
import Drawer from '@mui/material/Drawer';

import sidebarStore, { useSidebarStoreActions } from '~/v3/store/sidebar-store';
import { SxProps, useMediaQuery, useTheme } from '@mui/material';
import { Theme } from '@emotion/react';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import colors from '~/v3/theme/colors';
import SidebarContent from '../sidebar-content/sidebar-content';

function SidebarDrawer() {
  const { isSidebarOpen } = sidebarStore();
  const theme = useTheme();
  const isTabletScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { onCloseSidebar } = useSidebarStoreActions();
  const isOpen = isTabletScreen ? isSidebarOpen : true;

  const mobileStyle: SxProps<Theme> = {
    width: '100%',
  };

  const desktopStyle: SxProps<Theme> = {
    width: 245,
    minWidth: 245,
  };

  const paperStyle: SxProps<Theme> = isTabletScreen
    ? mobileStyle
    : desktopStyle;

  const variant = isTabletScreen ? 'temporary' : 'persistent';

  const history = useHistory();

  useEffect(() => {
    const unsubscribe = history.listen(() => {
      onCloseSidebar();
    });

    return () => {
      unsubscribe();
    };
  }, [history, onCloseSidebar]);

  return (
    <Drawer
      sx={{
        zIndex: 1000,
        ...paperStyle,
      }}
      PaperProps={{
        sx: {
          position: 'initial',
          backgroundColor: colors.white,
        },
      }}
      open={isOpen}
      data-testid="sidebar"
      variant={variant}
    >
      <SidebarContent />
    </Drawer>
  );
}

export default SidebarDrawer;
