import { useSelector } from 'react-redux';

import useGetSubscription from '~/v3/react-query/hooks/use-get-subscription';
import useSyncUserSubscription from '~/v3/react-query/hooks/use-sync-user-subscriptions';
import { api } from './api';
import { selectCurrentUser } from './authApi';
import subscriptionGraphql from './subscriptionGraphql';

export const subscriptionTag = 'subscription';
const subscriptionURLTag = 'subscriptionURL';

const extendedApi = api.injectEndpoints({
  endpoints: (build) => ({
    getProductsPricing: build.query({
      queryFn: subscriptionGraphql.getProducts,
    }),
    getSubscriptionUrl: build.query({
      queryFn: subscriptionGraphql.getSubscriptionUrl,
      providesTags: [subscriptionURLTag],
    }),
    cancelSubscription: build.mutation({
      queryFn: subscriptionGraphql.cancelSubscription,
      invalidatesTags: [subscriptionTag, subscriptionURLTag],
    }),
    createFastSpringSecurePayload: build.mutation({
      queryFn: subscriptionGraphql.createFastSpringSecurePayload,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetSubscriptionUrlQuery,
  useLazyGetSubscriptionUrlQuery,
  useGetProductsPricingQuery,
  useCancelSubscriptionMutation,
  useCreateFastSpringSecurePayloadMutation,
} = extendedApi;

export function useConfiguredGetSubscriptionQuery(arg, options) {
  const { data: user, isLoading: isLoadingUser } =
    useSelector(selectCurrentUser);

  const isAuthenticated = !!user;

  const {
    data: syncedSubscription,
    isLoading: isLoadingSyncUserSubscription,
    isFetching,
  } = useSyncUserSubscription({
    skip: !isAuthenticated,
  });

  const getSubscriptionQuery = useGetSubscription(arg, {
    ...options,
    skip: !syncedSubscription,
  });

  return {
    ...getSubscriptionQuery,
    isLoading:
      getSubscriptionQuery.isLoading ||
      isLoadingUser ||
      (isLoadingSyncUserSubscription && isFetching),
  };
}
