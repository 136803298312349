import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isOpen: false,
  color: null,
};

export const fullScreenColorSlice = createSlice({
  name: 'fullScreenColor',
  initialState,
  reducers: {
    open: (state, action) => {
      state.isOpen = true;
      state.color = action.payload;
    },
    close: () => initialState,
  },
});

export const { open, close } = fullScreenColorSlice.actions;

export default fullScreenColorSlice.reducer;

export const selectColor = (state) => state.fullScreenColor.color;
export const selectIsOpen = (state) => state.fullScreenColor.isOpen;
