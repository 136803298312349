import events from '~/v2/analytics/events';
import { isChipClickEventId } from '~/v2/analytics/utils';
import RoutePathname from '~/v2/constants/route';
import { store } from '~/v2/store';

export function getPreviousRoute({ previousPage }) {
  switch (previousPage?.route) {
    case RoutePathname.myPalettes:
      return 'from_mypalettes';

    case RoutePathname.colorPicker:
      return 'from_pick';

    case RoutePathname.colorExtract:
      return 'from_extract';

    case RoutePathname.colorConvert:
      return 'from_convert';

    case RoutePathname.trending:
      return 'from_trending';

    case RoutePathname.search:
      return 'from_search';

    case RoutePathname.colorStory:
    case RoutePathname.colorStoryNew:
    case RoutePathname.colorStoryTemplate:
    case RoutePathname.colorStoryCreate:
    case RoutePathname.colorStoryUpdate:
      return 'from_colorstory';

    default:
      return '';
  }
}

const colorStoryUpdate = RoutePathname.colorStoryUpdate.replace(':id', '');

const colorStoryConfig = {
  route: RoutePathname.colorStoryCreate,
  prefix: null,
  getPageOverrides: () => {
    const reduxState = store?.getState?.();

    return {
      EVENT_PATH: '/colorStory/create',
      EVENT_PAGE: 'new_color_story',
      EVENT_AREA: reduxState?.colorStory?.layoutId,
      EVENT_PLACE: reduxState?.colorStory?.layoutId,
      EVENT_COMPONENT: 'try_color_story',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    };
  },
  event: null,
};

export default {
  [RoutePathname.search]: {
    route: RoutePathname.search,
    prefix: 'search',
    area: 'na',
    event: null,
    getPageOverrides: ({ event }) => ({
      EVENT_PATH: 'search?query=value',
      EVENT_PAGE: 'search',
      EVENT_PLACE: 'body',
      EVENT_COMPONENT: isChipClickEventId(event.EVENT_ID)
        ? 'search_results_area'
        : null,
    }),
  },
  [RoutePathname.myPalettes]: {
    route: RoutePathname.myPalettes,
    prefix: null,
    event: events.e32,
    getPageOverrides: ({ query }) => ({
      EVENT_PATH: '/myPalettes',
      EVENT_PAGE: 'myPalettes',
      EVENT_PLACE: 'lower_body',
      EVENT_AREA: query.tab,
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
  },
  [RoutePathname.colorConvert]: {
    route: RoutePathname.colorConvert,
    prefix: null,
    area: 'from_convert',
    event: events.e110,
    getPageOverrides: () => ({
      EVENT_PATH: '/convert',
      EVENT_PAGE: 'convert',
      EVENT_PLACE: 'body',
      EVENT_COMPONENT: 'convert_button_bar',
    }),
  },
  [RoutePathname.colorExtract]: {
    route: RoutePathname.colorExtract,
    prefix: null,
    area: 'from_extract',
    event: events.e109,
    getPageOverrides: () => ({
      EVENT_PATH: '/extract',
      EVENT_PAGE: 'extract',
      EVENT_PLACE: 'upload',
      EVENT_GROUP: 'na',
      EVENT_AREA: 'extract_upload',
    }),
  },
  [RoutePathname.colorStoryCreate]: colorStoryConfig,
  [colorStoryUpdate]: colorStoryConfig,
  [RoutePathname.colorStory]: {
    route: RoutePathname.colorStory,
    prefix: null,
    getPageOverrides: ({ query }) => ({
      EVENT_PATH: '/colorStory/new',
      EVENT_PAGE: 'new_color_story',
      EVENT_AREA: query?.templateId,
      EVENT_PLACE: query?.templateId,
      EVENT_COMPONENT: 'try_color_story',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
    event: events.e131,
  },
  [RoutePathname.colorPicker]: {
    route: RoutePathname.colorPicker,
    prefix: null,
    area: 'na',
    event: events.e108,
    getPageOverrides: ({ query, previousPage }) => ({
      EVENT_PATH: '/picker',
      EVENT_PAGE: 'picker',
      EVENT_PLACE: 'body',
      EVENT_COMPONENT:
        previousPage?.route === RoutePathname.myPalettes
          ? 'edit_palette'
          : 'create_new',
      EVENT_AREA: previousPage
        ? getPreviousRoute({ query, previousPage })
        : 'from_pick',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
  },
  [RoutePathname.colorMeasure]: {
    route: RoutePathname.colorMeasure,
    prefix: null,
    area: 'na',
    event: events.e111,
    getPageOverrides: () => ({
      EVENT_PATH: '/measure',
      EVENT_PAGE: 'measure',
      EVENT_PLACE: 'body',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
  },
  [RoutePathname.trending]: {
    route: RoutePathname.trending,
    prefix: null,
    area: 'na',
    event: events.e121,
    getPageOverrides: () => ({
      EVENT_PATH: '/trending',
      EVENT_PAGE: 'trending',
      EVENT_COMPONENT: 'trending_color_of_the_year',
      EVENT_PLACE: 'body',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
  },
  [RoutePathname.myProfile]: {
    route: RoutePathname.myProfile,
    prefix: null,
    event: events.e100,
    getPageOverrides: () => ({
      EVENT_PATH: '/profile',
      EVENT_PAGE: 'myProfile',
      EVENT_PLACE: 'lower_body',
      EVENT_GROUP: 'na',
      EVENT_BOOK_ID: 'na',
    }),
  },
  [RoutePathname.colorInfo]: {
    route: RoutePathname.colorInfo,
    prefix: null,
    area: 'na',
    event: null,
    getPageOverrides: ({ query }) => ({
      EVENT_PATH: `info/?tab=${query.tab}`,
      EVENT_PAGE: query.tab,
      EVENT_AREA: query.tab,
      EVENT_COMPONENT: `search_results_info_${query.tab}`,
    }),
  },
  [RoutePathname.auth.welcome]: {
    route: RoutePathname.auth.welcome,
    getPageOverrides: () => ({
      EVENT_PATH: '/welcome',
      EVENT_PAGE: 'welcome',
      EVENT_PLACE: 'body',
    }),
  },
  [RoutePathname.auth.signInEmail]: {
    route: RoutePathname.auth.signInEmail,
    getPageOverrides: () => ({
      EVENT_PATH: '/welcome',
      EVENT_PAGE: 'welcome',
      EVENT_PLACE: 'body',
    }),
  },
  [RoutePathname.auth.createAccount]: {
    route: RoutePathname.auth.createAccount,
    getPageOverrides: () => ({
      EVENT_PATH: '/create-account',
      EVENT_PAGE: 'create-account',
      EVENT_PLACE: 'body',
    }),
  },
  [RoutePathname.auth.passwordRecovery]: {
    route: RoutePathname.auth.passwordRecovery,
    getPageOverrides: () => ({
      EVENT_PATH: '/password-verification',
      EVENT_PAGE: 'password-verification',
      EVENT_PLACE: 'body',
    }),
  },
  [RoutePathname.legacyCouponCodes]: {
    route: RoutePathname.legacyCouponCodes,
    event: null,
    getPageOverrides: () => ({
      EVENT_PATH: '/redeem-code',
      EVENT_PAGE: 'redeem_code',
    }),
  },
  [RoutePathname.explore]: {
    route: RoutePathname.explore,
    event: null,
    prefix: null,
    area: 'na',
    getPageOverrides: () => ({
      EVENT_PATH: '/explore',
      EVENT_PAGE: 'explore',
    }),
  },
  [RoutePathname.insight]: {
    route: RoutePathname.insight,
    event: null,
    prefix: null,
    area: 'na',
    getPageOverrides: () => ({
      EVENT_PATH: '/color-insider',
      EVENT_PAGE: 'color-insider',
    }),
  },
  [RoutePathname.paywall]: {
    route: RoutePathname.paywall,
    event: null,
    prefix: null,
    getPageOverrides: () => ({
      EVENT_PATH: '/paywall',
      EVENT_PAGE: 'paywall',
      EVENT_PLACE: 'body',
    }),
  },
  [RoutePathname.auth.userSegment]: {
    route: RoutePathname.auth.userSegment,
    event: null,
    prefix: null,
    area: 'na',
    getPageOverrides: () => ({
      EVENT_PATH: '/user-segment',
      EVENT_PAGE: 'user-segment',
    }),
  },
  [RoutePathname.statistics]: {
    route: RoutePathname.statistics,
    event: null,
    prefix: null,
    area: 'na',
    getPageOverrides: () => ({
      EVENT_PATH: '/statistics',
      EVENT_PAGE: 'statistics',
    }),
  },
};
