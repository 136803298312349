import React, { useEffect, useState } from 'react';
import { Tooltip, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import InputBase from '@mui/material/InputBase';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import useTracker from '~/v2/analytics/useTracker';
import events from '~/v2/analytics/events';
import colors from '~/v2/theme/colors';
import RoutePath from '~/v2/constants/route';
import useDebounce from '~/v3/hooks/use-debounce';
import useWindow from '~/v2/hooks/useWindow';
import SearchIcon from '~/v3/components/shared/icons/search-icon';
import { searchBarZIndex } from '~/v3/constants/search-bar-z-index';
import useRouter from '~/v3/hooks/use-router';
import { userProfileAvatarWidth } from '~/v3/components/shared/avatar-icon';
import {
  headerItemMobileWidth,
  headerBorderBottomSize,
  headerHeight,
  scaledHeaderPadding,
  searchButtonWidth,
  customerServiceButtonWidth,
  scaledHeaderPaddingMobile,
  searchButtonMobileWidth,
} from '../model/constants';

const SEARCH_WIDTH = searchButtonWidth;
const MIN_SEARCH_QUERY_LENGTH = 3;
const LIVE_SEARCH_TIMEOUT = 600;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '99%',
    position: 'absolute',
    alignItems: 'center',
    justifyContent: 'flex-start',
    background: '#fff',
    zIndex: searchBarZIndex,
    right: 0,
    top: 0,
    backgroundColor: theme.palette.background.paper,
  },
  input: {
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2.5),
    },
  },
  searchButton: {
    display: 'flex',
    color: colors.JetBlack['600'],
    width: SEARCH_WIDTH,
    height: '100%',
    padding: 0,
    '&:hover': {
      background: 'none',
    },
    marginTop: headerBorderBottomSize, // used to center the search icon with the header border bottom
    [theme.breakpoints.down('sm')]: {
      width: searchButtonMobileWidth,
    },
  },
  closeButton: {
    padding: 0,
  },
  searchIcon: {
    marginRight: theme.spacing(2),
    marginLeft: theme.spacing(2),
  },
  noSearchIcon: {
    marginRight: theme.spacing(2),
    color: theme.palette.primary.main,
  },
  iconPointer: {
    cursor: 'pointer',
  },
  clickAway: {
    position: 'relative',
    height: headerHeight - headerBorderBottomSize,
    width: SEARCH_WIDTH,
    backgroundColor: theme.palette.background.default,
    [theme.breakpoints.down('sm')]: {
      width: searchButtonMobileWidth,
    },
    [theme.breakpoints.down(380)]: {
      height: `calc(${headerHeight}px - ${headerBorderBottomSize}px - 10px)`,
    },
  },
}));

let previousPathname;

function SearchBar() {
  const theme = useTheme();
  const classes = useStyles();
  const { location, query, push, updateURLQuery } = useRouter();
  const matches = useMediaQuery(theme.breakpoints.down('md'), { noSsr: true });
  const isExtraSmallScreen = useMediaQuery('(max-width:360px)', {
    noSsr: true,
  });
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchValue, setSearchValue] = useState(query.query || '');
  const debouncedSearchValue = useDebounce(searchValue, LIVE_SEARCH_TIMEOUT);
  const [active, setActive] = useState(location.pathname === RoutePath.search);
  const { t } = useTranslation();
  const ga = useTracker();

  useEffect(() => {
    if (debouncedSearchValue?.length >= MIN_SEARCH_QUERY_LENGTH) {
      ga.trackEvent({ ...events.e6, TERM: debouncedSearchValue });
      updateURLQuery({ query: debouncedSearchValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  useEffect(() => {
    setActive(location.pathname === RoutePath.search);
    if (!query?.query) {
      setSearchValue('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const handleClickAway = () => {
    setActive(false);

    setSearchValue('');
    updateURLQuery({ query: null });

    if (previousPathname) {
      push(previousPathname);
    } else {
      push(RoutePath.myPalettes);
    }

    ga.trackEvent(events.e5);
  };

  const onClick = () => {
    setActive(true);
    push(RoutePath.search);
    previousPathname = location.pathname;
    ga.trackEvent(events.e4);
  };

  const onChange = ({ target: { value } }) => {
    setSearchValue(value);
  };

  const {
    windowSize: { width: windowWidth },
  } = useWindow();

  const muiThemeScaleFactor = Number(theme.spacing().slice(0, -2));

  const userAvatar = isSmallScreen
    ? headerItemMobileWidth
    : userProfileAvatarWidth;

  const customerService = isSmallScreen
    ? headerItemMobileWidth
    : customerServiceButtonWidth;

  const headerPadding = isSmallScreen
    ? scaledHeaderPaddingMobile
    : scaledHeaderPadding;

  // NOTE: this will change when we implement the new Search Bar design in V3
  // If we add more items left of the search bar, we need to add their width in this calculation
  const expandedSearchBarWidth =
    windowWidth -
    headerPadding * muiThemeScaleFactor -
    customerService -
    userAvatar;

  const collapsedSearchWidth = isSmallScreen
    ? searchButtonMobileWidth
    : SEARCH_WIDTH;

  return (
    <div className={classes.clickAway}>
      <motion.div
        className={classes.root}
        animate={
          active
            ? { width: expandedSearchBarWidth }
            : {
                width: collapsedSearchWidth,
              }
        }
      >
        {active ? (
          <InputBase
            classes={{ root: classes.input }}
            autoFocus
            startAdornment={
              isExtraSmallScreen ? (
                <span className={classes.noSearchIcon} />
              ) : (
                <SearchIcon className={classes.searchIcon} />
              )
            }
            endAdornment={
              <Tooltip
                title={t('labels.close')}
                PopperProps={{
                  modifiers: [
                    {
                      name: 'offset',
                    },
                  ],
                }}
              >
                <IconButton
                  data-testid="close-search-button"
                  className={`${classes.iconPointer} ${classes.closeButton}`}
                  onClick={handleClickAway}
                  size="large"
                >
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            }
            placeholder={
              matches ? t('labels.searchText') : t('labels.searchTextLong')
            }
            inputProps={{
              'aria-label': 'search google maps',
              'data-testid': 'search-input',
            }}
            onChange={onChange}
            value={searchValue}
          />
        ) : (
          <Tooltip
            title={t('labels.search')}
            PopperProps={{
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [0, -20],
                  },
                },
              ],
            }}
          >
            <IconButton
              data-testid="open-search-button"
              className={classes.searchButton}
              onClick={onClick}
              size="large"
            >
              <SearchIcon
                sx={{
                  [theme.breakpoints.down('sm')]: {
                    width: 15.7,
                    height: 19,
                  },
                }}
              />
            </IconButton>
          </Tooltip>
        )}
      </motion.div>
    </div>
  );
}

export default SearchBar;
