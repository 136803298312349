import React, {
  PropsWithChildren,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

type MenuContextType = {
  isMenuOpen: boolean;
  onOpenMenu: () => void;
  onCloseMenu: () => void;
  anchorEl: React.RefObject<HTMLElement>;
};

const MenuContext = React.createContext<MenuContextType | undefined>(undefined);

function MenuProvider({
  children,
}: PropsWithChildren<Record<string, unknown>>) {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const anchorEl = useRef<HTMLElement>(null);

  const onOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
  }, []);

  const onCloseMenu = useCallback(() => {
    setIsMenuOpen(false);
  }, []);

  const value = useMemo(
    () => ({
      isMenuOpen,
      onOpenMenu,
      onCloseMenu,
      anchorEl,
    }),
    [isMenuOpen, onOpenMenu, onCloseMenu, anchorEl]
  );

  return <MenuContext.Provider value={value}>{children}</MenuContext.Provider>;
}

export const useMenu = () => {
  const menuContext = React.useContext(MenuContext);

  if (!menuContext) {
    throw new Error(
      'MenuContext is undefined. Please wrap your component in MenuProvider.'
    );
  }

  return menuContext;
};

export default MenuProvider;
