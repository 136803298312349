import React from 'react';
import MUIModal, { ModalProps as MUIModalProps } from '@mui/material/Modal';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';

type ModalProps = {
  children: React.ReactNode;
} & MUIModalProps;

function Modal({ children, open, onClose, ...rest }: ModalProps) {
  const theme = useTheme();

  return (
    <MUIModal
      open={open}
      onClose={onClose}
      sx={{
        zIndex: 2000,
      }}
      {...rest}
    >
      <Box
        data-id="modal-content"
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          outline: 0,
          background: 'white',
          padding: theme.spacing(3),
          borderRadius: theme.spacing(0.375),
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(1),
          overflowX: 'hidden',
          [theme.breakpoints.down('md')]: {
            height: '95%',
            overflowY: 'auto',
          },
          [theme.breakpoints.down('sm')]: {
            px: theme.spacing(0),
            width: '80%',
          },
        }}
      >
        {children}
      </Box>
    </MUIModal>
  );
}

export default Modal;
