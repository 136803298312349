import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@mui/styles';

import useRouter from '~/v3/hooks/use-router';
import PantoneLogoWithIcon from 'components/PantoneLogoWithIcon';

const useStyles = makeStyles((theme) => ({
  content: {
    maxWidth: 800,
    padding: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  logo: {
    cursor: 'pointer',
  },
}));

function UnauthHeader({ children }) {
  const classes = useStyles();
  const { push } = useRouter();

  const handlePantoneLogoClick = () => {
    push('/');
  };

  return (
    <>
      <div className={classes.content}>
        <PantoneLogoWithIcon
          width={150}
          onClick={handlePantoneLogoClick}
          className={classes.logo}
        />
      </div>
      {children}
    </>
  );
}

UnauthHeader.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UnauthHeader;
