import { rgbToHls } from '@xrite/color-js';
import {
  FIRST_OF_FEBRUARY_2024,
  HueRange,
  RgbToLuminanceConversion,
  TEN_DEGREES,
} from '~/v3/pick/components/constants';
import { Color, PantoneColorWithBook, Rgb } from '~/v3/types/pantone-color';
// @ts-expect-error Hilbert doesn't have a declaration file
import hilbert from 'hilbert';

import hueFilters from '~/v3/pick/utils/hue-filters-manager';

type HilbertColor = { hilbertRGB?: number } & PantoneColorWithBook;

export function sortColorsByBookPosition(colors: PantoneColorWithBook[]) {
  return colors.sort(
    (a, b) => Number(a?.positionInBook) - Number(b?.positionInBook)
  );
}

export function sortColorsByHueGroups(colors: PantoneColorWithBook[]) {
  const colorGroups = hueFilters.collection.map((hueFilter) =>
    colors.filter(hueFilter.filterFunction)
  );

  const colorsByHueGroups: PantoneColorWithBook[] = [];

  colorGroups.forEach((group) => {
    group.sort((a, b) => a.hsl.l - b.hsl.l || a.hsl.s - b.hsl.s);
    colorsByHueGroups.push(...group);
  });

  return colorsByHueGroups;
}

export function sortColorsByHilbert(colors: PantoneColorWithBook[]) {
  const colorsByHilbert: HilbertColor[] = colors.map((c) => {
    const newColor: HilbertColor = { ...c };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
    newColor.hilbertRGB = hilbert?.xyz2d(c.rgb.r, c.rgb.g, c.rgb.b) as number;
    return newColor;
  });
  colorsByHilbert.sort((a, b) => Number(a?.hilbertRGB) - Number(b?.hilbertRGB));

  return colorsByHilbert;
}

export function filterColorsByHueRange(
  colors: PantoneColorWithBook[],
  hue: HueRange
): PantoneColorWithBook[] {
  const filteredColors = colors.filter((color) => {
    const colorHsl = rgbToHls({
      r: color.rgb.r,
      g: color.rgb.g,
      b: color.rgb.b,
    });

    return colorHsl.h > hue - TEN_DEGREES && colorHsl.h < hue + TEN_DEGREES;
  });

  return sortColorsByBookPosition(filteredColors);
}

export function filterColorsByName(
  colors: PantoneColorWithBook[],
  searchQuery: string
) {
  return (
    colors?.filter(
      (color) =>
        color?.name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        color?.code?.toLowerCase().includes(searchQuery.toLowerCase())
    ) || []
  );
}

export function getColorLuminance(rgbValues: Rgb) {
  const normalizedR = rgbValues.r / 255;
  const normalizedG = rgbValues.g / 255;
  const normalizedB = rgbValues.b / 255;

  const luminance =
    RgbToLuminanceConversion.RED_WEIGHT * normalizedR +
    RgbToLuminanceConversion.GREEN_WEIGHT * normalizedG +
    RgbToLuminanceConversion.BLUE_WEIGHT * normalizedB;

  return parseFloat(luminance.toFixed(2));
}

export function isLightColor(rgbValues: Rgb) {
  const luminance = getColorLuminance(rgbValues);

  return luminance < Number(RgbToLuminanceConversion.LUMINANCE_THRESHOLD);
}

export function filterColorByCreationDate(color: Color, date: Date) {
  if (!color.creationDate) {
    return false;
  }

  return new Date(color.creationDate) > date;
}

export function doesBookIncludeOnlyNewColors(colors: PantoneColorWithBook[]) {
  return colors.every((color) =>
    filterColorByCreationDate(color, FIRST_OF_FEBRUARY_2024)
  );
}
