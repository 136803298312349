import {
  PANTONE_WEB_REDIRECT,
  USER_REDIRECTED_FROM_PANTONE,
} from 'constants/storage';
import { ARTICLE_ID } from '~/v3/constants/storage';
import { getSessionStorage } from '~/v3/utils/storage';
import { BaseRoutePaths } from '../constants/route';

export const shouldBeRedirectedToColorInfoPage = () => {
  const isFromPantoneWebsite = getSessionStorage(USER_REDIRECTED_FROM_PANTONE);

  const colorCode = getSessionStorage(PANTONE_WEB_REDIRECT);

  return isFromPantoneWebsite && colorCode;
};

export const shouldBeRedirectedToTrendsPage = () => {
  return !!getSessionStorage(ARTICLE_ID);
};

export const redirectToColorInfoPage = ({ router }) => {
  const colorCode = getSessionStorage(PANTONE_WEB_REDIRECT);

  if (colorCode) {
    router.push(`/info/${colorCode}`);
  }
};

export const redirectToPickPage = ({ router }) => {
  router.push(BaseRoutePaths.picker);
};

export const redirectToTrends = ({ router }) => {
  const articleId = getSessionStorage(ARTICLE_ID);
  router.push(`${BaseRoutePaths.insight}/${articleId}`);
};
