import { Theme, useMediaQuery, useTheme } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import React, { CSSProperties } from 'react';

import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { listChipHeight, listChipMinWidth } from '~/v3/constants/chip';
import { dragAndDropVariants } from '~/v3/constants/drag-and-drop';
import { PantoneColorWithBook } from '~/v3/types/pantone-color';
import { mergeRefs } from '~/v3/utils/refs';
import colorConstants from '~/v3/theme/colors';
import NewColorDotIndicatorListView from '~/v3/pick/components/new-color-dot-indicator-list-view';
import { filterColorByCreationDate } from '~/v3/pick/utils/color-utils';
import { FIRST_OF_FEBRUARY_2024 } from '~/v3/pick/components/constants';
import ChipInfoArea from './chip-info-area';

export type ListChipProps = {
  color: PantoneColorWithBook;
  onClick: (event: React.MouseEvent, color: PantoneColorWithBook) => void;
  onRightClick?: React.MouseEventHandler<HTMLDivElement>;
  areAllColorsNew?: boolean;
  sx?: BoxProps['sx'];
  style: React.CSSProperties;
  children?: React.ReactNode;
  elevation?: { color: boolean; info: boolean };
  dataTestid?: string;
};

const ListChip = React.forwardRef<HTMLDivElement, ListChipProps>(
  (
    {
      children,
      color,
      onClick,
      areAllColorsNew,
      style,
      elevation = { color: false, info: false },
      onRightClick,
      dataTestid,
      ...rest
    },
    ref
  ) => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery((t: Theme) => t.breakpoints.down('sm'));
    const isLargeScreen = useMediaQuery((t: Theme) => t.breakpoints.down('lg'));

    const isNewColor = filterColorByCreationDate(color, FIRST_OF_FEBRUARY_2024);

    // TO-DO: add  prop to enable/disable drag and drop

    const [, drag, preview] = useDrag(() => ({
      type: dragAndDropVariants.V3_PANTONE_INFO_CHIP,
      item: { color },
    }));

    preview(getEmptyImage(), { captureDraggingState: true });

    const colorContainerStyle: CSSProperties = {
      display: 'flex',
      position: 'relative',
      minWidth: listChipMinWidth,
      height: listChipHeight,
      padding: theme.spacing(1, 1, 0),
      cursor: 'pointer',
      ...(isLargeScreen && { maxWidth: '100%' }),
      ...(isSmallScreen && { minWidth: 0, flexBasis: '100%' }),
    };

    const colorInfoStyle: CSSProperties = {
      flexGrow: 1,
      maxWidth: isSmallScreen ? 102 : 115,
      display: 'flex',
      alignItems: 'center',
      background: colorConstants.white,
      ...(elevation?.info && {
        boxShadow: theme.palette.shadows.listChipShadow.chipInfoAreaShadow,
      }),
    };

    const colorAreaStyle: CSSProperties = {
      background: `rgb(${color?.rgb.r},${color?.rgb.g},${color?.rgb.b})`,
      flexGrow: 1,
      ...(elevation?.color && {
        boxShadow: theme.palette.shadows.listChipShadow.chipColorAreaShadow,
      }),
    };

    return (
      <Box
        data-testid="list-chip-container"
        ref={mergeRefs(ref, drag)}
        onClick={(event) => {
          onClick(event, color);
        }}
        style={{ ...colorContainerStyle, ...style }}
        id={`color-${color?.code}`}
        onContextMenu={onRightClick}
        {...rest}
      >
        <div data-testid={dataTestid} style={colorAreaStyle}>
          {children}
        </div>
        <div style={colorInfoStyle} data-testid="list-chip-info">
          <ChipInfoArea color={color} />
        </div>
        {!areAllColorsNew && isNewColor && (
          <NewColorDotIndicatorListView rgbValues={color.rgb} />
        )}
      </Box>
    );
  }
);

export default ListChip;
