import React from 'react';
import { useTranslation } from 'react-i18next';
import { Language, Promotion } from '~/v3/types/promotion';
import { AuthContainer } from '../auth-container-small-screen.elements';

function AuthContainerSmallScreen({
  children,
  promotion,
}: Readonly<{ children: React.ReactNode; promotion: Promotion }>) {
  const translation = useTranslation();

  const language = translation.i18n.language.split('-')[0] || Language.EN;

  return (
    <AuthContainer
      promotion={promotion}
      language={language as Language}
      data-testid="auth-container-v3"
    >
      {children}
    </AuthContainer>
  );
}

export default AuthContainerSmallScreen;
