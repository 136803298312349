import { ListItemButton, ListItemText, Tooltip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

function SeeMoreButton() {
  const { t } = useTranslation();

  return (
    <Tooltip
      title="Coming soon, more items will be displayed in the Flyout."
      placement="bottom"
    >
      <span>
        <ListItemButton
          disabled
          sx={{
            padding: 0,
          }}
        >
          <ListItemText
            sx={{
              '& span': {
                fontSize: 12,
              },
            }}
            primary={t('labels.seeMore')}
          />
        </ListItemButton>
      </span>
    </Tooltip>
  );
}

export default SeeMoreButton;
