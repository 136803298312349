import { useState } from 'react';
import { useLocation } from 'react-router';
import useTracker from '~/v2/analytics/useTracker';
import {
  SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE,
  SUBSCRIPTION_TYPE,
  USED_PRODUCT_IDS_FOR_PAYLOAD,
  USER_REDIRECTED_FROM_PANTONE,
} from '~/v3/constants/storage';
import useRouter from '~/v3/hooks/use-router';
import useCreateFastSpringMultipleProductsSecurePayload from '~/v3/react-query/hooks/use-create-fastspring-multiple-products-secure-payload';
import useGetCurrentUser from '~/v3/react-query/hooks/use-get-current-user';
import { Subscription, SubscriptionStatus } from '~/v3/types/subsription';
import {
  getSessionStorage,
  readSessionStorage,
  removeSessionStorage,
  setSessionStorage,
} from '~/v3/utils/storage';
import { queryClient } from '~/v3/react-query';
import { premiumProducts, trendsProducts } from '~/v3/trends/utils';
import { PAYMENT_DETAILS_STORAGE_KEY } from '~/v3/paywall/constants';
import { FastSpringProducts } from '~/v3/fastspring-products';
import RoutePathname from '~/v2/constants/route';
import {
  subscriptionStarted,
  checkoutCompleted,
} from '~/v3/paywall/analytics/events';
import { ProductSeatsMap, ProductType } from '~/v3/paywall/types';
import { isPantoneDomainURL } from '~/v2/utils/links';
import useFastSpringPopupStore from './use-fastspring-popup-store';
import useSyncSubscription from './use-sync-subscriptions';

function usePurchaseFastSpringSubscription(onSubscribe?: () => Promise<void>) {
  const { data: user } = useGetCurrentUser();
  const { push, pathname } = useRouter();
  const { syncSubscription } = useSyncSubscription({
    shouldStopSyncing: (
      userSubscription: Subscription,
      productIds: string[] | null
    ) => {
      if (Array.isArray(productIds)) {
        const allSubscriptionsActive =
          productIds.reduce((acc, productId) => {
            const subscription = userSubscription?.subscriptions.find(
              (sub) => sub.product === (productId as ProductType)
            );

            return (
              acc &&
              (subscription?.state === SubscriptionStatus.ACTIVE ||
                subscription?.state === SubscriptionStatus.TRIAL ||
                subscription?.state === SubscriptionStatus.COUPON_CODE_TRIAL)
            );
          }, true) || false;

        return allSubscriptionsActive;
      }

      return false;
    },
  });

  const { mutateAsync } = useCreateFastSpringMultipleProductsSecurePayload();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const source = searchParams.get('source');

  const isFromPantoneWebsite =
    isPantoneDomainURL(source) ||
    getSessionStorage(USER_REDIRECTED_FROM_PANTONE);
  const { trackPaywallEvent } = useTracker();

  const [isPurchasing, setIsPurchasing] = useState(false);

  const { handleOpenFastSpringPopup } = useFastSpringPopupStore({
    onClose: () => {
      setIsPurchasing(false);
    },
    onSubscribe: async (productIds, orderReference) => {
      queryClient.removeQueries({
        queryKey: ['products'],
      });

      const subscriptionId = await syncSubscription(productIds);

      const productSeatsMap = getSessionStorage(
        SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE
      ) as string | undefined;

      if (productSeatsMap) {
        const parsedProductSeatsMap = JSON.parse(
          productSeatsMap
        ) as ProductSeatsMap;
        removeSessionStorage(SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE);

        const eventProps = productIds?.reduce(
          (acc, productId, idx) => {
            let quantity = 1;

            if (premiumProducts.includes(productId as FastSpringProducts)) {
              quantity = parsedProductSeatsMap[ProductType.PREMIUM];
            } else if (
              trendsProducts.includes(productId as FastSpringProducts)
            ) {
              quantity = parsedProductSeatsMap[ProductType.TRENDS];
            }

            return {
              ...acc,
              [`EVENT_PRODUCT_ID_${idx + 1}`]: productId,
              [`EVENT_PRODUCT_QUANTITY_${idx + 1}`]: quantity,
            };
          },
          { SUBSCRIPTION_ID: subscriptionId }
        );

        const paymentDetails = readSessionStorage<string>(
          PAYMENT_DETAILS_STORAGE_KEY
        );
        const { price, currency } = paymentDetails
          ? (JSON.parse(paymentDetails) as { price: string; currency: string })
          : { price: '0', currency: 'USD' };

        trackPaywallEvent({
          ...checkoutCompleted,
          ...eventProps,
          order_id: orderReference?.id,
          transaction_id: orderReference?.id,
          value: Number.parseFloat(price),
          currency,
        });

        trackPaywallEvent({
          ...subscriptionStarted,
          ...eventProps,
        });
      }

      if (onSubscribe) {
        await onSubscribe();
      }

      if (productIds?.length && pathname.includes(RoutePathname.paywall)) {
        push(RoutePathname.colorPicker);
      }
    },
  });

  const onPurchase = async (
    productSeatsMap: ProductSeatsMap
  ): Promise<void> => {
    if (user) {
      setIsPurchasing(true);

      const { securePayload, secureKey, productIds } = await mutateAsync({
        email: user.attributes.email,
        firstName: user.attributes.given_name,
        lastName: user.attributes.family_name,
        productSeatsMap,
      });

      setSessionStorage(USED_PRODUCT_IDS_FOR_PAYLOAD, productIds);
      setSessionStorage(SUBSCRIPTION_MAP_BEFORE_SUBSCRIBE, productSeatsMap);

      handleOpenFastSpringPopup(securePayload, secureKey);
    } else if (!user && isFromPantoneWebsite) {
      setSessionStorage(SUBSCRIPTION_TYPE, productSeatsMap);
      push(`${RoutePathname.auth.welcome}?case=createAccount`);
    }
  };

  return {
    onPurchase,
    isPurchasing,
  };
}

export default usePurchaseFastSpringSubscription;
