import React from 'react';
import IconButtonLib from '@mui/material/IconButton';
import PropTypes from 'prop-types';
import { makeStyles, createStyles, useTheme } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      padding: theme.spacing(0.5),
      margin: theme.spacing(0.5),
    },
  })
);

const IconButton = React.forwardRef(({ ...props }, ref) => {
  const classes = useStyles(props);
  const theme = useTheme();
  const { color, tooltip } = props;
  let variantColor = color;
  if (theme.palette.mode === 'dark' && color === 'primary') {
    variantColor = 'primary';
  }

  const button = (
    <IconButtonLib
      {...props}
      ref={ref}
      color={variantColor}
      classes={{ root: classes.root }}
    />
  );

  return tooltip ? <Tooltip title={tooltip}>{button}</Tooltip> : button;
});

IconButton.defaultProps = {
  color: 'primary',
  tooltip: null,
};

IconButton.propTypes = {
  color: PropTypes.string,
  tooltip: PropTypes.string,
};
export default IconButton;
