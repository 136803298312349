import React from 'react';
import ButtonBase, { ButtonBaseProps } from '@mui/material/ButtonBase';
import { StyledComponent } from '@mui/styles';

type Props = ButtonBaseProps & {
  icon: React.ReactNode;
  text: React.ReactNode;
  as?: StyledComponent<Record<string, unknown>>;
  anchorEl?: React.RefObject<HTMLElement>;
};

function BaseFooterNavigation({
  icon,
  text,
  as: FooterNavigation = ButtonBase,
  anchorEl,
  ...props
}: Props) {
  return (
    <FooterNavigation {...props} ref={anchorEl}>
      {icon}
      {text}
    </FooterNavigation>
  );
}

export default BaseFooterNavigation;
